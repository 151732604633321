import { FC, useCallback, useMemo, useState } from "react";
import { Flex } from "antd";
import { Toggle } from "@ui/ui/Toggle/Toggle";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { Permission } from "@ui/app/api/groups";
import { getMainTabToggleSettings } from "@ui/pages/Main/components/MainTabs/utils/getMainTabToggleSettings";
import { MainTabProps } from "../../types";
import { MainTabCompanyNormal } from "./components/MainTabCompanyNormal";
import { MainTabCompanyOgrn } from "./components/MainTabCompanyOgrn";

type MainTabCompanyProps = MainTabProps;

export const MainTabCompany: FC<MainTabCompanyProps> = ({ onSubmit }) => {
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups[0].permissions;

  const { shouldShowToggle, initialType } = useMemo(
    () =>
      getMainTabToggleSettings<"normal", "ogrn">({
        userPermissions,
        firstPermission: Permission.ReportInnUlPermission,
        secondPermission: Permission.ReportOgrnPermission,
        firstType: "normal",
        secondType: "ogrn",
      }),
    [userPermissions],
  );

  const [type, setType] = useState<"ogrn" | "normal">(initialType);

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("ogrn");
    } else {
      setType("normal");
    }
  }, []);

  return (
    <Flex vertical gap={24}>
      {type === "normal" ? (
        <MainTabCompanyNormal onSubmit={onSubmit} />
      ) : (
        <MainTabCompanyOgrn onSubmit={onSubmit} />
      )}
      {shouldShowToggle && (
        <Toggle
          value={type === "ogrn"}
          onChange={onTypeChange}
          title="По номеру ОГРН"
        />
      )}
    </Flex>
  );
};
