import { WordType } from "./types";

const mapWordTypeToOrder: Record<WordType, number> = {
  [WordType.LastName]: 0,
  [WordType.FirstName]: 1,
  [WordType.MiddleName]: 2,
  [WordType.Other]: 3,
  [WordType.Date]: 4,
  [WordType.Age]: 5,
  [WordType.Passport]: 6,
};

export const getOtherWords = (
  wordsByType: Record<WordType, string[]>,
): string[] =>
  Object.entries(wordsByType)
    .sort((a, b) => {
      const wordTypeDelta = mapWordTypeToOrder[a[0]] - mapWordTypeToOrder[b[0]];
      return wordTypeDelta;
    })
    .map(([_type, words]) => words)
    .flat();
