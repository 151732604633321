import { LuShieldClose } from "react-icons/lu";
import { getScoringType } from "@ui/components/Scoring/utils/getScoringType";
import { ScoringType } from "@ui/components/Scoring/constants";
import { StandardReportSpec } from "../types";

interface GetStandardReportsProps {
  scoringMarker?: number;
  scoringMarkerIsSuccess: boolean;
}

export const getStandardReports = ({
  scoringMarker,
  scoringMarkerIsSuccess,
}: GetStandardReportsProps): StandardReportSpec[] => [
  {
    title: "Краткая сводка",
  },
  {
    title: "Отчеты по найденным лицам",
  },
  {
    title: "Открытые данные",
  },
  {
    title: "Пересечение границы",
  },
  {
    title: "Связанные лица",
  },
  {
    title: "Адреса",
  },
  {
    title: "Доходы",
  },
  {
    color:
      scoringMarkerIsSuccess &&
      getScoringType(scoringMarker) === ScoringType.Bad
        ? "red"
        : undefined,
    title: "Результаты скоринга",
  },
  {
    title: "Карта заказов",
  },
  {
    title: "Телефонная книга",
  },
  {
    color: "red",
    icon: <LuShieldClose size={18} />,
    title: "Метки",
  },
];
