/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Flex } from "antd";
import { Input } from "@ui/ui/Input/Input";
import { Text } from "@ui/ui/Text/Text";
import _ from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import { useReportsRetrieve } from "@ui/app/api/report/retrieve/mutations";
import { isReportType, ReportType } from "@shared/types/report/constants";
import {
  PersonInfoKeys,
  ReportsFioRetrieveResponse,
  ReportsScoringRetrieveResponse,
} from "@shared/types/report/retrieve";
import { getSourceTitleColor } from "@ui/utils/reports/getSourceTitleColor";
import s from "./ReportLayoutAsideMenu.module.scss";
import { StandardReportSpec } from "./types";
import { getStandardReports } from "./utils/getStandardReports";
// eslint-disable-next-line import/no-cycle
import { MultiQueryReportTypes } from "./components/MultiQueryReportTypes";

interface ReportLayoutAsideMenuProps {
  collapsed: boolean;
  isTablet?: boolean;
  onCollapse: (collapsed: boolean) => void;
}

export interface IReportWithDataId {
  dataId?: string;
  title: string;
}

const getColoredItem = (value: string, search: string) => {
  const lowValue = value.toLocaleLowerCase();
  const lowSearch = search.toLocaleLowerCase();
  const start = lowValue.indexOf(lowSearch);
  const end = start + search.length;
  return start !== -1 ? (
    <>
      {value.slice(0, start)}
      <span className={s.colored}>{value.slice(start, end)}</span>
      {value.slice(end, value.length)}
    </>
  ) : (
    value
  );
};

export const ReportLayoutAsideMenu: FC<ReportLayoutAsideMenuProps> = ({
  collapsed,
  onCollapse,
  isTablet,
}) => {
  const [search, setSearch] = useState<string>("");
  const [reports, setReports] = useState<IReportWithDataId[]>([]);

  const { reportId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const reportType = isReportType(type) ? type : ReportType.SEARCH_FIO;

  const { data: report, isSuccess: reportIsSuccess } = useReportsRetrieve(
    reportType,
    {
      id: reportId,
    },
  );
  const isMultiQueryReport = useMemo(
    () =>
      report &&
      (report as ReportsFioRetrieveResponse).multi_query?.result !== null,
    [report],
  );

  const scoringMarker = useMemo(() => {
    try {
      const overAll = (report as ReportsFioRetrieveResponse)?.person
        ?.person_info?.[PersonInfoKeys.scoring_result]?.over_all;
      if (typeof overAll === "number") {
        return overAll;
      }
      return (report as ReportsScoringRetrieveResponse)?.scoring_result
        ?.over_all;
    } catch {
      return undefined;
    }
  }, [report]);

  const standardReportsByTitles: Record<string, StandardReportSpec> = useMemo(
    () =>
      _.keyBy(
        getStandardReports({
          scoringMarker,
          scoringMarkerIsSuccess: reportIsSuccess,
        }),
        "title",
      ),
    [scoringMarker, reportIsSuccess],
  );

  const filteredStandardReports = useMemo(() => {
    const elements = document.querySelectorAll("div[data-name='block-report']");
    const result: StandardReportSpec[] = [];
    elements.forEach((value) => {
      const standardReportSpec = standardReportsByTitles[value.id];

      if (standardReportSpec) {
        const blockId = value.getAttribute("data-id") || undefined;
        result.push({ ...standardReportSpec, blockId });
      }
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  const loadReports = useCallback(() => {
    const elements = document.querySelectorAll("div[data-name='block-report']");
    const result: IReportWithDataId[] = [];
    const filteredStandardReportsTitles = filteredStandardReports.map(
      ({ title }) => title,
    );
    const searchLc = search.toLocaleLowerCase();

    elements.forEach((value) => {
      if (
        value.id.toLocaleLowerCase().includes(searchLc) &&
        !filteredStandardReportsTitles.includes(value.id)
      ) {
        const blockId = value.getAttribute("data-id") || undefined;
        result.push({ dataId: blockId, title: value.id });
      }
    });
    setReports(result);
  }, [filteredStandardReports, search]);

  useEffect(() => {
    loadReports();
  }, [search, collapsed, loadReports]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const summaryBlock = document.getElementById("Краткая сводка");
      if (summaryBlock) {
        onCollapse(false);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [onCollapse]);

  const scrollToBlock = useCallback(
    (block = "", dataId = "") => {
      const elements = document.querySelectorAll(
        `div[data-id=${CSS.escape(dataId)}]`,
      );
      elements.forEach((value) => {
        if (value.id === block) {
          value.scrollIntoView({
            behavior: "smooth",
          });
        }
      });
      if (isTablet) onCollapse(true);
    },
    [isTablet, onCollapse],
  );

  return (
    <div>
      {!collapsed && (
        <Flex vertical gap={16} className={s.menu}>
          <Text variant="label-4">Навигация</Text>
          <Input
            placeholder="Поиск по отчёту"
            value={search}
            onUpdate={setSearch}
          />
          {filteredStandardReports.map(({ title, icon, color, blockId }) => (
            <div
              onClick={() => scrollToBlock(title, blockId)}
              className={s.standard_item}
              key={title}
            >
              <Text variant="header-1" color={color}>
                {icon ? (
                  <Flex align="center" gap={4}>
                    {title}
                    {icon}
                  </Flex>
                ) : (
                  title
                )}
              </Text>
            </div>
          ))}
          <Flex className={s.items} vertical gap={16}>
            {reports.map(({ title, dataId }, index) => (
              <div
                onClick={() => scrollToBlock(title, dataId)}
                className={s.item}
                // eslint-disable-next-line react/no-array-index-key
                key={`${title}${index}`}
              >
                <Text variant="body-2" color={getSourceTitleColor(title)}>
                  {getColoredItem(title, search)}
                </Text>
              </div>
            ))}
          </Flex>
          {isMultiQueryReport ? (
            <Flex vertical gap={16}>
              <MultiQueryReportTypes
                scrollToBlock={scrollToBlock}
                collapsed={collapsed}
              />
            </Flex>
          ) : null}
        </Flex>
      )}
    </div>
  );
};
