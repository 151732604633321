import { FC, useCallback, useState } from "react";
import { Text } from "@ui/ui/Text/Text";

import { Flex } from "antd";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { User } from "@ui/app/api/groups";
import { Button } from "../../../../ui/Button/Button";
import s from "./SettingsModal.module.scss";
import { SettingsModalGraphReportType } from "./components/SettingsModalGraphReportType/SettingsModalGraphReportType";
import { SettingsModalUserIds } from "./components/SettingsModalUserIds/SettingsModalUserIds";
import { SettingsModalInterval } from "./components/SettingsModalInterval/SettingsModalInterval";
import { SettingsModalBlock } from "./constants";
import { SettingsModalPieReportType } from "./components/SettingsModalPieReportType/SettingsModalPieReportType";
import { SettingsModalGranularity } from "./components/SettingsModalGranularity/SettingsModalGranularity";

interface SettingsModalProps {
  visibleBlocks: Set<SettingsModalBlock>;
  defaultValue: AnalyticsFilters;
  allUserIds?: Set<number>;
  usersById: Record<User["user_id"], User>;
  onSubmit: (value: AnalyticsFilters) => void;
  onClear: () => void;
  onClose: () => void;
}

export const SettingsModal: FC<SettingsModalProps> = ({
  visibleBlocks,
  defaultValue,
  allUserIds,
  usersById,
  onSubmit,
  onClear,
  onClose,
}) => {
  const [modalValue, setModalValue] = useState<AnalyticsFilters>(defaultValue);

  const handleClearFilters = useCallback(() => {
    onClear();
    setModalValue(defaultValue);
    onSubmit(defaultValue);
  }, [onSubmit, onClear, defaultValue]);

  const submit = useCallback(() => {
    onSubmit(modalValue);
    onClose();
  }, [modalValue, onClose, onSubmit]);

  return (
    <Flex className={s.container} vertical gap={24}>
      <Text variant="header-6">Настройки отображения</Text>
      <Flex className={s.container__content} vertical gap={16}>
        {visibleBlocks.has(SettingsModalBlock.Granularity) ? (
          <SettingsModalGranularity
            modalValue={modalValue}
            setModalValue={setModalValue}
          />
        ) : null}
        {visibleBlocks.has(SettingsModalBlock.Interval) ? (
          <SettingsModalInterval
            modalValue={modalValue}
            setModalValue={setModalValue}
          />
        ) : null}
        {visibleBlocks.has(SettingsModalBlock.GraphReportType) ? (
          <SettingsModalGraphReportType
            modalValue={modalValue}
            setModalValue={setModalValue}
          />
        ) : null}
        {visibleBlocks.has(SettingsModalBlock.PieReportType) ? (
          <SettingsModalPieReportType
            modalValue={modalValue}
            setModalValue={setModalValue}
          />
        ) : null}
        {visibleBlocks.has(SettingsModalBlock.UserIds) && allUserIds ? (
          <SettingsModalUserIds
            allUserIds={allUserIds}
            usersById={usersById}
            modalValue={modalValue}
            setModalValue={setModalValue}
          />
        ) : null}
      </Flex>
      <Flex justify="flex-end" gap={24} className={s.container__buttons}>
        <Button type="secondary" onClick={handleClearFilters} size="m">
          Очистить фильтры
        </Button>
        <Button onClick={submit} size="m">
          Применить
        </Button>
      </Flex>
    </Flex>
  );
};
