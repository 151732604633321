import { Text } from "@ui/ui/Text/Text";
import { pluralize } from "@ui/utils/pluralize";
import { Button } from "@ui/ui/Button/Button";
import { useCallback } from "react";
import { useModalMessage } from "@ui/ui/Modal/useModalMessage";
import { IGroupMessage } from "@shared/types/report/retrieve/telegramInfo/constants";
import { GroupMessagesModal } from "../GroupMessagesModal/GroupMessagesModal";

export const GroupMessages = ({
  messages,
  groupName,
}: {
  messages: IGroupMessage[];
  groupName: string;
}) => {
  const { openModal, closeModal } = useModalMessage();

  const show = useCallback(
    () =>
      openModal({
        children: (
          <GroupMessagesModal messages={messages} groupName={groupName} />
        ),
        destroyOnClose: true,
        maskClosable: true,
      }),
    [openModal, closeModal],
  );

  return (
    <div>
      <Text variant="body-5">
        {messages.length}{" "}
        {pluralize({
          count: messages.length,
          oneLabel: "сообщение",
          fromTwoTillFourLabel: "сообщения",
          label: "сообщений",
        })}
      </Text>
      <Button type="text_secondary" onClick={() => show()}>
        Показать
      </Button>
    </div>
  );
};
