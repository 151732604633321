import dayjs from "dayjs";

/**
 * Order from most popular in Russia to less
 */
export const POSSIBLE_DATE_FORMATS = [
  "DD.MM.YYYY",
  "DD.MM.YY",
  "DD-MM-YYYY",
  "DD-MM-YY",
  "DD/MM/YYYY",
  "DD/MM/YY",
  "Dd-mmmm-yyyy",
  "Dd-mmmm-YYYY",

  "YYYY-MM-DD",

  "MM.DD.YYYY",
  "MM.DD.YY",
  "MM/DD/YYYY",
  "MM/DD/YY",
  "MM-DD-YYYY",
  "MM-DD-YY",
  "Mmmm-dd-yyyy",
];

interface IsValidDateProps {
  date: string;
  format?: string;
  strict?: boolean;
}

export const isValidDate = ({ date, format, strict }: IsValidDateProps) => {
  if (format) {
    return dayjs(date, format, strict).isValid();
  }

  return dayjs(date, POSSIBLE_DATE_FORMATS, strict).isValid();
};
