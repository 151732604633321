import ReactDOMServer from "react-dom/server";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { ReportType } from "@shared/types/report/constants";
import { PdfHeader } from "@shared/components/pdfLayout/components/PdfHeader/PdfHeader";
import { pdfLayout, savePdf } from "@shared/components/pdfLayout/pdfLayout";
import { StatisticBlock } from "@ui/entities/RetrieveVk/components/StatisticBlock/StatisticBlock";
import { HistoryBlock } from "@ui/entities/RetrieveVk/components/HistoryBlock/HistoryBlock";
import { FriendsHistoryBlock } from "@ui/entities/RetrieveVk/components/FriendsHistoryBlock/FriendsHistoryBlock";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { ReportsVkRetrieveResponse } from "@shared/types/report/retrieve/responses/vkRetrieve";

interface GeneratePdfParams {
  report: ReportsVkRetrieveResponse;
  type: ReportType;
  reportId: string;
}

export const buildPdf = ({ report, type, reportId }: GeneratePdfParams) => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const fullDossier = ReactDOMServer.renderToString(
    <>
      <PdfHeader requestDescribe={report.request_describe} />
      {report?.user_info?.status && (
        <FullDossier
          requestDescribe={report?.request_describe}
          vkCities={report?.user_info?.cities}
          vkCountries={report?.user_info?.countries}
          vkDobs={report?.user_info?.dobs}
          vkEmails={report?.user_info?.emails}
          vkNames={report?.user_info?.names}
          vkPhones={report?.user_info?.phones}
          type={type}
          reportId={reportId}
          note={report.note}
          status={report?.user_info?.status}
          print={RetrieveCardPrint.print}
        />
      )}
      {report?.friends_stats?.status && report?.friends_stats?.statistic && (
        <StatisticBlock
          statistic={report?.friends_stats?.statistic}
          status={report?.friends_stats?.status}
          print={RetrieveCardPrint.print}
        />
      )}
      {report?.user_info?.history?.map((history) => (
        <HistoryBlock
          key={`history-block-${history.date}`}
          history={history}
          status={report?.user_info?.status}
          print={RetrieveCardPrint.print}
        />
      ))}
      {report?.friends_stats?.friends_history?.map((friend) => (
        <FriendsHistoryBlock
          key={`friends-history-block-${friend.date}`}
          friendsHistory={friend}
          status={report?.friends_stats?.status}
          print={RetrieveCardPrint.print}
        />
      ))}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", fullDossier);
  return outputHtml;
};

export const generatePdf = (
  generatePdfParams: GeneratePdfParams,
): Promise<void> => {
  const outputHtml = buildPdf(generatePdfParams);
  return savePdf(outputHtml, generatePdfParams.reportId);
};

export const framePdf = (generatePdfParams: GeneratePdfParams) => {
  const outputHtml = buildPdf(generatePdfParams);

  const { iframe } = pdfLayout(outputHtml, generatePdfParams.reportId);

  return iframe;
};
