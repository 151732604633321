import { client } from "@ui/app/api/client";
import {
  ReportsFioRetrieveResponse,
  ReportsGrnRetrieveResponse,
  ReportsInnFlRetrieveResponse,
  ReportsPassportRetrieveResponse,
  ReportsSnilsRetrieveResponse,
  ReportsVinRetrieveResponse,
  ReportsRatingRetrieveResponse,
  ReportsEmailRetrieveResponse,
  ReportsInnUlRetrieveResponse,
  ReportsOgrnRetrieveResponse,
  ReportsPhoneRetrieveResponse,
  ReportsPhotoByFaceRetrieveResponse,
  ReportsPhotoByFioRetrieveResponse,
  ReportsRetrieveAllResponse,
  ReportsAddressRetrieveResponse,
  ReportsCreditRatingRetrieveResponse,
  ReportsScoringRetrieveResponse,
  ReportsInfoRetrieveResponse,
  ReportsKadNumberRetrieveResponse,
  ReportsFullCreditRatingRetrieveResponse,
  ReportsTelegramRetrieveResponse,
} from "@shared/types/report/retrieve/responses";
import { ReportsVkRetrieveResponse } from "@shared/types/report/retrieve/responses/vkRetrieve";
import {
  ReportsRetrieveAllRequest,
  ReportsRetrieveRequest,
} from "@shared/types/report/retrieve";
import { ReportsEndpoint } from "../endpoints";

export const reportsFioRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsFioRetrieveResponse>(
    ReportsEndpoint.FioRetrieve,
    data,
  );

export const reportsPhoneRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsPhoneRetrieveResponse>(
    ReportsEndpoint.PhoneRetrieve,
    data,
  );

export const reportsAddressRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsAddressRetrieveResponse>(
    ReportsEndpoint.AddressRetrieve,
    data,
  );

export const reportsKadNumberRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsKadNumberRetrieveResponse>(
    ReportsEndpoint.KadNumberRetrieve,
    data,
  );

export const reportsInnFlRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsInnFlRetrieveResponse>(
    ReportsEndpoint.InnFlRetrieve,
    data,
  );

export const reportsSnilsRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsSnilsRetrieveResponse>(
    ReportsEndpoint.SnilsRetrieve,
    data,
  );

export const reportsPassportRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsPassportRetrieveResponse>(
    ReportsEndpoint.PassportRetrieve,
    data,
  );

export const reportsGrnRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsGrnRetrieveResponse>(
    ReportsEndpoint.GrnRetrieve,
    data,
  );

export const reportsVinRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsVinRetrieveResponse>(
    ReportsEndpoint.VinRetrieve,
    data,
  );

export const reportsDriverLicenseRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsRatingRetrieveResponse>(
    ReportsEndpoint.DriverLicenseRetrieve,
    data,
  );

export const reportsEmailRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsEmailRetrieveResponse>(
    ReportsEndpoint.EmailRetrieve,
    data,
  );

export const reportsInnUlRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsInnUlRetrieveResponse>(
    ReportsEndpoint.InnUlRetrieve,
    data,
  );

export const reportsOgrnRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsOgrnRetrieveResponse>(
    ReportsEndpoint.OgrnRetrieve,
    data,
  );

export const reportsPhotoByFaceRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsPhotoByFaceRetrieveResponse>(
    ReportsEndpoint.PhotoByFaceRetrieve,
    data,
  );

export const reportsPhotoByFioRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsPhotoByFioRetrieveResponse>(
    ReportsEndpoint.PhotoByFioRetrieve,
    data,
  );

export const reportsCreditRatingRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsCreditRatingRetrieveResponse>(
    ReportsEndpoint.CreditRatingRetrieve,
    data,
  );

export const reportsFullCreditRatingRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsFullCreditRatingRetrieveResponse>(
    ReportsEndpoint.FullCreditRatingRetrieve,
    data,
  );

export const reportsScoringRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsScoringRetrieveResponse>(
    ReportsEndpoint.ScoringRetrieve,
    data,
  );

export const reportsInfoRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsInfoRetrieveResponse>(
    ReportsEndpoint.InfoRetrieve,
    data,
  );

export const reportsVkRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsVkRetrieveResponse>(
    ReportsEndpoint.VKRetrieve,
    data,
  );

export const reportsTelegramRetrieve = (data: ReportsRetrieveRequest) =>
  client.post<undefined, ReportsTelegramRetrieveResponse>(
    ReportsEndpoint.TelegramRetrieve,
    data,
  );

export const reportsRetrieveAll = (data: ReportsRetrieveAllRequest) =>
  client.post<undefined, ReportsRetrieveAllResponse>(
    ReportsEndpoint.RetrieveAll,
    data,
  );
