import { Flex } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { Toggle } from "@ui/ui/Toggle/Toggle";
import { mapPermissionToLabel } from "@ui/utils/permissions/constants";
import { Permission } from "@ui/app/api/groups";
import { AccessesSettingsFormValues } from "../../types";

interface AccessesSettingsPermissionReportsProps {
  control: Control<AccessesSettingsFormValues>;
  trigger: UseFormTrigger<AccessesSettingsFormValues>;
  watch: UseFormWatch<AccessesSettingsFormValues>;
  setValue: UseFormSetValue<AccessesSettingsFormValues>;
  permissions: Permission[];
}

export const AccessesSettingsPermissionReports: FC<
  AccessesSettingsPermissionReportsProps
> = ({ control, watch, setValue, permissions, trigger }) => {
  const [isAllReportsEnabled, setIsAllReportsEnabled] = useState(false);

  const watchPermissions = watch(permissions);
  const allChecked = watchPermissions.every(Boolean);

  useEffect(() => {
    setIsAllReportsEnabled((currIsAllReportsEnabled) => {
      if (currIsAllReportsEnabled !== allChecked) {
        return allChecked;
      }
      return currIsAllReportsEnabled;
    });
  }, [allChecked]);

  const onAllReportsEnabledChange = useCallback(
    async (newIsAllReportsEnabled: boolean) => {
      if (newIsAllReportsEnabled) {
        permissions.forEach((permission) => {
          setValue(permission, true);
        });
      } else if (allChecked) {
        permissions.forEach((permission) => {
          setValue(permission, false);
        });
      }

      setIsAllReportsEnabled(newIsAllReportsEnabled);
      await trigger().catch(() => {});
    },
    [setValue, trigger, allChecked, permissions],
  );

  return (
    <Flex vertical gap={8}>
      <Toggle
        value={isAllReportsEnabled}
        onChange={onAllReportsEnabledChange}
        title="Все проверки"
      />
      {permissions.map((permission) => (
        <Controller
          key={permission}
          name={permission}
          control={control}
          render={({ field, fieldState }) => (
            <Toggle
              {...field}
              validate={fieldState}
              title={mapPermissionToLabel[permission]}
            />
          )}
        />
      ))}
    </Flex>
  );
};
