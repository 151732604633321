export enum SessionDeviceType {
  Browser = "BROWSER",
}

export interface Session {
  created_at: string;
  device_type: SessionDeviceType.Browser;
  expires_at: string;
  group_id: number;
  id: string;
  ip: string;
  is_active: boolean;
  last_active_at: string;
  user_id: number;
}

export interface SessionsSearchRequest {
  group_id?: number;
}

export type SessionsSearchResponse = Session[];

export interface SessionsDeleteRequest {
  user_id: number;
}

export type SessionsDeleteResponse = unknown;

export type SessionsDeleteOtherRequest = unknown;

export type SessionsDeleteOtherResponse = unknown;
