import { StatisticBlock } from "@ui/entities/RetrieveVk/components/StatisticBlock/StatisticBlock";
import { FriendsHistoryBlock } from "@ui/entities/RetrieveVk/components/FriendsHistoryBlock/FriendsHistoryBlock";
import { HistoryBlock } from "@ui/entities/RetrieveVk/components/HistoryBlock/HistoryBlock";
import { useCallback, useMemo, useState } from "react";
import { useNotification } from "@ui/hooks/useNotification";
import { handleBackendErrors } from "@ui/utils/notifications/handleBackendErrors";
import { ReportCommonProps } from "@ui/entities/types";
import { RetrieveStatus } from "@ui/entities/RetrieveStatus/RetrieveStatus";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { ReportsVkRetrieveResponse } from "@shared/types/report/retrieve/responses/vkRetrieve";
import { ReportStatus } from "@shared/types/report/constants";
import { Photo } from "@shared/types/report/retrieve/photo";
import { framePdf, generatePdf } from "./generatePdf";
import s from "./RetrieveVk.module.scss";

export interface RetrieveVkProps extends ReportCommonProps {
  report: ReportsVkRetrieveResponse;
}

export const RetrieveVk = ({ report, type, reportId }: RetrieveVkProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const [api] = useNotification();

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId })
      .then(() => setIsLoadingGeneratePdf(false))
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
        });
      });
  }, [report, type, reportId, api]);

  const handleFramePdf = useCallback(
    async () => framePdf({ report, type, reportId }),
    [report, type, reportId],
  );

  const photo = useMemo<Photo | null | undefined>(
    () => ({
      photos: report?.user_info?.photos?.map((photoItem) => ({
        faces: [photoItem],
        score: 0,
        source: photoItem,
      })),
      found: report?.user_info?.found,
      status: report?.user_info?.status,
    }),
    [report?.user_info],
  );

  return (
    <RetrieveStatus status={ReportStatus.Finished}>
      <div className={s.container}>
        {report?.user_info?.status && (
          <FullDossier
            photo={photo}
            requestDescribe={report?.request_describe}
            vkCities={report?.user_info?.cities}
            vkCountries={report?.user_info?.countries}
            vkDobs={report?.user_info?.dobs}
            vkEmails={report?.user_info?.emails}
            vkNames={report?.user_info?.names}
            vkPhones={report?.user_info?.phones}
            type={type}
            reportId={reportId}
            note={report.note}
            status={report?.user_info?.status}
            handleGeneratePdf={handleGeneratePdf}
            handleFramePdf={handleFramePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
          />
        )}
        {report?.friends_stats?.status && report?.friends_stats?.statistic && (
          <StatisticBlock
            statistic={report?.friends_stats?.statistic}
            status={report?.friends_stats?.status}
          />
        )}
        {report?.user_info?.history?.map((history) => (
          <HistoryBlock
            key={`history-block-${history.date}`}
            history={history}
            status={report?.user_info?.status}
          />
        ))}
        {report?.friends_stats?.friends_history?.map((friend) => (
          <FriendsHistoryBlock
            key={`friends-history-block-${friend.date}`}
            friendsHistory={friend}
            status={report?.friends_stats?.status}
          />
        ))}
      </div>
    </RetrieveStatus>
  );
};
