import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { groups_remove_member } from "../queries";
import { GroupsRemoveMemberRequest } from "../types";

export const useGroupsRemoveMember = () =>
  useMutation({
    mutationFn: (data: GroupsRemoveMemberRequest) => groups_remove_member(data),
    mutationKey: ["groupsRemoveMember"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
