import { useQuery } from "@tanstack/react-query";

import { groups } from "../queries";

interface UseGroupsProps {
  enabled?: boolean;
}

export const useGroups = ({ enabled }: UseGroupsProps = {}) => {
  const response = useQuery({
    enabled,
    queryFn: groups,
    queryKey: ["groups"],
  });
  return {
    ...response,
    data: response.data,
  };
};
