import { Context, useContext } from "react";

export const useAssertedContext = <T>(context: Context<T | null>): T => {
  const value = useContext<T | null>(context);

  if (!value) {
    throw new Error("Use context inside his provider");
  }

  return value;
};
