import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useCallback,
  useId,
  useState,
} from "react";

import { Flex } from "antd";
import { useLogin } from "@ui/app/api/auth";
import { Text } from "@ui/ui/Text/Text";
import { Button } from "@ui/ui/Button/Button";
import { AxiosError } from "axios";
import { handleBackendErrors } from "@ui/utils/notifications/handleBackendErrors";
import { useNotification } from "@ui/hooks/useNotification";
import s from "./AuthFormConfirmCode.module.scss";

interface AuthFormConfirmCodeProps {
  onSuccess: () => void;
  email: string;
  password: string;
}

export const AuthFormConfirmCode: FC<AuthFormConfirmCodeProps> = ({
  onSuccess,
  email,
  password,
}) => {
  const [code, setCode] = useState(Array(6).fill(""));

  const id = useId();
  const getCellId = useCallback((index: number) => `${id}_${index}`, [id]);

  const handleChange = useCallback(
    (index: number): ChangeEventHandler<HTMLInputElement> =>
      (e) => {
        const newCode = [...code];
        newCode[index] = e.target.value.slice(0, 1);
        setCode(newCode);

        if (e.target.value.length === 1 && index < 5) {
          const nextElemId = getCellId(index + 1);

          document.getElementById(nextElemId)?.focus();
        }
      },
    [code, getCellId],
  );

  const handleKeyDown = useCallback(
    (index: number): KeyboardEventHandler<HTMLInputElement> =>
      (e) => {
        if (
          e.code === "Backspace" &&
          e.currentTarget.value.length === 0 &&
          index > 0
        ) {
          const prevElemId = getCellId(index - 1);

          document.getElementById(prevElemId)?.focus();
        }
      },
    [getCellId],
  );

  const resendMailConfirm = useCallback(() => {
    // TODO implement
    // eslint-disable-next-line no-console
    console.log("resendMailConfirm");
  }, []);

  // const { mutateAsync: verifyCode } = useVerifyCode();
  const verifyCode = useCallback((data: unknown) => Promise.resolve(data), []);

  const { mutateAsync: signIn } = useLogin();

  const [api] = useNotification();

  const onConfirm = useCallback(() => {
    if (!email) return;
    const data = { email, verification_code: code.join("") };

    verifyCode(data)
      .then(() => {
        signIn({
          login: email,
          password,
        })
          .catch(() => {})
          .finally(() => {
            api.error({
              message: "Ваша почта успешно подтверждена!",
            });
            onSuccess();
          });
      })
      .catch((error: AxiosError) => {
        const isIncorrectCode = error.status === 400;

        if (isIncorrectCode) {
          api.error({
            message: "Неверный код",
          });
          return;
        }

        handleBackendErrors({
          api,
          error,
        });
      });
  }, [code, email, onSuccess, verifyCode, api, password, signIn]);

  return (
    <Flex component="section" vertical gap={24}>
      <Flex vertical gap={4}>
        <Text variant="header-6">Введите код</Text>
        <Text variant="body-4">
          Мы отправили код подтверждения на почту {email}
        </Text>
      </Flex>
      <Flex gap={16}>
        {code.map((_, index) => {
          const elemId = getCellId(index);

          return (
            <input
              // Always 6 elements
              key={elemId}
              id={elemId}
              className={s.cell}
              type="text"
              maxLength={1}
              value={code[index]}
              onChange={handleChange(index)}
              onKeyDown={handleKeyDown(index)}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          );
        })}
      </Flex>
      <Button onClick={onConfirm}>Подтвердить</Button>
      <Button onClick={resendMailConfirm} type="text">
        Отправить код повторно
      </Button>
    </Flex>
  );
};
