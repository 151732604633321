import {
  IndicatorIconEnum,
  IndicatorModel,
} from "@ui/components/Indicator/Indicator";

export const getCreditRatingIndicatorModel = (
  marker?: number,
): IndicatorModel => {
  if (!marker)
    return {
      text: "дефолт",
      indicatorIcon: IndicatorIconEnum.cancel,
      color: "red",
    };

  if (marker >= 961)
    return {
      text: "Отличная оценка",
      indicatorIcon: IndicatorIconEnum.check,
      color: "accent",
    };

  if (marker <= 960 && marker >= 801)
    return {
      text: "стандартный балл",
      indicatorIcon: IndicatorIconEnum.check,
      color: "accent",
    };

  if (marker <= 800 && marker >= 721)
    return {
      text: "средняя оценка",
      indicatorIcon: IndicatorIconEnum.normal,
      color: "warning",
    };

  if (marker <= 720 && marker >= 641)
    return {
      text: "ниже среднего",
      indicatorIcon: IndicatorIconEnum.normal,
      color: "warning",
    };

  if (marker <= 640 && marker >= 300)
    return {
      text: "низкая оценка",
      indicatorIcon: IndicatorIconEnum.cancel,
      color: "red",
    };

  return {
    text: "дефолт",
    indicatorIcon: IndicatorIconEnum.cancel,
    color: "red",
  };
};
