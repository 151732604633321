import React, { FC, useCallback, useMemo, useState } from "react";
import FilterSortModal from "@ui/ui/FilterSortModal/FilterSortModal";
import { ReportType } from "@shared/types/report/constants";
import { Flex } from "antd";
import { CurrentUser, User } from "@ui/app/api/groups";
import { isUserAdministrator } from "@ui/utils/permissions/isUserAdministrator";
import { HistoryListSettingsStorage } from "../HistoryListFiltersStorage";
import { EHistoryListSetting, EHistoryListSubSetting } from "../../types";
import { FilterRulesList } from "./components/FilterRulesList";
import { Text } from "../../../../ui/Text/Text";
import { UserIdsList } from "./components/UserIdsList";

interface IHistoryListFiltersModalProps {
  setActiveFilterRules: (selectedFilterRules: ReportType[]) => void;
  activeFilterRules: ReportType[];
  activeUserIds: number[];
  setActiveUserIds: (selectedUserIds: number[]) => void;
  users?: User[];
  user?: CurrentUser;
}

const HistoryListFiltersModal: FC<IHistoryListFiltersModalProps> = ({
  setActiveFilterRules,
  activeFilterRules,
  activeUserIds,
  setActiveUserIds,
  users,
  user,
}) => {
  const isCurrentUserAdministrator = isUserAdministrator(user);

  const [selectedFilterRules, setSelectedFilterRules] = useState<ReportType[]>(
    [],
  );
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const [
    isShowAllFilterRulesCheckboxChecked,
    setIsShowAllFilterRulesCheckboxChecked,
  ] = useState(true);
  const [isShowAllUserIdsCheckboxChecked, setIsShowAllUserIdsCheckboxChecked] =
    useState(true);

  useMemo(() => {
    setSelectedFilterRules(activeFilterRules);
    setSelectedUserIds(activeUserIds);
    setIsShowAllFilterRulesCheckboxChecked(activeFilterRules.length === 0);
    setIsShowAllUserIdsCheckboxChecked(activeUserIds.length === 0);
  }, [activeFilterRules, activeUserIds]);

  const handleShowAllFilterRulesCheckboxCheck = useCallback(
    (checked: boolean) => {
      setIsShowAllFilterRulesCheckboxChecked(checked);
      if (checked) setSelectedFilterRules([]);
    },
    [],
  );
  const handleShowAllUserIdsCheckboxCheck = useCallback((checked: boolean) => {
    setIsShowAllUserIdsCheckboxChecked(checked);
    if (checked) setSelectedUserIds([]);
  }, []);

  const handleApplyFilters = useCallback(() => {
    setActiveFilterRules(selectedFilterRules);
    HistoryListSettingsStorage.set(
      EHistoryListSetting.FILTERS,
      JSON.stringify(selectedFilterRules),
      EHistoryListSubSetting.REPORT_TYPE,
    );

    setActiveUserIds(selectedUserIds);
    HistoryListSettingsStorage.set(
      EHistoryListSetting.FILTERS,
      JSON.stringify(selectedUserIds),
      EHistoryListSubSetting.USER_IDS,
    );
  }, [
    selectedFilterRules,
    selectedUserIds,
    setActiveFilterRules,
    setActiveUserIds,
  ]);

  return (
    <FilterSortModal
      modalTitle="Фильтр"
      title="Фильтры"
      onApply={handleApplyFilters}
      onCancel={() => {
        setSelectedFilterRules(activeFilterRules);
        setSelectedUserIds(activeUserIds);
      }}
    >
      <Flex vertical gap={16}>
        <Flex vertical gap={12}>
          <Text variant="subheader-4">Показывать типы проверки:</Text>
          <FilterRulesList
            selectedFilterRules={selectedFilterRules}
            setIsShowAllFilterRulesCheckboxChecked={
              setIsShowAllFilterRulesCheckboxChecked
            }
            setSelectedFilterRules={setSelectedFilterRules}
            checked={isShowAllFilterRulesCheckboxChecked}
            onUpdate={handleShowAllFilterRulesCheckboxCheck}
          />
        </Flex>
        {user && isCurrentUserAdministrator && users && (
          <Flex vertical gap={12}>
            <Text variant="subheader-4">Показывать проверки сотрудника:</Text>
            <UserIdsList
              users={users}
              selectedUserIds={selectedUserIds}
              setIsShowAllUserIdsCheckboxChecked={
                setIsShowAllUserIdsCheckboxChecked
              }
              setSelectedUserIds={setSelectedUserIds}
              checked={isShowAllUserIdsCheckboxChecked}
              onUpdate={handleShowAllUserIdsCheckboxCheck}
            />
          </Flex>
        )}
      </Flex>
    </FilterSortModal>
  );
};

export default HistoryListFiltersModal;
