import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@ui/entities/types";
import { RetrieveVk } from "@ui/entities/RetrieveVk/RetrieveVk";
import { useReportsVkRetrieve } from "@ui/app/api/report/retrieve/mutations";

export const SearchVkWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: vkReport, isLoading } = useReportsVkRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {vkReport && (
        <RetrieveVk report={vkReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
