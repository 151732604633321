import { Text } from "@ui/ui/Text/Text";
import { FC } from "react";
import dayjs from "dayjs";
import { CamrobotItem } from "@shared/types/report/retrieve";
import { ReportStatus } from "@shared/types/report/constants";
import s from "./Groups.module.scss";
import { RetrieveCard } from "../../../../../../../components/RetrieveCard/RetrieveCard";
import { RetrieveCardVariant } from "../../../../../../../components/RetrieveCard/types";

interface IGroupsProps {
  camrobot?: CamrobotItem[];
}

const UserNames = ({ userNames }: { userNames: CamrobotItem["userNames"] }) =>
  userNames &&
  userNames.length > 0 && (
    <RetrieveCard
      title="Никнеймы"
      variant={RetrieveCardVariant.noBorder}
      status={ReportStatus.Finished}
      className={s.dropdownContainer}
      titleClassName={s.dropdownContainer_title}
      defaultOpen={false}
    >
      {userNames.map((userName) => (
        <span key={userName.userName} className={s.dropdownContainer__group}>
          <div className={s.container__list__item}>
            <Text variant="body-1" color="secondary">
              Никнейм
            </Text>
            <Text>{userName.userName}</Text>
          </div>
          <div className={s.container__list__item}>
            <Text variant="body-1" color="secondary">
              Дата
            </Text>
            <Text>{dayjs(userName.dt).format("DD.MM.YYYY")}</Text>
          </div>
          {userNames.length > 1 && <span className={s.separator} />}
        </span>
      ))}
    </RetrieveCard>
  );

export const Groups: FC<IGroupsProps> = ({ camrobot }) => (
  <div className={s.container}>
    <Text variant="subheader-5">Группы и чаты</Text>
    <div className={s.container__list}>
      {camrobot?.map((c) => (
        <>
          {c.firstName && (
            <div className={s.container__list__item}>
              <Text variant="subheader-1" color="secondary">
                Имя
              </Text>
              <Text variant="label-4">{c.firstName}</Text>
            </div>
          )}
          {c.lastName && (
            <div className={s.container__list__item}>
              <Text variant="subheader-1" color="secondary">
                Фамилия
              </Text>
              <Text variant="label-4">{c.lastName}</Text>
            </div>
          )}
          {c.names && (
            <div className={s.container__list__item}>
              <Text variant="subheader-1" color="secondary">
                Имена
              </Text>
              <Text variant="label-4">{c.names}</Text>
            </div>
          )}
          {c.addresses && (
            <div className={s.container__list__item}>
              <Text variant="subheader-1" color="secondary">
                Адреса
              </Text>
              <Text variant="label-4">{c.addresses}</Text>
            </div>
          )}
          {c.tags && (
            <div className={s.container__list__item}>
              <Text variant="subheader-1" color="secondary">
                Теги
              </Text>
              <Text variant="label-4">{c.tags}</Text>
            </div>
          )}
          <div className={s.container__list__item}>
            <Text variant="subheader-1" color="secondary">
              Бот
            </Text>
            <Text variant="label-4">{c.bot ? "Да" : "Нет"}</Text>
          </div>
          <UserNames userNames={c.userNames} />
          {c.phones && c.phones.length > 0 && (
            <RetrieveCard
              title="Номера телефонов"
              variant={RetrieveCardVariant.noBorder}
              status={ReportStatus.Finished}
              className={s.dropdownContainer}
              titleClassName={s.dropdownContainer_title}
              defaultOpen={false}
            >
              {c.phones.map((phone) => (
                <span key={phone.phone} className={s.dropdownContainer__group}>
                  <div className={s.container__list__item}>
                    <Text variant="body-1" color="secondary">
                      Номер телефона
                    </Text>
                    <Text>{phone.phone}</Text>
                  </div>
                  <div className={s.container__list__item}>
                    <Text variant="body-1" color="secondary">
                      Регион
                    </Text>
                    <Text>{phone.region}</Text>
                  </div>
                  <div className={s.container__list__item}>
                    <Text variant="body-1" color="secondary">
                      Имена
                    </Text>
                    <Text>{phone.names}</Text>
                  </div>
                  <div className={s.container__list__item}>
                    <Text variant="body-1" color="secondary">
                      Адреса
                    </Text>
                    <Text>{phone.addresses}</Text>
                  </div>
                  <div className={s.container__list__item}>
                    <Text variant="body-1" color="secondary">
                      Дата
                    </Text>
                    <Text>{dayjs(phone.date).format("DD.MM.YYYY")}</Text>
                  </div>
                  {c.phones.length > 1 && <span className={s.separator} />}
                </span>
              ))}
            </RetrieveCard>
          )}
          <span className={s.separator} />
          {c.groups &&
            c.groups.length > 0 &&
            c.groups.map((group) => {
              const { channel } = group;

              return (
                <RetrieveCard
                  key={group.id}
                  title={group.title}
                  variant={RetrieveCardVariant.noBorder}
                  status={ReportStatus.Finished}
                  className={s.dropdownContainer}
                  titleClassName={s.dropdownContainer_title}
                  defaultOpen={false}
                >
                  {group.joined && (
                    <div className={s.container__list__item}>
                      <Text variant="body-1" color="secondary">
                        Дата вступления
                      </Text>
                      <Text>{dayjs(group.joined).format("DD.MM.YYYY")}</Text>
                    </div>
                  )}
                  {group.count && (
                    <div className={s.container__list__item}>
                      <Text variant="body-1" color="secondary">
                        Количество участников
                      </Text>
                      <Text>{group.count}</Text>
                    </div>
                  )}
                  {group.username && (
                    <div className={s.container__list__item}>
                      <Text variant="body-1" color="secondary">
                        Никнейм
                      </Text>
                      <Text>{group.username}</Text>
                    </div>
                  )}
                  <UserNames userNames={group.userNames} />
                  {channel.title && (
                    <RetrieveCard
                      title={channel.title}
                      variant={RetrieveCardVariant.noBorder}
                      status={ReportStatus.Finished}
                      className={s.dropdownContainer}
                      titleClassName={s.dropdownContainer_title}
                      defaultOpen={false}
                    >
                      {channel.username && (
                        <div className={s.container__list__item}>
                          <Text variant="body-1" color="secondary">
                            Никнейм
                          </Text>
                          <Text>{channel.username}</Text>
                        </div>
                      )}
                      <UserNames userNames={channel.userNames} />
                    </RetrieveCard>
                  )}
                </RetrieveCard>
              );
            })}
        </>
      ))}
    </div>
  </div>
);
