import { Flex } from "antd";
import { Block } from "@ui/ui/Block/Block";
// import { AccessesApi } from './components/AccessesApi/AccessesApi';
import { AppRoute } from "@ui/app/routes/constants";
import { useParams } from "react-router-dom";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { AccessesSettings } from "@ui/components/AccessesSettings/AccessesSettings";
import { useGroup } from "@ui/app/api/groups/queries/useGroup";
import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import s from "./AccessesAdd.module.scss";

const AccessesAdd = () => {
  const { data: user } = useCurrentUserQuery();
  const { group_id } = useParams();
  const { data: group } = useGroup(
    { group_id: Number(group_id) },
    { enabled: Boolean(group_id) },
  );

  if (!user || !group) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Новый доступ"
        mapPathToTitle={{
          [AppRoute.Accesses]: "Доступы",
          [AppRoute.AccessesAdd]: "Новый доступ",
        }}
        paths={[AppRoute.Accesses, AppRoute.AccessesAdd]}
      />
      <Block>
        <Flex vertical gap={60}>
          <AccessesSettings group={group} />
        </Flex>
      </Block>
      <div className={s.wrapper__dummyDrawer} />
    </Flex>
  );
};

export default AccessesAdd;
