import { Text } from "@ui/ui/Text/Text";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import cn from "classnames";
import { PhoneBookItem } from "@shared/types/report/retrieve";
import s from "./PhoneBook.module.scss";

export type PhoneBookProps = {
  phoneBook?: PhoneBookItem[];
} & RetrieveCardCommonProps;

export const PhoneBook = ({
  phoneBook,
  status,
  print = RetrieveCardPrint.device,
}: PhoneBookProps) => {
  if (!phoneBook || phoneBook?.length < 1) return null;

  return (
    <RetrieveCard title="Телефонная книга" status={status} print={print}>
      <div
        className={cn(
          s.container,
          print === RetrieveCardPrint.print && s.container_print,
        )}
      >
        <Text color="secondary" variant="subheader-2">
          Записан
        </Text>
        <Text color="secondary" variant="subheader-2">
          Номер телефонной книжки
        </Text>

        {phoneBook?.map((phone: PhoneBookItem) => (
          <>
            <Text variant="header-1">#{phone.name}</Text>
            <Text variant="header-1">{phone.phone}</Text>
          </>
        ))}
      </div>
    </RetrieveCard>
  );
};
