import { Text } from "@ui/ui/Text/Text";
import { Flex } from "antd";
import s from "./Row.module.scss";

export interface RowProps {
  prop: string;
  value?: string;
  properties?: Record<string, string | number>;
}

export const Row = ({ prop, value, properties }: RowProps) => (
  <div className={s.container}>
    <Text variant="label-1" color="secondary">
      {prop}
    </Text>
    {Boolean(value) && <Text variant="header-1">{value}</Text>}
    {Boolean(properties) && properties && (
      <Flex vertical>
        {Object.keys(properties).map((property) => (
          <>
            {Boolean(properties[property]) && (
              <Text variant="header-1">
                <b>{property}</b> {properties[property]}
              </Text>
            )}
          </>
        ))}
      </Flex>
    )}
  </div>
);
