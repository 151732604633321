import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { PageSearchPointer } from "@ui/components/PageSearchPointer/PageSearchPointer";
import { Text } from "@ui/ui/Text/Text";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import cn from "classnames";
import { Income } from "@shared/types/report/retrieve";
import { ReportStatus } from "@shared/types/report/constants";
import s from "./Incomes.module.scss";

type IncomesProps = {
  incomes?: Income[] | null;
} & RetrieveCardCommonProps;

export const Incomes = ({
  incomes,
  status,
  print = RetrieveCardPrint.device,
}: IncomesProps) => {
  if (
    status === ReportStatus.Finished &&
    (!incomes || (incomes && incomes?.length < 1))
  )
    return null;
  return (
    <RetrieveCard dataId={6} title="Доходы" status={status} print={print}>
      <div className={s.container}>
        <div
          className={cn(
            s.container__table,
            print === RetrieveCardPrint.print && s.container__table_print,
          )}
        >
          <div className={s.container__table__item}>
            <Text color="secondary" variant="body-1">
              Место работы
            </Text>
            <Text color="secondary" variant="body-1">
              Сумма
            </Text>
            <Text
              color="secondary"
              variant="body-1"
              className={cn(
                print === RetrieveCardPrint.device && s.container__table__right,
              )}
            >
              Год актуальности
            </Text>
          </div>
          {incomes?.map((income: Income, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={s.container__table__item} key={index}>
              <PageSearchPointer title={income.work_place} />
              <Text variant="body-5">{income.sum && `${income.sum} р.`}</Text>
              <Text
                variant="body-5"
                className={cn(
                  print === RetrieveCardPrint.device &&
                    s.container__table__right,
                )}
              >
                {" "}
                {income.year}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </RetrieveCard>
  );
};
