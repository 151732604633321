import { PersonInfo } from "./person";
import { ReportStatus, ReportType } from "../constants";

export enum EMultiQueryKeys {
  email = "email",
  fio = "fio",
  grn = "grn",
  inn = "inn",
  passport = "passport",
  phone = "phone",
  snils = "snils",
}

export interface MultiQuery {
  status: ReportStatus;
  found: number;
  result: Record<EMultiQueryKeys, Record<string, PersonInfo>>;
}

export const mapMultiQueryKeyToTitle: Record<EMultiQueryKeys, string> = {
  [EMultiQueryKeys.email]: "Почты",
  [EMultiQueryKeys.fio]: "ФИО и ДР",
  [EMultiQueryKeys.grn]: "Автомобили",
  [EMultiQueryKeys.inn]: "ИНН",
  [EMultiQueryKeys.passport]: "Паспорта",
  [EMultiQueryKeys.phone]: "Телефоны",
  [EMultiQueryKeys.snils]: "СНИЛС",
};

export const mapMultiQueryKeyToReportType: Record<EMultiQueryKeys, ReportType> =
  {
    [EMultiQueryKeys.email]: ReportType.SEARCH_EMAIL,
    [EMultiQueryKeys.fio]: ReportType.SEARCH_FIO,
    [EMultiQueryKeys.grn]: ReportType.SEARCH_GRN,
    [EMultiQueryKeys.inn]: ReportType.SEARCH_INN_FL,
    [EMultiQueryKeys.passport]: ReportType.SEARCH_PASSPORT,
    [EMultiQueryKeys.phone]: ReportType.SEARCH_PHONE,
    [EMultiQueryKeys.snils]: ReportType.SEARCH_SNILS,
  };
