import { FC, PropsWithChildren } from "react";
import cn from "classnames";
import s from "./Block.module.scss";

export interface BlockProps extends PropsWithChildren {
  className?: string;
  id?: string;
  dataName?: string;
  dataId?: string;
}

export const Block: FC<BlockProps> = ({
  children,
  className,
  id,
  dataName,
  dataId,
}) => (
  <div
    id={id}
    data-id={dataId}
    data-name={dataName || "block-report"}
    className={cn(s.block, className)}
  >
    {children}
  </div>
);
