import { FC, useCallback } from "react";
import { Text } from "@ui/ui/Text/Text";

import { Flex } from "antd";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { StatisticsGranularity } from "@ui/app/api/analytics/types";
import { Checkbox } from "@ui/ui/Checkbox/Checkbox";
import { mapGranularityToLabel } from "@ui/pages/Analytics/constants";

const allGranularity: StatisticsGranularity[] = Object.values(
  StatisticsGranularity,
);

interface SettingsModalGranularityProps {
  modalValue: AnalyticsFilters;
  setModalValue: (
    value:
      | AnalyticsFilters
      | ((prevValue: AnalyticsFilters) => AnalyticsFilters),
  ) => void;
}

export const SettingsModalGranularity: FC<SettingsModalGranularityProps> = ({
  modalValue,
  setModalValue,
}) => {
  const onToggleGranularity = useCallback(
    (granularity: StatisticsGranularity, checked: boolean) => {
      if (!checked) return;

      setModalValue((prevFilters) => ({
        ...prevFilters,
        granularity,
      }));
    },
    [setModalValue],
  );

  return (
    <Flex vertical gap={12}>
      <Text variant="label-4">Агрегировать:</Text>
      <Flex vertical gap={8}>
        {allGranularity.map((granularity) => (
          <Checkbox
            key={granularity}
            checked={modalValue.granularity === granularity}
            onUpdate={(checked) => onToggleGranularity(granularity, checked)}
          >
            {mapGranularityToLabel[granularity]}
          </Checkbox>
        ))}
      </Flex>
    </Flex>
  );
};
