import { MainTab } from "@ui/pages/Main/constants";

const TabsWithMultiQuery = [
  MainTab.Phone,
  MainTab.Email,
  MainTab.Passport,
  MainTab.Photo,
];

export const isTabWithMultiQuery = (tab: MainTab) =>
  TabsWithMultiQuery.includes(tab);
