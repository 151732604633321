import { FC, SetStateAction } from "react";
import { Flex } from "antd";
import { User } from "@ui/app/api/groups";
import { Checkbox } from "@ui/ui/Checkbox/Checkbox";
import { IShowAllCheckboxProps, ShowAllCheckbox } from "./ShowAllCheckbox";
import { handleCheckboxCheck } from "./utils/handleCheckboxCheck";

interface IUserIdsListProps extends IShowAllCheckboxProps {
  selectedUserIds: number[];
  setSelectedUserIds: (value: SetStateAction<number[]>) => void;
  setIsShowAllUserIdsCheckboxChecked: (value: boolean) => void;
  users: User[];
}

export const UserIdsList: FC<IUserIdsListProps> = ({
  selectedUserIds,
  setSelectedUserIds,
  setIsShowAllUserIdsCheckboxChecked,
  checked: showAllCheckboxChecked,
  onUpdate,
  users,
}) => (
  <Flex vertical gap={9.5}>
    <ShowAllCheckbox checked={showAllCheckboxChecked} onUpdate={onUpdate} />
    {users.map((user) => (
      <Checkbox
        key={user.user_id}
        checked={selectedUserIds.includes(user.user_id)}
        onChange={(e) => {
          const { checked } = e.target;
          handleCheckboxCheck(
            checked,
            user.user_id,
            setSelectedUserIds,
            setIsShowAllUserIdsCheckboxChecked,
          );
        }}
      >
        {user.name}
      </Checkbox>
    ))}
  </Flex>
);
