import { FC, PropsWithChildren, useCallback, useId } from "react";

import { Radio as BaseRadio } from "antd";
import cn from "classnames";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import s from "./Radio.module.scss";

export interface RadioProps extends PropsWithChildren {
  className?: string;
  checked?: boolean;
  onUpdate: (value: boolean) => void;
  title?: string;
  disabled?: boolean;
}

export const Radio: FC<RadioProps> = ({
  className,
  checked,
  title,
  onUpdate,
  disabled,
  children,
}) => {
  const id = useId();

  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      onUpdate(e.target.checked);
    },
    [onUpdate],
  );

  return (
    <BaseRadio
      id={id}
      checked={checked}
      title={title}
      onChange={onChange}
      className={cn(s.radio, className)}
      disabled={disabled}
    >
      {children}
    </BaseRadio>
  );
};
