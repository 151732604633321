export enum ReportsPersonInfoDataEnum {
  full_name = "full_name",
  birth_date = "birth_date",
  inn = "inn",
  passport = "passport",
  phone = "phone",
  snils = "snils",
  grn = "grn",
}

export const reportsPersonInfoDataWithTitles: Record<
  ReportsPersonInfoDataEnum,
  string
> = {
  [ReportsPersonInfoDataEnum.full_name]: "Полное имя",
  [ReportsPersonInfoDataEnum.birth_date]: "Дата рождения",
  [ReportsPersonInfoDataEnum.inn]: "Инн",
  [ReportsPersonInfoDataEnum.passport]: "Паспорт",
  [ReportsPersonInfoDataEnum.phone]: "Телефон",
  [ReportsPersonInfoDataEnum.snils]: "СНИЛС",
  [ReportsPersonInfoDataEnum.grn]: "Гос. номер транспорта",
};
