import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { Flex } from "antd";
import { ReportStatus } from "@shared/types/report/constants";
import { calculateReportStatus } from "@ui/utils/getReportStatus";
import { Text } from "@ui/ui/Text/Text";
import { formatDate } from "@ui/utils/date/formatDate";
import { IGroup } from "@shared/types/report/retrieve/telegramInfo/constants";
import { Input } from "@ui/ui/Input/Input";
import debounce from "lodash/debounce";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "../RetrieveCard/RetrieveCard";
import { RetrieveCardPrint } from "../RetrieveCard/types";
import { GroupMessages } from "./components/GroupMessages/GroupMessages";
import s from "./TelegramGroup.module.scss";

type TTelegramGroupProps = {
  groups?: IGroup[];
} & RetrieveCardCommonProps;

export const TelegramGroup: FC<TTelegramGroupProps> = ({
  groups,
  print = RetrieveCardPrint.device,
}) => {
  const groupsStatuses = groups?.map((group) => group.status);
  const groupsStatus =
    (groupsStatuses &&
      calculateReportStatus({ sourceStatuses: groupsStatuses })) ||
    ReportStatus.Finished;

  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const onDebouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 500),
    [],
  );

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
      onDebouncedSearch(e.currentTarget.value);
    },
    [onDebouncedSearch],
  );

  const groupsArrayFiltered: IGroup[] | null = useMemo(() => {
    const searchTermLc = searchTerm.toLowerCase();
    if (!groups || groups?.length === 0) return null;

    return groups
      ?.filter((group: IGroup) =>
        `${group?.title} ${group?.joined} ${group?.id}`
          .toLowerCase()
          .includes(searchTermLc),
      )
      .filter((group) => group !== null);
  }, [searchTerm, groups]);

  return (
    <RetrieveCard
      dataId={9}
      title="Группы"
      bottomTitle={
        <Input value={search} placeholder="Поиск" onChange={onSearch} />
      }
      status={groupsStatus}
      print={print}
    >
      <Flex vertical gap={8} className={s.tableContainer}>
        <Flex gap={32} className={s.tableContainer__table}>
          <Text color="secondary" variant="body-1">
            Название
          </Text>
          <Text color="secondary" variant="body-1">
            ID группы
          </Text>
          <Text color="secondary" variant="body-1">
            Дата подключения
          </Text>
          <Text color="secondary" variant="body-1">
            Сообщения
          </Text>
        </Flex>
        {groupsArrayFiltered?.map((group) => (
          <Flex gap={32} key={group.id} className={s.tableContainer__table}>
            <Text variant="body-5">{group.title}</Text>
            <Text variant="body-5">{group.id}</Text>
            <Text variant="body-5">{formatDate(group.joined)}</Text>
            {group.messages && group.messages.length > 0 && (
              <GroupMessages
                messages={group.messages}
                groupName={group.title}
              />
            )}
          </Flex>
        ))}
      </Flex>
    </RetrieveCard>
  );
};
