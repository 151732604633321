import { CrossingBorderItemEnum } from "@shared/types/report/retrieve";
import { FC, SVGProps } from "react";

import { ReactComponent as Event } from "src/assets/event.svg";
import { ReactComponent as DateRange } from "src/assets/date_range.svg";
import { ReactComponent as FlightLand } from "src/assets/flight_land.svg";
import { ReactComponent as FlightTakeOff } from "src/assets/flight_takeoff.svg";
import { ReactComponent as MenuBook } from "src/assets/menu_book.svg";
import { ReactComponent as Flag } from "src/assets/flag.svg";
import {
  MdNavigation,
  MdCompareArrows,
  MdInfo,
  MdOutlineConfirmationNumber,
  MdLocationCity,
} from "react-icons/md";

export const mapCrossingBorderItemEnumToIcon: Record<
  CrossingBorderItemEnum,
  FC<SVGProps<SVGSVGElement>>
> = {
  [CrossingBorderItemEnum.birthDate]: Event,
  [CrossingBorderItemEnum.crossingDate]: DateRange,
  [CrossingBorderItemEnum.destinationCountry]: FlightLand,
  [CrossingBorderItemEnum.documentCountry]: Flag,
  [CrossingBorderItemEnum.documentIssue_Date]: Event,
  [CrossingBorderItemEnum.documentNumber]: MenuBook,
  [CrossingBorderItemEnum.dstCity]: FlightLand,
  [CrossingBorderItemEnum.grn]: MenuBook,
  [CrossingBorderItemEnum.info]: MdInfo,
  [CrossingBorderItemEnum.operation]: MdCompareArrows,
  [CrossingBorderItemEnum.punkt]: MdLocationCity,
  [CrossingBorderItemEnum.sourceCountry]: FlightTakeOff,
  [CrossingBorderItemEnum.srcCity]: FlightTakeOff,
  [CrossingBorderItemEnum.transportType]: MdNavigation,
  [CrossingBorderItemEnum.tripNum]: MdOutlineConfirmationNumber,
};
