import { Text } from "@ui/ui/Text/Text";
import { Legend } from "@ui/pages/Analytics/components/PieBlock/Legend/Legend";
import {
  searchCriteriaColors,
  searchCriteriaDescriptions,
} from "@ui/pages/Analytics/components/PieBlock/constants";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { useWindowSize } from "react-use";
import { Flex, Skeleton } from "antd";
import { FC, useMemo } from "react";
import { Button } from "@ui/ui/Button/Button";
import { VscSettings } from "react-icons/vsc";
import { useGroupStatisticsByType } from "@ui/app/api/analytics/queries/useStatisticsGroupByType";
import { PieChartComponent } from "./PieChartComponent/PieChartComponent";
import s from "./PieBlock.module.scss";
import { LegendHint } from "../LegendHint/LegendHint";
import { PieItems } from "./PieItems/PieItems";

interface PieBlockProps {
  group_id: number;
  filters: AnalyticsFilters;
  onOpenSettings: () => void;
}

export const PieBlock: FC<PieBlockProps> = ({
  filters,
  group_id,
  onOpenSettings,
}) => {
  const { data: statisticsGroupByType } = useGroupStatisticsByType(
    {
      granularity: filters.granularity,
      date_from: filters.startDate,
      date_to: filters.endDate,
      group_id,
      users: filters.userIds ? Array.from(filters.userIds) : undefined,
      types: filters.pieReportType
        ? Array.from(filters.pieReportType)
        : undefined,
    },
    {
      enabled: Boolean(group_id),
    },
  );
  const items = useMemo(
    () =>
      statisticsGroupByType?.items.map(({ type, value }) => ({
        value,
        name: searchCriteriaDescriptions[type] || type,
        color: searchCriteriaColors[type] || "#BF2600",
      })) || [],
    [statisticsGroupByType],
  );

  const colors: string[] = useMemo(
    () => items.map(({ color }): string => color) || [],
    [items],
  );

  const { width } = useWindowSize();

  let size = 300;
  if (width < 500) {
    size = 200;
  }
  if (width < 350) {
    size = 100;
  }

  return (
    <div className={s.container}>
      <Flex justify="space-between">
        <Text variant="header-6" className={s.container__title}>
          Типы проверок
        </Text>
        <Button
          type="secondary"
          size="m"
          icon={<VscSettings className={s.container__settings_button_icon} />}
          className={s.container__settings_button}
          onClick={onOpenSettings}
        />
      </Flex>
      <Skeleton
        loading={!statisticsGroupByType}
        className={s.container__skeleton}
      >
        {statisticsGroupByType && (
          <>
            <div className={s.container__total}>
              <div className={s.container__total__item}>
                <Text variant="subheader-1">По всем типам проверок</Text>
                <Text variant="header-7">
                  {statisticsGroupByType.items.reduce(
                    (acc, dataItem) => acc + dataItem.value,
                    0,
                  )}
                </Text>
                <LegendHint
                  interval={filters.interval}
                  reportTypesHint={
                    filters.pieReportType
                      ? "Проверка выбранных типов"
                      : undefined
                  }
                />
              </div>
            </div>

            <div className={s.container__chart}>
              <PieChartComponent
                width={size}
                height={size}
                data={items}
                colors={colors}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={size / 2}
                innerRadius={size / 4}
                fill="#8884d8"
                dataKey="value"
              />
              <Legend dataItems={items} />
              <PieItems items={items} />
            </div>
          </>
        )}
      </Skeleton>
    </div>
  );
};
