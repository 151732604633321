import { FC } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdDownload } from "react-icons/md";
import { Button } from "@ui/ui/Button/Button";
import cn from "classnames";
import s from "./DownloadReportPdfButton.module.scss";

interface DownloadReportPdfButtonProps {
  isLoadingGeneratePdf?: boolean;
  isMultiQueryReport?: boolean;
  handleGeneratePdf?: () => void;
}

export const DownloadReportPdfButton: FC<DownloadReportPdfButtonProps> = ({
  isLoadingGeneratePdf,
  handleGeneratePdf,
  isMultiQueryReport,
}) => (
  <Button
    size="s"
    disabled={isLoadingGeneratePdf}
    width="full"
    className={cn(s.button, isMultiQueryReport && s.multiQueryButton)}
    onClick={handleGeneratePdf}
  >
    {isLoadingGeneratePdf ? (
      <AiOutlineLoading3Quarters className={s.button__loader} />
    ) : (
      <>
        {`Скачать${isMultiQueryReport ? " полный" : ""} отчет`}
        <MdDownload size="20" />
      </>
    )}
  </Button>
);
