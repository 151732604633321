import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Text } from "@ui/ui/Text/Text";
import { ReactComponent as Send } from "src/assets/send.svg";
import { ReactComponent as AddTicket } from "src/assets/add_ticket.svg";
import { Input } from "@ui/ui/Input/Input";
import { TextArea } from "@ui/ui/TextArea/TextArea";
import { Button } from "@ui/ui/Button/Button";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { IoMdAttach } from "react-icons/io";
import { schema } from "@ui/pages/SupportChat/schema";
import { Loading } from "@ui/ui/Loading/Loading";
import {
  Chat,
  Message,
  ChatResponse,
  MessageResponse,
  useGetChats,
  useGetMessages,
  useCreateMessage,
  useCreateChat,
  useFileMessage,
} from "@femida1/gui_web_core";
import { AppRoute } from "@ui/app/routes/constants";
import { Block } from "@ui/ui/Block/Block";
import { Link } from "react-router-dom";
import { useAppFeatures } from "@ui/app/providers";
import s from "./SupportChat.module.scss";
import { CardChat } from "../CardChat/CardChat";
import { CardMessage } from "../CardMessage/CardMessage";
import { CurrentDate } from "../CurrentDate/CurrentDate";
import { Sentinel } from "../Sentinel/Sentinel";
import { AddTicketModal } from "../AddTicketModal/AddTicketModal";

function getDate(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.format("DD.MM.YYYY");
  } catch {
    return "";
  }
}

function getLocaleDate(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.locale("ru").format("DD MMMM YYYY");
  } catch {
    return "";
  }
}

function getTime(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.format("HH:mm");
  } catch {
    return "";
  }
}

function isNowDate(isoDateStr: string): boolean {
  try {
    const date = dayjs(isoDateStr);
    const now = dayjs(new Date().toISOString());
    return date.format("DD.MM.YYYY") === now.format("DD.MM.YYYY");
  } catch {
    return false;
  }
}

function isLocaleNowDate(isoDateStr: string): boolean {
  try {
    const now = dayjs(new Date().toISOString());
    return isoDateStr === now.locale("ru").format("DD MMMM YYYY");
  } catch {
    return false;
  }
}

const sortChats = (a: Chat, b: Chat) => {
  const bDate = b.created_at ? new Date(b?.created_at).getTime() : 0;
  const aDate = a.created_at ? new Date(a.created_at).getTime() : 0;
  return bDate - aDate;
};

const sortMessages = (a: Message, b: Message) => {
  const bDate = new Date(b.created_at).getTime();
  const aDate = new Date(a.created_at).getTime();
  return aDate - bDate;
};

type MapMessageType = { date: string; messages: Message[] }[];

export const SupportChat = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const [hiddenDates, setHiddenDates] = useState<string[]>([]);
  const [chats, setChats] = useState<ChatResponse | null>(null);
  const [messages, setMessages] = useState<MessageResponse | null>(null);
  const [currentChat, setCurrentChat] = useState<Chat | undefined>();
  const { mutateAsync: getChats, isPending: isLoadingChats } = useGetChats();
  const { mutateAsync: getMessages, isPending: isLoadingMessages } =
    useGetMessages();
  const { mutateAsync: createChat } = useCreateChat();
  const { mutateAsync: createMessage } = useCreateMessage();
  const { mutateAsync: downloadFile } = useFileMessage();
  const { chatsAvailable } = useAppFeatures();

  const {
    control,
    watch,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<{
    name: string;
    message: string;
    question?: string;
    file?: FileList;
  }>({
    defaultValues: {
      name: "",
      message: "",
      question: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const hasError = Object.keys(errors).length > 0;

  const file = watch("file");

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const getChatsHandler = async () => {
    const data = await getChats({
      limit: 500,
      offset: 0,
    });
    setChats(data);
  };

  const getMessagesHandler = async () => {
    if (currentChat) {
      const data = await getMessages({
        support_chat_id: Number(currentChat.id),
        limit: 500,
        offset: 0,
      });
      setMessages(data);
    }
  };

  useEffect(() => {
    if (chatsAvailable) getChatsHandler();
  }, [chatsAvailable]);

  useEffect(() => {
    if (chatsAvailable) getMessagesHandler();
  }, [chatsAvailable, currentChat]);

  useEffect(() => {
    chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
  }, [messages]);

  const onHiddenHandler = (value: boolean, date: string) => {
    setHiddenDates((dates) =>
      value ? [...dates, date] : dates.filter((item) => item !== date),
    );
  };

  const isHidden = (date: string) =>
    !!hiddenDates.find((value) => value === date);

  const mapMessages = useMemo<MapMessageType>(
    () =>
      [
        ...new Set(
          messages?.results
            ?.sort(sortMessages)
            .map((value) => getLocaleDate(value.created_at)),
        ),
      ].map((date) => ({
        date,
        messages:
          messages?.results?.filter(
            (message) => date === getLocaleDate(message.created_at),
          ) ?? [],
      })),
    [messages],
  );

  const onCreateChatHandler = async () => {
    const message = watch("message");
    const name = watch("name");
    const data = await createChat({
      subject: name,
      question: message,
    });
    if (data) {
      resetField("name");
      resetField("message");
      getChatsHandler();
    }
  };

  const onCurrentChatHandler = (chat: Chat) => {
    setCurrentChat(chat);
  };

  const onCreateMessageHandler = async () => {
    if (currentChat) {
      const question = watch("question");
      const data = await createMessage({
        chat_id: currentChat.id,
        message: question ?? "",
        file: file?.[0],
      });
      if (data) {
        resetField("question");
        resetField("file");
        getMessagesHandler();
      }
    }
  };

  const keyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.nativeEvent.key === "Enter" && !e.shiftKey) {
      onCreateMessageHandler();
    }
  };

  const handleRemoveFile = () => {
    setValue("file", undefined);
  };

  if (isLoadingChats) {
    return <Loading />;
  }
  return (
    <div onKeyUp={keyUp} className={s.container}>
      <PageHeader
        title="Техническая поддержка"
        mapPathToTitle={{
          [AppRoute.Support]: "Техническая поддержка",
        }}
        paths={[AppRoute.Support]}
      />
      {chatsAvailable ? (
        <>
          <div className={s.wrapper}>
            <div
              className={cn(
                s.block,
                s.chats,
                chats?.count && chats?.count > 0
                  ? s.height_block
                  : s.without_chats,
              )}
            >
              <div className={s.header}>
                <Text variant="subheader-5">Тикеты</Text>
              </div>
              {chats?.count && chats?.count > 0 ? (
                <>
                  <div className={s.list}>
                    {chats?.results?.sort(sortChats).map((chat) => {
                      let date = "";
                      if (chat.created_at) date = getDate(chat.created_at);
                      if (chat.created_at && isNowDate(chat.created_at))
                        date = `Сегодня ${getTime(chat.created_at)}`;

                      return (
                        <CardChat
                          key={chat.id}
                          date={date}
                          title={chat.subject}
                          message={chat.question}
                          onClick={() => onCurrentChatHandler(chat)}
                        />
                      );
                    })}
                  </div>
                  <div className={s.footer}>
                    <Button
                      iconRight={<AddTicket width={20} height={20} />}
                      size="l"
                      width="full"
                      onClick={openModal}
                    >
                      Создать тикет
                    </Button>
                  </div>
                </>
              ) : (
                <div className={s.list}>
                  <Text variant="subheader-3" color="secondary">
                    Добавьте тикет, чтоб мы могли сделать наш сервис лучше
                  </Text>
                </div>
              )}
            </div>
            {chats?.count && chats?.count > 0 && !currentChat && (
              <div className={cn(s.messages, s.height_block)} />
            )}
            {chats?.count && chats?.count === 0 && (
              <div className={cn(s.block, s.create_tiket)}>
                <div className={s.header}>
                  <Text variant="subheader-5">Создать тикет</Text>
                </div>
                <div className={s.list}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        placeholder="Название тикета"
                        required
                        validate={fieldState}
                      />
                    )}
                  />
                  <Controller
                    name="message"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextArea
                        {...field}
                        placeholder="Сообщение"
                        required
                        rows={10}
                        validate={fieldState}
                      />
                    )}
                  />
                </div>
                <div className={s.footer}>
                  <Button
                    iconRight={<AddTicket width={20} height={20} />}
                    onClick={onCreateChatHandler}
                    size="l"
                    width="full"
                  >
                    Создать тикет
                  </Button>
                </div>
              </div>
            )}
            {chats?.count && chats?.count > 0 && Boolean(currentChat) && (
              <div className={cn(s.block, s.messages, s.height_block)}>
                <div className={s.header}>
                  <Text variant="subheader-5">{currentChat?.subject}</Text>
                </div>
                <div ref={chatRef} className={s.list_messages}>
                  {mapMessages.map((item) => (
                    <div className={s.messages_wrapper} key={item.date}>
                      <Sentinel
                        onHidden={(value) => onHiddenHandler(value, item.date)}
                      />
                      <CurrentDate
                        isHidden={isHidden(item.date)}
                        date={
                          isLocaleNowDate(item.date) ? `Сегодня` : item.date
                        }
                      />
                      {item.messages.sort(sortMessages).map((message) => (
                        <CardMessage
                          key={message.id}
                          date={getTime(message.created_at)}
                          message={message.message}
                          isSupport={message.support_reply}
                          fileName={message?.files?.[0]}
                          onFileClick={() =>
                            downloadFile({
                              filename: message?.files?.[0],
                              id: Number(currentChat?.id),
                            })
                          }
                        />
                      ))}
                    </div>
                  ))}
                  {isLoadingMessages && <Loading />}
                </div>
                <div className={s.footer}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    type="button"
                    className={s.button}
                    onClick={onCreateMessageHandler}
                    disabled={hasError}
                  >
                    <Send width={20} height={20} />
                  </button>
                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <div className={s.fileInputWrapper}>
                        <input
                          type="file"
                          id="fileInput"
                          onChange={(e) => field.onChange(e.target.files)}
                          style={{ display: "none" }}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="fileInput" className={s.attach}>
                          <IoMdAttach size={20} />
                        </label>
                        {file?.[0] && (
                          <div className={s.attach__message}>
                            <span>
                              {errors?.file
                                ? errors?.file?.message
                                : file[0]?.name}
                            </span>
                            <button type="button" onClick={handleRemoveFile}>
                              Удалить
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name="question"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextArea
                        {...field}
                        onPressEnter={(e) => !e.shiftKey && e.preventDefault()}
                        placeholder="Сообщение"
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        validate={fieldState}
                        withIcon
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
          <AddTicketModal
            getChats={getChatsHandler}
            onClose={closeModal}
            isModalOpen={isModalOpen}
          />
        </>
      ) : (
        <Block>
          <Text variant="subheader-4">
            По всем вопросам обращаться на{" "}
            <Link
              target="_blank"
              to="https://mail.google.com/mail/?view=cm&fs=1&to=support@odi.is"
            >
              почту
            </Link>
          </Text>
        </Block>
      )}
    </div>
  );
};
