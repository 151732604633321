import { useParams } from "react-router-dom";
import { useReportsFioRetrieve } from "@ui/app/api/report/retrieve/mutations";
import { SearchFull } from "@ui/entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@ui/entities/types";

export const SearchFioWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: fioReport, isLoading } = useReportsFioRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {fioReport && (
        <SearchFull
          isFioSearch
          report={fioReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
