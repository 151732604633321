import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@ui/pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import {
  MainTabFieldValues,
  MainTabProps,
} from "@ui/pages/Main/components/MainTabs/types";
import { dropZoneFileListSchema } from "@ui/pages/Main/components/MainTabs/constants";
import { ReportsGrnSearchRequest } from "@shared/types/report/search";
import { useReportsGrnSearch } from "@ui/app/api/report/search/mutations";
import { transformAutoNum } from "@ui/utils/transformers/transformAutoNum";
import { Input } from "@ui/ui/Input/Input";
import { validationMessage } from "@ui/utils/constants";
import { correctAutoNumberRegexCallback } from "@ui/utils/validation";
import s from "../MainTabAuto.module.scss";

interface SearchAutoRegistrationNumberFieldValues
  extends ReportsGrnSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object<ReportsGrnSearchRequest>()
  .shape({
    grn: yup
      .string()
      .transform(transformAutoNum)
      .test(
        "digits-check",
        validationMessage.WrongAutoNumber,
        correctAutoNumberRegexCallback,
      )
      .required(validationMessage.Required),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabAutoNormalProps = MainTabProps;

export const MainTabAutoNormal: FC<MainTabAutoNormalProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      grn: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsGrnSearch();

  const onSubmitInner = useCallback(
    (requestData: SearchAutoRegistrationNumberFieldValues) =>
      onSubmit({ requestData, search, withSopd: true }).then(() => reset()),
    [onSubmit, search, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="grn"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              placeholder="Гос. номер транспорта"
              mask="a[a]000[0]a[a]00[0]"
              {...field}
              value={transformAutoNum(field.value)}
              onChange={(e) => {
                e.target.value = transformAutoNum(e.target.value);
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
