import { useCallback, useState, FC, useRef, useMemo } from "react";
import { Flex, List } from "antd";
import { Input } from "@ui/ui/Input/Input";
import { Pagination } from "@ui/ui/Pagination/Pagination";
import debounce from "lodash/debounce";
import { useTelegramUserBotRetrieveAll } from "@ui/app/api/telegram/queries/useTelegramUserBotRetrieveAll";
import { TelegramUserBot } from "@ui/app/api/telegram/types";
import { AnimatedListItem } from "@ui/components/AnimatedListItem/AnimatedListItem";
import { usePrevious } from "react-use";
import { MdSearch } from "react-icons/md";
import { TelegramListCard } from "./components/TelegramListCard/TelegramListCard";
import s from "./TelegramList.module.scss";

interface TelegramListProps {
  withPagination?: boolean;
  filterBots?: (bot: TelegramUserBot) => boolean;
  limit?: number;
}

export const TelegramList: FC<TelegramListProps> = ({
  withPagination = false,
  filterBots,
  limit = 5,
}) => {
  const [filterUi, setFilterUi] = useState("");
  const [filter, setFilter] = useState("");
  const prevFilter = useRef<string>("");

  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setFilter(value);
      if (value !== prevFilter.current) {
        setPage(1);
      }
      prevFilter.current = value;
    }, 1300),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  const requestData = useMemo(
    () => ({
      limit,
      offset: Number.isInteger(offset) ? offset : undefined,
    }),
    [limit, offset],
  );

  const { data: botsResponse, isSuccess: isBotsSuccess } =
    useTelegramUserBotRetrieveAll(requestData);
  /**
   * TODO move filter to backend
   */
  const bots = useMemo(() => {
    const filterLc = filter.toLowerCase();

    return botsResponse?.filter(
      (bot) =>
        (filterBots?.(bot) ?? true) &&
        (bot.telegram_username.toLowerCase().includes(filterLc) ||
          String(bot.telegram_id).toLowerCase().includes(filterLc)),
    );
  }, [botsResponse, filter, filterBots]);

  const firstBot = bots?.[0];
  const prevFirstBot = usePrevious(firstBot);
  const isAnimated = prevFirstBot?.telegram_id !== firstBot?.telegram_id;

  const totalCount = bots?.length;

  const pagination = useMemo(() => {
    if (withPagination && isBotsSuccess && totalCount) {
      return (
        <Flex justify="center">
          <Pagination
            total={totalCount}
            pageSize={limit}
            page={page}
            onUpdate={setPage}
          />
        </Flex>
      );
    }

    return null;
  }, [withPagination, isBotsSuccess, limit, page, totalCount]);

  return (
    <Flex vertical gap={32}>
      <Input
        placeholder="Поиск по логину"
        placeholderIcon={<MdSearch size={20} />}
        value={filterUi}
        onUpdate={onFilterUpdate}
      />
      <Flex vertical gap={16} component="ul" className={s.list}>
        {isBotsSuccess && bots
          ? bots.map((bot) => (
              <AnimatedListItem key={bot.telegram_id} isAnimated={isAnimated}>
                <TelegramListCard key={bot.telegram_id} bot={bot} />
              </AnimatedListItem>
            ))
          : Array.from({ length: 15 }).map((_v, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <List.Item key={i}>
                <TelegramListCard isLoading />
              </List.Item>
            ))}
      </Flex>
      {pagination}
    </Flex>
  );
};
