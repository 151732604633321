import { Text } from "@ui/ui/Text/Text";
import {
  CrossingBordersSummaryKeys,
  isTransportListItems,
  TransportListItem,
  TransportListValues,
} from "@shared/types/report/retrieve";
import s from "./SummaryDetail.module.scss";

interface SummaryDetailProps {
  summaryKey: CrossingBordersSummaryKeys;
  summary?: TransportListValues;
}

export const SummaryDetail = ({ summaryKey, summary }: SummaryDetailProps) => {
  if (
    summaryKey === CrossingBordersSummaryKeys.count &&
    typeof summary === "number"
  ) {
    return <Text variant="header-1">{summary}</Text>;
  }

  if (
    summaryKey === CrossingBordersSummaryKeys.transportList &&
    summary &&
    isTransportListItems(summary)
  ) {
    return (
      <div className={s.transport}>
        {summary.map((item: TransportListItem, index: number) => (
          // TODO поправить
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <Text variant="header-1">
              {item.transport_type} ({item.count})
            </Text>
          </div>
        ))}
      </div>
    );
  }

  if (Array.isArray(summary)) {
    return <Text variant="header-1">{summary.join(", ")}</Text>;
  }

  return <div />;
};
