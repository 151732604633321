import { AppFeatures } from "../types";

export const odysseyBlackConfig: AppFeatures = {
  authResetPasswordEnabled: true,
  authUserSelfRegistrationEnabled: true,
  chatsAvailable: false,
  documentsPageEnabled: true,
  fioTabSopdEnabled: false,
  mainIncludeOpenSourcesEnabled: false,
  mainMultiQueryEnabled: false,
  mainTabSopdEnabled: false,
  onboardingEnabled: false,
  themeSwitchEnabled: false,
  vkTabSopdEnabled: false,
};
