import { FC, useId } from "react";

import { Flex, Switch } from "antd";
import cn from "classnames";
import { ControllerFieldState } from "react-hook-form";
import { parseError } from "@ui/utils/parseError";
import s from "./Toggle.module.scss";
import { Text } from "../Text/Text";

export interface ToggleProps {
  validate?: Partial<ControllerFieldState>;
  className?: string;
  value?: boolean;
  title?: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export const Toggle: FC<ToggleProps> = ({
  validate,
  className,
  value,
  title,
  onChange,
  disabled,
}) => {
  const id = useId();
  const { error } = validate || {};

  return (
    <label htmlFor={id} className={cn(className)}>
      <Flex align="center" gap={6}>
        <Switch
          id={id}
          value={value}
          title={title}
          onChange={onChange}
          className={cn(s.toggle, { [s.toggle_checked]: value })}
          disabled={disabled}
        />
        {title ? <Text variant="body-5">{title}</Text> : null}
        {error && <span className={s.toggle_error}>{parseError(error)}</span>}
      </Flex>
    </label>
  );
};
