import { client } from "@ui/app/api/client";

import {
  StatisticsGranularity,
  GroupStatisticsLabelWithDate,
  GroupStatisticsRequest,
  GroupStatisticsResponse,
  GroupStatisticsTransformedResponse,
  GroupStatisticsTransformedRequest,
  GroupStatisticsByTypeTransformedResponse,
  GroupStatisticsByTypeTransformedItem,
  GroupStatisticsByTypeResponse,
} from "@ui/app/api/analytics/types";
import dayjs, { OpUnitType } from "dayjs";
import _ from "lodash";
import { ReportType } from "@shared/types/report/constants";
import { endpoints } from "./endpoints";

enum LabelPosition {
  Single = "Single",
  First = "First",
  Middle = "Middle",
  Last = "Last",
}

export const lowestGranularity = StatisticsGranularity.Day;

const mapGranularityToDaysJsFormat: Record<StatisticsGranularity, string> = {
  [StatisticsGranularity.Day]: "DD.MM.YYYY",
  [StatisticsGranularity.Month]: "MMMM",
  [StatisticsGranularity.Year]: "YYYY",
};

const mapGranularityToDaysJsUnit: Record<StatisticsGranularity, OpUnitType> = {
  [StatisticsGranularity.Day]: "day",
  [StatisticsGranularity.Month]: "month",
  [StatisticsGranularity.Year]: "year",
};

const lowestFormat = mapGranularityToDaysJsFormat[lowestGranularity];

const getLabelMonthOrYear = ({ label, position, data }: GetLabelProps) => {
  const dateLabel = dayjs(label.date, { locale: "RU" }).format(
    mapGranularityToDaysJsFormat[data.granularity],
  );
  const dateLabelCapitalized = _.capitalize(dateLabel);
  const unit = mapGranularityToDaysJsUnit[data.granularity];

  const result = {
    dateLabelCapitalized,
    end: "",
    start: "",
  };

  if (
    position === LabelPosition.First &&
    data.date_from?.isSame(label.date, unit)
  ) {
    result.start = data.date_from.format(lowestFormat);
  } else if (
    position === LabelPosition.Last &&
    data.date_to?.isSame(label.date, unit)
  ) {
    result.end = data.date_to.format(lowestFormat);
  } else if (position === LabelPosition.Single) {
    if (data.date_from?.isSame(label.date, unit)) {
      result.start = data.date_from.format(lowestFormat);
    }
    if (data.date_to?.isSame(label.date, unit)) {
      result.end = data.date_to.format(lowestFormat);
    }
  }

  const startBlock = result.start ? `с ${result.start}` : "";
  const endBlock = result.end ? `по ${result.end}` : "";

  const blocks = [startBlock, endBlock].filter(Boolean);

  return `${result.dateLabelCapitalized}${blocks.length ? ` (${blocks.join(" ")})` : ""}`;
};

interface GetLabelProps {
  data: GroupStatisticsTransformedRequest;
  label: GroupStatisticsLabelWithDate;
  position: LabelPosition;
}

const mapGranularityToGetLabel: Record<
  StatisticsGranularity,
  (props: GetLabelProps) => string
> = {
  [StatisticsGranularity.Day]: ({ label, data }: GetLabelProps): string =>
    dayjs(label.date, { locale: "RU" }).format(
      mapGranularityToDaysJsFormat[data.granularity],
    ),
  [StatisticsGranularity.Month]: getLabelMonthOrYear,
  [StatisticsGranularity.Year]: getLabelMonthOrYear,
};

interface GetLabelsProps {
  data: GroupStatisticsTransformedRequest;
  labels: GroupStatisticsLabelWithDate[];
}

const getLabels = ({ labels, data }: GetLabelsProps) => {
  const getLabel = mapGranularityToGetLabel[data.granularity];

  return labels.map((label, index, self) => {
    let position = LabelPosition.Middle;
    if (self.length === 1) {
      position = LabelPosition.Single;
    } else if (index === 0) {
      position = LabelPosition.First;
    } else if (index === self.length - 1) {
      position = LabelPosition.Last;
    }

    return {
      date: label.date,
      label: getLabel({ data, label, position }),
    };
  });
};

export const getGroupStatistics = (data: GroupStatisticsTransformedRequest) =>
  client.post<
    GroupStatisticsTransformedResponse,
    GroupStatisticsTransformedResponse,
    GroupStatisticsRequest<false>
  >(
    endpoints.GROUPS_STATISTICS,
    {
      ..._.omit(data, "date_from", "date_to"),
      date_from: data.date_from?.toISOString(),
      date_to: data.date_to?.toISOString(),
      group_by_type: false,
    },
    {
      /**
       * TODO move to nodejs
       */
      transformResponse(response: string): GroupStatisticsTransformedResponse {
        const res: GroupStatisticsResponse = JSON.parse(response);

        const uniqueArr = Array.from(new Set(res.labels));
        const labelWithDates = uniqueArr.map((label) => ({
          date: new Date(label),
          label,
        }));

        const timeByLabel =
          labelWithDates.reduce<Record<string, number>>(
            (acc, { label, date }) => {
              acc[label] = date.getTime();
              return acc;
            },
            {},
          ) || {};

        const sortedLabelWithDates: GroupStatisticsLabelWithDate[] =
          labelWithDates.sort(
            (a, b) => timeByLabel[a.label] - timeByLabel[b.label],
          );

        return {
          datasets: res.datasets[0]?.data.map((value) => value),
          labels: getLabels({ data, labels: sortedLabelWithDates }),
        };
      },
    },
  );

export const getGroupStatisticsByType = (
  data: GroupStatisticsTransformedRequest,
) =>
  client.post<
    GroupStatisticsByTypeTransformedResponse,
    GroupStatisticsByTypeTransformedResponse,
    GroupStatisticsRequest<true>
  >(
    endpoints.GROUPS_STATISTICS,
    {
      ..._.omit(data, "date_from", "date_to"),
      date_from: data.date_from?.toISOString(),
      date_to: data.date_to?.toISOString(),
      group_by_type: true,
    },
    {
      /**
       * TODO move to nodejs
       */
      transformResponse(
        response: string,
      ): GroupStatisticsByTypeTransformedResponse {
        const res: GroupStatisticsByTypeResponse = JSON.parse(response);
        const datasets = res.datasets[0]?.data.map((value) => value);

        const sumsByReportType: Map<ReportType, number> = res.labels.reduce(
          (acc: Map<ReportType, number>, label: ReportType, index: number) => {
            const value = datasets?.[index];
            const sum = acc.get(label);
            if (sum) {
              acc.set(label, sum + value);
            } else {
              acc.set(label, value);
            }
            return acc;
          },
          new Map<ReportType, number>(),
        );

        const items: GroupStatisticsByTypeTransformedItem[] = Array.from(
          sumsByReportType,
        )
          .map((item: [ReportType, number]) => ({
            type: item[0],
            value: item[1],
          }))
          .filter(({ value }) => value > 0)
          .sort((a, b) => b.value - a.value);

        return { items };
      },
    },
  );
