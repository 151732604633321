/* eslint-disable no-underscore-dangle */

import { ReportStatus } from "@shared/types/report/constants";
import { Text } from "@ui/ui/Text/Text";
import dayjs from "dayjs";
import s from "./PhotoData.module.scss";
import { RetrieveCardVariant } from "../../../../../../../components/RetrieveCard/types";
import { RetrieveCard } from "../../../../../../../components/RetrieveCard/RetrieveCard";
import { PhotoDataProps } from "../types";

export const PhotoData = ({
  id,
  meta,
  collectionObj,
  borderCross,
}: PhotoDataProps) => (
  <div className={s.container}>
    <Text variant="subheader-5">Данные фото</Text>
    <div className={s.container__list}>
      {id && (
        <div className={s.container__list__item}>
          <Text variant="subheader-1" color="secondary">
            Id
          </Text>
          <Text variant="label-4">{id}</Text>
        </div>
      )}
      {meta?.name && (
        <div className={s.container__list__item}>
          <Text variant="subheader-1" color="secondary">
            Имя
          </Text>
          <Text variant="label-4">{meta?.name}</Text>
        </div>
      )}
      {meta?.birthday && (
        <div className={s.container__list__item}>
          <Text variant="subheader-1" color="secondary">
            Дата
          </Text>
          <Text variant="label-4">{meta?.birthday}</Text>
        </div>
      )}
      {collectionObj?.date_created && (
        <div className={s.container__list__item}>
          <Text variant="subheader-1" color="secondary">
            Дата создания
          </Text>
          <Text variant="label-4">
            {dayjs(collectionObj?.date_created).format("DD.MM.YYYY")}
          </Text>
        </div>
      )}
      {borderCross?.map((cross) => {
        const source = cross._source;

        return (
          <RetrieveCard
            key={cross._id}
            title="Пересечение границ"
            variant={RetrieveCardVariant.noBorder}
            status={ReportStatus.Finished}
            className={s.dropdownContainer}
            titleClassName={s.dropdownContainer_title}
            defaultOpen={false}
          >
            {source.name && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  ФИО
                </Text>
                <Text>{source.name}</Text>
              </div>
            )}
            {source.birthday && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Дата рожения
                </Text>
                <Text>{source.birthday}</Text>
              </div>
            )}
            {source.document && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Паспорт
                </Text>
                <Text>
                  {source.document.num} {source.document.date}
                </Text>
              </div>
            )}
            {source.type && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Тип путешествия
                </Text>
                <Text>{source.type}</Text>
              </div>
            )}
            {source.from_type && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Тип транспорта
                </Text>
                <Text>{source.from_type}</Text>
              </div>
            )}
            {(source.country || source.from) && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Место вылета
                </Text>
                <Text>
                  {source.country}, {source.from}
                </Text>
              </div>
            )}
            {source.date && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Дата вылета
                </Text>
                <Text>{dayjs(source.date).format("DD.MM.YYYY")}</Text>
              </div>
            )}
            {(source.destination_country || source.destination_city) && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Место прилета
                </Text>
                <Text>
                  {source.destination_country}, {source.destination_city}
                </Text>
              </div>
            )}
            {source.date2 && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Дата прилета
                </Text>
                <Text>{dayjs(source.date2).format("DD.MM.YYYY")}</Text>
              </div>
            )}
            {source.airplane && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Борт
                </Text>
                <Text>{source.airplane}</Text>
              </div>
            )}
            {source.company && (
              <div className={s.container__list__item}>
                <Text variant="body-1" color="secondary">
                  Компания перевозчик
                </Text>
                <Text>{source.company}</Text>
              </div>
            )}
          </RetrieveCard>
        );
      })}
    </div>
  </div>
);
