import { FC, SetStateAction } from "react";
import { ReportType } from "@shared/types/report/constants";
import { Flex } from "antd";
import { Checkbox } from "@ui/ui/Checkbox/Checkbox";
import { mapReportTypeToLabel } from "@ui/utils/reports/mapReportTypeToLabel";
import { IShowAllCheckboxProps, ShowAllCheckbox } from "./ShowAllCheckbox";
import { handleCheckboxCheck } from "./utils/handleCheckboxCheck";

interface IFilterRulesListProps extends IShowAllCheckboxProps {
  selectedFilterRules: ReportType[];
  setSelectedFilterRules: (value: SetStateAction<ReportType[]>) => void;
  setIsShowAllFilterRulesCheckboxChecked: (value: boolean) => void;
}

export const FilterRulesList: FC<IFilterRulesListProps> = ({
  selectedFilterRules,
  setSelectedFilterRules,
  setIsShowAllFilterRulesCheckboxChecked,
  checked: showAllCheckboxChecked,
  onUpdate,
}) => (
  <Flex vertical gap={9.5}>
    <ShowAllCheckbox checked={showAllCheckboxChecked} onUpdate={onUpdate} />
    {Object.entries(ReportType).map(([key, value]) => (
      <Checkbox
        key={key}
        checked={selectedFilterRules.includes(value)}
        onChange={(e) => {
          const { checked } = e.target;
          handleCheckboxCheck(
            checked,
            value,
            setSelectedFilterRules,
            setIsShowAllFilterRulesCheckboxChecked,
          );
        }}
      >
        {mapReportTypeToLabel[value]}
      </Checkbox>
    ))}
  </Flex>
);
