import { Block } from "@ui/ui/Block/Block";
import { Text } from "@ui/ui/Text/Text";
import { FC } from "react";
import {
  EMultiQueryKeys,
  mapMultiQueryKeyToReportType,
  mapMultiQueryKeyToTitle,
} from "@shared/types/report/retrieve/multiQuery";
import {
  PersonInfo,
  ReportsFullRetrieve,
  ReportsPhoneRetrieveResponse,
} from "@shared/types/report/retrieve";
import { FoundStatus, ReportStatus } from "@shared/types/report/constants";
// eslint-disable-next-line import/no-cycle
import { SearchPhone } from "@ui/entities/SearchPhone/SearchPhone";
// eslint-disable-next-line import/no-cycle
import { SearchFull } from "@ui/entities/SearchFull/SearchFull";
import s from "./MultiQueryReportsByType.module.scss";

interface IMultiQueryReportsByTypeProps {
  multiQueryKey: EMultiQueryKeys;
  multiQueryRequests: Record<string, PersonInfo>;
}

export const MultiQueryReportsByType: FC<IMultiQueryReportsByTypeProps> = ({
  multiQueryKey,
  multiQueryRequests,
}) => (
  <Block
    className={s.container}
    dataName="multi-query-block-report"
    id={mapMultiQueryKeyToTitle[multiQueryKey]}
  >
    <Text className={s.container__title} color="secondary">
      {mapMultiQueryKeyToTitle[multiQueryKey]}
    </Text>
    {Object.entries(multiQueryRequests).map(([key, value], index) => {
      if (multiQueryKey === EMultiQueryKeys.phone) {
        const searchPhoneReport: ReportsPhoneRetrieveResponse = {
          status: ReportStatus.Finished,
          request_describe: key,
          person: {
            status: ReportStatus.Finished,
            found: FoundStatus.found,
            person_info: value,
          },
        };

        return (
          <>
            {index > 0 && (
              <div className={s.container__hr_container}>
                <div className={s.container__hr_container_hr} />
              </div>
            )}
            <SearchPhone
              isSubReport
              key={key}
              report={searchPhoneReport}
              type={mapMultiQueryKeyToReportType[multiQueryKey]}
              reportId=""
            />
          </>
        );
      }
      const searchFullReport: ReportsFullRetrieve = {
        status: ReportStatus.Finished,
        request_describe: key,
        has_sopd: false,
        person: {
          status: ReportStatus.Finished,
          found: FoundStatus.found,
          person_info: value,
        },
      };

      return (
        <>
          {index > 0 && (
            <div className={s.container__hr_container}>
              <div className={s.container__hr_container_hr} />
            </div>
          )}
          <SearchFull
            isSubReport
            key={key}
            report={searchFullReport}
            type={mapMultiQueryKeyToReportType[multiQueryKey]}
            reportId=""
          />
        </>
      );
    })}
  </Block>
);
