import { FC, useCallback } from "react";
import { Group } from "@ui/app/api/groups";
import { useGroupsSetNonRussianApiAllowed } from "@ui/app/api/groups/mutations/useGroupsGrantPermissions";
import { Checkbox, CheckboxChangeEvent } from "@ui/ui/Checkbox/Checkbox";

interface UserNonRussianIpAllowedProps {
  group: Group;
}

export const UserNonRussianIpAllowed: FC<UserNonRussianIpAllowedProps> = ({
  group,
}) => {
  const { group_id, non_russian_ip_allowed } = group;

  const {
    mutateAsync: setNonRussianApiAllowed,
    isPending: isGroupsSetNonRussianApiAllowedPending,
  } = useGroupsSetNonRussianApiAllowed();

  const onChange = useCallback(
    async (e: CheckboxChangeEvent) => {
      if (e.target.checked !== non_russian_ip_allowed) {
        await setNonRussianApiAllowed({
          group_id,
          non_russian_ip_allowed: e.target.checked,
        });
      }
    },
    [setNonRussianApiAllowed, non_russian_ip_allowed, group_id],
  );

  return (
    <Checkbox
      checked={non_russian_ip_allowed}
      onChange={onChange}
      disabled={isGroupsSetNonRussianApiAllowedPending}
    >
      Разрешить вход с любой страны
    </Checkbox>
  );
};
