import { Text } from "@ui/ui/Text/Text";
import { Score } from "@ui/components/Score/Score";
import { Buttons } from "@ui/entities/RetrievePhotoByFace/components/SearchResults/Row/Buttons/Buttons";
import PhotoComparison from "@ui/entities/RetrievePhotoByFace/components/SearchResults/Row/PhotoComparison/PhotoComparison";
import { Candidate } from "@shared/types/report/retrieve";
import s from "./Row.module.scss";

export interface RowProps {
  candidate: Candidate;
  sourcePhoto?: string;
}

export const Row = ({ candidate, sourcePhoto }: RowProps) => (
  <div className={s.container}>
    <PhotoComparison candidate={candidate} sourcePhoto={sourcePhoto} />
    <Score score={candidate?.score} />
    <Text>{candidate.collection_obj?.alias}</Text>
    <Buttons
      id={candidate.id}
      meta={candidate.meta}
      collectionObj={candidate.collection_obj}
      borderCross={candidate.border_cross}
      camrobot={candidate.camrobot}
    />
  </div>
);
