import { Flex, Skeleton } from "antd";
import { Block } from "@ui/ui/Block/Block";
import { AppRoute } from "@ui/app/routes/constants";
import { useParams } from "react-router-dom";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { AccessesList } from "@ui/components/AccessesList/AccessesList";
import { useGroup } from "@ui/app/api/groups/queries/useGroup";
import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import { useAppFeatures } from "@ui/app/providers";
import s from "./Accesses.module.scss";
import { OnboardingModalContainer } from "../../components/OnboardingModalContainer";

const Accesses = () => {
  const { data: user } = useCurrentUserQuery();
  const { group_id } = useParams();
  const { data: group } = useGroup(
    { group_id: Number(group_id) },
    { enabled: Boolean(group_id) },
  );

  const { onboardingEnabled } = useAppFeatures();

  if (!user) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="accesses" />
      ) : null}
      <PageHeader
        title="Доступы"
        mapPathToTitle={{ [AppRoute.Accesses]: "Доступы" }}
        paths={[AppRoute.Accesses]}
      />
      <Block>
        <Skeleton loading={!group}>
          {group && <AccessesList withPagination limit={10} group={group} />}
        </Skeleton>
      </Block>
    </Flex>
  );
};

export default Accesses;
