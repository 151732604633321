import { useMutation } from "@tanstack/react-query";

import { QueryKey } from "@ui/app/api/constants";
import { queryClient } from "@ui/app/api/client";
import { telegramUserBotDelete } from "../queries";

export const useTelegramUserBotDelete = () =>
  useMutation({
    mutationFn: telegramUserBotDelete,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QueryKey.TelegramUserBotAllClear(),
      }),
  });
