import { appConfigsMap } from "./appConfigsMap";

export const getAppConfig = (envTheme: string) => {
  if (envTheme === "odyssey_black") {
    return appConfigsMap.odysseyBlack;
  }
  if (envTheme === "kretchet") {
    return appConfigsMap.krechet;
  }
  return appConfigsMap.odyssey;
};
