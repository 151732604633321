import { useQuery } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { QueryKey } from "@ui/app/api/constants";
import { UsersProfilePictureRetrieveRequest } from "../types";
import { usersProfilePictureRetrieve } from "../queries";

export const useUsersProfilePictureRetrieveQuery = (
  data: UsersProfilePictureRetrieveRequest,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    enabled,
    queryFn: () =>
      usersProfilePictureRetrieve(data)
        .then((photo) => {
          const photoSrc = URL.createObjectURL(photo);
          return photoSrc;
        })
        .catch((error: AxiosError) => {
          if (isAxiosError(error) && error.response?.status === 404) {
            return "";
          }

          throw error;
        }),
    queryKey: QueryKey.ProfilePictureRetrieve(data),
  });
