import { Session, SessionDeviceType } from "@ui/app/api/session";
import { Flex } from "antd";
import { FC, ReactNode } from "react";
// import { MdOutlinePhoneIphone } from "react-icons/md";
import { MdOutlineDesktopMac } from "react-icons/md";
import { Text } from "@ui/ui/Text/Text";
import { toHumanReadableDate } from "@ui/utils/date/toHumanReadableDate";
import cn from "classnames";
import s from "./SessionCard.module.scss";

const mapDeviceTypeToIcon: Record<SessionDeviceType, ReactNode> = {
  // mobile: <MdOutlinePhoneIphone />,
  [SessionDeviceType.Browser]: <MdOutlineDesktopMac className={s.card__icon} />,
};

interface SessionCardProps {
  session: Session;
}

export const SessionCard: FC<SessionCardProps> = ({ session }) => {
  const { ip, created_at, device_type, is_active } = session;

  return (
    <Flex className={s.card} justify="space-between">
      <Flex vertical>
        <Flex align="center" gap={8}>
          {mapDeviceTypeToIcon[device_type]}
          <Text variant="subheader-3">{ip}</Text>
        </Flex>
        <Text variant="body-2" color="secondary">
          {toHumanReadableDate(created_at)}
        </Text>
      </Flex>
      <div>
        <Text
          variant="body-3"
          className={cn(s.badge, { [s.badge_active]: is_active })}
        >
          {is_active ? "В сети" : "Не в сети"}
        </Text>
      </div>
    </Flex>
  );
};
