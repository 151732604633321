import { Flex } from "antd";
import React, { FC } from "react";
import { appIcon } from "../../../../appIcon";
import s from "./PdfHeader.module.scss";
import { env } from "../../../../env";

interface PdfHeaderProps {
  requestDescribe?: string;
}

const isOdysseyBlack = env.REACT_APP_THEME === "odyssey_black";

export const PdfHeader: FC<PdfHeaderProps> = ({ requestDescribe }) => (
  <Flex vertical className={s.titleContainer} align="center" gap={30}>
    {env.REACT_APP_THEME === "kretchet" ? (
      <appIcon.desktop.light.Icon width={139} height={47} />
    ) : (
      <>
        <appIcon.desktop.light.Icon
          width={isOdysseyBlack ? 30 : 24}
          height={isOdysseyBlack ? 28 : 24}
        />
        {!isOdysseyBlack && <span>ODYSSEY SEARCH</span>}
      </>
    )}
    <span>{requestDescribe}</span>
  </Flex>
);
