import React, { Suspense } from "react";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { usePageSize } from "@ui/hooks/usePageSize";
import { Skeleton } from "antd";
import { env } from "@shared/env";
import { OnboardingModal } from "./OnboardingModal";
import { OnboardingPage } from "./OnboardingModal/types";

const isOdysseyBlack = env.REACT_APP_THEME === "odyssey_black";

const OnboardingModalContainer = ({
  onboardingPage,
}: {
  onboardingPage: OnboardingPage;
}) => {
  const { isDesktop } = usePageSize();
  const { isSuccess: isUserSuccess, isPending } = useCurrentUserQuery();

  if (isOdysseyBlack || !isDesktop || (!isPending && !isUserSuccess))
    return null;

  return (
    <Suspense fallback={<Skeleton />}>
      <OnboardingModal onboardingPage={onboardingPage} />
    </Suspense>
  );
};

export default OnboardingModalContainer;
