import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Flex, Tabs, TabsProps } from "antd";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdBriefcase,
  IoMdCar,
  IoMdInformationCircle,
  IoMdPerson,
} from "react-icons/io";
import { Text } from "@ui/ui/Text/Text";
import {
  MdAssignment,
  MdFeaturedVideo,
  MdMapsHomeWork,
  MdOutlinePlaylistAddCheck,
  MdOutlineSpeed,
  MdPhoneIphone,
  MdPortrait,
} from "react-icons/md";
import { FaVk, FaTelegramPlane } from "react-icons/fa";
import { BsPassportFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { handleBackendErrors } from "@ui/utils/notifications/handleBackendErrors";
import { useNotification } from "@ui/hooks/useNotification";
import { fileToBase64 } from "@ui/utils/files/fileToBase64";
import { SetMainTabAction } from "@ui/pages/Main/hooks/useMainTabSearchParam";
import { isAxiosError } from "axios";
import { AuthForm } from "@ui/components/AuthForm/AuthForm";
import {
  mainTabFioFormKey,
  useSessionStorage,
} from "@ui/hooks/useSessionStorage";
import _ from "lodash";
import { Checkbox, CheckboxChangeEvent } from "@ui/ui/Checkbox/Checkbox";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { Permission } from "@ui/app/api/groups";
import { Theme } from "@shared/types";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { getAvailableCheckTabs } from "@ui/utils/permissions/getAvailableCheckTabs";
import { Button } from "@ui/ui/Button/Button";
import cn from "classnames";
import { HintAlert } from "@ui/pages/Main/components/MainTabs/components/HintAlert/HintAlert";
import { MainTabTelegram } from "@ui/pages/Main/components/MainTabs/tabs/MainTabTelegram/MainTabTelegram";
import { env } from "@shared/env";
import { mapMainTabToLabel } from "@ui/utils/tabs/mapMainTabToLabel";
import { MainTabVk } from "@ui/pages/Main/components/MainTabs/tabs/MainTabVk/MainTabVk";
import { PossibleNetworkResults } from "@shared/types/report/retrieve";
import { SearchRequestExtended } from "@shared/types/report/search";
import { isTabWithMultiQuery } from "@ui/pages/Main/components/MainTabs/utils/isTabWithMultiQuery";
import { Tooltip } from "@ui/ui/Tooltip/Tooltip";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useAppFeatures, useAppTheme } from "@ui/app/providers";
import s from "./MainTabs.module.scss";
import { MainTabDriverLicense } from "./tabs/MainTabDriverLicense/MainTabDriverLicense";
import { MainTabCreditRating } from "./tabs/MainTabCreditRating/MainTabCreditRating";
import { MainTabCompany } from "./tabs/MainTabCompany/MainTabCompany";
import { MainTabPhoto } from "./tabs/MainTabPhoto/MainTabPhoto";
import { MainTabScoring } from "./tabs/MainTabScoring/MainTabScoring";
import { MainTabEmail } from "./tabs/MainTabEmail/MainTabEmail";
import { MainTabAuto } from "./tabs/MainTabAuto/MainTabAuto";
import { MainTabPassport } from "./tabs/MainTabPassport/MainTabPassport";
import { MainTabInnOrSnils } from "./tabs/MainTabInnOrSnils/MainTabInnOrSnils";
import { MainTabPhone } from "./tabs/MainTabPhone/MainTabPhone";
import { MainTabAddress } from "./tabs/MainTabAddress/MainTabAddress";
import { MainTabFio } from "./tabs/MainTabFio/MainTabFio";
import { ENavMenuItem, isMainTab, MainTab } from "../../constants";
import {
  MainTabFieldValues,
  MainTabOnSubmitProps,
  MainTabProps,
} from "./types";
import { MainTabInfo } from "./tabs/MainTabInfo/MainTabInfo";

const isOdyssey = env.REACT_APP_THEME !== "kretchet";

const infoTab = {
  key: MainTab.Info,
  Icon: IoMdInformationCircle,
  label: mapMainTabToLabel[MainTab.Info],
  TabComponent: MainTabInfo,
  hint: "Для поиска по ключевому слову введите не менее 10 символов. Для точности заключите фразу в кавычки",
};
const photoTab = {
  key: MainTab.Photo,
  Icon: MdPortrait,
  label: mapMainTabToLabel[MainTab.Photo],
  TabComponent: MainTabPhoto,
  hint: "",
};
const emailTab = {
  key: MainTab.Email,
  Icon: IoMail,
  label: mapMainTabToLabel[MainTab.Email],
  TabComponent: MainTabEmail,
  hint: "",
};

const items = [
  {
    key: MainTab.Fio,
    Icon: IoMdPerson,
    label: mapMainTabToLabel[MainTab.Fio],
    TabComponent: MainTabFio,
    hint: "Доступен поиск по неполным данным",
  },
  {
    key: MainTab.Phone,
    Icon: MdPhoneIphone,
    label: mapMainTabToLabel[MainTab.Phone],
    TabComponent: MainTabPhone,
    hint: "",
  },
  ...(isOdyssey ? [] : [infoTab]),
  {
    key: MainTab.Address,
    Icon: MdMapsHomeWork,
    label: mapMainTabToLabel[MainTab.Address],
    TabComponent: MainTabAddress,
    hint: "Для поиска по кадастровому номеру или адресу используйте переключатель",
  },
  ...(isOdyssey ? [] : [photoTab]),
  ...(isOdyssey ? [] : [emailTab]),
  {
    key: MainTab.InnOrSnils,
    Icon: MdAssignment,
    label: mapMainTabToLabel[MainTab.InnOrSnils],
    TabComponent: MainTabInnOrSnils,
    hint: "Для поиска по СНИЛС или по номеру ИНН используйте переключатель",
  },
  {
    key: MainTab.Passport,
    Icon: BsPassportFill,
    label: mapMainTabToLabel[MainTab.Passport],
    TabComponent: MainTabPassport,
    hint: "Заполните поле без пробелов и знаков препинания",
  },
  {
    key: MainTab.Auto,
    Icon: IoMdCar,
    label: mapMainTabToLabel[MainTab.Auto],
    TabComponent: MainTabAuto,
    hint: "Для поиска транспорта по VIN или гос. номеру используйте переключатель",
  },
  {
    key: MainTab.DriverLicense,
    Icon: MdFeaturedVideo,
    label: mapMainTabToLabel[MainTab.DriverLicense],
    TabComponent: MainTabDriverLicense,
    hint: "Введите номер без пробелов",
  },
  ...(isOdyssey ? [emailTab] : []),
  {
    key: MainTab.Company,
    Icon: IoMdBriefcase,
    label: mapMainTabToLabel[MainTab.Company],
    TabComponent: MainTabCompany,
    hint: "Для поиска юридического лица по номеру ОГРН или ИНН используйте переключатель",
  },
  ...(isOdyssey ? [photoTab] : []),
  {
    key: MainTab.CreditRating,
    Icon: MdOutlinePlaylistAddCheck,
    label: mapMainTabToLabel[MainTab.CreditRating],
    TabComponent: MainTabCreditRating,
    hint: "",
  },
  {
    key: MainTab.Scoring,
    Icon: MdOutlineSpeed,
    label: mapMainTabToLabel[MainTab.Scoring],
    TabComponent: MainTabScoring,
    hint: "",
  },
  {
    key: MainTab.Vk,
    Icon: FaVk,
    label: mapMainTabToLabel[MainTab.Vk],
    TabComponent: MainTabVk,
    hint: "",
  },
  ...(isOdyssey ? [infoTab] : []),
  {
    key: MainTab.Telegram,
    Icon: FaTelegramPlane,
    label: mapMainTabToLabel[MainTab.Telegram],
    TabComponent: MainTabTelegram,
    hint: "Для поиска по Telegram введите номер телефона, логин или ID",
  },
];

interface TabWrapperProps {
  tab: MainTab;
  hint: string;
  TabComponent: FC<MainTabProps>;
}

const TabWrapper: FC<TabWrapperProps> = ({ tab, hint, TabComponent }) => {
  const [api] = useNotification();
  const [excludeOpenSources, setExcludeOpenSources] = useState(false);
  const [enableMultiQuery, setEnableMultiQuery] = useState(false);
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups[0].permissions;

  const onExcludeOpenSources = useCallback(async (e: CheckboxChangeEvent) => {
    setExcludeOpenSources(!e.target.checked);
  }, []);
  const onEnableMultiQuery = useCallback(async (e: CheckboxChangeEvent) => {
    setEnableMultiQuery(e.target.checked);
  }, []);
  const [multiQueryCheckboxVisible, setMultiQueryCheckboxVisible] =
    useState(false);

  const {
    state: isAuthModalVisible,
    setTrue: showAuthModal,
    setFalse: hideAuthModal,
  } = useBooleanState(false);

  const [, setValue] = useSessionStorage<PossibleNetworkResults | null>(
    mainTabFioFormKey,
    null,
  );

  const onSubmit = useCallback(
    async <T extends MainTabFieldValues>({
      requestData,
      search,
      withSopd,
    }: MainTabOnSubmitProps<T>) => {
      const trimmedRequestData = _.mapValues(requestData, (v) =>
        typeof v === "string" ? v.trim() : v,
      ) as T;

      const data: SearchRequestExtended & T = {
        ...trimmedRequestData,
        sopd: undefined,
        sopdFileList: undefined,
      };

      if (excludeOpenSources) {
        data.exclude_open_sources = excludeOpenSources;
      }

      if (enableMultiQuery) {
        data.include_multi_query = enableMultiQuery;
      }

      if (withSopd) {
        const sopdFile: File | undefined =
          trimmedRequestData.sopdFileList?.[0].originFileObj;
        data.sopd = sopdFile
          ? await fileToBase64(sopdFile).then((b) =>
              b?.replace(/^data:application\/pdf;base64,/, ""),
            )
          : undefined;
      }

      return search(data)
        .then(() => {
          api.success({
            message: "Запрос успешно создан!",
          });

          setValue(null);
        })
        .catch((error: unknown) => {
          handleBackendErrors({
            api,
            error,
          });

          if (isAxiosError(error) && error.response?.status === 401) {
            showAuthModal();
          }
        });
    },
    [excludeOpenSources, enableMultiQuery, api, setValue, showAuthModal],
  );

  const { mainIncludeOpenSourcesEnabled, mainMultiQueryEnabled } =
    useAppFeatures();

  return (
    <Flex vertical gap={24}>
      {!!hint && <HintAlert hint={hint} tab={tab} />}
      <AuthForm
        onSuccess={hideAuthModal}
        onClose={hideAuthModal}
        modalVisible={isAuthModalVisible}
      />
      <TabComponent
        setMultiQueryCheckboxVisible={(value) => {
          setMultiQueryCheckboxVisible(value);
          if (!value) setEnableMultiQuery(value);
        }}
        onSubmit={onSubmit}
        enableMultiQuery={enableMultiQuery}
      />
      <Flex vertical gap={16} align="start">
        {mainIncludeOpenSourcesEnabled &&
        (userPermissions?.includes(Permission.SuperuserPermission) ||
          userPermissions?.includes(
            Permission.ReportIncludeOpenSourcesPermission,
          )) ? (
          <Checkbox
            checked={!excludeOpenSources}
            onChange={onExcludeOpenSources}
          >
            Использовать открытые источники
          </Checkbox>
        ) : null}
        {mainMultiQueryEnabled &&
        (multiQueryCheckboxVisible || isTabWithMultiQuery(tab)) ? (
          <Flex align="center">
            <Checkbox checked={enableMultiQuery} onChange={onEnableMultiQuery}>
              Мультизапрос
            </Checkbox>
            <Tooltip
              className={s.multiQueryTooltip}
              title={
                <Text color="white">
                  Все отчеты, собранные в одном месте по данным из краткой
                  сводки
                </Text>
              }
            >
              <Text color="secondary">
                <AiFillQuestionCircle size={12} />
              </Text>
            </Tooltip>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};

interface GetTabItemsProps {
  tab: MainTab | ENavMenuItem;
  theme: Theme;
}

const getTabItems = ({ tab, theme }: GetTabItemsProps): TabsProps["items"] =>
  items.map(({ key, label, Icon, TabComponent, hint }) => ({
    key,
    label: (
      <Text
        variant="body-2"
        color="inherit"
        className={cn(s[`tabs__tab_theme_${theme}`], {
          [s.tabs__tab_active]: tab === key,
        })}
      >
        <Flex gap={12}>
          {label}
          <Icon size={20} />
        </Flex>
      </Text>
    ),
    children: <TabWrapper tab={key} TabComponent={TabComponent} hint={hint} />,
  }));

interface MainTabsProps {
  tab: MainTab | ENavMenuItem;
  setTab: SetMainTabAction;
}

interface ITabsArrowsProps extends MainTabsProps {
  filteredTabItems: TabsProps["items"];
  tabsNavRef: RefObject<HTMLDivElement>;
}

const TabsArrows: FC<ITabsArrowsProps> = ({
  tab,
  setTab,
  filteredTabItems,
  tabsNavRef,
}) => {
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);

  const checkArrowsVisibility = useCallback(() => {
    const tabsNav = tabsNavRef.current;
    if (tabsNav) {
      const tabsNavWrap = tabsNav.querySelector(".ant-tabs-nav-wrap");
      if (tabsNavWrap) {
        const hasLeftPing = tabsNavWrap.classList.contains(
          "ant-tabs-nav-wrap-ping-left",
        );
        const hasRightPing = tabsNavWrap.classList.contains(
          "ant-tabs-nav-wrap-ping-right",
        );
        setIsLeftArrowVisible(hasLeftPing);
        setIsRightArrowVisible(hasRightPing);
      }
    }
  }, [tabsNavRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tabsNav = tabsNavRef.current;
    if (tabsNav) {
      const observer = new MutationObserver(checkArrowsVisibility);
      observer.observe(tabsNav, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      checkArrowsVisibility();
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const currentIndex = useMemo(
    () => filteredTabItems?.findIndex((item) => item.key === tab) || 0,
    [filteredTabItems, tab],
  );

  const handlePrev = useCallback(() => {
    if (!filteredTabItems) return;

    if (currentIndex > 0) {
      setTab(filteredTabItems[currentIndex - 1].key as MainTab);
    }
  }, [currentIndex, filteredTabItems, setTab]);

  const handleNext = useCallback(() => {
    if (!filteredTabItems) return;

    if (currentIndex < filteredTabItems.length - 1) {
      setTab(filteredTabItems[currentIndex + 1].key as MainTab);
    }
  }, [currentIndex, filteredTabItems, setTab]);

  return (
    <div className={s.tabsContainer__arrowContainer}>
      <Button
        type="flat"
        onClick={handlePrev}
        className={cn(
          s.tabsContainer__arrowContainer_arrow,
          isLeftArrowVisible && s.tabsContainer__arrowContainer_arrow_visible,
        )}
      >
        <IoIosArrowBack color="black" size={32} />
      </Button>
      <Button
        type="flat"
        onClick={handleNext}
        className={cn(
          s.tabsContainer__arrowContainer_arrow,
          isRightArrowVisible && s.tabsContainer__arrowContainer_arrow_visible,
        )}
      >
        <IoIosArrowForward color="black" size={32} />
      </Button>
    </div>
  );
};

export const MainTabs: FC<MainTabsProps> = ({ tab = MainTab.Fio, setTab }) => {
  const [theme] = useAppTheme();
  const tabsNavRef = useRef<HTMLDivElement>(null);
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups[0].permissions;
  const tabItems = useMemo(() => getTabItems({ tab, theme }), [tab, theme]);
  const filteredTabItems = useMemo(
    () => getAvailableCheckTabs(tabItems, userPermissions),
    [tabItems, userPermissions],
  );

  useEffect(() => {
    if (!filteredTabItems?.map(({ key }) => key).includes(tab)) {
      const firstAvailableTab = filteredTabItems?.[0].key;
      if (firstAvailableTab && isMainTab(firstAvailableTab)) {
        setTab(firstAvailableTab);
      }
    }
  }, [tab, setTab, filteredTabItems]);

  return (
    <div className={s.tabsContainer} ref={tabsNavRef}>
      {filteredTabItems && filteredTabItems.length > 8 && (
        <TabsArrows
          setTab={setTab}
          tab={tab}
          filteredTabItems={filteredTabItems}
          tabsNavRef={tabsNavRef}
        />
      )}
      <Tabs
        activeKey={tab}
        destroyInactiveTabPane
        items={filteredTabItems}
        type="line"
        onChange={setTab as TabsProps["onChange"]}
        className={s.tabs}
      />
    </div>
  );
};
