import React, { FC, ReactNode } from "react";
import { Flex, Modal } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { IEmployeeDto, IEmployeeRequest } from "@ui/app/api/employees";
import cn from "classnames";
import { Text } from "../../../../ui/Text/Text";
import s from "./EmployeeDataModal.module.scss";
import { Input } from "../../../../ui/Input/Input";
import { validationMessage } from "../../../../utils/constants";
import { Button } from "../../../../ui/Button/Button";
import { useBooleanState } from "../../../../hooks/useBooleanState";

interface IEmployeeDataModalProps {
  buttonTitle: ReactNode;
  modalTitle: string;
  onSuccess: (data: IEmployeeRequest) => void;
  submitButtonText: string;
  employee?: IEmployeeDto;
  isRequestLoading: boolean;
}

const schema = yup
  .object<IEmployeeRequest>()
  .shape({
    last_name: yup.string().required(validationMessage.Required),
    first_name: yup.string().required(validationMessage.Required),
    middle_name: yup.string().required(validationMessage.Required),
    birth_date: yup.string().required(validationMessage.Required),
    passport: yup.string().required(validationMessage.Required),
    inn: yup.string().required(validationMessage.Required),
    job_title: yup.string(),
    driver_license: yup.string(),
    driver_license_at: yup.string(),
    phone: yup.string(),
  })
  .required();

const EmployeeDataModal: FC<IEmployeeDataModalProps> = ({
  buttonTitle,
  modalTitle,
  onSuccess,
  submitButtonText,
  employee,
  isRequestLoading,
}) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...employee,
      driver_license_at: employee?.driver_license_at || undefined,
    },
  });

  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  const onSubmitInner = handleSubmit((data: IEmployeeRequest) => {
    const requestData = {
      ...data,
      passport: data.passport?.replace(" ", ""),
      driver_license: data.driver_license?.replace(" ", ""),
      phone: data.phone?.replace(/\D/g, ""),
    };

    onSuccess(requestData);
    if (!isRequestLoading) {
      hideModal();
      reset();
    }
  });

  return (
    <>
      <button
        type="button"
        onClick={showModal}
        className={s.openModalButtonContainer}
      >
        {buttonTitle}
      </button>
      <Modal
        destroyOnClose
        className={s.modal}
        title={<Text variant="subheader-5">{modalTitle}</Text>}
        open={isModalVisible}
        onCancel={hideModal}
        footer={[
          <Text
            key="cancel"
            onClick={hideModal}
            className={cn(s.modal_cancel, s.modal_footerButton)}
          >
            Отменить
          </Text>,
          <div key="submit" className={s.modal_footerButton}>
            <Button
              size="s"
              type="primary"
              onClick={onSubmitInner}
              disabled={isRequestLoading}
              width="full"
            >
              {submitButtonText}
            </Button>
          </div>,
        ]}
      >
        <Flex vertical gap={16} component="form" onSubmit={onSubmitInner}>
          <Flex vertical gap={16}>
            <Text variant="subheader-3">Данные сотрудника</Text>
            <Controller
              name="last_name"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  {...field}
                  value={field.value}
                  placeholder="Фамилия"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
            <Controller
              name="first_name"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  {...field}
                  value={field.value}
                  placeholder="Имя"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
            <Controller
              name="middle_name"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  {...field}
                  value={field.value}
                  placeholder="Отчество"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
            <Controller
              name="birth_date"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  {...field}
                  value={field.value}
                  mask="00.00.0000"
                  placeholder="Дата рождения"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
            <Controller
              name="passport"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  {...field}
                  value={field.value}
                  mask="0000 000000"
                  placeholder="Серия и номер паспорта"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
            <Controller
              name="inn"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  {...field}
                  value={field.value}
                  placeholder="ИНН"
                  mask="000000000000"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value}
                  placeholder="Номер телефона"
                  mask="+7 (000) 000-00-00"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
          </Flex>
          <Flex vertical gap={16}>
            <Text variant="subheader-3">Водительское удостоверение</Text>
            <Controller
              name="driver_license"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value}
                  placeholder="Номер ВУ"
                  mask="0000 000000"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
            <Controller
              name="driver_license_at"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value}
                  placeholder="Дата выдачи ВУ"
                  mask="00.00.0000"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
          </Flex>
          <Flex vertical gap={16}>
            <Text variant="subheader-3">Компания</Text>
            <Controller
              name="job_title"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value}
                  placeholder="Должность"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  validate={fieldState}
                />
              )}
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default EmployeeDataModal;
