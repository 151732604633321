import React, { FC, useCallback, useState } from "react";
import { Flex, Image, Modal } from "antd";
import classNames from "classnames";
import { MdPortrait } from "react-icons/md";
import { Text } from "../../../../ui/Text/Text";
import { Button } from "../../../../ui/Button/Button";
import { DropZone, DropZoneFile } from "../../../../ui/DropZone/DropZone";
import styles from "./AvatarModal.module.scss";
import { usePageSize } from "../../../../hooks/usePageSize";

interface IAvatarModalProps {
  open: boolean;
  onClose: () => void;
  onEditPhoto: (file: File) => Promise<void>;
}

const AvatarModal: FC<IAvatarModalProps> = ({ open, onClose, onEditPhoto }) => {
  const { isFromTabletVertical } = usePageSize();
  const [fileList, setFileList] = useState<DropZoneFile[]>([]);
  const file = fileList?.[0]?.originFileObj;

  const handleClear = useCallback(() => {
    setFileList([]);
  }, []);

  const handleOk = useCallback(async () => {
    await onEditPhoto(file);
    setFileList([]);
  }, [file, onEditPhoto]);

  const handleClose = useCallback(() => {
    onClose();
    setFileList([]);
  }, [onClose]);

  return (
    <Modal
      title={<Text variant="header-6">Загрузка фотографии</Text>}
      open={open}
      onCancel={handleClose}
      footer={[
        file && (
          <Button
            key="clear"
            type="flat_secondary"
            size="s"
            onClick={handleClear}
          >
            Очистить
          </Button>
        ),
        <Button
          key="submit"
          type="primary"
          size="s"
          onClick={handleOk}
          disabled={!file}
        >
          Применить
        </Button>,
      ]}
    >
      <DropZone
        accept=".jpg,.jpeg,.png"
        multiple={false}
        onChange={(value) => setFileList([value[value.length - 1]])}
        selectFileButtonTitle={file ? "Заменить" : "Выбрать файл"}
      >
        {file ? (
          <Flex align="center" vertical gap={8} className={styles.photoZone}>
            <Flex align="center" vertical gap={4}>
              <Image
                rootClassName={styles.photo}
                preview={false}
                src={URL.createObjectURL(file)}
                height={126}
                width={126}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex
            align="center"
            vertical
            gap={32}
            className={classNames(styles.zone, styles.dropZone)}
          >
            <Flex align="center" vertical gap={24}>
              <MdPortrait size={48} className={styles.icon} />
              <Text color="aqua" variant="body-6">
                Загрузите {isFromTabletVertical ? " или перетащите" : ""} файл в
                формате: jpg, png
              </Text>
            </Flex>
          </Flex>
        )}
      </DropZone>
    </Modal>
  );
};

export default AvatarModal;
