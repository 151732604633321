import { useSearchParams } from "react-router-dom";
import { ReportType } from "@shared/types/report/constants";
import { Flex } from "antd";
import { SearchFioWidget } from "@ui/widgets/SearchFioWidget/SearchFioWidget";
import { SearchPhoneWidget } from "@ui/widgets/SearchPhoneWidget/SearchPhoneWidget";
import { SearchAddressWidget } from "@ui/widgets/SearchAddressWidget/SearchAddressWidget";
import { SearchSnilsWidget } from "@ui/widgets/SearchSnilsWidget/SearchSnilsWidget";
import { SearchPassportWidget } from "@ui/widgets/SearchPassportWidget/SearchPassportWidget";
import { SearchGrnWidget } from "@ui/widgets/SearchGrnWidget/SearchGrnWidget";
import { SearchVinWidget } from "@ui/widgets/SearchVinWidget/SearchVinWidget";
import { SearchDriverLicenseWidget } from "@ui/widgets/SearchDriverLicenseWidget/SearchDriverLicenseWidget";
import { SearchEmailWidget } from "@ui/widgets/SearchEmailWidget/SearchEmailWidget";
import { SearchOgrnWidget } from "@ui/widgets/SearchOgrnWidget/SearchOgrnWidget";
import { SearchCreditRatingWidget } from "@ui/widgets/SearchCreditRatingWidget/SearchCreditRatingWidget";
import { SearchScoringWidget } from "@ui/widgets/SearchScoringWidget/SearchScoringWidget";
import { SearchInfoWidget } from "@ui/widgets/SearchInfoWidget/SearchInfoWidget";
import { SearchInnFlWidget } from "@ui/widgets/SearchInnFlWidget/SearchInnFlWidget";
import { SearchInnUlWidget } from "@ui/widgets/SearchInnUlWidget/SearchInnUlWidget";
import { SearchPhotoWidget } from "@ui/widgets/SearchPhotoWidget/SearchPhotoWidget";
import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import SearchKadNumberWidget from "@ui/widgets/SearchKadNumberWidget/SearchKadNumberWidget";
import { SearchFullCreditRatingWidget } from "@ui/widgets/SearchFullCreditRatingWidget/SearchFullCreditRatingWidget";
import { SearchTelegramWidget } from "@ui/widgets/SearchTelegramWidget/SearchTelegramWidget";
import { SearchVkWidget } from "@ui/widgets/SearchVkWidget/SearchVkWidget";
import { AppRoute } from "../../app/routes/constants";
import s from "./Report.module.scss";

const getReport = (type: string) => {
  switch (type) {
    case ReportType.SEARCH_FIO: {
      return <SearchFioWidget type={type} />;
    }

    case ReportType.SEARCH_PHONE: {
      return <SearchPhoneWidget type={type} />;
    }

    case ReportType.SEARCH_CREDIT_REPORT: {
      return <SearchCreditRatingWidget type={type} />;
    }

    case ReportType.SEARCH_FULL_CREDIT_REPORT: {
      return <SearchFullCreditRatingWidget type={type} />;
    }

    case ReportType.SEARCH_INN_UL: {
      return <SearchInnUlWidget type={type} />;
    }

    case ReportType.SEARCH_INN_FL: {
      return <SearchInnFlWidget type={type} />;
    }

    case ReportType.SEARCH_EMAIL: {
      return <SearchEmailWidget type={type} />;
    }

    case ReportType.SEARCH_VIN: {
      return <SearchVinWidget type={type} />;
    }

    case ReportType.SEARCH_OGRN: {
      return <SearchOgrnWidget type={type} />;
    }

    case ReportType.SEARCH_SNILS: {
      return <SearchSnilsWidget type={type} />;
    }

    case ReportType.SEARCH_PASSPORT: {
      return <SearchPassportWidget type={type} />;
    }

    case ReportType.SEARCH_PHOTO_BY_IMAGE: {
      return <SearchPhotoWidget type={type} />;
    }

    case ReportType.SEARCH_ADDRESS: {
      return <SearchAddressWidget type={type} />;
    }

    case ReportType.SEARCH_KAD_NUMBER: {
      return <SearchKadNumberWidget type={type} />;
    }

    case ReportType.SEARCH_GRN: {
      return <SearchGrnWidget type={type} />;
    }

    case ReportType.SEARCH_DRIVER_LICENSE: {
      return <SearchDriverLicenseWidget type={type} />;
    }

    case ReportType.SEARCH_INFO: {
      return <SearchInfoWidget type={type} />;
    }

    case ReportType.SEARCH_SCORING: {
      return <SearchScoringWidget type={type} />;
    }

    case ReportType.SEARCH_TELEGRAM: {
      return <SearchTelegramWidget type={type} />;
    }

    case ReportType.SEARCH_VK: {
      return <SearchVkWidget type={type} />;
    }

    default: {
      return null;
    }
  }
};

const Report = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") as ReportType;

  return (
    <Flex vertical gap={32} className={s.reportContainer}>
      <PageHeader
        title="Отчёт"
        mapPathToTitle={{
          [AppRoute.Report]: "Отчёт",
        }}
        paths={[AppRoute.Report]}
      />
      {getReport(type)}
    </Flex>
  );
};

export default Report;
