import { useAppFeatures } from "@ui/app/providers";
import { SupportChat as SupportChatDesktop } from "./components/SupportChatDesktop/SupportChat";
import { SupportChat as SupportChatMobile } from "./components/SupportChatMobile/SupportChat";
import { OnboardingModalContainer } from "../../components/OnboardingModalContainer";
import { usePageSize } from "../../hooks/usePageSize";

const SupportChat = () => {
  const { isUntilTabletVertical } = usePageSize();
  const { onboardingEnabled } = useAppFeatures();

  return (
    <>
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="support" />
      ) : null}
      {isUntilTabletVertical ? <SupportChatMobile /> : <SupportChatDesktop />}
    </>
  );
};

export default SupportChat;
