import { FC, FormEventHandler, useRef, useState } from "react";

import { Button, List, Typography, Flex } from "antd";
import { usePhotoByPhoto } from "@ui/app/api/photo";
import { ByPhotoResponse } from "@shared/types/common/types";
import s from "./ByPhoto.module.scss";
import { Face } from "../Face/Face";

export const ByPhoto: FC = () => {
  const imageRef = useRef<HTMLInputElement>(null);

  const [photoByPhoto, setPhotoByPhoto] = useState<ByPhotoResponse | null>(
    null,
  );
  const { mutateAsync: mutatePhotoByPhoto } = usePhotoByPhoto();

  const search: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (imageRef.current?.files?.[0])
      formData.append("image", imageRef.current.files[0]);

    const data = await mutatePhotoByPhoto(formData);

    setPhotoByPhoto(data ?? null);
  };

  return (
    <div className={s.wrapper}>
      <form
        className={s.redact_panel}
        onSubmit={search}
        encType="multipart/form-data"
      >
        <input type="file" name="image" accept="image/*" ref={imageRef} />
        <Button htmlType="submit">Поиск</Button>
      </form>
      {photoByPhoto && (
        <div className={s.wrapper}>
          <List bordered>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>Количество лиц</Typography.Text>
                </div>
                <div>{photoByPhoto.faces_count}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>full_response_uuid</Typography.Text>
                </div>
                <div>{photoByPhoto.full_response_uuid}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>preview</Typography.Text>
                </div>
                <div>{String(photoByPhoto.preview)}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>response_uuid</Typography.Text>
                </div>
                <div>{photoByPhoto.response_uuid}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>suspected</Typography.Text>
                </div>
                <div>{String(photoByPhoto.suspected)}</div>
              </Flex>
            </List.Item>
          </List>

          <Typography.Text mark>faces</Typography.Text>

          {photoByPhoto.faces.map((face, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Face key={index} face={face} />
          ))}
        </div>
      )}
    </div>
  );
};
