import { kretchetConfig } from "../configs/kretchet";
import { odysseyConfig } from "../configs/odyssey";
import { odysseyBlackConfig } from "../configs/odysseyBlackConfig";

export const appConfigsMap = {
  krechet: {
    configFile: kretchetConfig,
    fontName: "NotoSans",
    logo: "/logo_kretchet.svg",
    title: "Krechet",
  },
  odyssey: {
    configFile: odysseyConfig,
    fontName: "NotoSans",
    logo: "/logo_odyssey.svg",
    title: "Odyssey",
  },
  odysseyBlack: {
    configFile: odysseyBlackConfig,
    fontName: "Montserrat",
    logo: "/logo_odyssey_black.svg",
    title: "BL",
  },
};
