import React, { FC, PropsWithChildren, useCallback } from "react";
import { Flex, Modal } from "antd";
import { MdFilterList } from "react-icons/md";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import s from "./FilterSortModal.module.scss";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";

interface IFilterSortModalProps extends PropsWithChildren {
  modalTitle: string;
  title: string;
  onApply: () => void;
  onCancel: () => void;
}

const FilterSortModal: FC<IFilterSortModalProps> = ({
  children,
  modalTitle,
  title,
  onApply,
  onCancel,
}) => {
  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  const handleCancel = useCallback(() => {
    onCancel();
    hideModal();
  }, [hideModal, onCancel]);

  return (
    <>
      <Button type="secondary" size="s" onClick={showModal}>
        <Text variant="body-4" color="secondary" className={s.title}>
          <MdFilterList /> {title}
        </Text>
      </Button>
      <Modal
        destroyOnClose
        title={<Text variant="subheader-5">{modalTitle}</Text>}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} key="cancel" type="flat" size="s">
            <Text color="secondary" variant="subheader-2">
              Отменить
            </Text>
          </Button>,
          <Button
            key="submit"
            size="s"
            type="primary"
            onClick={() => {
              onApply();
              hideModal();
            }}
          >
            Применить
          </Button>,
        ]}
      >
        <Flex vertical gap={8}>
          {children}
        </Flex>
      </Modal>
    </>
  );
};

export default FilterSortModal;
