import { useMemo } from "react";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { Common } from "@ui/components/Common/Common";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { Flex } from "antd";
import { Text } from "@ui/ui/Text/Text";
import { getCreditRatingIndicatorModel } from "@ui/utils/creditRating/getCreditRatingIndicatorModel";
import { FullCreditRating } from "@shared/types/report/retrieve";
import { Description } from "./components/Description/Description";
import { Delimiter } from "../../../../ui/Delimiter/Delimiter";
import { DetailsTable } from "../../../../ui/DetailsTable/DetailsTable";

const inquiriesColumns = [
  { id: "period", title: "Срок", width: 250 },
  { id: "inquiries", title: "Кол-во", width: 250 },
];
const loanApplicationsColumns = [
  { id: "period", title: "Срок", width: 250 },
  { id: "approval", title: "Одобрений", width: 250 },
  { id: "reject", title: "Отказов", width: 250 },
];
const billsColumns = [
  { id: "type", title: "Тип", width: 250 },
  { id: "info", title: "Информация", width: 250 },
];
const creditProductsColumns = [
  { id: "type", title: "Тип", width: 250 },
  { id: "open", title: "Открытие", width: 250 },
  { id: "closed", title: "Закрытие", width: 250 },
];
const overdueLoansColumns = [
  { id: "type", title: "Тип", width: 250 },
  { id: "info", title: "Информация", width: 250 },
];

export type FullCreditRatingResultProps = {
  creditRating?: FullCreditRating;
} & RetrieveCardCommonProps;

export const FullCreditRatingResult = ({
  creditRating,
  status,
  print = RetrieveCardPrint.device,
}: FullCreditRatingResultProps) => {
  const inquiriesRows = useMemo(() => {
    const { inquiries } = creditRating || {};

    return [
      {
        period: "Неделя",
        inquiries: inquiries?.last_week_count || 0,
      },
      {
        period: "Две недели",
        inquiries: inquiries?.last_two_weeks_count || 0,
      },
      {
        period: "Месяц",
        inquiries: inquiries?.last_month_count || 0,
      },
      {
        period: "Всё время",
        inquiries: inquiries?.all_time_count || 0,
      },
    ];
  }, [creditRating]);

  const loanApplicationsRows = useMemo(() => {
    const { applications } = creditRating || {};

    return [
      {
        period: "Две недели",
        approval: applications?.last_two_weeks_approval_count || 0,
        reject: applications?.last_month_reject_count || 0,
      },
      {
        period: "Месяц",
        approval: applications?.last_month_approval_count || 0,
        reject: applications?.last_month_reject_count || 0,
      },
      {
        period: "Два месяца",
        approval: applications?.last_two_months_approval_count || 0,
        reject: applications?.last_two_months_reject_count || 0,
      },
    ];
  }, [creditRating]);

  const billsRows = useMemo(() => {
    const { credits } = creditRating || {};

    return [
      {
        type: "Активные",
        info: credits?.active_credits_count
          ? `${credits.active_credits_count.from} — ${credits.active_credits_count.to}`
          : "",
      },
      {
        type: "Как поручитель",
        info: credits?.active_credits_as_guarantee_count
          ? `${credits?.active_credits_as_guarantee_count.from} — ${credits?.active_credits_as_guarantee_count.to}`
          : "",
      },
      {
        type: "Дата закрытия",
        info:
          credits?.last_credit_close_date === "9999-12-01T00:00:00"
            ? "Не найдено"
            : credits?.last_credit_close_date,
      },
    ];
  }, [creditRating]);

  const creditProductsRows = useMemo(
    () => creditRating?.credits?.credit_type_infos || [],
    [creditRating],
  );

  const overdueLoansRows = useMemo(() => {
    const { credits } = creditRating || {};

    return [
      {
        type: "Максимальный срок просрочки по активным счетам",
        info: credits?.worst_overdue_for_active_credits || 0,
      },
      {
        type: "Сумма действующей просрочки",
        info: credits?.active_credits_overdue_amount
          ? `${credits?.active_credits_overdue_amount.from} — ${credits?.active_credits_overdue_amount.to}`
          : "",
      },
      {
        type: "Максимальный срок просрочки по всем счетам",
        info: credits?.worst_overdue_for_all_credits || 0,
      },
      {
        type: "Безнадежный долг / Передано на взыскание",
        info: credits?.has_bad_debt_or_passed_to_collection
          ? "Да"
          : "Не найдено",
      },
    ];
  }, [creditRating]);

  return (
    <RetrieveCard
      dataId={11}
      title="Результаты кредитного рейтинга"
      status={status}
      print={print}
    >
      <Common
        marker={creditRating?.score}
        indicatorModel={getCreditRatingIndicatorModel(creditRating?.score)}
      />
      <Delimiter />
      <Flex vertical gap={20}>
        <Text variant="label-3">Запросы кредитной истории</Text>
        <DetailsTable columns={inquiriesColumns} rows={inquiriesRows} />
      </Flex>
      <Delimiter />
      <Flex vertical gap={20}>
        <Text variant="label-3">Заявления на получения кредита:</Text>
        <DetailsTable
          columns={loanApplicationsColumns}
          rows={loanApplicationsRows}
        />
      </Flex>
      <Delimiter />
      <Flex vertical gap={20}>
        <Text variant="label-3">Счета</Text>
        <DetailsTable columns={billsColumns} rows={billsRows} />
      </Flex>
      <Delimiter />
      <Flex vertical gap={20}>
        <Text variant="label-3">Кредитные продукты</Text>
        <DetailsTable
          columns={creditProductsColumns}
          rows={creditProductsRows}
        />
      </Flex>
      <Delimiter />
      <Flex vertical gap={20}>
        <Text variant="label-3">Просрочки</Text>
        <DetailsTable columns={overdueLoansColumns} rows={overdueLoansRows} />
      </Flex>
      <Delimiter />
      <Description print={print} />
    </RetrieveCard>
  );
};
