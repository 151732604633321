import cn from "classnames";
import { Block } from "@ui/ui/Block/Block";
import React, { PropsWithChildren, ReactNode } from "react";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { StatusPaper } from "@ui/components/StatusPaper/StatusPaper";
import { ReportStatus } from "@shared/types/report/constants";
import { Expand } from "../../ui/Expand/Expand";
import { Text } from "../../ui/Text/Text";
import s from "./RetrieveCard.module.scss";
import { RetrieveCardPrint, RetrieveCardVariant } from "./types";
import { getSourceTitleColor } from "../../utils/reports/getSourceTitleColor";

export interface RetrieveCardCommonProps {
  status?: ReportStatus;
  print?: RetrieveCardPrint;
}

export type RetrieveCardProps = PropsWithChildren<
  {
    title: string;
    header?: ReactNode;
    subtitle?: ReactNode;
    bottomTitle?: ReactNode;
    variant?: RetrieveCardVariant;
    titleClassName?: string;
    className?: string;
    defaultOpen?: boolean;
    isShowIncompleteData?: boolean;
    isSubReport?: boolean;
    expanded?: boolean;
    dataId?: number | string;
  } & RetrieveCardCommonProps
>;

export const RetrieveCard = ({
  title,
  header,
  subtitle,
  bottomTitle,
  variant = RetrieveCardVariant.withBorder,
  print = RetrieveCardPrint.device,
  status,
  children,
  titleClassName,
  className,
  defaultOpen = true,
  isShowIncompleteData,
  isSubReport,
  expanded,
  dataId,
}: RetrieveCardProps) => {
  const { state, toggle } = useBooleanState(defaultOpen);

  return (
    <Block
      id={title}
      dataId={`${isSubReport ? "multi-query-block-report" : "block-report"}-${dataId}`}
      dataName={isSubReport ? "multi-query-block-report" : undefined}
      className={cn(
        className,
        s.container,
        print === RetrieveCardPrint.print && s.container_print,
      )}
    >
      {header}

      {(!isSubReport || (isSubReport && expanded)) && (
        <>
          {((isShowIncompleteData && status === ReportStatus.InProgress) ||
            status === ReportStatus.Finished) && (
            <div
              className={cn(
                titleClassName,
                s.container__title,
                state &&
                  variant === RetrieveCardVariant.withBorder &&
                  s.container__title_withBorder,
                print === RetrieveCardPrint.print && s.container__title_print,
              )}
            >
              {title ? (
                <>
                  {print === RetrieveCardPrint.device && (
                    <Expand
                      state={state}
                      toggle={toggle}
                      className={s.container__icon}
                    >
                      <Text
                        variant="subheader-4"
                        color={getSourceTitleColor(title)}
                      >
                        {title}
                      </Text>
                    </Expand>
                  )}
                  {print === RetrieveCardPrint.print && (
                    <Text
                      variant="subheader-4"
                      color={getSourceTitleColor(title)}
                    >
                      {title}
                    </Text>
                  )}
                </>
              ) : null}
              {state && subtitle}
              {state && bottomTitle}
            </div>
          )}
          {!isShowIncompleteData &&
            status !== ReportStatus.Finished &&
            title && (
              <Text variant="subheader-4" color={getSourceTitleColor(title)}>
                {title}
              </Text>
            )}

          {status && status !== ReportStatus.Finished && (
            <StatusPaper status={status} />
          )}

          {state &&
            ((isShowIncompleteData && status === ReportStatus.InProgress) ||
              status === ReportStatus.Finished) &&
            children}
        </>
      )}
    </Block>
  );
};
