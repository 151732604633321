import { ReactNode, useMemo } from "react";
import { Text } from "@ui/ui/Text/Text";
import { Flex } from "antd";
import s from "./DetailsTable.module.scss";

export interface DetailsTableColumn<K extends string> {
  id: K;
  title: string;
  width: number;
}

interface DetailsTableProps<
  K extends string,
  T extends Record<string, ReactNode>,
> {
  columns: DetailsTableColumn<K>[];
  rows: T[];
}

export const DetailsTable = <
  K extends string,
  T extends Record<K, ReactNode> = Record<K, ReactNode>,
>({
  columns,
  rows,
}: DetailsTableProps<K, T>) => {
  const columnById = useMemo(
    () =>
      columns.reduce<Record<string, DetailsTableColumn<K> & { index: number }>>(
        (acc, curr, index) => {
          acc[curr.id] = { ...curr, index };
          return acc;
        },
        {},
      ),
    [columns],
  );

  const sortedRows = useMemo(
    () =>
      rows.map((row) => {
        const cells: [string, ReactNode][] = Object.entries(row);
        const sortedCells: [string, ReactNode][] = columns.map(({ id }) => [
          id,
          null,
        ]);
        cells.forEach((cell) => {
          const idx = columnById[cell[0]]?.index;
          if (typeof idx === "number") {
            sortedCells[idx] = cell;
          }
        });

        return sortedCells;
      }),
    [columnById, rows, columns],
  );

  return (
    <Flex className={s.table} vertical gap={8}>
      <Flex gap={8}>
        {columns.map(({ id, title, width }) => (
          <div key={id} className={s.table__cell} style={{ width }}>
            <Text color="secondary" variant="body-1">
              {title}
            </Text>
          </div>
        ))}
      </Flex>
      {sortedRows.map((row, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Flex gap={8} key={index}>
          {row.map(([columnId, content]) => (
            <div
              key={columnId}
              className={s.cell}
              style={{ width: columnById[columnId].width }}
            >
              <Text variant="body-5">{content}</Text>
            </div>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};
