import React, { FC, useCallback, useMemo, useState } from "react";
import { ReportCommonProps } from "@ui/entities/types";
import { RetrieveStatus } from "@ui/entities/RetrieveStatus/RetrieveStatus";
import {
  framePdf,
  generatePdf,
} from "@ui/entities/RetrieveTelegram/generatePdf";
import { ReportsTelegramRetrieveResponse } from "@shared/types/report/retrieve";
import { GalleryItem } from "@shared/types/common/types";
import s from "./RetrieveTelegram.module.scss";
import { FullDossier } from "../../components/FullDossier/FullDossier";
import { TelegramGroup } from "../../components/TelegramGroup/TelegramGroup";

type TRetrieveTelegramProps = {
  report: ReportsTelegramRetrieveResponse;
} & ReportCommonProps;

export const RetrieveTelegram: FC<TRetrieveTelegramProps> = ({
  report,
  reportId,
  type,
}) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const telegramInfo = report.telegram.telegram_info;
  const userPhones = useMemo(
    () =>
      telegramInfo.phones
        ? telegramInfo.phones.map((phone) => String(phone.phone))
        : null,
    [telegramInfo.phones],
  );
  const userPhotos: GalleryItem[] | null = useMemo(
    () =>
      telegramInfo.photos && telegramInfo.photos.length > 0
        ? [{ faces: telegramInfo.photos, score: telegramInfo.photos.length }]
        : null,
    [telegramInfo.photos],
  );

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({
      report,
      type,
      reportId,
      telegramInfo,
      userPhones,
      userPhotos,
    }).then(() => setIsLoadingGeneratePdf(false));
  }, [report, type, reportId, telegramInfo, userPhones, userPhotos]);

  const handleFramePdf = useCallback(
    async () =>
      framePdf({
        report,
        type,
        reportId,
        telegramInfo,
        userPhones,
        userPhotos,
      }),
    [report, type, reportId],
  );

  return (
    <RetrieveStatus status={report?.status}>
      <div className={s.container}>
        {report.request_describe && (
          <FullDossier
            requestDescribe={report.request_describe}
            phones={userPhones}
            telegramAddresses={
              telegramInfo.addresses ? [telegramInfo.addresses] : null
            }
            names={telegramInfo.names ? [telegramInfo.names] : null}
            telegramPhotos={userPhotos}
            userNames={telegramInfo.userNames?.map(
              (userName) => userName.userName,
            )}
            tags={telegramInfo.tags ? [telegramInfo.tags] : null}
            isBot={[telegramInfo.bot ? "Да" : "Нет"]}
            userId={telegramInfo.id ? [telegramInfo.id] : null}
            groupsCount={
              telegramInfo.groups ? [telegramInfo.groups.length] : null
            }
            region={telegramInfo.region ? [telegramInfo.region] : null}
            type={type}
            handleFramePdf={handleFramePdf}
            handleGeneratePdf={handleGeneratePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
            status={report?.status}
            reportId={reportId}
          />
        )}
        {telegramInfo.groups && telegramInfo.groups.length > 0 && (
          <TelegramGroup groups={telegramInfo.groups} />
        )}
      </div>
    </RetrieveStatus>
  );
};
