import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Text } from "@ui/ui/Text/Text";
import {
  useSessionsDeleteOther,
  useSessionsSearchQuery,
} from "@ui/app/api/session";
import { Button } from "@ui/ui/Button/Button";
import { useModalMessage } from "@ui/ui/Modal/useModalMessage";
import { CommonModal } from "@ui/components/CommonModal/CommonModal";
import { IoIosWarning } from "react-icons/io";
import { handleBackendErrors } from "@ui/utils/notifications/handleBackendErrors";
import { useNotification } from "@ui/hooks/useNotification";
import { SessionCard } from "./components/SessionCard/SessionCard";
import s from "./UserSettingsSessions.module.scss";

interface UserSettingsSessionsProps {}

export const UserSettingsSessions: FC<UserSettingsSessionsProps> = () => {
  const { data: sessions } = useSessionsSearchQuery({});
  const { mutateAsync: deleteOther, isPending: isSessionsDeleting } =
    useSessionsDeleteOther();
  const { openModal } = useModalMessage();

  const [api] = useNotification();

  const onDeleteConfirm = useCallback(() => {
    deleteOther({}).catch((error) => {
      handleBackendErrors({
        api,
        error,
      });
    });
  }, [deleteOther, api]);

  const onDeleteOther = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          icon={<IoIosWarning size={120} />}
          iconSize={120}
          iconWrapperClassName={s.confirm_modal__icon}
          buttonsVertical
          buttonsGap={16}
          title="Вы уверены, что хотите завершить сеансы? Действие невозможно будет отменить"
          primaryButtonProps={{
            type: "primary",
            children: "Да, продолжить",
            size: "l",
            onClick: onDeleteConfirm,
          }}
          secondaryButtonProps={{
            type: "flat_secondary",
            children: "Отменить",
            size: "l",
          }}
        />
      ),
      closable: true,
    });
  }, [onDeleteConfirm, openModal]);

  return (
    <Flex vertical gap={32}>
      <Text variant="header-6">Сеансы</Text>
      <Flex vertical gap={16}>
        {sessions
          ?.filter(Boolean)
          .map((session) => <SessionCard key={session.id} session={session} />)}
      </Flex>
      <Flex justify="center">
        <div className={s.closeAllSessionsButtonContainer}>
          <Button
            width="full"
            className={s.closeAllSessionsButton}
            type="flat"
            onClick={onDeleteOther}
            disabled={isSessionsDeleting}
          >
            <Text variant="body-4" color="accent">
              Завершить все сеансы
            </Text>
          </Button>
        </div>
      </Flex>
    </Flex>
  );
};
