import {
  PossibleNetworkResults,
  PossibleNetworks,
} from "@shared/types/report/retrieve";
import { useCallback, useState, ChangeEvent, useMemo, Fragment } from "react";
import { Flex } from "antd";
import { useReportsPossibleNetworksExportCsv } from "@ui/app/api/report/search/mutations";
import { RetrieveCard } from "@ui/components/RetrieveCard/RetrieveCard";
import { Input } from "@ui/ui/Input/Input";
import debounce from "lodash/debounce";
import { Text } from "@ui/ui/Text/Text";
import { Button } from "@ui/ui/Button/Button";
import { pluralize } from "@ui/utils/pluralize";
import { useModalMessage } from "@ui/ui/Modal/useModalMessage";
import { FrequentNetworks } from "@shared/types/report/retrieve/frequentNetworks";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { Expand } from "@ui/ui/Expand/Expand";
import { ReportStatus } from "@shared/types/report/constants";
import { ReactComponent as Download } from "src/assets/download.svg";
import { RelatedPersonsModal } from "./RelatedPersonsModal/RelatedPersonsModal";
import s from "./RelatedPersons.module.scss";

interface RelatedPersonsProps {
  possibleNetworks?: PossibleNetworks["result"];
  frequentNetworks?: FrequentNetworks["result"];
  status?: ReportStatus;
  reportId: string;
}

const PIVOT = 6;

export const RelatedPersons = ({
  possibleNetworks,
  frequentNetworks,
  status,
  reportId,
}: RelatedPersonsProps) => {
  const { openModal, closeModal } = useModalMessage();

  const { mutateAsync: getPossibleNetworksExportCsv } =
    useReportsPossibleNetworksExportCsv();

  const csvLinkOnClick = useCallback(() => {
    getPossibleNetworksExportCsv({ id: reportId }).then((response) => {
      const bom = "\uFEFF";
      const csvContent = bom + response;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `possible-networks-${reportId}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }, [reportId, getPossibleNetworksExportCsv]);

  const {
    state: isShowFullFrequentNetworks,
    toggle: toggleShowFullFrequentNetworks,
  } = useBooleanState(false);

  const show = useCallback(
    (selectedPossibleNetworks?: PossibleNetworkResults[]) =>
      openModal({
        children: (
          <RelatedPersonsModal
            possibleNetworks={selectedPossibleNetworks}
            onClose={closeModal}
          />
        ),
        maskClosable: true,
      }),
    [openModal, closeModal],
  );

  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const onDebouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 500),
    [],
  );

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
      onDebouncedSearch(e.currentTarget.value);
    },
    [onDebouncedSearch],
  );

  const groupsArrayFiltered: [string, PossibleNetworkResults[]][] =
    useMemo(() => {
      const searchTermLc = searchTerm.toLowerCase();

      return Object.entries(possibleNetworks || {})
        .map(([source, persons]) => {
          const filteredPersons = persons.filter(
            (name: PossibleNetworkResults) =>
              `${name?.last_name} ${name?.first_name} ${name?.middle_name}`
                .toLowerCase()
                .includes(searchTermLc),
          );

          return filteredPersons.length > 0 ? [source, filteredPersons] : null;
        })
        .filter((group) => group !== null) as [
        string,
        PossibleNetworkResults[],
      ][];
    }, [searchTerm, possibleNetworks]);

  if (
    status === ReportStatus.Finished &&
    (!possibleNetworks || Object.keys(possibleNetworks).length < 1)
  )
    return null;

  const renderFrequentNetworks = isShowFullFrequentNetworks
    ? frequentNetworks
    : frequentNetworks?.slice(0, PIVOT);

  return (
    <RetrieveCard
      dataId={4}
      title="Связанные лица"
      status={status}
      bottomTitle={
        <Input value={search} placeholder="Поиск" onChange={onSearch} />
      }
      subtitle={
        <button
          type="button"
          onClick={csvLinkOnClick}
          className={s.container__link}
        >
          <Text variant="subheader-2">Экспортировать в CSV</Text>
          <Download />
        </button>
      }
      isShowIncompleteData
    >
      <div className={s.container}>
        <Flex vertical gap={30}>
          <div className={s.container__table}>
            {frequentNetworks && frequentNetworks?.length > 0 && (
              <>
                <Text color="secondary" variant="body-1">
                  Часто используемые
                </Text>
                <Text color="secondary" variant="body-1">
                  Количество связей
                </Text>
              </>
            )}

            {renderFrequentNetworks?.map((frequentNetwork, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`frequent-networks-${index}`}>
                <Text variant="body-5">
                  {frequentNetwork.full_name_with_birth_date}
                </Text>
                <Text variant="body-5">{frequentNetwork.count}</Text>
              </Fragment>
            ))}

            {frequentNetworks && frequentNetworks?.length > PIVOT && (
              <div>
                <Expand
                  state={isShowFullFrequentNetworks}
                  toggle={toggleShowFullFrequentNetworks}
                  iconSize={20}
                  className={s.watchAll}
                >
                  {isShowFullFrequentNetworks
                    ? "Показать меньше"
                    : "Показать все"}
                </Expand>
              </div>
            )}
          </div>

          <div className={s.container__table}>
            {groupsArrayFiltered.length > 0 && (
              <>
                <Text color="secondary" variant="body-1">
                  Источник связи
                </Text>
                <Text color="secondary" variant="body-1">
                  Количество связей
                </Text>
              </>
            )}

            {groupsArrayFiltered.map(([source, persons], index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`groups-array-filtered-${index}`}>
                <Text variant="body-5">{source}</Text>
                <div>
                  <Text variant="body-5">
                    {persons.length}{" "}
                    {pluralize({
                      count: persons.length,
                      oneLabel: "связанное лицо",
                      fromTwoTillFourLabel: "связанных лица",
                      label: "связанных лиц",
                    })}
                  </Text>
                  <Button type="text_secondary" onClick={() => show(persons)}>
                    Показать
                  </Button>
                </div>
              </Fragment>
            ))}
          </div>
        </Flex>
      </div>
    </RetrieveCard>
  );
};
