import { FC } from "react";
import { Flex } from "antd";
import { Text } from "@ui/ui/Text/Text";
import { Link } from "react-router-dom";
import { AppRoute } from "@ui/app/routes/constants";
import { HistoryList } from "@ui/components/HistoryList/HistoryList";
import { ENavMenuItem, MainTab } from "@ui/pages/Main/constants";
import s from "./MainHistory.module.scss";

interface MainHistoryProps {
  tab: MainTab | ENavMenuItem;
}

export const MainHistory: FC<MainHistoryProps> = ({ tab }) => (
  <Flex vertical gap={32}>
    <Flex justify="space-between" align="center">
      <Text variant="header-6">Последние проверки</Text>
      <Text variant="body-4" color="primary">
        <Link to={AppRoute.History} className={s.link}>
          Смотреть все
        </Link>
      </Text>
    </Flex>
    <HistoryList withPagination limit={5} showFilters={false} tab={tab} />
  </Flex>
);
