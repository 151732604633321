import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { Flex } from "antd";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { History } from "@shared/types/report/retrieve/responses/vkRetrieve";
import { Row } from "./Row/Row";
import s from "./HistoryBlock.module.scss";

export type HistoryBlockProps = {
  history: History;
} & RetrieveCardCommonProps;

export const HistoryBlock = ({
  history,
  status,
  print = RetrieveCardPrint.device,
}: HistoryBlockProps) => (
  <RetrieveCard
    title={`Страница (${history.date})`}
    status={status}
    print={print}
  >
    <Flex vertical gap={12}>
      {history.photo && (
        <img src={history.photo} className={s.photo} alt="history-photo" />
      )}
      <Flex vertical gap={8}>
        {history?.first_name && <Row prop="Имя" value={history.first_name} />}
        {history?.last_name && <Row prop="Фамилия" value={history.last_name} />}
        {history?.birth_date && (
          <Row prop="Дата рождения" value={history.birth_date} />
        )}
        {history?.sex && <Row prop="Пол" value={history.sex} />}
        {history?.country && <Row prop="Страна" value={history.country} />}
        {history?.city && <Row prop="Город" value={history.city} />}
        {history?.about_me &&
          (history?.about_me?.alcohol ||
            history?.about_me?.life_main ||
            history?.about_me?.people_main ||
            history?.about_me?.smoking) && (
            <Row prop="О себе" properties={history.about_me} />
          )}
        {history?.busyness &&
          (history?.busyness?.id ||
            history?.busyness?.name ||
            history?.busyness?.type) && (
            <Row prop="Занятость" properties={history.busyness} />
          )}
      </Flex>
    </Flex>
  </RetrieveCard>
);
