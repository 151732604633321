import { FC, useCallback, useLayoutEffect, useState } from "react";
import FilterSortModal from "@ui/ui/FilterSortModal/FilterSortModal";
import { Radio } from "@ui/ui/Radio/Radio";
import { Text } from "@ui/ui/Text/Text";
import { OrderType } from "@shared/types/report/retrieve";
import { historyListSortingRules, historyListSortingRulesOrder } from "./types";
import { HistoryListSettingsStorage } from "../HistoryListFiltersStorage";
import { EHistoryListSetting } from "../../types";

interface IHistoryListSortingModalProps {
  setActiveSortingRule: (selectedSortingRule: OrderType) => void;
  activeSortingRule: OrderType;
}

const HistoryListSortingModal: FC<IHistoryListSortingModalProps> = ({
  setActiveSortingRule,
  activeSortingRule,
}) => {
  const [selectedSortingRule, setSelectedSortingRule] =
    useState<OrderType>(activeSortingRule);

  useLayoutEffect(() => {
    setSelectedSortingRule(activeSortingRule);
  }, [activeSortingRule]);

  const handleApplySorting = useCallback(() => {
    setActiveSortingRule(selectedSortingRule);
    HistoryListSettingsStorage.set(
      EHistoryListSetting.SORTING,
      selectedSortingRule,
    );
  }, [selectedSortingRule, setActiveSortingRule]);

  return (
    <FilterSortModal
      modalTitle="Сортировка"
      title="Сортировка"
      onApply={handleApplySorting}
      onCancel={() => setSelectedSortingRule(activeSortingRule)}
    >
      {historyListSortingRulesOrder.map((sortingRule: OrderType) => (
        <Radio
          key={sortingRule}
          checked={selectedSortingRule === sortingRule}
          onUpdate={(checked) => {
            if (checked) setSelectedSortingRule(sortingRule);
          }}
        >
          <Text variant="body-5">{historyListSortingRules[sortingRule]}</Text>
        </Radio>
      ))}
    </FilterSortModal>
  );
};

export default HistoryListSortingModal;
