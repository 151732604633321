import { useQuery } from "@tanstack/react-query";
import {
  ReportsRetrieveAllRequest,
  ReportsRetrieveRequest,
} from "@shared/types/report/retrieve";
import { ReportStatus, ReportType } from "@shared/types/report/constants";
import { getReportStatus } from "@ui/utils/getReportStatus";
import { QueryKey, REFETCH_INTERVAL } from "../../constants";
import {
  reportsAddressRetrieve,
  reportsCreditRatingRetrieve,
  reportsDriverLicenseRetrieve,
  reportsEmailRetrieve,
  reportsFioRetrieve,
  reportsFullCreditRatingRetrieve,
  reportsGrnRetrieve,
  reportsInfoRetrieve,
  reportsInnFlRetrieve,
  reportsInnUlRetrieve,
  reportsKadNumberRetrieve,
  reportsOgrnRetrieve,
  reportsPassportRetrieve,
  reportsPhoneRetrieve,
  reportsPhotoByFaceRetrieve,
  reportsPhotoByFioRetrieve,
  reportsRetrieveAll,
  reportsScoringRetrieve,
  reportsSnilsRetrieve,
  reportsTelegramRetrieve,
  reportsVinRetrieve,
  reportsVkRetrieve,
} from "./queries";

export const useReportsFioRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    enabled: Boolean(data.id),
    queryFn: () => reportsFioRetrieve(data),
    queryKey: QueryKey.ReportsFioRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPhoneRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    enabled: Boolean(data.id),
    queryFn: () => reportsPhoneRetrieve(data),
    queryKey: QueryKey.ReportsPhoneRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsAddressRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsAddressRetrieve(data),
    queryKey: QueryKey.ReportsAddressRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsKadNumberRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsKadNumberRetrieve(data),
    queryKey: QueryKey.ReportsKadNumberRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsInnFlRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsInnFlRetrieve(data),
    queryKey: QueryKey.ReportsInnFlRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsSnilsRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsSnilsRetrieve(data),
    queryKey: QueryKey.ReportsSnilsRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPassportRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsPassportRetrieve(data),
    queryKey: QueryKey.ReportsPassportRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsGrnRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsGrnRetrieve(data),
    queryKey: QueryKey.ReportsGrnRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsVinRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsVinRetrieve(data),
    queryKey: QueryKey.ReportsVinRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsDriverLicenseRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsDriverLicenseRetrieve(data),
    queryKey: QueryKey.ReportsDriverLicenseRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsEmailRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsEmailRetrieve(data),
    queryKey: QueryKey.ReportsEmailRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsInnUlRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsInnUlRetrieve(data),
    queryKey: QueryKey.ReportsInnUlRetrieve(data),
  });

export const useReportsOgrnRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsOgrnRetrieve(data),
    queryKey: QueryKey.ReportsOgrnRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPhotoByFaceRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsPhotoByFaceRetrieve(data),
    queryKey: QueryKey.ReportsPhotoByFaceRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsPhotoByFioRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsPhotoByFioRetrieve(data),
    queryKey: QueryKey.ReportsPhotoByFioRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsCreditRatingRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsCreditRatingRetrieve(data),
    queryKey: QueryKey.ReportsCreditRatingRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsFullCreditRatingRetrieve = (
  data: ReportsRetrieveRequest,
) =>
  useQuery({
    queryFn: () => reportsFullCreditRatingRetrieve(data),
    queryKey: QueryKey.ReportsFullCreditRatingRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsScoringRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsScoringRetrieve(data),
    queryKey: QueryKey.ReportsScoringRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsInfoRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsInfoRetrieve(data),
    queryKey: QueryKey.ReportsInfoRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsTelegramRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    enabled: Boolean(data.id),
    queryFn: () => reportsTelegramRetrieve(data),
    queryKey: QueryKey.ReportsTelegramRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

export const useReportsVkRetrieve = (data: ReportsRetrieveRequest) =>
  useQuery({
    queryFn: () => reportsVkRetrieve(data),
    queryKey: QueryKey.ReportsInfoRetrieve(data),
    refetchInterval: ({ state }) =>
      state.data?.status === ReportStatus.InProgress ? REFETCH_INTERVAL : false,
  });

const mapReportType: Record<
  ReportType,
  (data: ReportsRetrieveRequest) => Promise<unknown>
> = {
  [ReportType.SEARCH_ADDRESS]: reportsAddressRetrieve,
  [ReportType.SEARCH_CREDIT_REPORT]: reportsCreditRatingRetrieve,
  [ReportType.SEARCH_DRIVER_LICENSE]: reportsDriverLicenseRetrieve,
  [ReportType.SEARCH_EMAIL]: reportsEmailRetrieve,
  [ReportType.SEARCH_FIO]: reportsFioRetrieve,
  [ReportType.SEARCH_FULL_CREDIT_REPORT]: reportsFullCreditRatingRetrieve,
  [ReportType.SEARCH_GRN]: reportsGrnRetrieve,
  [ReportType.SEARCH_INFO]: reportsInfoRetrieve,
  [ReportType.SEARCH_INN_FL]: reportsInnFlRetrieve,
  [ReportType.SEARCH_INN_UL]: reportsInnUlRetrieve,
  [ReportType.SEARCH_KAD_NUMBER]: reportsKadNumberRetrieve,
  [ReportType.SEARCH_OGRN]: reportsOgrnRetrieve,
  [ReportType.SEARCH_PASSPORT]: reportsPassportRetrieve,
  [ReportType.SEARCH_PHONE]: reportsPhoneRetrieve,
  [ReportType.SEARCH_PHOTO_BY_IMAGE]: reportsPhotoByFaceRetrieve,
  [ReportType.SEARCH_SCORING]: reportsScoringRetrieve,
  [ReportType.SEARCH_SNILS]: reportsSnilsRetrieve,
  [ReportType.SEARCH_VIN]: reportsVinRetrieve,
  [ReportType.SEARCH_PHOTO_BY_FIO]: reportsPhotoByFioRetrieve,
  [ReportType.SEARCH_TELEGRAM]: reportsTelegramRetrieve,
  [ReportType.SEARCH_VK]: reportsPhotoByFioRetrieve,
};

export const useReportsRetrieve = (
  type: ReportType,
  data: ReportsRetrieveRequest,
) =>
  useQuery({
    enabled: Boolean(data.id),
    queryFn: () => {
      const fn = mapReportType[type];
      return fn?.(data);
    },
    queryKey: QueryKey.ReportsRetrieve(type, data),
    refetchInterval: ({ state }) => {
      if (
        typeof state.data === "object" &&
        state.data !== null &&
        "status" in state.data
      ) {
        const { status } = state.data;
        return status === ReportStatus.InProgress ? REFETCH_INTERVAL : false;
      }
      return false;
    },
  });

export const useReportsRetrieveAll = (
  data: ReportsRetrieveAllRequest,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    enabled,
    queryFn: () => reportsRetrieveAll(data),
    queryKey: QueryKey.Reports(data),
    refetchInterval: ({ state }) => {
      const isSomeReportInProgress = state.data?.some(
        (report) =>
          getReportStatus({
            found: report?.found,
            sourceStatuses: report?.source_statuses,
          }) === ReportStatus.InProgress,
      );

      return isSomeReportInProgress ? REFETCH_INTERVAL : false;
    },
  });
