import { Permission } from "@ui/app/api/groups";
import { MainTab } from "@ui/pages/Main/constants";
import { mapPermissionToMainTab } from "./constants";

export const getAvailableCheckTabs = <T extends { key: string }[]>(
  tabs: T | undefined,
  permissions?: Permission[],
): T | undefined => {
  if (!tabs) return undefined;

  if (!permissions || permissions.includes(Permission.SuperuserPermission)) {
    return tabs;
  }

  const availableTabKeys: MainTab[] = permissions
    .map((permission) => mapPermissionToMainTab[permission] as MainTab)
    .filter((permission) => permission);

  const availableTabKeysSet = new Set(availableTabKeys);

  return tabs.filter(
    (tab) => tab.key && availableTabKeysSet.has(tab.key as MainTab),
  ) as T;
};
