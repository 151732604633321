import { FC } from "react";
import { Flex, Modal } from "antd";
import { Score } from "@ui/components/Score/Score";
import { Text } from "@ui/ui/Text/Text";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { Button } from "@ui/ui/Button/Button";
import { BiChevronLeft } from "react-icons/bi";
import { Candidate } from "@shared/types/report/retrieve";
import s from "./PhotoComparison.module.scss";

interface IPhotoComparisonProps {
  candidate: Candidate;
  sourcePhoto?: string;
}

const PhotoComparison: FC<IPhotoComparisonProps> = ({
  candidate,
  sourcePhoto,
}) => {
  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);
  const {
    state: isSourcePhotoVisible,
    setTrue: showSourcePhoto,
    setFalse: hideSourcePhoto,
  } = useBooleanState(false);

  return (
    <>
      <button type="button" onClick={showModal}>
        <img
          src={candidate.photos?.[0]}
          alt={candidate.collection_obj?.alias}
          className={s.photo}
        />
      </button>
      <Modal
        destroyOnClose
        rootClassName={s.modalRoot}
        style={{ maxWidth: isSourcePhotoVisible ? "65%" : "35%" }}
        open={isModalVisible}
        onCancel={() => {
          hideModal();
          hideSourcePhoto();
        }}
        footer={null}
      >
        <Flex align="center" justify="center">
          {isSourcePhotoVisible && (
            <span className={s.photoInModalContainer}>
              <div className={s.photoInModalContainer__helper}>
                <div
                  className={s.photoInModalContainer__helper__sourcePhotoText}
                >
                  <Text>Загруженное фото</Text>
                </div>
              </div>
              <img
                className={s.photoInModalContainer__photoInModal}
                src={`data:image/png;base64,${sourcePhoto}`}
                alt="source"
              />
            </span>
          )}
          <span className={s.photoInModalContainer}>
            <div className={s.photoInModalContainer__helper}>
              <Score score={candidate.score} />
            </div>
            <img
              className={s.photoInModalContainer__photoInModal}
              src={candidate.photos?.[0]}
              alt={candidate.collection_obj?.alias}
            />
          </span>
        </Flex>
        <div className={s.footer}>
          {isSourcePhotoVisible ? (
            <Button onClick={hideSourcePhoto} className={s.footer__backButton}>
              <BiChevronLeft size={20} /> Назад к фотографии
            </Button>
          ) : (
            <Flex gap={16} justify="space-between" align="center">
              <Flex gap={16} align="center">
                <Score score={candidate.score} />
                <Text variant="label-3" color="white">
                  {candidate.meta?.name}
                </Text>
                <Text color="white">{candidate.meta?.birthday}</Text>
              </Flex>
              <Button
                className={s.footer__compareButton}
                onClick={showSourcePhoto}
              >
                Сравнить фотографии
              </Button>
            </Flex>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PhotoComparison;
