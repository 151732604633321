import { ReportsFoundPersons } from "@ui/components/ReportsFoundPersons/ReportsFoundPersons";
import { Data } from "@ui/components/Data/Data";
import { RelatedPersons } from "@ui/components/RelatedPersons/RelatedPersons";
import { ReportCommonProps } from "@ui/entities/types";
import { calculateReportStatus } from "@ui/utils/getReportStatus";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { RetrieveStatus } from "@ui/entities/RetrieveStatus/RetrieveStatus";
import { OrderMap } from "@ui/entities/SearchPhone/components/OrderMap/OrderMap";
import { OpenedData } from "@ui/entities/SearchFull/components/OpenedData/OpenedData";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  isReportsOpenSourcesIoSphere,
  ReportsPhoneRetrieveResponse,
} from "@shared/types/report/retrieve";
import { FoundStatus, ReportStatus } from "@shared/types/report/constants";
import { Expand } from "@ui/ui/Expand/Expand";
import { Text } from "@ui/ui/Text/Text";
import cn from "classnames";
// eslint-disable-next-line import/no-cycle
import { MultiQueryReportsByType } from "@ui/entities/MultiQueryReportsByType/MultiQueryReportsByType";
import { EMultiQueryKeys } from "@shared/types/report/retrieve/multiQuery";
import { MultiQueryDossier } from "@ui/components/MultiQueryDossier/MultiQueryDossier";
import { Flex } from "antd";
import { useSubReportsVisibility } from "@ui/pages/Root/components/SubReportsVisibilityContextProps/useSubReportsVisibility";
import { PhoneBook } from "./components/PhoneBook/PhoneBook";
import s from "./SearchPhone.module.scss";
import { framePdf, generatePdf } from "./generatePdf";

export type SearchPhoneProps = {
  report: ReportsPhoneRetrieveResponse;
  isSubReport?: boolean;
} & ReportCommonProps;

export const SearchPhone = ({
  report,
  type,
  reportId,
  isSubReport,
}: SearchPhoneProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);
  const { visibilityState, setVisibilityState } = useSubReportsVisibility();
  const isMultiQueryReport = useMemo(
    () => report.multi_query?.result !== null,
    [report.multi_query?.result],
  );
  const expanded: boolean = useMemo(
    () => !!report.request_describe && visibilityState[report.request_describe],
    [report.request_describe, visibilityState],
  );
  const toggleExpanded = useCallback(() => {
    if (report.request_describe)
      setVisibilityState(report.request_describe, !expanded);
  }, [expanded, report.request_describe, setVisibilityState]);

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);

  useEffect(() => {
    if (report.request_describe)
      setVisibilityState(report.request_describe, !isSubReport);
  }, []);

  const handleFramePdf = useCallback(
    async () => framePdf({ report, type, reportId }),
    [report, type, reportId],
  );

  return (
    <RetrieveStatus
      isSubReport={isSubReport}
      status={
        calculateReportStatus({
          sourceStatuses: [
            report?.person?.status,
            report?.sub_reports?.status,
            report?.possible_networks?.status,
            report?.open_sources?.iosphere?.status,
          ],
          person: report?.person?.status,
          found: report?.person?.found === FoundStatus.found,
        }) || report?.status
      }
    >
      {report.multi_query && isMultiQueryReport && (
        <MultiQueryDossier
          multiQueryReport={report.multi_query}
          type={type}
          status={report.multi_query.status}
          reportId={reportId}
          handleGeneratePdf={handleGeneratePdf}
          handleFramePdf={handleFramePdf}
          note={report.note}
          isLoadingGeneratePdf={isLoadingGeneratePdf}
          requestDescribe={report?.request_describe}
        />
      )}
      <div
        id={isSubReport ? report?.request_describe : undefined}
        data-name={isSubReport ? "block-sub-report" : undefined}
        className={cn(
          s.container,
          isMultiQueryReport && s.containerWithMultiSearch,
        )}
      >
        {report?.person?.status && (
          <FullDossier
            isSubReport={isSubReport}
            isMultiQueryReport={isMultiQueryReport}
            phones={report?.person?.person_info?.phones}
            snilses={report?.person?.person_info?.snilses}
            inns={report?.person?.person_info?.inns}
            passports={report?.person.person_info?.passports}
            emails={report?.person?.person_info?.emails}
            requestDescribe={report?.request_describe}
            autos={report?.person?.person_info?.autos}
            persons={report?.person?.person_info?.persons}
            type={type}
            status={report?.person?.status}
            otherLastNames={report?.other_last_names}
            handleGeneratePdf={handleGeneratePdf}
            handleFramePdf={handleFramePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
            reportId={reportId}
          />
        )}
        {(!isSubReport || (isSubReport && expanded)) && (
          <>
            {report?.sub_reports?.status && (
              <ReportsFoundPersons
                report_list={report?.sub_reports?.report_list}
                status={report?.sub_reports?.status}
              />
            )}
            {report?.possible_networks?.status && (
              <RelatedPersons
                reportId={reportId}
                possibleNetworks={report?.possible_networks?.result}
                frequentNetworks={report?.frequent_networks?.result}
                status={report?.possible_networks?.status}
              />
            )}
            {report?.open_sources && (
              <OpenedData
                isPhoneSearch
                openSources={report?.open_sources}
                status={
                  report?.open_sources &&
                  isReportsOpenSourcesIoSphere(report?.open_sources)
                    ? report?.open_sources?.iosphere?.status
                    : ReportStatus.Finished
                }
              />
            )}
            {report?.person?.status && (
              <PhoneBook
                phoneBook={report?.person?.person_info?.phone_book}
                status={report?.person?.status}
              />
            )}
            {report?.person?.status && Boolean(report?.order_map?.found) && (
              <OrderMap
                status={report?.order_map?.status}
                orderMap={report?.order_map?.data}
              />
            )}
            {report?.person?.person_info?.data?.map(
              (reportsPersonInfoData, index) => (
                <Data
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  reportsPersonInfoData={reportsPersonInfoData}
                  status={report?.person?.status}
                  isSubReport={isSubReport}
                />
              ),
            )}
          </>
        )}
        {isSubReport && (
          <Flex justify="center">
            <Expand
              state={expanded}
              toggle={toggleExpanded}
              iconSize={20}
              className={s.container__expand}
            >
              <Text color="inherit">{`${expanded ? "Скрыть" : "Показать"} отчет`}</Text>
            </Expand>
          </Flex>
        )}
      </div>
      {report.multi_query &&
        isMultiQueryReport &&
        Object.entries(report.multi_query.result).map(([key, value]) => {
          const multiQueryRequests = Object.values(value);
          if (!multiQueryRequests.length) return null;

          return (
            <MultiQueryReportsByType
              key={key}
              multiQueryRequests={value}
              multiQueryKey={key as EMultiQueryKeys}
            />
          );
        })}
    </RetrieveStatus>
  );
};
