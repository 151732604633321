import { useCallback, useState } from "react";

import { Header } from "@ui/pages/Analytics/components/Header";
import { GraphBlock } from "@ui/pages/Analytics/components/GraphBlock/GraphBlock";
import { PieBlock } from "@ui/pages/Analytics/components/PieBlock/PieBlock";
import { SettingsModal } from "@ui/pages/Analytics/components/SettingsModal/SettingsModal";
import { useParams } from "react-router-dom";
import { useGroup, User } from "@ui/app/api/groups";
import _ from "lodash";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { isUserAdministrator } from "@ui/utils/permissions/isUserAdministrator";
import { StatisticsInterval } from "@ui/app/api/analytics/types";
import { lowestGranularity } from "@ui/app/api/analytics/queries";
import { useAppFeatures } from "@ui/app/providers";
import { useModalMessage } from "../../ui/Modal/useModalMessage";
import s from "./Analytics.module.scss";
import { OnboardingModalContainer } from "../../components/OnboardingModalContainer";
import { AnalyticsFilters } from "./types";
import { SettingsModalBlock } from "./components/SettingsModal/constants";

const defaultValue: AnalyticsFilters = {
  granularity: lowestGranularity,
  interval: StatisticsInterval.all,
  startDate: undefined,
  endDate: undefined,
  graphReportType: undefined,
  pieReportType: undefined,
  userIds: undefined,
};

const Analytics = () => {
  const [filters, setFilters] = useState<AnalyticsFilters>(defaultValue);
  const params = useParams<{
    group_id?: string;
    user_id?: string;
  }>();
  const group_id = Number(params.group_id);
  const user_id = Number(params.user_id);

  const { data: group } = useGroup(
    { group_id },
    { enabled: Boolean(group_id) },
  );
  const { data: user } = useCurrentUserQuery();
  const userIds = group?.users?.map((i) => i.user_id);
  const usersById: Record<User["user_id"], User> = _?.keyBy(
    group?.users,
    "user_id",
  );

  const onClearFilters = useCallback(() => {
    setFilters(defaultValue);
  }, []);

  const { openModal, closeModal } = useModalMessage();

  const openModalSettings = useCallback(
    (visibleBlocks: Set<SettingsModalBlock>) => {
      openModal({
        children: (
          <SettingsModal
            visibleBlocks={visibleBlocks}
            defaultValue={filters}
            allUserIds={userIds ? new Set(userIds) : undefined}
            usersById={usersById}
            onClear={onClearFilters}
            onSubmit={setFilters}
            onClose={closeModal}
          />
        ),
      });
    },
    [closeModal, filters, userIds, usersById, openModal, onClearFilters],
  );

  const onOpenHeaderSettings = useCallback(() => {
    openModalSettings(new Set([SettingsModalBlock.Interval]));
  }, [openModalSettings]);

  const onOpenGraphBlockSettings = useCallback(() => {
    openModalSettings(
      new Set([
        SettingsModalBlock.Granularity,
        SettingsModalBlock.Interval,
        SettingsModalBlock.GraphReportType,
      ]),
    );
  }, [openModalSettings]);

  const onOpenPieBlockSettings = useCallback(() => {
    const visibleBlocks = new Set([
      SettingsModalBlock.Interval,
      SettingsModalBlock.PieReportType,
    ]);

    if (isUserAdministrator(user)) {
      visibleBlocks.add(SettingsModalBlock.UserIds);
    }

    openModalSettings(visibleBlocks);
  }, [openModalSettings, user]);

  const { onboardingEnabled } = useAppFeatures();

  return (
    <div className={s.container}>
      <Header onOpenSettings={onOpenHeaderSettings} />
      <GraphBlock
        filters={filters}
        group_id={group_id}
        user_id={user_id}
        onOpenSettings={onOpenGraphBlockSettings}
      />
      <PieBlock
        filters={filters}
        group_id={group_id}
        onOpenSettings={onOpenPieBlockSettings}
      />
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="analytics" />
      ) : null}
    </div>
  );
};

export default Analytics;
