import { FC } from "react";
import { Text } from "@ui/ui/Text/Text";
import cn from "classnames";
import s from "./CurrentDate.module.scss";

interface CurrentDateProps {
  date: string;
  isHidden: boolean;
}

export const CurrentDate: FC<CurrentDateProps> = ({ date, isHidden }) => (
  <div className={cn({ [s.hidden]: !isHidden }, s.date_message)}>
    <Text variant="body-2" color="secondary">
      {date}
    </Text>
  </div>
);
