import { useCallback, useMemo } from "react";
import { Text } from "@ui/ui/Text/Text";
import cn from "classnames";
import { ReactComponent as SwitchRight } from "src/assets/switch_right.svg";
import { ReactComponent as Informer } from "src/assets/informer.svg";
import { usePagination } from "@ui/hooks/usePagination";
import { Pagination } from "@ui/ui/Pagination/Pagination";
import { Flex, Tooltip } from "antd";
import { DatePicker } from "@ui/ui/DatePicker/DatePicker";
import dayjs from "dayjs";
import {
  CrossingBorderItemEnum,
  crossingBorderItemEnumOrder,
  crossingBorderItemTitles,
} from "@shared/types/report/retrieve";
import s from "./CrossingBordersTable.module.scss";
import { CrossingBordersFilters } from "../../types";
import { CrossingBordersDateOrder } from "../../constants";
import { mapCrossingBorderItemEnumToIcon } from "./constants";

type DataType<KeyType extends string> = Record<KeyType, string>;

interface CrossingBordersTableProps<KeyType extends string> {
  data?: DataType<KeyType>[];
  limit: number;
  filters: CrossingBordersFilters;
  onFiltersChange: (filters: CrossingBordersFilters) => void;
  dateSortOrder: CrossingBordersDateOrder;
  onToggleDateSortOrder: () => void;
}

export const CrossingBordersTable = <KeyType extends string>({
  data = [],
  limit,
  filters,
  onFiltersChange,
  dateSortOrder,
  onToggleDateSortOrder,
}: CrossingBordersTableProps<KeyType>) => {
  const { page, setPage, dataSegment } = usePagination<Record<string, string>>({
    data,
    limit,
  });

  const onStartDateHandler = useCallback(
    (startDate: dayjs.Dayjs) => {
      setPage(1);
      onFiltersChange({ ...filters, startDate });
    },
    [onFiltersChange, setPage, filters],
  );

  const onEndDateHandler = useCallback(
    (endDate: dayjs.Dayjs) => {
      setPage(1);
      onFiltersChange({ ...filters, endDate });
    },
    [onFiltersChange, setPage, filters],
  );

  const dataMultiplesOfLimit = useMemo(() => {
    const lastPageItems = dataSegment.length % limit;
    if (lastPageItems === 0) {
      return dataSegment;
    }

    const result = dataSegment.slice();
    for (let i = 0; i < limit - lastPageItems; i += 1) {
      result.push({});
    }
    return result;
  }, [limit, dataSegment]);

  return (
    <div className={s.table}>
      <Flex className={s.table__header}>
        <div className={s.table__header__aside} />
        <div className={s.table__header__content}>
          <div className={s.table__header__datepickers}>
            <DatePicker
              defaultValue={filters?.startDate}
              onChange={onStartDateHandler}
              className={s.table__header__datepicker}
            />
            <DatePicker
              defaultValue={filters?.endDate}
              onChange={onEndDateHandler}
              className={s.table__header__datepicker}
            />
          </div>
        </div>
      </Flex>
      <Flex className={s.table__main}>
        <div className={s.table__main__aside}>
          {crossingBorderItemEnumOrder.map((header) => {
            const Icon = mapCrossingBorderItemEnumToIcon[header];

            return (
              <div
                key={header}
                className={cn(s.table__item, s.table__main__aside_with_action)}
              >
                <Text
                  variant="header-1"
                  color="secondary"
                  className={cn(
                    s.table__item__text,
                    s.table__main__aside__text,
                  )}
                  ellipsis
                >
                  {crossingBorderItemTitles[header]}
                </Text>
                <Icon className={s.table__main__aside__icon} />
                {header === CrossingBorderItemEnum.crossingDate ? (
                  <button
                    aria-label="switch-sort-date-order"
                    onClick={onToggleDateSortOrder}
                    type="button"
                    className={s.table__main__aside__action}
                  >
                    <SwitchRight
                      className={cn({
                        [s["table__main__aside__action_sort-icon_desc"]]:
                          dateSortOrder === CrossingBordersDateOrder.Desc,
                      })}
                    />
                  </button>
                ) : (
                  <Tooltip
                    title={
                      <Text color="white">
                        {crossingBorderItemTitles[header]}
                      </Text>
                    }
                  >
                    <Informer
                      className={s["table__main__aside__action__info-icon"]}
                    />
                  </Tooltip>
                )}
              </div>
            );
          })}
        </div>
        <div
          className={s.table__main__content}
          style={{
            gridTemplateColumns: `repeat(${dataMultiplesOfLimit.length}, minmax(210px, 1fr))`,
          }}
        >
          {crossingBorderItemEnumOrder.map((header) =>
            dataMultiplesOfLimit.map(
              (dataItem: Record<string, string>, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={header + index}
                  className={cn(s.table__item, {
                    [s.table__item_odd]: index % 2 === 1,
                  })}
                >
                  <Tooltip
                    title={<Text color="white">{dataItem[header]}</Text>}
                  >
                    <Text
                      variant="header-1"
                      className={s.table__item__text}
                      ellipsis
                    >
                      {dataItem[header]}
                    </Text>
                  </Tooltip>
                </div>
              ),
            ),
          )}
        </div>
      </Flex>
      <Flex className={s.table__footer}>
        <div className={s.table__footer__aside} />
        <Flex
          className={s.table__footer__content}
          align="center"
          justify="center"
        >
          <Pagination
            className={s.table__footer__pagination}
            page={page}
            total={data.length}
            onUpdate={setPage}
            pageSize={limit}
          />
        </Flex>
      </Flex>
    </div>
  );
};
