import { useQuery } from "@tanstack/react-query";

import { current_user } from "../queries";

export const useCurrentUserQuery = () =>
  useQuery({
    queryFn: current_user,
    queryKey: ["currentUser"],
    refetchInterval: 10000,
  });
