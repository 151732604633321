import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { ReportCommonProps } from "@ui/entities/types";
import { useCallback, useState } from "react";
import { RetrieveStatus } from "@ui/entities/RetrieveStatus/RetrieveStatus";
import { calculateReportStatus } from "@ui/utils/getReportStatus";
import { useNotification } from "@ui/hooks/useNotification";
import { ReportsFullCreditRatingRetrieveResponse } from "@shared/types/report/retrieve";
import { ReportStatus } from "@shared/types/report/constants";
import { generatePdf, framePdf } from "./generatePdf";
import { FullCreditRatingResult } from "./components/FullCreditRatingResult/FullCreditRatingResult";
import s from "./RetrieveFullCreditRating.module.scss";
import { handleBackendErrors } from "../../utils/notifications/handleBackendErrors";

export type RetrieveFullCreditRatingProps = {
  report: ReportsFullCreditRatingRetrieveResponse;
} & ReportCommonProps;

export const RetrieveFullCreditRating = ({
  report,
  type,
  reportId,
}: RetrieveFullCreditRatingProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const [api] = useNotification();

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId })
      .then(() => setIsLoadingGeneratePdf(false))
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
        });
      });
  }, [report, type, reportId, api]);

  const handleFramePdf = useCallback(
    async () => framePdf({ report, type, reportId }),
    [report, type, reportId],
  );

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.credit_rating?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <div className={s.container}>
        {report?.request_describe && (
          <FullDossier
            requestDescribe={report?.request_describe}
            type={type}
            handleGeneratePdf={handleGeneratePdf}
            handleFramePdf={handleFramePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
            title=""
            reportId={reportId}
          />
        )}
        <FullCreditRatingResult
          creditRating={report?.credit_rating}
          status={report?.credit_rating?.status}
        />
      </div>
    </RetrieveStatus>
  );
};
