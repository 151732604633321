import { ReportStatus } from "@shared/types/report/constants";

interface CalculateReportStatusParams {
  sourceStatuses: (ReportStatus | undefined)[];
  person?: ReportStatus;
  found?: boolean;
}

export const calculateReportStatus = ({
  sourceStatuses,
  person,
  found,
}: CalculateReportStatusParams): ReportStatus | undefined => {
  const sourceStatusesSet = new Set(
    sourceStatuses?.filter((sourceStatus) => sourceStatus),
  );

  if (sourceStatusesSet.size === 0) return undefined;

  if (sourceStatusesSet.has(ReportStatus.InProgress))
    return ReportStatus.InProgress;

  if (
    person
      ? person === ReportStatus.Canceled
      : sourceStatusesSet.has(ReportStatus.Canceled)
  )
    return ReportStatus.Canceled;

  if (
    (person
      ? person === ReportStatus.Finished
      : sourceStatusesSet.has(ReportStatus.Finished)) &&
    found
  )
    return ReportStatus.Finished;

  if (
    (person
      ? person === ReportStatus.Finished
      : sourceStatusesSet.has(ReportStatus.Finished)) &&
    !found
  )
    return ReportStatus.NoData;

  return undefined;
};

interface GetReportStatus {
  sourceStatuses?: Record<string, ReportStatus>;
  found?: boolean;
}

export const getReportStatus = ({
  sourceStatuses,
  found = true,
}: GetReportStatus): ReportStatus | undefined => {
  if (!sourceStatuses) return undefined;

  const sourceStatusesValues: ReportStatus[] = Object.values(sourceStatuses);

  return calculateReportStatus({
    sourceStatuses: sourceStatusesValues,
    found,
    person: sourceStatuses?.person,
  });
};
