import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { Item } from "@ui/entities/RetrieveVk/components/StatisticBlock/Item/Item";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { Statistic } from "@shared/types/report/retrieve/responses/vkRetrieve";
import s from "./StatisticBlock.module.scss";

export type StatisticBlockProps = {
  statistic: Statistic;
} & RetrieveCardCommonProps;
export const StatisticBlock = ({
  statistic,
  status,
  print = RetrieveCardPrint.device,
}: StatisticBlockProps) => (
  <RetrieveCard title="Анализ по друзьям" status={status} print={print}>
    <div className={s.container}>
      <Item
        print={print}
        title="Страны"
        items={statistic?.countries?.map(
          (country) => `${country.count} ${country.name}`,
        )}
      />
      <Item
        print={print}
        title="Города"
        items={statistic?.cities?.map((city) => `${city.count} ${city.name}`)}
      />
      <Item
        print={print}
        title="Университеты"
        items={statistic?.universities?.map(
          (university) => `${university.count} ${university.name}`,
        )}
      />
      <Item
        print={print}
        title="Город по университету"
        items={statistic?.university_cities?.map(
          (university_city) =>
            `${university_city.count} ${university_city.name}`,
        )}
      />
      <Item
        print={print}
        title="Школы"
        items={statistic?.schools?.map(
          (school) => `${school.count} ${school.name}`,
        )}
      />
      <Item
        print={print}
        title="Город по школам"
        items={statistic?.school_cities?.map(
          (school_city) => `${school_city.count} ${school_city.name}`,
        )}
      />
      <Item
        print={print}
        title="Город по работам"
        items={statistic?.career_cities?.map(
          (career_city) => `${career_city.count} ${career_city.name}`,
        )}
      />
      <div />
    </div>
  </RetrieveCard>
);
