import { FC, useCallback } from "react";
import { Alert, Flex } from "antd";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { UserCard } from "@ui/components/UserCard/UserCard";
import { Text } from "@ui/ui/Text/Text";
import { ReactComponent as Error } from "src/assets/error.svg";
import { Button } from "@ui/ui/Button/Button";
import { AppRoute } from "@ui/app/routes/constants";
import { Link } from "react-router-dom";
import { useLogout } from "@ui/app/api/auth/mutations/useLogout";
import { handleBackendErrors } from "@ui/utils/notifications/handleBackendErrors";
import { useNotification } from "@ui/hooks/useNotification";
import { toHumanReadableDate } from "@ui/utils/date/toHumanReadableDate";
import dayjs from "dayjs";
import { getPluralRule } from "@ui/utils/getPluralRule";
import { pluralize } from "@ui/utils/pluralize";
import { useAppFeatures } from "@ui/app/providers";
import s from "./MainLayoutUserMenu.module.scss";
import { getIsExpireSoon } from "./utils/getIsExpireSoon";

const mapPluralRuleToLabel: Record<Intl.LDMLPluralRule, string> = {
  few: "дней",
  many: "дней",
  one: "день",
  other: "дней",
  two: "дня",
  zero: "дней",
};

interface MainLayoutUserMenuProps {
  onClose: () => void;
}

export const MainLayoutUserMenu: FC<MainLayoutUserMenuProps> = ({
  onClose,
}) => {
  const { data: user } = useCurrentUserQuery();
  const { mutateAsync: logout, isPending: isLogoutPending } = useLogout();

  const [api] = useNotification();

  const onLogout = useCallback(() => {
    logout({})
      .then(() => {
        onClose();
      })
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
        });
      });
  }, [api, logout, onClose]);

  const { documentsPageEnabled } = useAppFeatures();

  if (!user) {
    return null;
  }

  const expiresAtDayjs = dayjs(user.expires_at, "DD.MM.YYYY hh:mm:ss");
  const diffDays = expiresAtDayjs.diff(undefined, "days");
  const pluralRule = getPluralRule(diffDays);
  const remainingUserRequestsCount =
    user.request_quota === null
      ? null
      : user.request_quota - user.request_usage;

  const remainingGroupRequestsCount =
    remainingUserRequestsCount === null && user.groups[0].request_quota !== null
      ? user.groups[0].request_quota - user.groups[0].request_usage
      : null;

  return (
    <Flex vertical gap={16} className={s.menu}>
      <Link to={AppRoute.User} className={s.menu__link} onClick={onClose}>
        <UserCard user={user} type="flat" size="s" />
      </Link>
      {getIsExpireSoon(user) ? (
        <Alert
          icon={<Error height={12} width={12} />}
          showIcon
          type="error"
          message={
            <Flex vertical>
              <Text variant="label-1">Истекает срок договора</Text>
              <Text variant="body-1">
                Осталось {diffDays}{" "}
                {mapPluralRuleToLabel[pluralRule] || mapPluralRuleToLabel.other}
                . До {toHumanReadableDate(expiresAtDayjs.toISOString())}
              </Text>
            </Flex>
          }
        />
      ) : null}
      <Flex vertical className={s.menu__card} gap={8}>
        <Flex vertical gap={4}>
          <Text color="secondary" variant="body-1">
            Остаток проверок на балансе
          </Text>
          {remainingUserRequestsCount !== null ||
          remainingGroupRequestsCount !== null ? (
            <Text variant="label-3">
              {remainingUserRequestsCount || remainingGroupRequestsCount
                ? "до"
                : ""}{" "}
              {remainingUserRequestsCount || remainingGroupRequestsCount}{" "}
              {pluralize({
                count:
                  remainingUserRequestsCount ||
                  remainingGroupRequestsCount ||
                  0,
                fromTwoTillFourLabel: "проверки",
                label: "проверок",
                oneLabel: "проверка",
              })}
            </Text>
          ) : (
            <Text variant="label-3">Безлимит</Text>
          )}
        </Flex>
        <Flex vertical gap={4}>
          <Text color="secondary" variant="body-1">
            Совершено проверок
          </Text>
          <Flex gap={4} component="span" align="baseline">
            <Text variant="subheader-4">{user.request_usage}</Text>
            <Text variant="body-1">
              {pluralize({
                count: user.request_usage,
                fromTwoTillFourLabel: "стандартные проверки",
                label: "стандартных проверок",
                oneLabel: "стандартная проверка",
              })}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical gap={12} className={s.menu__footer}>
        {documentsPageEnabled ? (
          <Link
            to={AppRoute.Documents}
            className={s.menu__link}
            onClick={onClose}
          >
            <Text variant="body-5">Мои документы</Text>
          </Link>
        ) : null}
        <Link to={AppRoute.User} className={s.menu__link} onClick={onClose}>
          <Text variant="body-5">Аккаунт и безопасность</Text>
        </Link>
        <Button
          type="text"
          onClick={onLogout}
          disabled={isLogoutPending}
          className={s.menu__button}
        >
          <Text variant="body-5">Выход</Text>
        </Button>
      </Flex>
    </Flex>
  );
};
