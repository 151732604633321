import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "@ui/ui/Input/Input";
import { SubmitButton } from "@ui/pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import {
  MainTabFieldValues,
  MainTabProps,
} from "@ui/pages/Main/components/MainTabs/types";
import { dropZoneFileListSchema } from "@ui/pages/Main/components/MainTabs/constants";
import { correctKadNumberRegexCallback } from "@ui/utils/validation";
import { validationMessage } from "@ui/utils/constants";
import { useReportsKadNumberSearch } from "@ui/app/api/report/search/mutations";
import s from "./MainTabAddressCadastral.module.scss";

interface MainTabAddressCadastralFormValues extends MainTabFieldValues {
  kad_number: string;
}

const schema = yup
  .object<MainTabAddressCadastralFormValues>()
  .shape({
    kad_number: yup
      .string()
      .test(
        "valid-kad",
        validationMessage.WrongMiddleName,
        correctKadNumberRegexCallback,
      )
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabAddressCadastralProps = MainTabProps;

export const MainTabAddressCadastral: FC<MainTabAddressCadastralProps> = ({
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      kad_number: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsKadNumberSearch();

  const onSubmitInner = useCallback(
    (requestData) =>
      onSubmit({ requestData, search, withSopd: true }).then(() => reset()),
    [search, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="kad_number"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              mask="00:00:0000000:0000"
              placeholder="Кадастровый номер"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
