import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Flex, Upload, UploadFile } from "antd";
import { UploadRef } from "antd/es/upload/Upload";
import classNames from "classnames";
import { useNotification } from "@ui/hooks/useNotification";
import s from "./DropZone.module.scss";
import { Button } from "../Button/Button";
import { Text } from "../Text/Text";

export interface DropZoneFile {
  uid: string;
  name: string;
  lastModifiedDate: Date;
  originFileObj: File;
}

export interface DropZoneProps extends PropsWithChildren {
  value?: DropZoneFile[];
  onChange?: (value: DropZoneFile[]) => void;
  multiple?: boolean;
  accept?: string;
  selectFileButtonTitle?: string;
  clearFileButton?: (onClear: () => void) => ReactNode;
  withAbilityToPaste?: boolean;
}

export const DropZone = forwardRef<UploadRef, DropZoneProps>(
  (
    {
      children,
      value,
      onChange,
      multiple,
      accept,
      selectFileButtonTitle,
      clearFileButton,
      withAbilityToPaste,
    },
    ref,
  ) => {
    const [api] = useNotification();

    const handleChange = useCallback(
      ({ fileList }: { fileList: UploadFile[] }) => {
        const files: DropZoneFile[] = [];

        fileList.forEach(
          ({ uid, name, originFileObj, lastModifiedDate, lastModified }) => {
            if (originFileObj) {
              files.push({
                uid,
                name,
                originFileObj,
                lastModifiedDate:
                  lastModifiedDate ||
                  (lastModified && new Date(lastModified)) ||
                  new Date(),
              });
            }
          },
        );

        onChange?.(files);
      },
      [onChange],
    );
    const handleDrop = useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const files = Array.from(event.dataTransfer.files);
        const validExtensions =
          accept?.split(",").map((ext) => ext.trim()) || [];
        const invalidFiles = files.filter(
          (file) =>
            !validExtensions.some((ext) =>
              file.name.toLowerCase().endsWith(ext),
            ),
        );

        if (invalidFiles.length > 0) {
          api.error({
            message: `Файл должен быть в формате: ${validExtensions.join(", ")}`,
          });
        }
      },
      [accept, api],
    );
    const handlePaste = useCallback(
      (event: ClipboardEvent) => {
        const items = event.clipboardData?.items;
        if (!items) return;

        const files: DropZoneFile[] = [];
        const validExtensions =
          accept?.split(",").map((ext) => ext.trim()) || [];

        Array.from(items).forEach((item) => {
          const file = item.getAsFile();
          if (file) {
            if (
              validExtensions.length === 0 ||
              validExtensions.some((ext) =>
                file.name.toLowerCase().endsWith(ext),
              )
            ) {
              files.push({
                uid: file.name + Date.now(),
                name: file.name,
                originFileObj: file,
                lastModifiedDate: new Date(),
              });
            } else {
              api.error({
                message: `Файл должен быть в формате: ${validExtensions.join(", ")}`,
              });
            }
          }
        });

        if (files.length > 0) {
          onChange?.(files);
        }
      },
      [accept, api, onChange],
    );
    const handleClear = useCallback(() => {
      onChange?.([]);
    }, [onChange]);

    const fileList: UploadFile[] | undefined = useMemo(
      () =>
        value?.map((dropZoneFile) => ({
          ...dropZoneFile,
          originFileObj: {
            ...dropZoneFile.originFileObj,
            uid: dropZoneFile.uid,
            lastModifiedDate: dropZoneFile.lastModifiedDate,
          },
        })),
      [value],
    );

    const uploadProps = {
      ref,
      fileList,
      onChange: handleChange,
      beforeUpload: () => false,
      accept,
      multiple,
      showUploadList: false,
    };

    useEffect(() => {
      if (withAbilityToPaste) document.addEventListener("paste", handlePaste);
      return () => {
        document.removeEventListener("paste", handlePaste);
      };
    }, [handlePaste, withAbilityToPaste]);

    return (
      <Upload.Dragger
        {...uploadProps}
        rootClassName={classNames(s.dropZone, { [s.dropZone_hover]: true })}
        onDrop={handleDrop}
        openFileDialogOnClick={false}
      >
        {children}
        <Flex gap={8} justify="center">
          {clearFileButton?.(handleClear)}
          {selectFileButtonTitle && (
            <Upload {...uploadProps}>
              <Button type="flat" size="s">
                <Text variant="body-4" color="accent">
                  {selectFileButtonTitle}
                </Text>
              </Button>
            </Upload>
          )}
        </Flex>
      </Upload.Dragger>
    );
  },
);
