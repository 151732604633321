import { client } from "@ui/app/api/client";

import {
  ReportsSearchResponse,
  ReportsDeleteResponse,
  ReportsExportCsvResponse,
  ReportsFioMultiSearchResponse,
  ReportsTotalCountResponse,
  ReportsFioMultiParseResponse,
  ReportsAddressSearchRequest,
  ReportsCreditRatingSearchRequest,
  ReportsDeleteRequest,
  ReportsDriverLicenseSearchRequest,
  ReportsEmailSearchRequest,
  ReportsExportCsvRequest,
  ReportsFioMultiParseRequest,
  ReportsFioMultiSearchRequest,
  ReportsFioSearchRequest,
  ReportsFullCreditRatingSearchRequest,
  ReportsGrnSearchRequest,
  ReportsInfoSearchRequest,
  ReportsInnFlSearchRequest,
  ReportsInnUlSearchRequest,
  ReportsKadNumberSearchRequest,
  ReportsOgrnSearchRequest,
  ReportsPassportSearchRequest,
  ReportsPhoneSearchRequest,
  ReportsPhotoByFaceSearchRequest,
  ReportsPhotoByFioSearchRequest,
  ReportsScoringSearchRequest,
  ReportsSnilsSearchRequest,
  ReportsTelegramSearchRequest,
  ReportsTotalCountRequest,
  ReportsUpdateRequest,
  ReportsVinSearchRequest,
  ReportsVkSearchRequest,
} from "@shared/types/report/search";
import { ReportsRetrieveRequest } from "@shared/types/report/retrieve";
import { ReportsEndpoint } from "../endpoints";

export const reportsAddressSearch = (data: ReportsAddressSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.AddressSearch,
    data,
  );

export const reportsKadNumberSearch = (data: ReportsKadNumberSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.KadNumberSearch,
    data,
  );

export const reportsCreditRatingSearch = (
  data: ReportsCreditRatingSearchRequest,
) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.CreditRatingSearch,
    data,
  );

export const reportsFullCreditRatingSearch = (
  data: ReportsFullCreditRatingSearchRequest,
) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.FullCreditRatingSearch,
    data,
  );

export const reportsDelete = (data: ReportsDeleteRequest) =>
  client.post<undefined, ReportsDeleteResponse>(ReportsEndpoint.Delete, data);

export const reportsUpdate = (data: ReportsUpdateRequest) =>
  client.patch<undefined, void>(ReportsEndpoint.Update, data);

export const reportsEmailSearch = (data: ReportsEmailSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.EmailSearch,
    data,
  );

export const reportsExportCsv = (data: ReportsExportCsvRequest) =>
  client.post<undefined, ReportsExportCsvResponse>(
    ReportsEndpoint.ExportCsv,
    data,
  );

export const reportsPossibleNetworksExportCsv = (
  data: ReportsRetrieveRequest,
) =>
  client.post<undefined, string>(
    ReportsEndpoint.PossibleNetworksExportCsv,
    data,
  );

export const reportsFioMultiSearch = (data: ReportsFioMultiSearchRequest) =>
  client.post<undefined, ReportsFioMultiSearchResponse>(
    ReportsEndpoint.FioMultiSearch,
    data,
  );

export const reportsFioMultiParse = (data: ReportsFioMultiParseRequest) =>
  client.post<
    undefined,
    ReportsFioMultiParseResponse,
    ReportsFioMultiParseRequest
  >(ReportsEndpoint.FioMultiParse, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const reportsFioSearch = (data: ReportsFioSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.FioSearch,
    data,
  );

export const reportsGrnSearch = (data: ReportsGrnSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.GrnSearch,
    data,
  );

export const reportsDriverLicenseSearch = (
  data: ReportsDriverLicenseSearchRequest,
) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.DriverLicenseSearch,
    data,
  );

export const reportsInfoSearch = (data: ReportsInfoSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.InfoSearch,
    data,
  );

export const reportsTelegramSearch = (data: ReportsTelegramSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.TelegramSearch,
    data,
  );

export const reportsInnFlSearch = (data: ReportsInnFlSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.InnFlSearch,
    data,
  );

export const reportsInnUlSearch = (data: ReportsInnUlSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.InnUlSearch,
    data,
  );

export const reportsOgrnSearch = (data: ReportsOgrnSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.OgrnSearch,
    data,
  );

export const reportsPassportSearch = (data: ReportsPassportSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PassportSearch,
    data,
  );

export const reportsPhoneSearch = (data: ReportsPhoneSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PhoneSearch,
    data,
  );

export const reportsPhotoByFaceSearch = (
  data: ReportsPhotoByFaceSearchRequest,
) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PhotoByFaceSearch,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

export const reportsPhotoByFioSearch = (data: ReportsPhotoByFioSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PhotoByFioSearch,
    data,
  );

export const reportsTotalCount = (data: ReportsTotalCountRequest) =>
  client.post<undefined, ReportsTotalCountResponse>(
    ReportsEndpoint.TotalCount,
    data,
  );

export const reportsSnilsSearch = (data: ReportsSnilsSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.SnilsSearch,
    data,
  );

export const reportsVinSearch = (data: ReportsVinSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.VinSearch,
    data,
  );

export const reportsScoringSearch = (data: ReportsScoringSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.ScoringSearch,
    data,
  );

export const reportsVkSearch = (data: ReportsVkSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(ReportsEndpoint.VkSearch, data);
