import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { AxiosError } from "axios";
import {
  UsersProfilePictureDeleteRequest,
  UsersProfilePictureDeleteResponse,
} from "../types";
import { usersProfilePictureDelete } from "../queries";

export const useUsersProfilePictureDeleteMutation = () =>
  useMutation<
    UsersProfilePictureDeleteResponse,
    AxiosError,
    UsersProfilePictureDeleteRequest
  >({
    mutationFn: (data: UsersProfilePictureDeleteRequest) =>
      usersProfilePictureDelete(data),
    mutationKey: ["profilePicture"],
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["profilePicture"] }),
  });
