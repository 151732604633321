import { Flex, Skeleton } from "antd";
import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import { AppRoute } from "@ui/app/routes/constants";
import { Block } from "@ui/ui/Block/Block";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { ActionList } from "@femida1/gui_web_core";
import { CurrentUser } from "@femida1/gui_web_core/dist/app/api/groups";

const ActionLog = () => {
  const { data: user } = useCurrentUserQuery();
  return (
    <Flex vertical gap={32}>
      <PageHeader
        title="Журнал действий"
        mapPathToTitle={{ [AppRoute.Action]: "Журнал действий" }}
        paths={[AppRoute.Action]}
      />
      <Block>
        <Skeleton loading={!user}>
          {user && <ActionList user={user as CurrentUser} />}
        </Skeleton>
      </Block>
    </Flex>
  );
};

export default ActionLog;
