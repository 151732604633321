import { Flex } from "antd";
import { Text } from "@ui/ui/Text/Text";
import { formatDate } from "@ui/utils/date/formatDate";
import { IGroupMessage } from "@shared/types/report/retrieve/telegramInfo/constants";
import s from "./GroupMessagesModal.module.scss";

export const GroupMessagesModal = ({
  messages,
  groupName,
}: {
  messages: IGroupMessage[];
  groupName: string;
}) => (
  <Flex vertical gap={34}>
    <Text variant="header-6">Сообщения {groupName}</Text>
    <Flex gap={16} vertical className={s.messageContainer}>
      {messages.length > 0 &&
        messages.map((message) => (
          <Flex
            vertical
            gap={8}
            key={message.id}
            className={s.messageContainer__message}
          >
            <Text variant="body-5">{message.text}</Text>
            <Text color="secondary" variant="body-1">
              {formatDate(message.dt)}
            </Text>
          </Flex>
        ))}
    </Flex>
  </Flex>
);
