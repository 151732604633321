export enum WordType {
  Passport = "Passport",
  LastName = "LastName",
  FirstName = "FirstName",
  MiddleName = "MiddleName",
  Date = "Date",
  Age = "Age",
  Other = "Other",
}

export interface WordWithGuessedType {
  word: string;
  type: WordType;
}
