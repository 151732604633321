import { Text, TextColor } from "@ui/ui/Text/Text";
import { FaMinus } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { ReactComponent as Bad } from "src/assets/bad.svg";

import s from "./Indicator.module.scss";

// eslint-disable-next-line react-refresh/only-export-components
export enum IndicatorIconEnum {
  check = "check",
  normal = "normal",
  cancel = "cancel",
}

export interface IndicatorCommonProps {
  marker?: number;
}

export interface IndicatorModel {
  text: string;
  color: TextColor;
  indicatorIcon: IndicatorIconEnum;
}

type IndicatorProps = IndicatorModel & IndicatorCommonProps;

export const Indicator = ({
  marker,
  text,
  color,
  indicatorIcon,
}: IndicatorProps) => (
  <div className={s.container}>
    <span className={s.check}>
      {indicatorIcon === IndicatorIconEnum.check && (
        <FaCheck className={s.check__inner} size={10} />
      )}
      {indicatorIcon === IndicatorIconEnum.normal && (
        <FaMinus className={s.normal__inner} size={10} />
      )}
      {indicatorIcon === IndicatorIconEnum.cancel && (
        <Bad className={s.cancel__inner} />
      )}
    </span>
    <Text variant="header-6" color={color} className={s.container__text}>
      {marker} - {text}
    </Text>
  </div>
);
