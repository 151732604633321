import { createBrowserRouter, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
import {
  Accesses,
  AccessesAdd,
  AccessesEdit,
  ActionLog,
  Admin,
  Alerts,
  Analytics,
  Contacts,
  Documents,
  Employees,
  History,
  Main,
  Report,
  Search,
  SupportChat,
  Tariffs,
  Telegram,
  UserSettings,
} from "@ui/pages";
import Root from "@ui/pages/Root/Root";
import { Skeleton } from "antd";
import { env } from "@shared/env";
import { TemporaryShutdown } from "@ui/pages/TemporaryShutdown/TemporaryShutdown";
import { AppRoute } from "./constants";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";

const isOdyssey =
  env.REACT_APP_THEME !== "kretchet" && env.REACT_APP_THEME !== "odyssey_black";

export const router = createBrowserRouter([
  {
    children: [
      {
        element: (
          <Navigate
            to={isOdyssey ? AppRoute.TemporaryShutdown : AppRoute.Lk}
            replace
          />
        ),
        path: "",
      },
      ...(isOdyssey
        ? [
            {
              element: <TemporaryShutdown />,
              path: AppRoute.TemporaryShutdown,
            },
          ]
        : [
            {
              element: (
                <Suspense fallback={<Skeleton />}>
                  <Report />
                </Suspense>
              ),
              path: AppRoute.Report,
            },
            {
              element: (
                <Suspense fallback={<Skeleton />}>
                  <Tariffs />
                </Suspense>
              ),
              path: AppRoute.Tariffs,
            },
            {
              children: [
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Main />
                    </Suspense>
                  ),
                  path: "",
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Admin />
                    </Suspense>
                  ),
                  path: AppRoute.Admin,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <UserSettings />
                    </Suspense>
                  ),
                  path: AppRoute.User,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Analytics />
                    </Suspense>
                  ),
                  path: AppRoute.Analytics,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Employees />
                    </Suspense>
                  ),
                  path: AppRoute.Employees,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Accesses />
                    </Suspense>
                  ),
                  path: AppRoute.Accesses,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <AccessesAdd />
                    </Suspense>
                  ),
                  path: AppRoute.AccessesAdd,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <AccessesEdit />
                    </Suspense>
                  ),
                  path: AppRoute.AccessesEdit,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Search />
                    </Suspense>
                  ),
                  path: AppRoute.Search,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Telegram />
                    </Suspense>
                  ),
                  path: AppRoute.Telegram,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <History />
                    </Suspense>
                  ),
                  path: AppRoute.History,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Contacts />
                    </Suspense>
                  ),
                  path: AppRoute.Contacts,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Alerts />
                    </Suspense>
                  ),
                  path: AppRoute.Alerts,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <Documents />
                    </Suspense>
                  ),
                  path: AppRoute.Documents,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <SupportChat />
                    </Suspense>
                  ),
                  path: AppRoute.Support,
                },
                {
                  element: (
                    <Suspense fallback={<Skeleton />}>
                      <ActionLog />
                    </Suspense>
                  ),
                  path: AppRoute.Action,
                },
              ],
              element: <PrivateRoute />,
              path: AppRoute.Lk,
            },
          ]),
    ],
    element: <Root />,
    path: AppRoute.Root,
  },
]);
