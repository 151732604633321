import { ReportType } from "@shared/types/report/constants";

export const mapReportTypeToLabel: Record<ReportType, string> = {
  [ReportType.SEARCH_ADDRESS]: "По адресу",
  [ReportType.SEARCH_KAD_NUMBER]: "По кадастровому номеру",
  [ReportType.SEARCH_CREDIT_REPORT]: "Кредитный рейтинг",
  [ReportType.SEARCH_FULL_CREDIT_REPORT]: "Расширенный кредитный рейтинг",
  [ReportType.SEARCH_EMAIL]: "По email",
  [ReportType.SEARCH_FIO]: "По ФИО",
  [ReportType.SEARCH_GRN]: "По номеру авто",
  [ReportType.SEARCH_DRIVER_LICENSE]: "По ВУ",
  [ReportType.SEARCH_INFO]: "По ключевому слову",
  [ReportType.SEARCH_INN_FL]: "По ИНН физ.лица",
  [ReportType.SEARCH_INN_UL]: "По ИНН юр.лица",
  [ReportType.SEARCH_OGRN]: "По ОГРН",
  [ReportType.SEARCH_PASSPORT]: "По паспорту",
  [ReportType.SEARCH_PHONE]: "По телефону",
  [ReportType.SEARCH_SNILS]: "По СНИЛС",
  [ReportType.SEARCH_VIN]: "По VIN",
  [ReportType.SEARCH_PHOTO_BY_IMAGE]: "По фото",
  [ReportType.SEARCH_PHOTO_BY_FIO]: "Поиск фото по ФИО",
  [ReportType.SEARCH_SCORING]: "Скоринг",
  [ReportType.SEARCH_TELEGRAM]: "По Telegram",
  [ReportType.SEARCH_VK]: "Отчет VK",
};
