import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { groups_add_member } from "../queries";
import { GroupsAddMemberRequest } from "../types";

export const useGroupsAddMember = () =>
  useMutation({
    mutationFn: (data: GroupsAddMemberRequest) => groups_add_member(data),
    mutationKey: ["groups"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
