import { FC, useState } from "react";

import { Button, Input } from "antd";
import { SearchResponse, useSearchFio } from "@ui/app/api/search";
import s from "./Fio.module.scss";
import { Lists } from "../Lists/Lists";

export const Fio: FC = () => {
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [dob, setDob] = useState("");
  const [searchData, setSearchData] = useState<SearchResponse | null>(null);
  const { mutateAsync: searchFio } = useSearchFio();

  const search = async () => {
    const data = await searchFio({
      surname,
      name,
      patronymic,
      dob,
    });
    setSearchData(data ?? null);
  };
  return (
    <div className={s.wrapper}>
      <div className={s.redact_panel}>
        <Input
          required
          value={surname}
          placeholder="Фамилия"
          onChange={(e) => {
            setSurname(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={name}
          placeholder="Имя"
          onChange={(e) => {
            setName(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={patronymic}
          placeholder="Отчество"
          onChange={(e) => {
            setPatronymic(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={dob}
          placeholder="Дата рождения"
          onChange={(e) => {
            setDob(e.currentTarget.value);
          }}
        />
        <Button onClick={search}>Поиск</Button>
      </div>
      {searchData && <Lists lists={searchData} />}
    </div>
  );
};
