import { FC, useCallback, useMemo } from "react";
import { Text } from "@ui/ui/Text/Text";
import { Expand } from "@ui/ui/Expand/Expand";
import { Flex } from "antd";
import { useSubReportsVisibility } from "@ui/pages/Root/components/SubReportsVisibilityContextProps/useSubReportsVisibility";
import s from "../ReportLayoutAsideMenu.module.scss";
// eslint-disable-next-line import/no-cycle
import { IReportWithDataId } from "../ReportLayoutAsideMenu";

interface IMultiQuerySubReportsProps {
  scrollToBlock: (block?: string, dataId?: string) => void;
  subReportItems?: IReportWithDataId[];
  title: string;
}

export const MultiQuerySubReports: FC<IMultiQuerySubReportsProps> = ({
  title,
  subReportItems,
  scrollToBlock,
}) => {
  const { visibilityState, setVisibilityState } = useSubReportsVisibility();
  const isExpanded = useMemo(
    () => visibilityState[title],
    [title, visibilityState],
  );
  const toggleExpand = useCallback(
    () => setVisibilityState(title, !isExpanded),
    [isExpanded, setVisibilityState, title],
  );

  return (
    <div>
      {subReportItems && subReportItems.length > 0 ? (
        <>
          <Expand className={s.items} state={isExpanded} toggle={toggleExpand}>
            <Text variant="body-5">{title}</Text>
          </Expand>
          {isExpanded && (
            <Flex vertical gap={16}>
              {subReportItems.map(({ title: subReportItem, dataId }, index) => (
                <Text
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${subReportItem}_${index}`}
                  className={s.item}
                  onClick={() => scrollToBlock(subReportItem, dataId)}
                  variant="body-5"
                  style={{ marginLeft: "12px" }}
                >
                  {subReportItem}
                </Text>
              ))}
            </Flex>
          )}
        </>
      ) : (
        <Text
          className={s.item}
          onClick={() => scrollToBlock(title)}
          variant="body-5"
        >
          {title}
        </Text>
      )}
    </div>
  );
};
