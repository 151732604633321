import ReactDOMServer from "react-dom/server";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { OpenedData } from "@ui/entities/SearchFull/components/OpenedData/OpenedData";
import { Addresses } from "@ui/entities/SearchFull/components/Addresses/Addresses";
import { Incomes } from "@ui/entities/SearchFull/components/Incomes/Incomes";
import { pdfLayout, savePdf } from "@shared/components/pdfLayout/pdfLayout";
import { Tags } from "@ui/entities/SearchFull/components/Tags/Tags";
import { PdfHeader } from "@shared/components/pdfLayout/components/PdfHeader/PdfHeader";
import {
  isReportsOpenSourcesIoSphere,
  ReportsFullRetrieve,
} from "@shared/types/report/retrieve";
import { ReportStatus, ReportType } from "@shared/types/report/constants";
import { Scoring } from "../../components/Scoring/Scoring";
import { Data } from "../../components/Data/Data";
import { CrossingBordersPdf } from "./components/CrossingBorders/CrossingBordersPdf";

export interface GeneratePdfParams {
  report: ReportsFullRetrieve;
  type: ReportType;
  reportId: string;
  isFioSearch?: boolean;
}

export const buildPdf = ({
  report,
  type,
  reportId,
  isFioSearch,
}: GeneratePdfParams) => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const fullDossier = ReactDOMServer.renderToString(
    <>
      <PdfHeader requestDescribe={report.request_describe} />
      {report?.person?.status && (
        <FullDossier
          print={RetrieveCardPrint.print}
          phones={report?.person?.person_info?.phones}
          snilses={report?.person?.person_info?.snilses}
          inns={report?.person?.person_info?.inns}
          passports={report?.person?.person_info?.passports}
          citizens={report?.person?.person_info?.citizens}
          otherLastNames={report?.other_last_names}
          emails={report?.person?.person_info?.emails}
          requestDescribe={report?.request_describe}
          persons={report?.person?.person_info?.persons}
          autos={report?.person?.person_info?.autos}
          photo={report?.photo}
          type={type}
          status={report?.person?.status}
          reportId={reportId}
        />
      )}
      {report?.open_sources && (
        <OpenedData
          isFioSearch={isFioSearch}
          print={RetrieveCardPrint.print}
          openSources={report?.open_sources}
          status={
            (report?.open_sources &&
              isReportsOpenSourcesIoSphere(report?.open_sources) &&
              report?.open_sources?.iosphere?.status) ||
            ReportStatus.Finished
          }
        />
      )}
      {report?.person?.status && report?.person?.person_info?.addresses && (
        <Addresses
          print={RetrieveCardPrint.print}
          addresses={report?.person?.person_info?.addresses}
          status={report?.person?.status}
        />
      )}
      {report?.person?.status && report?.person?.person_info?.incomes && (
        <Incomes
          print={RetrieveCardPrint.print}
          incomes={report?.person?.person_info?.incomes}
          status={report?.person?.status}
        />
      )}
      {report?.person?.status &&
        report?.person?.person_info?.scoring_result && (
          <Scoring
            print={RetrieveCardPrint.print}
            scoringResult={report?.person?.person_info?.scoring_result}
            status={report?.person?.status}
          />
        )}
      {report?.crossing_borders && (
        <CrossingBordersPdf
          crossingBorders={report.crossing_borders}
          status={report.crossing_borders.status}
        />
      )}
      {report?.tags?.status && (
        <Tags
          tags={report?.tags?.result}
          status={report?.tags?.status}
          print={RetrieveCardPrint.print}
        />
      )}
      {report?.person?.person_info?.data?.map(
        (reportsPersonInfoData, index) => (
          <Data
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            print={RetrieveCardPrint.print}
            reportsPersonInfoData={reportsPersonInfoData}
            status={report?.person?.status}
          />
        ),
      )}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", fullDossier);
  return outputHtml;
};

export const generatePdf = ({
  report,
  type,
  reportId,
  isFioSearch,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
    isFioSearch,
  });
  return savePdf(outputHtml, reportId);
};

export const framePdf = ({
  report,
  type,
  reportId,
  isFioSearch,
}: GeneratePdfParams) => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
    isFioSearch,
  });

  const { iframe } = pdfLayout(outputHtml, reportId);

  return iframe;
};
