import { FC, memo, useLayoutEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { env } from "@shared/env";
import { router } from "@ui/app/routes/router";
import { useAppFavicon } from "./hooks/useAppFavicon";
import { AppFeaturesProvider, AppThemeProvider } from "../providers";

export const App: FC = memo(() => {
  useLayoutEffect(() => {
    document.documentElement.dataset.theme = env.REACT_APP_THEME;
  }, []);

  useAppFavicon();

  return (
    <AppThemeProvider>
      <AppFeaturesProvider>
        <RouterProvider router={router} />
      </AppFeaturesProvider>
    </AppThemeProvider>
  );
});

App.displayName = "App";
