import ReactDOMServer from "react-dom/server";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { PdfHeader } from "@shared/components/pdfLayout/components/PdfHeader/PdfHeader";
import { pdfLayout, savePdf } from "@shared/components/pdfLayout/pdfLayout";
import { TelegramGroup } from "@ui/components/TelegramGroup/TelegramGroup";
import { GalleryItem } from "@shared/types/common/types";
import { ITelegramInfo } from "@shared/types/report/retrieve/telegramInfo/constants";
import { ReportsTelegramRetrieveResponse } from "@shared/types/report/retrieve";
import { ReportType } from "@shared/types/report/constants";

interface GeneratePdfParams {
  report: ReportsTelegramRetrieveResponse;
  type: ReportType;
  reportId: string;
  userPhotos: GalleryItem[] | null;
  telegramInfo: ITelegramInfo;
  userPhones: string[] | null;
}

export const buildPdf = ({
  report,
  type,
  reportId,
  userPhotos,
  telegramInfo,
  userPhones,
}: GeneratePdfParams) => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const fullDossier = ReactDOMServer.renderToString(
    <>
      <PdfHeader requestDescribe={report.request_describe} />
      {report.request_describe && (
        <FullDossier
          requestDescribe={report.request_describe}
          phones={userPhones}
          telegramAddresses={
            telegramInfo.addresses ? [telegramInfo.addresses] : null
          }
          names={telegramInfo.names ? [telegramInfo.names] : null}
          telegramPhotos={userPhotos}
          userNames={telegramInfo.userNames?.map(
            (userName) => userName.userName,
          )}
          tags={telegramInfo.tags ? [telegramInfo.tags] : null}
          isBot={[telegramInfo.bot ? "Да" : "Нет"]}
          userId={telegramInfo.id ? [telegramInfo.id] : null}
          groupsCount={
            telegramInfo.groups ? [telegramInfo.groups.length] : null
          }
          region={telegramInfo.region ? [telegramInfo.region] : null}
          type={type}
          status={report?.status}
          reportId={reportId}
        />
      )}
      {telegramInfo.groups && telegramInfo.groups.length > 0 && (
        <TelegramGroup groups={telegramInfo.groups} />
      )}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", fullDossier);
  return outputHtml;
};

export const generatePdf = ({
  report,
  type,
  reportId,
  userPhotos,
  telegramInfo,
  userPhones,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
    userPhotos,
    telegramInfo,
    userPhones,
  });
  return savePdf(outputHtml, reportId);
};

export const framePdf = ({
  report,
  type,
  reportId,
  userPhotos,
  telegramInfo,
  userPhones,
}: GeneratePdfParams) => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
    userPhotos,
    telegramInfo,
    userPhones,
  });

  const { iframe } = pdfLayout(outputHtml, reportId);

  return iframe;
};
