import { client } from "@ui/app/api/client";

import {
  ByFioRequest,
  ByFioResponse,
  ByPhotoRequest,
  ByPhotoResponse,
} from "@shared/types/common/types";
import { endpoints } from "./endpoints";

export const photo_by_fio = (data: ByFioRequest) =>
  client.post<undefined, ByFioResponse>(endpoints.BY_FIO_SEARCH, data);

export const photo_by_photo = (data: ByPhotoRequest) =>
  client.post<undefined, ByPhotoResponse>(endpoints.BY_PHOTO_SEARCH, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
