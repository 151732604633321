import { client } from "@ui/app/api/client";

import { AllowedIpEndpoints } from "./endpoints";
import {
  AllowedIpCreateRequest,
  AllowedIpCreateResponse,
  AllowedIpDeleteRequest,
  AllowedIpDeleteResponse,
  AllowedIpSearchRequest,
  AllowedIpSearchResponse,
} from "./types";

export const allowedIpCreate = (data: AllowedIpCreateRequest) =>
  client.post<undefined, AllowedIpCreateResponse, AllowedIpCreateRequest>(
    AllowedIpEndpoints.Create,
    data,
  );

export const allowedIpDelete = (data: AllowedIpDeleteRequest) =>
  client.post<undefined, AllowedIpDeleteResponse, AllowedIpDeleteRequest>(
    AllowedIpEndpoints.Delete,
    data,
  );

export const allowedIpSearch = (data: AllowedIpSearchRequest) =>
  client.post<undefined, AllowedIpSearchResponse, AllowedIpSearchRequest>(
    AllowedIpEndpoints.Search,
    data,
  );
