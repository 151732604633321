import classNames from "classnames";
import s from "./Delimiter.module.scss";

interface DelimiterProps {
  className?: string;
}

export const Delimiter = ({ className }: DelimiterProps) => (
  <div className={classNames(s.delimiter, className)} />
);
