import { FC, useMemo } from "react";
import { Flex } from "antd";
import { useReportsRetrieveAll } from "@ui/app/api/report/retrieve/mutations";
import { getReportTypeToTitleGetter } from "@ui/utils/reports/getReportTypeToTitleGetter";
import { Text } from "@ui/ui/Text/Text";
import { generatePath, Link } from "react-router-dom";
import { AppRoute } from "@ui/app/routes/constants";
import { Button } from "@ui/ui/Button/Button";
import { useGroups, User } from "@ui/app/api/groups";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { SearchItem } from "./components/SearchItem/SearchItem";
import s from "./MainLayoutHeaderSearch.module.scss";
import { getFlatMenuItems } from "../../../constants";

const REPORTS_SHOW_ELEMENTS_COUNT = 3;

const filterUsersByQuery =
  (query: string) =>
  ({ user_id, name }: User) =>
    String(user_id).toLowerCase().includes(query) ||
    name.toLowerCase().includes(query);

interface MainLayoutHeaderSearchProps {
  onShowAll: () => void;
  onShowItem: () => void;
  query: string;
}

export const MainLayoutHeaderSearch: FC<MainLayoutHeaderSearchProps> = ({
  query,
  onShowItem,
  onShowAll,
}) => {
  const queryLc = query.toLowerCase();

  const { data: user } = useCurrentUserQuery();
  const { data: groups } = useGroups();
  const group = groups?.[0];

  const { data: reports } = useReportsRetrieveAll(
    {
      limit: REPORTS_SHOW_ELEMENTS_COUNT,
      request_contains: queryLc,
    },
    { enabled: Boolean(queryLc) },
  );
  const users = useMemo(() => {
    const mappedUsers = groups
      ?.map((g) => g.users.map((u) => ({ ...u, group_id: g.group_id })))
      .flat();

    if (!queryLc) {
      return mappedUsers;
    }

    const filteredUsers = mappedUsers?.filter(filterUsersByQuery(queryLc));

    return filteredUsers?.sort((a, b) => a.user_id - b.user_id);
  }, [queryLc, groups]);

  const flatMenuItems = useMemo(() => {
    const items = getFlatMenuItems({
      group_id: group?.group_id,
      user_id: user?.id,
    });

    return items.filter(
      ({ title, meta }) =>
        meta.toLowerCase().includes(queryLc) ||
        title.toLowerCase().includes(queryLc),
    );
  }, [group?.group_id, user?.id, queryLc]);

  return (
    <Flex className={s.search} vertical gap={8}>
      {flatMenuItems?.length ? (
        <Flex vertical>
          <Text className={s.search__title} variant="subheader-4">
            Навигация
          </Text>
          {flatMenuItems.slice(0, 5).map(({ key, title, href, icon }) => (
            <SearchItem
              key={key}
              icon={icon}
              title={title}
              description={href}
              onClick={onShowItem}
              href={href}
            />
          ))}
        </Flex>
      ) : null}
      {users?.length ? (
        <Flex vertical>
          <Text className={s.search__title} variant="subheader-4">
            Аналитика
          </Text>
          {users.slice(0, 3).map(({ user_id, name, group_id, login }) => {
            const href = generatePath(AppRoute.Analytics, {
              group_id: String(group_id),
              user_id: String(user_id),
            });

            return (
              <SearchItem
                key={href}
                title={name || login}
                description={href}
                onClick={onShowItem}
                href={href}
              />
            );
          })}
        </Flex>
      ) : null}
      {reports?.length ? (
        <Flex vertical>
          <Text className={s.search__title} variant="subheader-4">
            История проверок
          </Text>
          {reports.map(({ type, request, id }) => {
            const href = `${generatePath(AppRoute.Report, {
              reportId: id,
            })}?type=${type}`;

            return (
              <SearchItem
                key={id}
                title={getReportTypeToTitleGetter(type)(request)}
                description={href}
                onClick={onShowItem}
                href={href}
              />
            );
          })}
        </Flex>
      ) : null}
      <Flex justify="center" className={s.search__more}>
        {users?.length || reports?.length ? (
          <Link
            to={`${generatePath(AppRoute.Search)}?query=${query}`}
            className={s.search__more_link}
          >
            <Button width="full" size="s" type="flat" onClick={onShowAll}>
              <Text color="accent">Показать все</Text>
            </Button>
          </Link>
        ) : (
          <Text color="secondary" className={s.search__more_placeholder}>
            Ничего не найдено
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
