import cn from "classnames";
import { Button } from "@ui/ui/Button/Button";
import { Flex } from "antd";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { createPortal } from "react-dom";
import { GalleryItem } from "@shared/types/common/types";
import s from "./PhotoGallery.module.scss";
import { PhotoGalleryModal } from "./PhotoGalleryModal/PhotoGalleryModal";

const VIEW_PARAM = "view";
const VIEW_PARAM_GALLERY = "gallery";

export interface PhotoGalleryProps {
  photos: GalleryItem[];
  sourcePhoto?: string;
}

export const PhotoGallery = ({ photos, sourcePhoto }: PhotoGalleryProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = useMemo(() => {
    const result = [...searchParams.entries()];
    result.push([VIEW_PARAM, VIEW_PARAM_GALLERY]);
    return new URLSearchParams(result);
  }, [searchParams]);

  const openGallery = useCallback(() => {
    setSearchParams((currSearchParams) => {
      currSearchParams.append(VIEW_PARAM, VIEW_PARAM_GALLERY);
      return currSearchParams;
    });
  }, [setSearchParams]);

  const closeGallery = useCallback(() => {
    setSearchParams((currSearchParams) => {
      currSearchParams.delete(VIEW_PARAM);
      return currSearchParams;
    });
  }, [setSearchParams]);

  if (!photos.length) {
    return null;
  }

  return (
    <Flex vertical gap={18} align="flex-start" className={s.container}>
      <Link to={`${location.pathname}?${newSearchParams.toString()}`}>
        <Flex vertical gap={18}>
          <div className={cn(s.images)}>
            {sourcePhoto ? (
              <img
                src={`data:image/png;base64,${sourcePhoto}`}
                alt="Some"
                className={cn(s.images__image, s.images__image_first)}
              />
            ) : (
              <>
                {photos[0]?.faces[0] && (
                  <img
                    src={photos[0].faces[0]}
                    alt="Some"
                    className={cn(s.images__image, s.images__image_first)}
                  />
                )}
                {photos[1]?.faces[0] && (
                  <img
                    src={photos[1].faces[0]}
                    alt="Some"
                    className={cn(s.images__image, s.images__image_second)}
                  />
                )}
                {photos[2]?.faces[0] && (
                  <img
                    src={photos[2].faces[0]}
                    alt="Some"
                    className={cn(s.images__image, s.images__image_third)}
                  />
                )}
              </>
            )}
          </div>
          <Button type="text_secondary" onClick={openGallery}>
            {photos.length}+ Смотреть
          </Button>
        </Flex>
      </Link>
      {createPortal(
        <PhotoGalleryModal
          onClose={closeGallery}
          open={searchParams.get(VIEW_PARAM) === VIEW_PARAM_GALLERY}
          photos={photos}
        />,
        document.body,
      )}
    </Flex>
  );
};
