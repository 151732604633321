import { Button } from "@ui/ui/Button/Button";
import { useModalMessage } from "@ui/ui/Modal/useModalMessage";
import { useCallback } from "react";
import { Groups } from "./Groups/Groups";
import { PhotoDataProps } from "./types";
import s from "./Buttons.module.scss";
import { PhotoData } from "./PhotoData/PhotoData";

export type ButtonsProps = PhotoDataProps;

export const Buttons = ({
  id,
  meta,
  collectionObj,
  borderCross,
  camrobot,
}: ButtonsProps) => {
  const { openModal } = useModalMessage();

  const openPhotoData = useCallback(
    () =>
      openModal({
        children: (
          <PhotoData
            id={id}
            meta={meta}
            collectionObj={collectionObj}
            borderCross={borderCross}
          />
        ),
        rootClassName: s.modalRoot,
        destroyOnClose: true,
        maskClosable: true,
        closable: true,
      }),
    [id, meta, openModal],
  );

  const openGroups = useCallback(
    () =>
      openModal({
        children: <Groups camrobot={camrobot} />,
        rootClassName: s.modalRoot,
        destroyOnClose: true,
        maskClosable: true,
        closable: true,
      }),
    [openModal],
  );

  return (
    <div className={s.container}>
      <div className={s.container__inner}>
        <Button type="secondary" size="s" onClick={openPhotoData}>
          Данные фото
        </Button>
        {camrobot && (
          <Button type="secondary" size="s" onClick={openGroups}>
            Группы и чаты
          </Button>
        )}
      </div>
    </div>
  );
};
