import { client } from "@ui/app/api/client";

import { endpoints } from "./endpoints";
import {
  TwoFactorLoginRequest,
  LoginRequest,
  TwoFactorGenerate,
  TwoFactorEnable,
  LoginResponse,
  LogoutRequest,
  LogoutResponse,
} from "./types";

export const login = (data: LoginRequest) =>
  client.post<undefined, LoginResponse>(endpoints.LOGIN, data);

export const logout = (data: LogoutRequest) =>
  client.post<undefined, LogoutResponse>(endpoints.LOGOUT, data);

export const two_factor_login = (data: TwoFactorLoginRequest) =>
  client.post<undefined, string>(endpoints.TWO_FACTOR_LOGIN, data);

export const two_factor_generate = () =>
  client.get<undefined, TwoFactorGenerate>(endpoints.TWO_FACTOR_GENERATE);

export const two_factor_enable = (data: TwoFactorEnable) =>
  client.post<undefined, string>(endpoints.TWO_FACTOR_ENABLE, data);

export const two_factor_disable = () =>
  client.post<undefined, string>(endpoints.TWO_FACTOR_DISABLE);
