import { StatisticsInterval } from "@ui/app/api/analytics/types";
import { mapIntervalToLabel } from "@ui/pages/Analytics/constants";
import { Flex } from "antd";
import { FC } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { Text } from "@ui/ui/Text/Text";
import { Tooltip } from "@ui/ui/Tooltip/Tooltip";

interface LegendHintProps {
  interval: StatisticsInterval;
  reportTypesHint?: string;
}

export const LegendHint: FC<LegendHintProps> = ({
  interval,
  reportTypesHint = "Проверка всех типов",
}) => {
  const label = mapIntervalToLabel[interval];

  let intervalHint = "";

  if (interval === StatisticsInterval.custom) {
    intervalHint = "За выбранный период в фильтрах";
  }

  const hints = [intervalHint, reportTypesHint].filter(Boolean);

  const hintNode = hints.length ? (
    <Flex vertical>
      {hints.map((hint) => (
        <Text key={hint} color="white">
          {hint}
        </Text>
      ))}
    </Flex>
  ) : undefined;

  return (
    <Text variant="body-1" color="secondary">
      {hintNode ? (
        <Flex gap={4} align="center">
          {label}
          <Tooltip title={hintNode}>
            <AiFillQuestionCircle />
          </Tooltip>
        </Flex>
      ) : (
        label
      )}
    </Text>
  );
};
