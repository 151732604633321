/**
 * Функция вычисляет среднюю точку на поверхности сферы (Земли) среди набора географических координат.
 * @param coords - Массив координат, каждая координата представлена в виде [широта, долгота].
 * @returns Средняя координата в виде [широта, долгота].
 */
export const averageGeographicCoordinate = (
  coords: [number | string, number | string][],
): [number, number] => {
  interface Accumulator {
    x: number;
    y: number;
    z: number;
  }

  const sum = coords.reduce<Accumulator>(
    (acc, coord) => {
      let [lat, lon] = coord;

      // Преобразуем градусы в радианы
      lat = (Number(lat) * Math.PI) / 180;
      lon = (Number(lon) * Math.PI) / 180;

      // Преобразуем в декартовы координаты и суммируем
      acc.x += Math.cos(lat) * Math.cos(lon);
      acc.y += Math.cos(lat) * Math.sin(lon);
      acc.z += Math.sin(lat);

      return acc;
    },
    { x: 0, y: 0, z: 0 },
  );

  const total = coords.length;
  const avgX = sum.x / total;
  const avgY = sum.y / total;
  const avgZ = sum.z / total;

  // Преобразуем обратно в градусы
  const centralLon = Math.atan2(avgY, avgX);
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  const centralLat = Math.atan2(avgZ, hyp);

  return [
    (centralLat * 180) / Math.PI, // широта
    (centralLon * 180) / Math.PI, // долгота
  ];
};
