import { Flex, Modal } from "antd";
import { FC, ReactNode, useCallback, useLayoutEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import cn from "classnames";
import { handleBackendErrors } from "@ui/utils/notifications/handleBackendErrors";
import { useNotification } from "@ui/hooks/useNotification";
import { useReportsUpdate } from "@ui/app/api/report/search/mutations";
import { ReportType } from "@shared/types/report/constants";
import { Button } from "../../ui/Button/Button";
import { useBooleanState } from "../../hooks/useBooleanState";
import { TextArea } from "../../ui/TextArea/TextArea";
import { Text } from "../../ui/Text/Text";
import s from "./NoteModal.module.scss";

interface INoteModalProps {
  buttonTitle: ReactNode;
  type: ReportType;
  reportId?: string;
  isNoteEditing?: boolean;
  note?: string;
  buttonClassName?: string;
}

const NoteModal: FC<INoteModalProps> = ({
  buttonTitle,
  type,
  reportId,
  isNoteEditing = true,
  note,
  buttonClassName,
}) => {
  const {
    mutateAsync: changeNote,
    isPending: isNoteChanging,
    isSuccess: isNoteChanged,
  } = useReportsUpdate(type, reportId);

  const [noteText, setNoteText] = useState(note);
  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  useLayoutEffect(() => {
    if (isNoteChanged) hideModal();
  }, [hideModal, isNoteChanged]);

  const [api] = useNotification();

  const handleChangeNote = useCallback(() => {
    changeNote({ note: noteText, id: reportId }).catch((error) => {
      handleBackendErrors({
        api,
        error,
      });
    });
  }, [changeNote, noteText, reportId]);

  return (
    <>
      <Button
        type="flat"
        size="s"
        width="fit"
        className={cn(s.button, buttonClassName)}
        onClick={showModal}
      >
        {buttonTitle}
      </Button>
      <Modal
        title={<Text variant="header-6">Заметка</Text>}
        open={isModalVisible}
        onCancel={hideModal}
        footer={
          isNoteEditing
            ? [
                <Button
                  key="clear"
                  type="flat_secondary"
                  size="s"
                  onClick={() => setNoteText("")}
                >
                  <Flex align="center" gap={4}>
                    <MdDeleteOutline color="inherit" size={20} /> Очистить
                  </Flex>
                </Button>,
                <Button
                  key="next"
                  type="primary"
                  size="s"
                  disabled={isNoteChanging}
                  onClick={handleChangeNote}
                >
                  Сохранить
                </Button>,
              ]
            : null
        }
      >
        {isNoteEditing ? (
          <TextArea
            rootClassName={s.modalContent}
            value={noteText}
            maxLength={1000}
            onUpdate={setNoteText}
            supportingText="До 1000 символов"
          />
        ) : (
          <div>
            <Text variant="body-5">{note}</Text>
          </div>
        )}
      </Modal>
    </>
  );
};

export default NoteModal;
