import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ChangeUserPasswordRequest } from "@ui/app/api/groups";
import { changeUserPassword } from "@ui/app/api/groups/queries";
import { queryClient } from "@ui/app/api/client";

export const useUserChangePasswordMutation = () =>
  useMutation<undefined, AxiosError, ChangeUserPasswordRequest>({
    mutationFn: (data: ChangeUserPasswordRequest) => changeUserPassword(data),
    mutationKey: ["groupUser"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
