import { Text } from "@ui/ui/Text/Text";
import { ReportCommonProps } from "@ui/entities/types";
import cn from "classnames";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactComponent as NotesPin } from "src/assets/notes_pin.svg";
import { mapReportTypeToLabel } from "@ui/utils/reports/mapReportTypeToLabel";
import { Flex } from "antd";
import { MdEdit } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoPerson } from "react-icons/io5";
import { queryClient } from "@ui/app/api/client";
import { useMemo } from "react";
import { validationRegex } from "@ui/utils/constants";
import { useNotification } from "@ui/hooks/useNotification";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import {
  OtherLastNames,
  PersonInfo,
  PersonInfoKeys,
  ReportsPersonInfoPerson,
} from "@shared/types/report/retrieve";
import { UserInfoInfo, UserInfoKeys } from "@shared/types/report/retrieve/vk";
import { Photo } from "@shared/types/report/retrieve/photo";
import {
  ETelegramDossierInfoKeys,
  ITelegramDossierInfo,
} from "@shared/types/report/retrieve/telegramInfo/constants";
import { ReportStatus, ReportType } from "@shared/types/report/constants";
import { Column } from "./Column/Column";
import s from "./FullDossier.module.scss";
import { PhotoGallery } from "../PhotoGallery/PhotoGallery";
import { EMPTY_KEY } from "./Column/constants";
import { DownloadReportPdfButton } from "./components/DownloadReportPdfButton/DownloadReportPdfButton";
import { RetrieveCardPrint } from "../RetrieveCard/types";
import NoteModal from "../NoteModal/NoteModal";
import { Option, OptionsMenu } from "../OptionsMenu/OptionsMenu";
import SearchByFound from "./components/SearchByFound/SearchByFound";

const PHOTO = "photo";
const OTHER_LAST_NAMES = "otherLastNames";

export type FullDossierProps = PersonInfo &
  UserInfoInfo &
  ReportCommonProps & {
    requestDescribe?: string;
    photo?: Photo | null;
    [OTHER_LAST_NAMES]?: OtherLastNames;
    handleGeneratePdf?: () => void;
    handleFramePdf?: () => Promise<HTMLIFrameElement>;
    isLoadingGeneratePdf?: boolean;
    title?: string;
    isShowHeader?: boolean;
    containerClassName?: string;
    note?: string;
    isMultiQueryReport?: boolean;
    isSubReport?: boolean;
    expanded?: boolean;
  } & RetrieveCardCommonProps &
  ITelegramDossierInfo;

const COLUMN_IN_ROW = 4;
const ORDER = [
  PHOTO,
  ETelegramDossierInfoKeys.telegramPhotos,
  ETelegramDossierInfoKeys.userId,
  ETelegramDossierInfoKeys.userNames,
  PersonInfoKeys.persons,
  PersonInfoKeys.phones,
  PersonInfoKeys.emails,
  PersonInfoKeys.passports,
  PersonInfoKeys.snilses,
  PersonInfoKeys.inns,
  PersonInfoKeys.citizens,
  PersonInfoKeys.autos,
  ETelegramDossierInfoKeys.region,
  ETelegramDossierInfoKeys.names,
  ETelegramDossierInfoKeys.telegramAddresses,
  ETelegramDossierInfoKeys.tags,
  ETelegramDossierInfoKeys.isBot,
  ETelegramDossierInfoKeys.groupsCount,
  OTHER_LAST_NAMES,
  UserInfoKeys.vkCities,
  UserInfoKeys.vkCountries,
  UserInfoKeys.vkDobs,
  UserInfoKeys.vkEmails,
  UserInfoKeys.vkNames,
  UserInfoKeys.vkPhones,
];

export const FullDossier = ({
  requestDescribe,
  type,
  status,
  handleGeneratePdf,
  handleFramePdf,
  isLoadingGeneratePdf,
  isShowHeader = true,
  containerClassName,
  note,
  reportId,
  isMultiQueryReport,
  isSubReport,
  expanded,
  print = RetrieveCardPrint.device,
  title = "Краткая сводка",
  ...props
}: FullDossierProps) => {
  const isPrintView = print === RetrieveCardPrint.print;
  const label = mapReportTypeToLabel[type];
  const requestDescribeWithoutDob = requestDescribe
    ?.replace(validationRegex.date, "")
    .replace(/\.+$/, "")
    .trim();
  const requestDescribeDob = requestDescribe?.match(validationRegex.date)?.[0];

  const propKeys = ORDER?.filter((propKey) =>
    Object.hasOwn(props, propKey),
  ).filter((propKey) => {
    if (type === ReportType.SEARCH_PHONE && propKey === PersonInfoKeys.phones)
      return false;

    if (type === ReportType.SEARCH_SNILS && propKey === PersonInfoKeys.snilses)
      return false;

    if (
      (type === ReportType.SEARCH_INN_FL ||
        type === ReportType.SEARCH_INN_UL) &&
      propKey === PersonInfoKeys.inns
    )
      return false;

    if (
      type === ReportType.SEARCH_PASSPORT &&
      propKey === PersonInfoKeys.passports
    )
      return false;

    if (type === ReportType.SEARCH_EMAIL && propKey === PersonInfoKeys.emails)
      return false;

    if (
      (type === ReportType.SEARCH_GRN || type === ReportType.SEARCH_VIN) &&
      propKey === PersonInfoKeys.autos
    )
      return false;

    if (
      type === ReportType.SEARCH_FIO &&
      requestDescribeDob &&
      propKey === PersonInfoKeys.persons
    )
      return false;

    if (isPrintView && propKey === PHOTO) return false;

    return true;
  });
  const remainder =
    Number(propKeys?.length) % COLUMN_IN_ROW === 0
      ? 0
      : COLUMN_IN_ROW - (Number(propKeys?.length) % COLUMN_IN_ROW);
  const propKeysWithFake = [...propKeys];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < remainder; i++) {
    propKeysWithFake.push(EMPTY_KEY + i);
  }

  const {
    state: isSearchByFoundModalVisible,
    setTrue: showSearchByFoundModal,
    setFalse: hideSearchByFoundModal,
  } = useBooleanState(false);

  const [copyReportUrl] = useNotification();

  const options = useMemo<Option[]>(
    () =>
      [
        { text: "Поиск по найденному", onClick: showSearchByFoundModal },
        {
          text: "Печать",
          onClick: async () => {
            copyReportUrl.info({
              key: "iframe-print",
              message: "Отчет создается...",
              duration: 5,
              className: s.notification,
            });

            const iframe = await handleFramePdf?.();
            if (iframe) {
              iframe.onload = () => {
                iframe?.contentWindow?.print();
              };
            }
          },
        },
        {
          text: "Поделиться",
          onClick: () => {
            // eslint-disable-next-line no-restricted-globals
            const reportUrl = `${window.location.origin}${location.pathname}${location.search}`;
            navigator.clipboard.writeText(reportUrl).then(() =>
              copyReportUrl.success({
                key: "report-url",
                message: "Ссылка на отчет скопирована",
                className: s.notification,
              }),
            );
          },
        },
      ].filter(
        (point) =>
          !(
            (!props.passports || props?.passports?.length < 1) &&
            (!props.phones || props?.phones?.length < 1) &&
            (!props.autos || props?.autos?.length < 1) &&
            (!props.snilses || props?.snilses?.length < 1) &&
            (!props.inns || props?.inns?.length < 1) &&
            (!props.emails || props?.emails?.length < 1) &&
            point.text === "Поиск по найденному"
          ),
      ),
    [props],
  );

  return (
    <RetrieveCard
      dataId={`${isSubReport ? requestDescribe : ""}0`}
      print={print}
      title={title}
      status={status}
      className={containerClassName}
      isSubReport={isSubReport}
      expanded={expanded}
      header={
        isShowHeader && (
          <div className={s.container__header}>
            <Flex vertical gap={16} justify="center">
              {!isSubReport && !isPrintView && (
                <Text color="aqua">{label}</Text>
              )}
              {requestDescribe && (
                <div>
                  <Text
                    className={cn(
                      !isPrintView && s.container__header__name,
                      isPrintView && s.container__header__name_print,
                      isSubReport && s.container__header__subReport_name,
                    )}
                  >
                    {isPrintView ? (
                      <Flex vertical gap={7}>
                        {requestDescribeWithoutDob}
                        <Text color="secondary">{requestDescribeDob}</Text>
                      </Flex>
                    ) : (
                      requestDescribe
                    )}
                  </Text>
                </div>
              )}
              {!isSubReport && !isMultiQueryReport && !isPrintView && !note && (
                <QueryClientProvider client={queryClient}>
                  <NoteModal
                    type={type}
                    reportId={reportId}
                    buttonTitle={
                      <Text variant="body-4" color="secondary">
                        <Flex align="center" gap={4}>
                          <NotesPin /> Добавить заметку
                        </Flex>
                      </Text>
                    }
                  />
                </QueryClientProvider>
              )}
            </Flex>
            {!isPrintView && (
              <div
                className={cn(
                  s.container__header__options,
                  isSubReport && s.container__header__options__subReport,
                )}
              >
                <OptionsMenu
                  className={s.container__header__options__more}
                  options={options}
                />
                <DownloadReportPdfButton
                  handleGeneratePdf={handleGeneratePdf}
                  isLoadingGeneratePdf={isLoadingGeneratePdf}
                />
              </div>
            )}
            <QueryClientProvider client={queryClient}>
              <SearchByFound
                isSearchByFoundModalVisible={isSearchByFoundModalVisible}
                hideSearchByFoundModal={hideSearchByFoundModal}
                propKeys={propKeys}
                personInfo={{
                  [PersonInfoKeys.passports]: props.passports,
                  [PersonInfoKeys.phones]: props.phones,
                  [PersonInfoKeys.autos]: props.autos,
                  [PersonInfoKeys.snilses]: props.snilses,
                  [PersonInfoKeys.inns]: props.inns,
                  [PersonInfoKeys.emails]: props.emails,
                }}
              />
            </QueryClientProvider>
          </div>
        )
      }
    >
      <div
        className={cn(
          !isPrintView && s.container__row,
          isPrintView && s.container__row_print,
        )}
      >
        {propKeysWithFake.map((propKey: string) => {
          if (propKey === OTHER_LAST_NAMES)
            return (
              <Column
                print={print}
                key={propKey}
                keyProp={propKey}
                items={
                  props[propKey]?.status === ReportStatus.InProgress
                    ? ["В процессе сборки..."]
                    : props[propKey]?.result
                }
              />
            );

          if (propKey === ETelegramDossierInfoKeys.telegramPhotos) {
            const photos = props[propKey];
            return (
              <Flex key={propKey} vertical gap={8}>
                <Text variant="body-1" color="secondary">
                  Фотографии
                </Text>
                {photos && photos.length > 0 ? (
                  <PhotoGallery photos={photos} />
                ) : (
                  <Flex vertical gap={8}>
                    <Flex
                      align="center"
                      justify="center"
                      className={s.container__notFoundPhoto}
                    >
                      <IoPerson
                        size={24}
                        className={s.container__notFoundPhoto__icon}
                      />
                    </Flex>
                    <Text variant="body-5">Не найдено</Text>
                  </Flex>
                )}
              </Flex>
            );
          }

          if (propKey === PHOTO) {
            const photo = props?.[propKey];
            return (
              <Flex key={propKey} vertical gap={8}>
                <Text variant="body-1" color="secondary">
                  Фотографии
                </Text>
                {!isPrintView &&
                  ((props[propKey]?.status === ReportStatus.InProgress && (
                    <Flex
                      justify="center"
                      align="center"
                      className={s.container__plugPhotoContainer}
                    >
                      <AiOutlineLoading3Quarters
                        className={s.container__plugPhotoContainer__loading}
                        size={24}
                      />
                    </Flex>
                  )) ||
                    (props[propKey]?.status === ReportStatus.Finished &&
                      photo?.photos &&
                      photo.photos?.length > 0 && (
                        <PhotoGallery photos={photo.photos} />
                      )) || (
                      <Flex vertical gap={8}>
                        <Flex
                          align="center"
                          justify="center"
                          className={s.container__notFoundPhoto}
                        >
                          <IoPerson
                            size={24}
                            className={s.container__notFoundPhoto__icon}
                          />
                        </Flex>
                        <Text variant="body-5">Не найдено</Text>
                      </Flex>
                    ))}
              </Flex>
            );
          }
          if (propKey === PersonInfoKeys.persons)
            return (
              <Column
                print={print}
                key={propKey}
                keyProp={propKey}
                items={props[propKey]?.map(
                  (person: ReportsPersonInfoPerson) =>
                    `${person.last_name} ${person.first_name} ${person.middle_name} ${person.birth_date}`,
                )}
              />
            );

          return (
            <Column
              key={propKey}
              keyProp={propKey}
              items={props[propKey]}
              print={print}
            />
          );
        })}
        {!isMultiQueryReport && note && (
          <Flex vertical className={s.container__row__note}>
            <Flex gap={4} align="center">
              <Text color="secondary" variant="body-1">
                Заметка
              </Text>
              {!isPrintView && (
                <QueryClientProvider client={queryClient}>
                  <NoteModal
                    type={type}
                    reportId={reportId}
                    buttonTitle={
                      <Text color="secondary">
                        <MdEdit size={14} />
                      </Text>
                    }
                    note={note}
                  />
                </QueryClientProvider>
              )}
            </Flex>
            <Text variant="body-5">{note}</Text>
          </Flex>
        )}
      </div>
    </RetrieveCard>
  );
};
