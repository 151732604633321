import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@ui/pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "@ui/ui/Input/Input";
import { validationMessage } from "@ui/utils/constants";
import { correctDigitRegexCallback } from "@ui/utils/validation";
import { useReportsDriverLicenseSearch } from "@ui/app/api/report/search/mutations";
import { ReportsDriverLicenseSearchRequest } from "@shared/types/report/search";
import s from "./MainTabDriverLicense.module.scss";
import { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants";

interface ReportsDriverLicenseFieldValues
  extends ReportsDriverLicenseSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object<ReportsDriverLicenseFieldValues>()
  .shape({
    driver_license: yup
      .string()
      .test(
        "digits-check",
        validationMessage.WrongDriverLicense,
        correctDigitRegexCallback,
      )
      .min(10, validationMessage.WrongDriverLicense)
      .max(10, validationMessage.WrongDriverLicense)
      .required(validationMessage.Required),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabDriverLicenseProps = MainTabProps;

export const MainTabDriverLicense: FC<MainTabDriverLicenseProps> = ({
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      driver_license: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsDriverLicenseSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsDriverLicenseFieldValues) =>
      onSubmit({ requestData, search, withSopd: true }).then(() => reset()),
    [search, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="driver_license"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              mask="0000000000"
              placeholder="Номер водительского удостоверения"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
