import { Flex } from "antd";
import { FC } from "react";
import { Block } from "@ui/ui/Block/Block";
import { Text } from "@ui/ui/Text/Text";
import { HistoryList } from "@ui/components/HistoryList/HistoryList";
import { useMainTabSearchParam } from "@ui/pages/Main/hooks/useMainTabSearchParam";
import { useAppFeatures } from "@ui/app/providers";
import s from "./History.module.scss";
import { OnboardingModalContainer } from "../../components/OnboardingModalContainer";

const History: FC = () => {
  const [tab] = useMainTabSearchParam();
  const { onboardingEnabled } = useAppFeatures();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      {onboardingEnabled ? (
        <OnboardingModalContainer onboardingPage="history" />
      ) : null}
      <Block>
        <Flex vertical gap={32}>
          <Text variant="header-6">История проверок</Text>
          <HistoryList withPagination limit={15} tab={tab} />
        </Flex>
      </Block>
    </Flex>
  );
};

export default History;
