import {
  ClipboardEventHandler,
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@ui/pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "@ui/ui/Input/Input";
import {
  validationBounds,
  validationMessage,
  validationRegex,
} from "@ui/utils/constants";
import dayjs from "dayjs";
import {
  correctLettersSpacesHyphenDashRegexCallback,
  correctPassportRegexCallback,
} from "@ui/utils/validation";
import { Toggle } from "@ui/ui/Toggle/Toggle";
import { formatCompositeDate } from "@ui/utils/date/formatCompositeDate";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { Permission } from "@ui/app/api/groups";
import { getMainTabToggleSettings } from "@ui/pages/Main/components/MainTabs/utils/getMainTabToggleSettings";
import cn from "classnames";
import {
  useReportsCreditRatingSearch,
  useReportsFullCreditRatingSearch,
} from "@ui/app/api/report/search/mutations";
import { transformNumber } from "@ui/utils/transformers/transformNumber";
import s from "./MainTabCreditRating.module.scss";
import { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants";
import { guessWordTypes } from "../../utils/guessWordTypes";
import { WordType } from "../../utils/types";
import { POSSIBLE_DATE_FORMATS } from "../../utils/isValidDate";
import { getOtherWords } from "../../utils/getOtherWords";

const commonSchema = {
  last_name: yup
    .string()
    .test(
      "test-symbols",
      validationMessage.WrongLastName,
      correctLettersSpacesHyphenDashRegexCallback,
    )
    .required(),
  first_name: yup
    .string()
    .test(
      "test-symbols",
      validationMessage.WrongFirstName,
      correctLettersSpacesHyphenDashRegexCallback,
    )
    .required(),
  middle_name: yup
    .string()
    .test(
      "test-symbols",
      validationMessage.WrongMiddleName,
      correctLettersSpacesHyphenDashRegexCallback,
    ),
  birth_day: yup
    .number()
    .transform(transformNumber)
    .integer(validationMessage.BirthDay)
    .min(validationBounds.Day.Min, validationMessage.BirthDay)
    .max(validationBounds.Day.Max, validationMessage.BirthDay)
    .required(),
  birth_month: yup
    .number()
    .transform(transformNumber)
    .integer(validationMessage.BirthMonth)
    .min(validationBounds.Month.Min, validationMessage.BirthMonth)
    .max(validationBounds.Month.Max, validationMessage.BirthMonth)
    .required(),
  birth_year: yup
    .number()
    .transform(transformNumber)
    .integer(validationMessage.BirthYear)
    .min(validationBounds.Year.Min, validationMessage.BirthYear)
    .max(validationBounds.Year.Max, validationMessage.BirthYear)
    .required(),
};

interface ReportsCreditRatingSearchForm extends MainTabFieldValues {
  last_name: string;
  first_name: string;
  middle_name?: string;
  birth_day: number;
  birth_month: number;
  birth_year: number;
  passport?: string;
}

const schema = yup
  .object<ReportsCreditRatingSearchForm>()
  .shape({
    ...commonSchema,
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

interface ReportsCreditRatingFullSearchForm extends MainTabFieldValues {
  last_name: string;
  first_name: string;
  middle_name?: string;
  birth_day: number;
  birth_month: number;
  birth_year: number;
  passport: string;
}

const fullSchema = yup
  .object<ReportsCreditRatingFullSearchForm>()
  .shape({
    ...commonSchema,
    passport: yup
      .string()
      .test(
        "valid-test",
        validationMessage.WrongPassport,
        correctPassportRegexCallback,
      )
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

interface MainTabCreditRatingProps extends MainTabProps {}

export const MainTabCreditRating: FC<MainTabCreditRatingProps> = ({
  onSubmit,
}) => {
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups[0].permissions;

  const { shouldShowToggle, initialType } = useMemo(
    () =>
      getMainTabToggleSettings<"normal", "full">({
        userPermissions,
        firstPermission: Permission.ReportCreditRatingPermission,
        secondPermission: Permission.ReportFullCreditRatingPermission,
        firstType: "normal",
        secondType: "full",
      }),
    [userPermissions],
  );

  const [type, setType] = useState<"full" | "normal">(initialType);

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("full");
    } else {
      setType("normal");
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
    trigger,
  } = useForm<ReportsCreditRatingSearchForm>({
    resolver: yupResolver(type === "full" ? fullSchema : schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      middle_name: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsCreditRatingSearch();
  const { mutateAsync: fullSearch, isPending: isFullSearchPending } =
    useReportsFullCreditRatingSearch();

  const onSubmitInner = useCallback(
    (data: ReportsCreditRatingSearchForm) => {
      const requestData = {
        ...data,
        /**
         * DD.MM.YYYY
         */
        birth_date: formatCompositeDate(
          data.birth_day,
          data.birth_month,
          data.birth_year,
        ),
        passport:
          type === "full" ? data.passport?.replace(/\D/g, "") || "" : undefined,
      };

      return onSubmit({
        requestData,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        search: type === "full" ? fullSearch : search,
        withSopd: true,
      }).then(() => reset());
    },
    [search, fullSearch, type, onSubmit, reset],
  );

  const pasteDate = useCallback(
    (dateWord: string): boolean => {
      const date = dayjs(dateWord, POSSIBLE_DATE_FORMATS, true);
      if (date.isValid()) {
        const year = date.year();
        setValue("birth_year", year);

        const month = date.month();
        setValue("birth_month", month + 1);

        const day = date.date();
        setValue("birth_day", day);

        return true;
      }

      return false;
    },
    [setValue],
  );

  const onDayPaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedDate = e.clipboardData.getData("text");
      if (pasteDate(pastedDate)) {
        e.preventDefault();
      }

      await trigger().catch(() => {});
    },
    [pasteDate, trigger],
  );

  const onLastNamePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedText = e.clipboardData.getData("text");
      const pastedWords = pastedText.split(" ");
      if (!pastedWords.length) return;

      e.preventDefault();

      const wordsByType = guessWordTypes({ pastedWords });

      const dateWord = wordsByType[WordType.Date].shift();
      if (dateWord) {
        pasteDate(dateWord);
      }

      const probableOrderOfPastedWords: (keyof ReportsCreditRatingSearchForm)[] =
        [];

      const lastName = wordsByType[WordType.LastName].shift();
      if (lastName) {
        setValue("last_name", lastName);
      } else {
        probableOrderOfPastedWords.push("last_name");
      }
      const firstName = wordsByType[WordType.FirstName].shift();
      if (firstName) {
        setValue("first_name", firstName);
      } else {
        probableOrderOfPastedWords.push("first_name");
      }
      const middleName = wordsByType[WordType.MiddleName].shift();
      if (middleName) {
        setValue("middle_name", middleName);
      } else {
        probableOrderOfPastedWords.push("middle_name");
      }
      const passport = wordsByType[WordType.Passport].shift();
      if (passport) {
        setValue("passport", passport);
      } else {
        probableOrderOfPastedWords.push("passport");
      }

      const otherWords = getOtherWords(wordsByType);
      const maxLen = probableOrderOfPastedWords.length;
      if (otherWords.length > maxLen) {
        const restPastedWords = otherWords.slice(maxLen - 1);
        otherWords[maxLen - 1] = restPastedWords.join(" ");
        otherWords.length = maxLen;
      }

      otherWords.forEach((pastedWord, index) => {
        setValue(probableOrderOfPastedWords[index], pastedWord);
      });

      await trigger().catch(() => {});
    },
    [pasteDate, setValue, trigger],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} wrap="wrap">
        <Controller
          name="last_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Фамилия"
              onPaste={onLastNamePaste}
              {...field}
              value={field.value.replace(
                validationRegex.lettersSpacesHyphenDashExcept,
                "",
              )}
              onChange={(e) => {
                e.target.value = e.target.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                );
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="first_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Имя"
              {...field}
              value={field.value.replace(
                validationRegex.lettersSpacesHyphenDashExcept,
                "",
              )}
              onChange={(e) => {
                e.target.value = e.target.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                );
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="middle_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              placeholder="Отчество"
              {...field}
              value={field.value?.replace(
                validationRegex.lettersSpacesHyphenDashExcept,
                "",
              )}
              onChange={(e) => {
                e.target.value = e.target.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                );
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_day"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min={validationBounds.Day.Min}
              max={validationBounds.Day.Max}
              step="1"
              placeholder="День"
              onPaste={onDayPaste}
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_month"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min={validationBounds.Month.Min}
              max={validationBounds.Month.Max}
              step="1"
              placeholder="Месяц"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_year"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min={validationBounds.Year.Min}
              max={validationBounds.Year.Max}
              step="1"
              placeholder="Год"
              {...field}
              validate={fieldState}
            />
          )}
        />
        {type === "full" ? (
          <Controller
            name="passport"
            control={control}
            shouldUnregister
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                required
                mask="0000 000000"
                placeholder="Серия и номер паспорта"
                {...field}
                validate={fieldState}
              />
            )}
          />
        ) : (
          <span className={s.noop} />
        )}
        {shouldShowToggle && (
          <Toggle
            className={cn(s.checkbox_extended, s.input)}
            value={type === "full"}
            onChange={onTypeChange}
            title="Расширенный кредитный рейтинг"
          />
        )}
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending || isFullSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
