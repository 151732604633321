import { Permission } from "@ui/app/api/groups";

export const getMainTabToggleSettings = <T1 extends string, T2 extends string>({
  userPermissions,
  firstPermission,
  secondPermission,
  firstType,
  secondType,
}: {
  userPermissions?: Permission[];
  firstPermission: Permission;
  secondPermission: Permission;
  firstType: T1;
  secondType: T2;
}) => {
  if (
    !userPermissions ||
    userPermissions?.includes(Permission.SuperuserPermission)
  )
    return {
      shouldShowToggle: true,
      initialType: firstType,
    };

  const hasFirstPermission = userPermissions.includes(firstPermission);
  const hasSecondPermission = userPermissions.includes(secondPermission);

  const shouldShowToggle = hasFirstPermission && hasSecondPermission;
  const initialType = hasFirstPermission ? firstType : secondType;

  return {
    shouldShowToggle,
    initialType,
  };
};
