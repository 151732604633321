import ReactDOMServer from "react-dom/server";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { OpenedData } from "@ui/entities/SearchFull/components/OpenedData/OpenedData";
import { PhoneBook } from "@ui/entities/SearchPhone/components/PhoneBook/PhoneBook";
import { PdfHeader } from "@shared/components/pdfLayout/components/PdfHeader/PdfHeader";
import { pdfLayout, savePdf } from "@shared/components/pdfLayout/pdfLayout";
import {
  isReportsOpenSourcesIoSphere,
  ReportsPhoneRetrieveResponse,
} from "@shared/types/report/retrieve";
import { ReportStatus, ReportType } from "@shared/types/report/constants";
import { Data } from "../../components/Data/Data";

export interface GeneratePdfParams {
  report: ReportsPhoneRetrieveResponse;
  type: ReportType;
  reportId: string;
}

export const buildPdf = ({ report, type, reportId }: GeneratePdfParams) => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const fullDossier = ReactDOMServer.renderToString(
    <>
      <PdfHeader requestDescribe={report.request_describe} />
      {report?.person?.status && (
        <FullDossier
          print={RetrieveCardPrint.print}
          phones={report?.person?.person_info?.phones}
          snilses={report?.person?.person_info?.snilses}
          inns={report?.person?.person_info?.inns}
          passports={report?.person.person_info?.passports}
          emails={report?.person?.person_info?.emails}
          requestDescribe={report?.request_describe}
          autos={report?.person?.person_info?.autos}
          persons={report?.person?.person_info?.persons}
          otherLastNames={report?.other_last_names}
          type={type}
          status={report?.person?.status}
          reportId={reportId}
        />
      )}
      {report?.open_sources && (
        <OpenedData
          isPhoneSearch
          print={RetrieveCardPrint.print}
          openSources={report?.open_sources}
          status={
            report?.open_sources &&
            isReportsOpenSourcesIoSphere(report?.open_sources)
              ? report?.open_sources?.iosphere?.status
              : ReportStatus.Finished
          }
        />
      )}
      {report?.person?.status && (
        <PhoneBook
          print={RetrieveCardPrint.print}
          phoneBook={report?.person?.person_info?.phone_book}
          status={report?.person?.status}
        />
      )}
      {report?.person?.person_info?.data?.map(
        (reportsPersonInfoData, index) => (
          <Data
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            print={RetrieveCardPrint.print}
            reportsPersonInfoData={reportsPersonInfoData}
            status={report?.person?.status}
          />
        ),
      )}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", fullDossier);
  return outputHtml;
};

export const generatePdf = ({
  report,
  type,
  reportId,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
  });
  return savePdf(outputHtml, reportId);
};

export const framePdf = ({ report, type, reportId }: GeneratePdfParams) => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
  });

  const { iframe } = pdfLayout(outputHtml, reportId);

  return iframe;
};
