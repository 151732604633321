export enum UserInfoKeys {
  vkCities = "vkCities",
  vkCountries = "vkCountries",
  vkDobs = "vkDobs",
  vkEmails = "vkEmails",
  vkNames = "vkNames",
  vkPhones = "vkPhones",
}

export interface UserInfoInfo {
  [UserInfoKeys.vkCities]?: string[] | null;
  [UserInfoKeys.vkCountries]?: string[] | null;
  [UserInfoKeys.vkDobs]?: string[] | null;
  [UserInfoKeys.vkEmails]?: string[] | null;
  [UserInfoKeys.vkNames]?: string[] | null;
  [UserInfoKeys.vkPhones]?: string[] | null;
}
