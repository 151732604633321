import { useParams } from "react-router-dom";
import { useReportsCreditRatingRetrieve } from "@ui/app/api/report/retrieve/mutations";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@ui/entities/types";
import { RetrieveCreditRating } from "@ui/entities/RetrieveCreditRating/RetrieveCreditRating";

export const SearchCreditRatingWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: creditRatingReport, isLoading } =
    useReportsCreditRatingRetrieve({
      id: reportId,
    });

  return (
    <Skeleton loading={isLoading}>
      {creditRatingReport && (
        <RetrieveCreditRating
          report={creditRatingReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
