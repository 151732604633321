import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { QueryKey, QueryKeys } from "@ui/app/api/constants";
import {
  ReportsAddressSearchRequest,
  ReportsCreditRatingSearchRequest,
  ReportsDeleteRequest,
  ReportsDriverLicenseSearchRequest,
  ReportsEmailSearchRequest,
  ReportsExportCsvRequest,
  ReportsFioMultiParseRequest,
  ReportsFioMultiSearchRequest,
  ReportsFioSearchRequest,
  ReportsFullCreditRatingSearchRequest,
  ReportsGrnSearchRequest,
  ReportsInfoSearchRequest,
  ReportsInnFlSearchRequest,
  ReportsInnUlSearchRequest,
  ReportsKadNumberSearchRequest,
  ReportsOgrnSearchRequest,
  ReportsPassportSearchRequest,
  ReportsPhoneSearchRequest,
  ReportsPhotoByFaceSearchRequest,
  ReportsPhotoByFioSearchRequest,
  ReportsScoringSearchRequest,
  ReportsSnilsSearchRequest,
  ReportsTelegramSearchRequest,
  ReportsTotalCountRequest,
  ReportsUpdateRequest,
  ReportsVinSearchRequest,
  ReportsVkSearchRequest,
} from "@shared/types/report/search";
import { ReportType } from "@shared/types/report/constants";
import { ReportsRetrieveRequest } from "@shared/types/report/retrieve";
import {
  reportsAddressSearch,
  reportsCreditRatingSearch,
  reportsDelete,
  reportsDriverLicenseSearch,
  reportsEmailSearch,
  reportsExportCsv,
  reportsFioMultiParse,
  reportsFioMultiSearch,
  reportsFioSearch,
  reportsFullCreditRatingSearch,
  reportsGrnSearch,
  reportsInfoSearch,
  reportsInnFlSearch,
  reportsInnUlSearch,
  reportsKadNumberSearch,
  reportsOgrnSearch,
  reportsPassportSearch,
  reportsPhoneSearch,
  reportsPhotoByFaceSearch,
  reportsPhotoByFioSearch,
  reportsPossibleNetworksExportCsv,
  reportsScoringSearch,
  reportsSnilsSearch,
  reportsTelegramSearch,
  reportsTotalCount,
  reportsUpdate,
  reportsVinSearch,
  reportsVkSearch,
} from "./queries";

export const useReportsAddressSearch = () =>
  useMutation({
    mutationFn: (data: ReportsAddressSearchRequest) =>
      reportsAddressSearch(data),
    mutationKey: QueryKey.ReportsAddressSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsKadNumberSearch = () =>
  useMutation({
    mutationFn: (data: ReportsKadNumberSearchRequest) =>
      reportsKadNumberSearch(data),
    mutationKey: QueryKey.ReportsKadNumberSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsCreditRatingSearch = () =>
  useMutation({
    mutationFn: (data: ReportsCreditRatingSearchRequest) =>
      reportsCreditRatingSearch(data),
    mutationKey: QueryKey.ReportsCreditRatingSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsFullCreditRatingSearch = () =>
  useMutation({
    mutationFn: (data: ReportsFullCreditRatingSearchRequest) =>
      reportsFullCreditRatingSearch(data),
    mutationKey: QueryKey.ReportsFullCreditRatingSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsEmailSearch = () =>
  useMutation({
    mutationFn: (data: ReportsEmailSearchRequest) => reportsEmailSearch(data),
    mutationKey: QueryKey.ReportsEmailSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsFioSearch = () =>
  useMutation({
    mutationFn: (data: ReportsFioSearchRequest) => reportsFioSearch(data),
    mutationKey: QueryKey.ReportsFioSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsFioMultiSearch = () =>
  useMutation({
    mutationFn: (data: ReportsFioMultiSearchRequest) =>
      reportsFioMultiSearch(data),
    mutationKey: QueryKey.ReportsFioMultiSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsFioMultiParse = () =>
  useMutation({
    mutationFn: (data: ReportsFioMultiParseRequest) =>
      reportsFioMultiParse(data),
    mutationKey: QueryKey.ReportsFioMultiParse(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsGrnSearch = () =>
  useMutation({
    mutationFn: (data: ReportsGrnSearchRequest) => reportsGrnSearch(data),
    mutationKey: QueryKey.ReportsGrnSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsDriverLicenseSearch = () =>
  useMutation({
    mutationFn: (data: ReportsDriverLicenseSearchRequest) =>
      reportsDriverLicenseSearch(data),
    mutationKey: QueryKey.ReportsDriverLicenseSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsInfoSearch = () =>
  useMutation({
    mutationFn: (data: ReportsInfoSearchRequest) => reportsInfoSearch(data),
    mutationKey: QueryKey.ReportsInfoSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsTelegramSearch = () =>
  useMutation({
    mutationFn: (data: ReportsTelegramSearchRequest) =>
      reportsTelegramSearch(data),
    mutationKey: QueryKey.ReportsTelegramSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsInnFlSearch = () =>
  useMutation({
    mutationFn: (data: ReportsInnFlSearchRequest) => reportsInnFlSearch(data),
    mutationKey: QueryKey.ReportsInnFlSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsInnUlSearch = () =>
  useMutation({
    mutationFn: (data: ReportsInnUlSearchRequest) => reportsInnUlSearch(data),
    mutationKey: QueryKey.ReportsInnUlSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsOgrnSearch = () =>
  useMutation({
    mutationFn: (data: ReportsOgrnSearchRequest) => reportsOgrnSearch(data),
    mutationKey: QueryKey.ReportsOgrnSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsPassportSearch = () =>
  useMutation({
    mutationFn: (data: ReportsPassportSearchRequest) =>
      reportsPassportSearch(data),
    mutationKey: QueryKey.ReportsPassportSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsPhoneSearch = () =>
  useMutation({
    mutationFn: (data: ReportsPhoneSearchRequest) => reportsPhoneSearch(data),
    mutationKey: QueryKey.ReportsPhoneSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsPhotoByFaceSearch = () =>
  useMutation({
    mutationFn: (data: ReportsPhotoByFaceSearchRequest) =>
      reportsPhotoByFaceSearch(data),
    mutationKey: QueryKey.ReportsPhotoByFaceSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsPhotoByFioSearch = () =>
  useMutation({
    mutationFn: (data: ReportsPhotoByFioSearchRequest) =>
      reportsPhotoByFioSearch(data),
    mutationKey: QueryKey.ReportsPhotoByFioSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsSnilsSearch = () =>
  useMutation({
    mutationFn: (data: ReportsSnilsSearchRequest) => reportsSnilsSearch(data),
    mutationKey: QueryKey.ReportsSnilsSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsVinSearch = () =>
  useMutation({
    mutationFn: (data: ReportsVinSearchRequest) => reportsVinSearch(data),
    mutationKey: QueryKey.ReportsVinSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsScoringSearch = () =>
  useMutation({
    mutationFn: (data: ReportsScoringSearchRequest) =>
      reportsScoringSearch(data),
    mutationKey: QueryKey.ReportsScoringSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsVkSearch = () =>
  useMutation({
    mutationFn: (data: ReportsVkSearchRequest) => reportsVkSearch(data),
    mutationKey: QueryKey.ReportsVkSearch(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsTotalCount = (
  data: ReportsTotalCountRequest,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    enabled,
    queryFn: () => reportsTotalCount(data),
    queryKey: QueryKey.ReportsTotalCount(data),
  });

export const useReportsDelete = () =>
  useMutation({
    mutationFn: (data: ReportsDeleteRequest) => reportsDelete(data),
    mutationKey: QueryKey.ReportsDelete(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
  });

export const useReportsUpdate = (type: ReportType, reportId?: string) =>
  useMutation({
    mutationFn: (data: ReportsUpdateRequest) => reportsUpdate(data),
    mutationKey: QueryKey.ReportsUpdate(),
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: [QueryKeys.Reports] }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Reports, reportId, type],
        }),
      ]);
    },
  });

export const useReportsExportCsv = () =>
  useMutation({
    mutationFn: (data: ReportsExportCsvRequest) => reportsExportCsv(data),
    mutationKey: QueryKey.ReportsExportCsv(),
  });

export const useReportsPossibleNetworksExportCsv = () =>
  useMutation({
    mutationFn: (data: ReportsRetrieveRequest) =>
      reportsPossibleNetworksExportCsv(data),
    mutationKey: QueryKey.PossibleNetworksExportCsv(),
  });
