import React, { memo } from "react";
import { Flex } from "antd";
import { LuShieldClose } from "react-icons/lu";
import s from "./Common.module.scss";
import { Text } from "../../ui/Text/Text";
import {
  Indicator,
  IndicatorCommonProps,
  IndicatorModel,
} from "../Indicator/Indicator";

export type CommonProps = IndicatorCommonProps & {
  indicatorModel: IndicatorModel;
};

export const Common = memo(({ marker, indicatorModel }: CommonProps) => (
  <Flex justify="space-between" align="start">
    <Flex vertical gap={8}>
      <Text variant="body-1" color="secondary">
        Общий показатель
      </Text>
      <Indicator
        marker={marker}
        text={indicatorModel.text}
        color={indicatorModel.color}
        indicatorIcon={indicatorModel.indicatorIcon}
      />
    </Flex>
    {marker && marker < 1 && (
      <Text variant="subheader-3" color="red" className={s.negativeMarker}>
        <Flex align="center" gap={8}>
          Есть негатив <LuShieldClose size={24} />
        </Flex>
      </Text>
    )}
  </Flex>
));
