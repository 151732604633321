import { FC, useCallback } from "react";
import { Text } from "@ui/ui/Text/Text";

import { Flex } from "antd";
import { mapIntervalToLabel } from "@ui/pages/Analytics/constants";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { Radio } from "@ui/ui/Radio/Radio";
import { DatePicker } from "@ui/ui/DatePicker/DatePicker";
import dayjs from "dayjs";
import { StatisticsInterval } from "@ui/app/api/analytics/types";
import { assertUnreachable } from "@ui/utils/asserts";
import s from "./SettingsModalInterval.module.scss";

const intervalsOrder: StatisticsInterval[] = [
  StatisticsInterval.all,
  StatisticsInterval.last30Days,
  StatisticsInterval.last90Days,
  StatisticsInterval.custom,
];

interface SettingsModalIntervalProps {
  modalValue: AnalyticsFilters;
  setModalValue: (
    value:
      | AnalyticsFilters
      | ((prevValue: AnalyticsFilters) => AnalyticsFilters),
  ) => void;
}

export const SettingsModalInterval: FC<SettingsModalIntervalProps> = ({
  modalValue,
  setModalValue,
}) => {
  const onChangeStartDate = useCallback(
    (startDate: dayjs.Dayjs) => {
      setModalValue((prevFilters) => ({
        ...prevFilters,
        startDate,
      }));
    },
    [setModalValue],
  );

  const onChangeEndDate = useCallback(
    (endDate: dayjs.Dayjs) => {
      setModalValue((prevFilters) => ({
        ...prevFilters,
        endDate,
      }));
    },
    [setModalValue],
  );

  const onChangeInterval = useCallback(
    (interval: StatisticsInterval, checked: boolean) => {
      if (!checked) return;

      setModalValue((prevFilters) => {
        let { startDate, endDate } = prevFilters;
        switch (interval) {
          case StatisticsInterval.all: {
            startDate = undefined;
            endDate = undefined;
            break;
          }
          case StatisticsInterval.last30Days: {
            startDate = dayjs().subtract(30, "day");
            endDate = dayjs();
            break;
          }
          case StatisticsInterval.last90Days: {
            startDate = dayjs().subtract(90, "day");
            endDate = dayjs();
            break;
          }
          case StatisticsInterval.custom: {
            break;
          }
          default: {
            assertUnreachable(interval);
            break;
          }
        }

        return {
          ...prevFilters,
          startDate,
          endDate,
          interval,
        };
      });
    },
    [setModalValue],
  );

  return (
    <Flex vertical gap={12}>
      <Text variant="label-4">Показывать добавленные в папки:</Text>
      <Flex vertical gap={8}>
        {intervalsOrder.map((interval: StatisticsInterval) => (
          <Radio
            key={interval}
            checked={modalValue.interval === interval}
            onUpdate={(checked) => onChangeInterval(interval, checked)}
          >
            <Text>{mapIntervalToLabel[interval]}</Text>
          </Radio>
        ))}
      </Flex>
      <Flex gap={16}>
        <DatePicker
          className={s.container__datepicker}
          value={modalValue.startDate}
          onChange={onChangeStartDate}
          disabled={modalValue.interval !== StatisticsInterval.custom}
        />
        <DatePicker
          className={s.container__datepicker}
          value={modalValue.endDate}
          onChange={onChangeEndDate}
          disabled={modalValue.interval !== StatisticsInterval.custom}
        />
      </Flex>
    </Flex>
  );
};
