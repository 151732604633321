import { useState, Dispatch, SetStateAction, useMemo } from "react";

interface UsePaginationParams<TData> {
  data: TData[];
  limit: number;
  initialCursor?: number;
  onChange?: (page: number) => void;
}

interface UsePagination<TData> {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  dataSegment: TData[];
}

export const usePagination = <TData>({
  data,
  limit,
  initialCursor = 1,
}: UsePaginationParams<TData>): UsePagination<TData> => {
  const [page, setPage] = useState<number>(initialCursor);

  const pageSize = useMemo(() => data.length / limit, [data.length, limit]);

  const dataSegment: TData[] = useMemo(() => {
    const segmentStart = limit * (page - 1);

    return data.slice(segmentStart, segmentStart + limit);
  }, [data, limit, page]);

  return {
    page,
    setPage,
    pageSize,
    dataSegment,
  };
};
