import { FoundStatus, ReportStatus } from "../constants";
import { ReportsPersonInfoData, ReportsPersonInfoPerson } from "./response";
import { ScoringResult } from "./scoring";

export interface Address {
  address: string;
  count: number;
  year: string;
}

export interface Income {
  work_place: string;
  sum: string;
  year: number;
}

export interface PossibleNetworkResults {
  source: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  birth_date: string;
}

export interface OtherLastNames {
  found: FoundStatus;
  result: string[] | null;
  status: ReportStatus;
}

export interface PossibleNetworks {
  found: FoundStatus;
  result: Record<string, PossibleNetworkResults[]> | null;
  status: ReportStatus;
}

export enum PersonInfoKeys {
  persons = "persons",
  passports = "passports",
  phones = "phones",
  autos = "autos",
  snilses = "snilses",
  inns = "inns",
  emails = "emails",
  data = "data",
  scoring_result = "scoring_result",
  citizens = "citizens",
  addresses = "addresses",
  incomes = "incomes",
}

export interface PersonInfo {
  [PersonInfoKeys.persons]?: ReportsPersonInfoPerson[] | null;
  [PersonInfoKeys.passports]?: string[] | null;
  [PersonInfoKeys.phones]?: string[] | null;
  [PersonInfoKeys.autos]?: string[] | null;
  [PersonInfoKeys.snilses]?: string[] | null;
  [PersonInfoKeys.inns]?: string[] | null;
  [PersonInfoKeys.emails]?: string[] | null;
  [PersonInfoKeys.data]?: ReportsPersonInfoData[] | null;
  [PersonInfoKeys.scoring_result]?: ScoringResult | null;
  [PersonInfoKeys.citizens]?: string[] | null;
  [PersonInfoKeys.addresses]?: Address[] | null;
  [PersonInfoKeys.incomes]?: Income[] | null;
}

export interface Person {
  status: ReportStatus;
  found: number;
  person_info: PersonInfo;
}
