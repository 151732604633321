const mapLatinToCyrillic: Record<string, string> = {
  A: "А",
  B: "В",
  E: "Е",
  K: "К",
  M: "М",
  H: "Н",
  O: "О",
  P: "Р",
  C: "С",
  T: "Т",
  Y: "У",
  X: "Х",
};
const allowedCyrillic = new Set(Object.values(mapLatinToCyrillic));

export const transformAutoNum = (v) => {
  if (typeof v !== "string") return "";
  const vArr = v.toUpperCase().split("");
  return vArr
    .filter(
      (char) =>
        Number.isInteger(Number(char)) ||
        mapLatinToCyrillic[char] ||
        allowedCyrillic.has(char),
    )
    .map((char) => mapLatinToCyrillic[char] || char)
    .join("");
};
