import { Flex } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { Text } from "@ui/ui/Text/Text";
import { generatePath } from "react-router-dom";
import { AppRoute } from "@ui/app/routes/constants";
import { useReportsRetrieveAll } from "@ui/app/api/report/retrieve/mutations";
import { MdAnalytics } from "react-icons/md";
import { getReportTypeToTitleGetter } from "@ui/utils/reports/getReportTypeToTitleGetter";
import { Pagination } from "@ui/ui/Pagination/Pagination";
import { useReportsTotalCount } from "@ui/app/api/report/search/mutations";
import s from "./SearchReports.module.scss";
import { SearchItem } from "../SearchItem/SearchItem";

const LIMIT = 10;

interface SearchReportsProps {
  query: string;
  prevQuery: string;
}

export const SearchReports: FC<SearchReportsProps> = ({ query, prevQuery }) => {
  const [page, setPage] = useState(1);
  const offset = useMemo(() => (page - 1) * LIMIT, [page]);

  useEffect(() => {
    if (query !== prevQuery) {
      setPage(1);
    }
  }, [query, prevQuery]);

  const { data: countReports, isSuccess: isReportsTotalCountSuccess } =
    useReportsTotalCount(
      {
        request_contains: query,
      },
      {
        enabled: Boolean(query),
      },
    );

  const { data: reports } = useReportsRetrieveAll(
    {
      limit: LIMIT,
      offset: Number.isInteger(offset) ? offset : undefined,
      request_contains: query,
    },
    { enabled: Boolean(query) },
  );

  const pagination = useMemo(() => {
    if (isReportsTotalCountSuccess && countReports.total_count) {
      return (
        <Flex justify="center">
          <Pagination
            total={countReports.total_count}
            pageSize={LIMIT}
            page={page}
            onUpdate={setPage}
          />
        </Flex>
      );
    }

    return null;
  }, [isReportsTotalCountSuccess, countReports?.total_count, page]);

  return (
    <Flex vertical gap={8}>
      <Flex gap={8} align="center" className={s.item__header}>
        <MdAnalytics size={20} />
        <Text variant="subheader-5">История проверок</Text>
      </Flex>
      <Flex vertical>
        {reports?.length ? (
          reports.map(({ type, request, id }) => {
            const href = `${generatePath(AppRoute.Report, { reportId: id })}?type=${type}`;

            return (
              <SearchItem
                key={id}
                title={getReportTypeToTitleGetter(type)(request)}
                description={href}
                href={href}
              />
            );
          })
        ) : (
          <Text variant="body-5" color="secondary">
            Ничего не найдено
          </Text>
        )}
      </Flex>
      {pagination}
    </Flex>
  );
};
