import { FC, useCallback, useMemo, useState } from "react";
import { Flex, Image } from "antd";
import { Text } from "@ui/ui/Text/Text";
import {
  CurrentUser,
  EditMemberRequest,
  useUsersEdit,
} from "@ui/app/api/groups";
import { FaPen } from "react-icons/fa";
import { Button } from "@ui/ui/Button/Button";
import { IoClose } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";
import { Input } from "@ui/ui/Input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationMessage } from "@ui/utils/constants";
import * as yup from "yup";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import cn from "classnames";
import { useUsersProfilePictureRetrieveQuery } from "@ui/app/api/groups/mutations/useUsersProfilePictureRetrieveQuery";
import { useNotification } from "@ui/hooks/useNotification";
import { useUsersProfilePictureMutation } from "@ui/app/api/groups/mutations/useUsersProfilePictureMutation";
import { handleBackendErrors } from "@ui/utils/notifications/handleBackendErrors";
import { FaCircleUser } from "react-icons/fa6";
import { useUsersProfilePictureDeleteMutation } from "@ui/app/api/groups/mutations/useUsersProfilePictureDeleteMutation";
import { transformNumber } from "@ui/utils/transformers/transformNumber";
import s from "./UserCard.module.scss";
import AvatarModal from "./components/AvatarModal/AvatarModal";

const schema = yup
  .object<EditMemberRequest>()
  .shape({
    user_id: yup
      .number()
      .transform(transformNumber)
      .required(validationMessage.Required),
    name: yup.string().required(validationMessage.Required),
  })
  .required();

interface UserCardViewProps {
  user: CurrentUser;
  photo?: string;
}

const UserCardView: FC<UserCardViewProps> = ({ user, photo }) => {
  const { login, name } = user;

  return (
    <Flex gap={12}>
      {photo ? (
        <Image
          rootClassName={s.card__avatar}
          preview={false}
          src={photo}
          alt={`${user.id} avatar`}
        />
      ) : (
        <FaCircleUser className={s.card__icon} />
      )}
      <Flex vertical>
        <Text variant="subheader-4">{login}</Text>
        <Text color="secondary" variant="body-1">
          {name}
        </Text>
      </Flex>
    </Flex>
  );
};

interface UserCardEditProps {
  user: CurrentUser;
  photo?: string;
  onView: () => void;
}

const UserCardEdit: FC<UserCardEditProps> = ({ user, photo, onView }) => {
  const { name: initialName, id: user_id } = user;
  const { control, handleSubmit, watch, formState } =
    useForm<EditMemberRequest>({
      defaultValues: {
        user_id,
        name: initialName,
      },
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  const name = watch("name");
  const { isValid } = formState;

  const [defaultPhoto, setDefaultPhoto] = useState(photo);
  const [photoFormData, setPhotoFormData] = useState<FormData>();
  const [isUserDeletePhoto, setIsUserDeletePhoto] = useState(false);
  const isUserNameChanged = useMemo(
    () => initialName !== name,
    [name, initialName],
  );

  const { mutateAsync: editUser } = useUsersEdit();
  const { mutateAsync: editPhoto } = useUsersProfilePictureMutation();
  const { mutateAsync: deletePhoto } = useUsersProfilePictureDeleteMutation();

  const [api] = useNotification();

  const {
    state: isAvatarModalVisible,
    setTrue: showAvatarModal,
    setFalse: hideAvatarModal,
  } = useBooleanState(false);

  const onChangeUser = useCallback(
    async (userData: EditMemberRequest) => {
      await Promise.allSettled([
        isUserNameChanged &&
          editUser(userData)
            .then(() => {
              api.success({
                message: "Имя пользователя успешно изменено",
              });
            })
            .catch((error) => {
              handleBackendErrors({
                api,
                error,
              });
            }),
        isUserDeletePhoto &&
          deletePhoto({ user_id })
            .then(() => {
              api.success({
                message: "Аватар успешно удалён",
              });
            })
            .catch((error) => {
              handleBackendErrors({
                api,
                error,
              });
            }),
        photoFormData &&
          editPhoto(photoFormData)
            .then(() => {
              api.success({
                message: "Аватар успешно загружен",
              });
            })
            .catch((error) => {
              handleBackendErrors({
                api,
                error,
              });
            }),
      ]);

      onView();
    },
    [
      editUser,
      isUserDeletePhoto,
      deletePhoto,
      user_id,
      photoFormData,
      editPhoto,
      onView,
      api,
    ],
  );

  const onDeletePhoto = useCallback(async () => {
    setIsUserDeletePhoto(true);
    setPhotoFormData(undefined);
    setDefaultPhoto(undefined);
  }, []);

  const onEditPhoto = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", String(user.id));

      setPhotoFormData(formData);
      setIsUserDeletePhoto(false);
      setDefaultPhoto(URL.createObjectURL(file));
      hideAvatarModal();
    },
    [user.id, hideAvatarModal],
  );

  return (
    <form className={s.card_edit} onSubmit={handleSubmit(onChangeUser)}>
      <AvatarModal
        open={isAvatarModalVisible}
        onClose={hideAvatarModal}
        onEditPhoto={onEditPhoto}
      />
      <Flex gap={12} vertical>
        <Flex gap={16} align="center">
          <button type="button" onClick={showAvatarModal}>
            {defaultPhoto ? (
              <Image
                rootClassName={s.card__avatar}
                preview={false}
                src={defaultPhoto}
                alt={`${user.id} avatar`}
              />
            ) : (
              <FaCircleUser className={s.card__icon} />
            )}
          </button>
          <Button type="text" onClick={showAvatarModal}>
            <Text variant="body-5">Заменить</Text>
          </Button>
          <Button type="text" onClick={onDeletePhoto}>
            <Text variant="body-5" color="secondary">
              Удалить
            </Text>
          </Button>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="subheader-4">Имя</Text>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                {...field}
                required
                validate={fieldState}
              />
            )}
          />
        </Flex>
        <Button
          size="s"
          width="fit"
          htmlType="submit"
          disabled={
            !isValid ||
            (!isUserNameChanged && !isUserDeletePhoto && !photoFormData)
          }
        >
          Сохранить
        </Button>
      </Flex>
    </form>
  );
};

interface UserCardProps {
  user: CurrentUser;
  editable?: boolean;
  type: "flat" | "border-box";
  size: "s" | "m";
}

export const UserCard: FC<UserCardProps> = ({ user, editable, type, size }) => {
  const { state: isEdit, toggle } = useBooleanState(false);

  const { data: photo } = useUsersProfilePictureRetrieveQuery({
    user_id: user.id,
  });

  return (
    <Flex
      className={cn(s.card, s[`card_type_${type}`], s[`card_size_${size}`])}
    >
      {editable ? (
        <Button
          type="flat"
          pin="round"
          onClick={toggle}
          className={s.card_button}
        >
          {isEdit ? <IoClose /> : <FaPen />}
        </Button>
      ) : null}
      {isEdit ? (
        <UserCardEdit user={user} onView={toggle} photo={photo} />
      ) : (
        <UserCardView user={user} photo={photo} />
      )}
    </Flex>
  );
};
