export enum TelegramUserBotStatus {
  AwaitingApproval = "AWAITING_APPROVAL",
  Approved = "APPROVED",
  Declined = "DECLINED",
}

export interface TelegramUserBot {
  active?: boolean;
  created_at: string;
  status: TelegramUserBotStatus;
  telegram_full_name?: string;
  telegram_id: number;
  telegram_username: string;
}

export interface TelegramUserBotApproveRequest {
  telegram_id: number;
}

export type TelegramUserBotApproveResponse = undefined;

export interface TelegramUserBotDeclineRequest {
  telegram_id: number;
}

export type TelegramUserBotDeclineResponse = undefined;

export interface TelegramUserBotDeleteRequest {
  telegram_id: number;
}

export type TelegramUserBotDeleteResponse = TelegramUserBot[];

export interface TelegramUserBotRetrieveAllRequest {
  limit: number;
  offset?: number;
}

export type TelegramUserBotRetrieveAllResponse = TelegramUserBot[];

export interface TelegramUserBotActivateRequest {
  telegram_id: number;
}

export type TelegramUserBotActivateResponse = undefined;

export interface TelegramUserBotDeactivateRequest {
  telegram_id: number;
}

export type TelegramUserBotDeactivateResponse = undefined;
