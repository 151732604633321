import { UsersProfilePictureRetrieveRequest } from "@ui/app/api/groups";
import {
  ReportsRetrieveAllRequest,
  ReportsRetrieveRequest,
} from "@shared/types/report/retrieve";
import { ReportType } from "@shared/types/report/constants";
import { ReportsTotalCountRequest } from "@shared/types/report/search";
import { GroupStatisticsTransformedRequest } from "./analytics/types";
import { TelegramUserBotRetrieveAllRequest } from "./telegram/types";

export enum QueryKeys {
  GroupStatistics = "GroupStatistics",
  GroupStatisticsByType = "GroupStatisticsByType",
  TelegramUserBot = "TelegramUserBot",
  Reports = "reports",
  ProfilePictureRetrieve = "profilePicture",

  ReportsAddressSearch = "reportsAddressSearch",
  ReportsKadNumberSearch = "reportsKadNumberSearch",
  ReportsCreditRatingSearch = "reportsCreditRatingSearch",
  ReportsFullCreditRatingSearch = "reportsFullCreditRatingSearch",
  ReportsEmailSearch = "reportsEmailSearch",
  ReportsFioSearch = "reportsFioSearch",
  ReportsFioMultiSearch = "reportsFioMultiSearch",
  ReportsFioMultiParse = "reportsFioMultiParse",
  ReportsGrnSearch = "reportsGrnSearch",
  ReportsDriverLicenseSearch = "reportsDriverLicenseSearch",
  ReportsInfoSearch = "reportsInfoSearch",
  ReportsInnFlSearch = "reportsInnFlSearch",
  ReportsInnUlSearch = "reportsInnUlSearch",
  ReportsOgrnSearch = "reportsOgrnSearch",
  ReportsPassportSearch = "reportsPassportSearch",
  ReportsPhoneSearch = "reportsPhoneSearch",
  ReportsPhotoByFaceSearch = "reportsPhotoByFaceSearch",
  ReportsPhotoByFioSearch = "reportsPhotoByFioSearch",
  ReportsSnilsSearch = "reportsSnilsSearch",
  ReportsVinSearch = "reportsVinSearch",
  ReportsScoringSearch = "reportsScoringSearch",
  ReportsTelegramSearch = "reportsTelegramSearch",
  ReportsVkSearch = "reportsVkSearch",
  ReportsDelete = "reportsDelete",
  ReportsUpdate = "reportsUpdate",
  ReportsExportCsv = "reportsExportCsv",
  PossibleNetworksExportCsv = "possibleNetworksExportCsv",
}

export const QueryKey = {
  PossibleNetworksExportCsv: () => [QueryKeys.PossibleNetworksExportCsv],
  ProfilePictureRetrieve: (data: UsersProfilePictureRetrieveRequest) => [
    QueryKeys.ProfilePictureRetrieve,
    `user_ud:${data.user_id}`,
  ],
  Reports: (data: ReportsRetrieveAllRequest) => [
    QueryKeys.Reports,
    `limit:${data.limit}`,
    `offset:${data.offset}`,
    `request_contains:${data.request_contains}`,
    `report_task_types:${data.report_task_types?.join()}`,
    `order_type:${data.order_type}`,
    `user_ids:${data.user_ids?.join()}`,
  ],
  ReportsAddressRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_ADDRESS,
    data.id,
  ],
  ReportsAddressSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsAddressSearch,
  ],
  ReportsCreditRatingRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_CREDIT_REPORT,
    data.id,
  ],
  ReportsCreditRatingSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsCreditRatingSearch,
  ],
  ReportsDelete: () => [QueryKeys.ReportsDelete],
  ReportsDriverLicenseRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_DRIVER_LICENSE,
    data.id,
  ],
  ReportsDriverLicenseSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsDriverLicenseSearch,
  ],
  ReportsEmailRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_EMAIL,
    data.id,
  ],
  ReportsEmailSearch: () => [QueryKeys.Reports, QueryKeys.ReportsEmailSearch],
  ReportsExportCsv: () => [QueryKeys.ReportsExportCsv],
  ReportsFioMultiParse: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsFioMultiParse,
  ],
  ReportsFioMultiSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsFioMultiSearch,
  ],
  ReportsFioRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_FIO,
    data.id,
  ],
  ReportsFioSearch: () => [QueryKeys.Reports, QueryKeys.ReportsFioSearch],
  ReportsFullCreditRatingRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_FULL_CREDIT_REPORT,
    data.id,
  ],
  ReportsFullCreditRatingSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsFullCreditRatingSearch,
  ],
  ReportsGrnRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_GRN,
    data.id,
  ],
  ReportsGrnSearch: () => [QueryKeys.Reports, QueryKeys.ReportsGrnSearch],
  ReportsInfoRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_INFO,
    data.id,
  ],
  ReportsInfoSearch: () => [QueryKeys.Reports, QueryKeys.ReportsInfoSearch],
  ReportsInnFlRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_INN_FL,
    data.id,
  ],
  ReportsInnFlSearch: () => [QueryKeys.Reports, QueryKeys.ReportsInnFlSearch],
  ReportsInnUlRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_INN_UL,
    data.id,
  ],
  ReportsInnUlSearch: () => [QueryKeys.Reports, QueryKeys.ReportsInnUlSearch],
  ReportsKadNumberRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_KAD_NUMBER,
    data.id,
  ],
  ReportsKadNumberSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsKadNumberSearch,
  ],
  ReportsOgrnRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_OGRN,
    data.id,
  ],
  ReportsOgrnSearch: () => [QueryKeys.Reports, QueryKeys.ReportsOgrnSearch],
  ReportsPassportRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_PASSPORT,
    data.id,
  ],
  ReportsPassportSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsPassportSearch,
  ],
  ReportsPhoneRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_PHONE,
    data.id,
  ],
  ReportsPhoneSearch: () => [QueryKeys.Reports, QueryKeys.ReportsPhoneSearch],
  ReportsPhotoByFaceRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_PHOTO_BY_IMAGE,
    data.id,
  ],
  ReportsPhotoByFaceSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsPhotoByFaceSearch,
  ],
  ReportsPhotoByFioRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_PHOTO_BY_FIO,
    data.id,
  ],
  ReportsPhotoByFioSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsPhotoByFioSearch,
  ],
  ReportsRetrieve: (type: ReportType, data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    type,
    data.id,
  ],
  ReportsScoringRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_SCORING,
    data.id,
  ],
  ReportsScoringSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsScoringSearch,
  ],
  ReportsSnilsRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_SNILS,
    data.id,
  ],
  ReportsSnilsSearch: () => [QueryKeys.Reports, QueryKeys.ReportsSnilsSearch],
  ReportsTelegramRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_TELEGRAM,
    data.id,
  ],
  ReportsTelegramSearch: () => [
    QueryKeys.Reports,
    QueryKeys.ReportsTelegramSearch,
  ],
  ReportsTotalCount: (data: ReportsTotalCountRequest) => [
    QueryKeys.Reports,
    `request_contains:${data.request_contains}`,
    `report_task_types:${data.report_task_types?.join()}`,
  ],
  ReportsUpdate: () => [QueryKeys.ReportsUpdate],
  ReportsVinRetrieve: (data: ReportsRetrieveRequest) => [
    QueryKeys.Reports,
    ReportType.SEARCH_VIN,
    data.id,
  ],
  ReportsVinSearch: () => [QueryKeys.Reports, QueryKeys.ReportsVinSearch],
  ReportsVkSearch: () => [QueryKeys.Reports, QueryKeys.ReportsVkSearch],
  Statistics: (data: GroupStatisticsTransformedRequest) => [
    QueryKeys.GroupStatistics,
    `date_from:${data.date_from?.toISOString()}`,
    `date_to:${data.date_to?.toISOString()}`,
    `granularity:${data.granularity}`,
    `group_id:${data.group_id}`,
    `users:${data.users?.join()}`,
    `types::${data.types?.join()}`,
  ],
  StatisticsByType: (data: GroupStatisticsTransformedRequest) => [
    QueryKeys.GroupStatisticsByType,
    `date_from:${data.date_from?.toISOString()}`,
    `date_to:${data.date_to?.toISOString()}`,
    `granularity:${data.granularity}`,
    `group_id:${data.group_id}`,
    `users:${data.users?.join()}`,
    `types::${data.types?.join()}`,
  ],
  TelegramUserBotAll: (
    { limit, offset }: TelegramUserBotRetrieveAllRequest = { limit: 100 },
  ) => [QueryKeys.TelegramUserBot, `limit:${limit}`, `offset:${offset}`],
  TelegramUserBotAllClear: () => [QueryKeys.TelegramUserBot],
};

export const REFETCH_INTERVAL = 5000;
