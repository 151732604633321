import { Device } from "@shared/types";
import { useMedia } from "react-use";
import {
  TABLET_HORIZONTAL_WIDTH,
  TABLET_VERTICAL_WIDTH,
  MOBILE_MAX_WIDTH,
} from "src/shared/widthSize";

export interface UsePageSizeParams {
  isDesktop: boolean;
  isTabletHorizontal: boolean;
  isTabletVertical: boolean;
  isFromTabletVertical: boolean;
  isUntilTabletVertical: boolean;
  isMobile: boolean;
  device: Device;
}

export const usePageSize = (): UsePageSizeParams => {
  const isDesktop = useMedia(
    `only screen and (min-width: ${TABLET_HORIZONTAL_WIDTH}px)`,
    false,
  );
  const isTabletHorizontal = useMedia(
    `only screen and (min-width: ${TABLET_VERTICAL_WIDTH}px) and (max-width: ${TABLET_HORIZONTAL_WIDTH - 1}px)`,
    false,
  );
  const isFromTabletVertical = useMedia(
    `only screen and (min-width: ${TABLET_VERTICAL_WIDTH}px)`,
    false,
  );
  const isTabletVertical = useMedia(
    `only screen and (min-width: ${MOBILE_MAX_WIDTH}px) and (max-width: ${TABLET_VERTICAL_WIDTH - 1}px)`,
    true,
  );
  const isUntilTabletVertical = useMedia(
    `only screen and (max-width: ${TABLET_VERTICAL_WIDTH}px)`,
    false,
  );
  const isMobile = useMedia(
    `only screen and (max-width: ${MOBILE_MAX_WIDTH - 1}px)`,
    false,
  );

  return {
    isDesktop,
    isTabletHorizontal,
    isTabletVertical,
    isFromTabletVertical,
    isUntilTabletVertical,
    isMobile,
    device: isMobile ? "mobile" : "desktop",
  };
};
