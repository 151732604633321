import ReactDOMServer from "react-dom/server";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { PdfHeader } from "@shared/components/pdfLayout/components/PdfHeader/PdfHeader";
import { pdfLayout, savePdf } from "@shared/components/pdfLayout/pdfLayout";
import { ReportsScoringRetrieveResponse } from "@shared/types/report/retrieve";
import { ReportType } from "@shared/types/report/constants";
import { Scoring } from "../../components/Scoring/Scoring";

export interface GeneratePdfParams {
  report: ReportsScoringRetrieveResponse;
  type: ReportType;
  reportId: string;
}

export const buildPdf = ({ report, type, reportId }: GeneratePdfParams) => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const fullDossier = ReactDOMServer.renderToString(
    <>
      <PdfHeader requestDescribe={report.request_describe} />
      {report?.request_describe && (
        <FullDossier
          print={RetrieveCardPrint.print}
          requestDescribe={report?.request_describe}
          type={type}
          reportId={reportId}
        />
      )}
      <Scoring
        print={RetrieveCardPrint.print}
        scoringResult={report?.scoring_result}
        status={report?.scoring_result?.status}
      />
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", fullDossier);
  return outputHtml;
};

export const generatePdf = ({
  report,
  type,
  reportId,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
  });
  return savePdf(outputHtml, reportId);
};

export const framePdf = ({ report, type, reportId }: GeneratePdfParams) => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
  });

  const { iframe } = pdfLayout(outputHtml, reportId);

  return iframe;
};
