import { FC, useMemo, useRef } from "react";
import { Text } from "@ui/ui/Text/Text";
import { ChartOptions } from "chart.js";
import {
  getDatasets,
  mapAppToColors,
} from "@ui/pages/Analytics/components/GraphBlock/getDatasets";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { Flex, Skeleton } from "antd";
import { useGroupStatistics } from "@ui/app/api/analytics/queries/useStatisticsGroup";
import { Button } from "@ui/ui/Button/Button";
import { VscSettings } from "react-icons/vsc";
import { GroupStatisticsTransformedResponse } from "@ui/app/api/analytics/types";
import { env } from "@shared/env";
import _ from "lodash";
import { mapReportTypeToLabel } from "@ui/utils/reports/mapReportTypeToLabel";
import { useAppTheme } from "@ui/app/providers";
import { getOptions } from "../../constants";
import { LineChart } from "./LineChart/LineChart";
import s from "./GraphBlock.module.scss";
import { LegendHint } from "../LegendHint/LegendHint";

interface GraphBlockLegendItemProps {
  statistics?: GroupStatisticsTransformedResponse;
  color: string;
}

const GraphBlockLegendItem: FC<GraphBlockLegendItemProps> = ({
  statistics,
  color,
}) => (
  <Flex vertical gap={4}>
    <Flex gap={4} align="center">
      <div
        className={s.container__total__marker}
        style={{ backgroundColor: color }}
      />
      <Text variant="body-4">Все проверки</Text>
    </Flex>
    <Text variant="header-7">
      {statistics?.datasets?.reduce((dataItem, acc) => acc + dataItem, 0)}
    </Text>
  </Flex>
);

interface GraphBlockProps {
  group_id: number;
  user_id: number;
  filters: AnalyticsFilters;
  onOpenSettings: () => void;
}

export const GraphBlock: FC<GraphBlockProps> = ({
  filters,
  group_id,
  user_id,
  onOpenSettings,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [theme] = useAppTheme();

  const lineChartOptions = useMemo<ChartOptions<"line">>(
    () => getOptions({ theme }),
    [theme],
  );

  const { data: statisticsAll } = useGroupStatistics(
    {
      granularity: filters.granularity,
      date_from: filters.startDate,
      date_to: filters.endDate,
      group_id,
      users: user_id ? [user_id] : undefined,
    },
    {
      enabled: Boolean(group_id && user_id),
    },
  );
  const { data: statisticsTarget } = useGroupStatistics(
    {
      granularity: filters.granularity,
      date_from: filters.startDate,
      date_to: filters.endDate,
      group_id,
      users: user_id ? [user_id] : undefined,
      types: filters.graphReportType ? [filters.graphReportType] : undefined,
    },
    {
      enabled: Boolean(group_id && user_id && filters.graphReportType),
    },
  );

  const labels = useMemo(() => {
    const arr = [
      ...(statisticsAll?.labels || []),
      ...(statisticsTarget?.labels || []),
    ];
    const uniqueArr = _.uniqBy(arr, "label");

    const timeByLabel =
      arr.reduce<Record<string, number>>((acc, { label, date }) => {
        acc[label] = date.getTime();
        return acc;
      }, {}) || {};

    return uniqueArr.sort(
      (a, b) => timeByLabel[a.label] - timeByLabel[b.label],
    );
  }, [statisticsAll?.labels, statisticsTarget?.labels]);

  const datasets = useMemo(() => {
    const statisticsTargetData =
      statisticsTarget?.datasets === statisticsAll?.datasets
        ? undefined
        : statisticsTarget?.datasets.map((value, index) => ({
            value,
            label: statisticsTarget?.labels[index],
          }));
    const statisticsAllData = statisticsAll?.datasets.map((value, index) => ({
      value,
      label: statisticsAll?.labels[index],
    }));

    const datasetTargetByLabel = statisticsTargetData?.reduce<
      Record<string, number>
    >((acc, { label, value }) => {
      acc[label.label] = value;
      return acc;
    }, {});
    const datasetAllByLabel = statisticsAllData?.reduce<Record<string, number>>(
      (acc, { label, value }) => {
        acc[label.label] = value;
        return acc;
      },
      {},
    );

    const datasetTargetData = datasetTargetByLabel
      ? labels.map((label) => datasetTargetByLabel[label.label] || 0)
      : undefined;
    const datasetAllData = datasetAllByLabel
      ? labels.map((label) => datasetAllByLabel[label.label] || 0)
      : undefined;

    return getDatasets({
      datasetsTarget: datasetTargetData
        ? [{ data: datasetTargetData }]
        : undefined,
      datasetsAll: datasetAllData ? [{ data: datasetAllData }] : undefined,
      height: ref.current?.clientHeight || 0,
    });
  }, [statisticsTarget, statisticsAll, labels]);

  const { all, target } =
    mapAppToColors[env.REACT_APP_THEME] || mapAppToColors.odyssey;

  return (
    <div className={s.container} ref={ref}>
      <Flex justify="space-between">
        <Text variant="header-6" className={s.container__title}>
          Проверки
        </Text>
        <Button
          type="secondary"
          size="m"
          icon={<VscSettings className={s.container__settings_button_icon} />}
          className={s.container__settings_button}
          onClick={onOpenSettings}
        />
      </Flex>
      <Skeleton
        loading={!statisticsAll || !statisticsTarget}
        className={s.container__skeleton}
      >
        {statisticsAll && statisticsTarget && (
          <div className={s.container__total}>
            <Flex vertical gap={4}>
              <Flex gap={32}>
                <GraphBlockLegendItem
                  statistics={statisticsAll}
                  color={all.borderColor}
                />
                {statisticsAll === statisticsTarget ? null : (
                  <GraphBlockLegendItem
                    statistics={statisticsTarget}
                    color={target.borderColor}
                  />
                )}
              </Flex>
              <LegendHint
                interval={filters.interval}
                reportTypesHint={
                  filters.graphReportType
                    ? mapReportTypeToLabel[filters.graphReportType]
                    : undefined
                }
              />
            </Flex>
            <LineChart
              datasets={datasets}
              labels={labels.map(({ label }) => label)}
              options={lineChartOptions}
            />
          </div>
        )}
      </Skeleton>
    </div>
  );
};
