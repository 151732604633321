import { FC, PropsWithChildren } from "react";
import { env } from "@shared/env";
import { getAppConfig } from "@ui/app/providers/AppFeatures/appConfig/getAppConfig";
import { AppFeaturesContext } from "./AppFeaturesContext";

const { configFile } = getAppConfig(env.REACT_APP_THEME);

const appFeatures = configFile;

export const AppFeaturesProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppFeaturesContext.Provider value={appFeatures}>
      {children}
    </AppFeaturesContext.Provider>
  );
};
