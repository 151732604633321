import { FC, useCallback } from "react";
import Sider from "antd/es/layout/Sider";
import { Drawer } from "@ui/ui/Drawer/Drawer";
import s from "./MainLayoutUser.module.scss";
import { MainLayoutUserMenu } from "./components/MainLayoutUserMenu/MainLayoutUserMenu";

interface MainLayoutUserProps {
  collapsed: boolean;
  isTablet: boolean;
  onCollapse: (collapsed: boolean) => void;
  setWrapperRef: (element: HTMLElement | null) => void;
}

export const MainLayoutUser: FC<MainLayoutUserProps> = ({
  collapsed,
  onCollapse,
  setWrapperRef,
  isTablet,
}) => {
  const onClose = useCallback(() => {
    onCollapse(true);
  }, [onCollapse]);

  return isTablet ? (
    <Drawer
      width={230}
      placement="right"
      visible={!collapsed}
      onClose={onClose}
    >
      <span ref={setWrapperRef}>
        <MainLayoutUserMenu onClose={onClose} />
      </span>
    </Drawer>
  ) : (
    <Sider
      ref={setWrapperRef}
      className={s.sider}
      defaultCollapsed
      width={320}
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <MainLayoutUserMenu onClose={onClose} />
    </Sider>
  );
};
