import { useMutation } from "@tanstack/react-query";

import { QueryKey } from "@ui/app/api/constants";
import { queryClient } from "@ui/app/api/client";
import { telegramUserBotDeactivate } from "../queries";

export const useTelegramUserBotDeactivate = () =>
  useMutation({
    mutationFn: telegramUserBotDeactivate,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QueryKey.TelegramUserBotAllClear(),
      }),
  });
