/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return */
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import dayjs from "dayjs";
import notoSansFont from "@assets/fonts/notoSans/NotoSans-Medium.ttf";
import montserratFont from "@assets/fonts/montserrat/Montserrat-Medium.ttf";
import { getAppConfig } from "../../../ui/app/providers/AppFeatures/appConfig/getAppConfig";
import { env } from "../../env";

const isOdysseyBlack = env.REACT_APP_THEME === "odyssey_black";

const { title, fontName } = getAppConfig(env.REACT_APP_THEME);

const loadFont = async (fontPath: string): Promise<string> => {
  const response = await fetch(fontPath);
  const fontData = await response.arrayBuffer();
  const byteArray = new Uint8Array(fontData);
  const binary = byteArray.reduce(
    (acc, byte) => acc + String.fromCharCode(byte),
    "",
  );
  return btoa(binary);
};

const addCustomFont = async (pdf: jsPDF) => {
  const fontData = await loadFont(
    isOdysseyBlack ? montserratFont : notoSansFont,
  );
  pdf?.addFileToVFS(`${fontName}.ttf`, fontData);
  pdf?.addFont(`${fontName}.ttf`, fontName, "normal");
};

export const pdfLayout = (outputHtml: HTMLDivElement, reportId: string) => {
  const opt = {
    margin: [15, 8, 15, 0],
    filename: `${reportId || "download"}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
      scale: 2,
      height: 32767,
    },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    pagebreak: { mode: ["css", "legacy"] },
  };

  const iframe = document.createElement("iframe");
  const pdfFile = html2pdf()
    .from(outputHtml)
    .set(opt)
    .toPdf()
    .get("pdf")
    .then(async (pdf: jsPDF) => {
      // @ts-expect-error collections есть в internal
      const addImageImages = pdf?.internal?.collections?.addImage_images
        ? // @ts-expect-error collections есть в internal
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Object.keys(pdf?.internal?.collections?.addImage_images)?.length
        : null;
      const addImageImagesCount =
        addImageImages && addImageImages > 2 ? addImageImages - 2 : null;

      await addCustomFont(pdf);
      const date = new Date();
      const totalPages = Number(pdf?.internal?.pages?.length) - 1;
      const pageWidth = pdf?.internal?.pageSize?.getWidth();
      const pageHeight = pdf?.internal?.pageSize?.getHeight();

      // Идем по страницам в обратном порядке
      for (let i = totalPages; i >= 1; i -= 1) {
        if (addImageImagesCount && i > addImageImagesCount) {
          pdf.deletePage(i);

          // eslint-disable-next-line no-continue
          continue;
        }
        pdf.setPage(i);
        pdf.setFont("NotoSans");
        pdf.setFontSize(8);
        pdf.setTextColor(100);
        pdf.text(
          `${i}/${addImageImagesCount ?? totalPages}`,
          pageWidth - 11,
          pageHeight - 7,
        );

        pdf.text(`${window.location.href}`, 8, pageHeight - 7);
        pdf.text(`${title} - Просмотр отчета`, pageWidth / 2, 7);
        pdf.text(`${dayjs(date).format("DD.MM.YYYY, HH:mm")}`, 8, 7);
      }

      const pdfBlob = pdf.output("blob");
      const url = URL.createObjectURL(pdfBlob);

      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    });

  return { pdf: pdfFile, iframe };
};

export const savePdf = (outputHtml: HTMLDivElement, reportId: string) => {
  const { pdf } = pdfLayout(outputHtml, reportId);
  return pdf
    .catch((error: Error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    })
    .save();
};
