import { Flex } from "antd";
import { Block } from "@ui/ui/Block/Block";
// import { UserSettingsApi } from './components/UserSettingsApi/UserSettingsApi';
import { AppRoute } from "@ui/app/routes/constants";
import { useGroups } from "@ui/app/api/groups";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import { UserSettingsSessions } from "./components/UserSettingsSessions/UserSettingsSessions";
import { UserSettingsSecurity } from "./components/UserSettingsSecurity/UserSettingsSecurity";
import { UserSettingsMainInfo } from "./components/UserSettingsMainInfo/UserSettingsMainInfo";
import s from "./UserSettings.module.scss";

const UserSettings = () => {
  const { data: user } = useCurrentUserQuery();
  const { data: groups } = useGroups();
  const group = groups?.[0];

  if (!user || !group) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Настройки аккаунта"
        mapPathToTitle={{ [AppRoute.User]: "Настройки аккаунта" }}
        paths={[AppRoute.User]}
      />
      <Block>
        <UserSettingsMainInfo user={user} group={group} />
      </Block>
      <Block>
        <UserSettingsSecurity group={group} />
      </Block>
      {/* <Block>
        <UserSettingsApi />
      </Block> */}
      <Block>
        <UserSettingsSessions />
      </Block>
    </Flex>
  );
};

export default UserSettings;
