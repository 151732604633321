import { MdNorthEast } from "react-icons/md";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useCallback, useState } from "react";
import { Text } from "../../ui/Text/Text";
import s from "./PageSearchPointer.module.scss";
import { useNotification } from "../../hooks/useNotification";
import { useOutsideClick } from "../../hooks/useOutsideClick";

interface PageSearchPointerProps {
  title: string;
}

interface MessageProps extends PageSearchPointerProps {
  foundMatches: HTMLElement[];
  initialIndex: number;
}

// Функция для выделения текста и возврата массива найденных элементов <mark>
const highlightMatches = (searchTerm: string): HTMLElement[] => {
  if (!searchTerm) return [];

  // Создаем регулярное выражение для поиска
  const regex = new RegExp(`(${searchTerm})`, "gi");

  // Ищем все текстовые узлы в теле страницы
  const walker = document.createTreeWalker(
    document.body,
    NodeFilter.SHOW_TEXT,
    null,
  );
  const nodesToReplace: Text[] = [];
  let currentNode: Node | null;

  // Собираем все текстовые узлы
  // eslint-disable-next-line no-cond-assign
  while ((currentNode = walker.nextNode())) {
    const textNode = currentNode as Text;
    if (regex.test(textNode.textContent || "")) {
      nodesToReplace.push(textNode);
    }
  }

  const highlightedElements: HTMLElement[] = [];

  // Заменяем найденные вхождения на <mark> с подсветкой
  nodesToReplace.forEach((textNode) => {
    const span = document.createElement("span");
    span.innerHTML =
      textNode.textContent?.replace(regex, "<mark>$1</mark>") || "";
    highlightedElements.push(...Array.from(span.getElementsByTagName("mark")));
    textNode.replaceWith(span);
  });

  return highlightedElements; // Возвращаем все выделенные элементы <mark>
};

// Функция для удаления всех выделений
const clearHighlights = () => {
  const markedElements = document.querySelectorAll("mark");

  // Удаляем обертку <mark> и восстанавливаем оригинальный текст
  markedElements.forEach((mark) => {
    const parent = mark.parentNode;
    if (parent) {
      parent.replaceChild(
        document.createTextNode(mark.textContent || ""),
        mark,
      );
      parent.normalize(); // Объединяет смежные текстовые узлы
    }
  });
};

const scrollToMatch = (index: number, matchesArray: HTMLElement[]) => {
  if (matchesArray[index]) {
    matchesArray[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    matchesArray.forEach((el) => {
      // Сбрасываем цвет для всех совпадений
      // eslint-disable-next-line no-param-reassign
      el.style.backgroundColor = "yellow";
    });

    // Выделяем текущее совпадение
    // eslint-disable-next-line no-param-reassign
    matchesArray[index].style.backgroundColor = "orange";
  }
};

const Message = ({ title, foundMatches, initialIndex }: MessageProps) => {
  const [currentMatchIndex, setCurrentMatchIndex] =
    useState<number>(initialIndex); // Текущий индекс

  const handleNextMatch = () => {
    if (foundMatches.length === 0) return;
    const nextIndex =
      currentMatchIndex === foundMatches.length - 1 ? 0 : currentMatchIndex + 1;
    setCurrentMatchIndex(nextIndex);
    scrollToMatch(nextIndex, foundMatches);
  };

  const handlePrevMatch = () => {
    if (foundMatches.length === 0) return;
    const prevIndex =
      currentMatchIndex === 0 ? foundMatches.length - 1 : currentMatchIndex - 1;
    setCurrentMatchIndex(prevIndex);
    scrollToMatch(prevIndex, foundMatches);
  };
  return (
    <div className={s.message}>
      {title}
      <Text color="secondary">
        {currentMatchIndex + 1}/{foundMatches.length}
      </Text>
      <div className={s.message}>
        <FaChevronUp
          size={20}
          onClick={handlePrevMatch}
          className={s.message__control}
        />
        <FaChevronDown
          size={20}
          onClick={handleNextMatch}
          className={s.message__control}
        />
      </div>
    </div>
  );
};

export const PageSearchPointer = ({ title }: PageSearchPointerProps) => {
  const [search] = useNotification();

  const { ref } = useOutsideClick({
    callback: (event) => {
      const notification =
        document.getElementsByClassName("ant-notification")[0];

      if (notification) {
        if (!notification.contains(event.target as Node)) {
          search.destroy(title);
        }
      } else {
        search.destroy(title);
      }
    },
  });

  const handleSearch = useCallback(() => {
    clearHighlights(); // Убираем предыдущие подсветки
    if (title.length > 0) {
      const foundMatches = highlightMatches(title); // Выделяем новые совпадения
      if (foundMatches.length > 0) {
        scrollToMatch(1, foundMatches); // Прокручиваем к первому совпадению

        search.open({
          key: title,
          message: (
            <Message
              title={title}
              foundMatches={foundMatches}
              initialIndex={1}
            />
          ),
          duration: null,
        });
      }
    }
  }, [search, title]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={s.container} onClick={handleSearch} ref={ref}>
      <MdNorthEast className={s.container__icon} />
      <Text variant="body-5">{title}</Text>
    </div>
  );
};
