import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./setup";
import "../styles/index.css";
import "../styles/modal.scss";
import "../styles/menu.scss";
import "./validation/yupConfig";
import "@femida1/gui_web_core/styles.css";
import { queryClient } from "@ui/app/api/client";
import { ConfigProvider } from "antd";
import { StrictMode } from "react";
import { env } from "@shared/env";
import { getAppConfig } from "@ui/app/providers/AppFeatures/appConfig/getAppConfig";
import ErrorBoundary from "./layouts/ErrorBoundary/ErrorBoundary";
import { App } from "./App/App";

const { title, logo } = getAppConfig(env.REACT_APP_THEME);

const favicon = document.getElementById("app-logo") as HTMLLinkElement;
favicon.href = logo;

document.title = title;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={{ hashed: false }}>
          <App />
        </ConfigProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>,
);
