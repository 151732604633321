import { FC } from "react";
import { MainLayout } from "@ui/app/layouts/MainLayout/MainLayout";
import { Outlet } from "react-router-dom";

interface RootProps {}

const Root: FC<RootProps> = () => (
  <MainLayout>
    <Outlet />
  </MainLayout>
);

export default Root;
