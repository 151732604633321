import { GroupRequest } from "../types";
import { useGroups } from "./useGroups";

interface UseGroupsProps {
  enabled?: boolean;
}

export const useGroup = (
  { group_id }: GroupRequest,
  { enabled }: UseGroupsProps = {},
) => {
  const groupsQuery = useGroups({ enabled });

  return {
    ...groupsQuery,
    data: groupsQuery.data?.find((g) => g.group_id === group_id),
  };
};
