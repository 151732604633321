import { PersonInfoKeys } from "@shared/types/report/retrieve";
import { ETelegramDossierInfoKeys } from "@shared/types/report/retrieve/telegramInfo/constants";
import { UserInfoKeys } from "@shared/types/report/retrieve/vk";

export const EMPTY_KEY = "EMPTY_KEY";

export const titles: Record<string, string> = {
  [PersonInfoKeys.persons]: "Лица",
  [PersonInfoKeys.passports]: "Паспорт",
  [PersonInfoKeys.phones]: "Номера телефонов",
  [PersonInfoKeys.autos]: "Автомобили",
  [PersonInfoKeys.snilses]: "СНИЛС",
  [PersonInfoKeys.inns]: "ИНН",
  [PersonInfoKeys.emails]: "E-mail",
  [PersonInfoKeys.citizens]: "Двойное гражданство",
  [ETelegramDossierInfoKeys.userId]: "ID",
  [ETelegramDossierInfoKeys.userNames]: "Имя пользователя",
  [ETelegramDossierInfoKeys.region]: "Регион",
  [ETelegramDossierInfoKeys.names]: "Имена",
  [ETelegramDossierInfoKeys.tags]: "Теги",
  [ETelegramDossierInfoKeys.telegramAddresses]: "Адреса",
  [ETelegramDossierInfoKeys.isBot]: "Бот",
  [ETelegramDossierInfoKeys.groupsCount]: "Кол-во групп",
  [UserInfoKeys.vkCities]: "Город",
  [UserInfoKeys.vkCountries]: "Страна",
  [UserInfoKeys.vkDobs]: "Дата рождения",
  [UserInfoKeys.vkEmails]: "E-mail",
  [UserInfoKeys.vkNames]: "Имя пользователя",
  [UserInfoKeys.vkPhones]: "Номера телефонов",
  otherLastNames: "Прошлые фамилии",
};
