import {
  CrossingBorderItemEnum,
  CrossingBordersSummaryKeys,
} from "../crossingBorders";

export const crossingBorderItemEnumOrder: CrossingBorderItemEnum[] = [
  CrossingBorderItemEnum.crossingDate,
  CrossingBorderItemEnum.operation,
  CrossingBorderItemEnum.transportType,
  CrossingBorderItemEnum.sourceCountry,
  CrossingBorderItemEnum.destinationCountry,
  CrossingBorderItemEnum.documentCountry,

  CrossingBorderItemEnum.documentNumber,
  CrossingBorderItemEnum.documentIssue_Date,
  CrossingBorderItemEnum.birthDate,
  CrossingBorderItemEnum.grn,
  CrossingBorderItemEnum.punkt,
  CrossingBorderItemEnum.tripNum,
  CrossingBorderItemEnum.srcCity,
  CrossingBorderItemEnum.dstCity,
  CrossingBorderItemEnum.info,
];

export const crossingBorderItemTitles: Record<CrossingBorderItemEnum, string> =
  {
    [CrossingBorderItemEnum.crossingDate]: "Дата пересечения",
    [CrossingBorderItemEnum.operation]: "Тип пересечения",
    [CrossingBorderItemEnum.transportType]: "Транспорт",
    [CrossingBorderItemEnum.sourceCountry]: "Страна откуда",
    [CrossingBorderItemEnum.destinationCountry]: "Страна куда",
    [CrossingBorderItemEnum.documentCountry]: "Страна выдачи документа",

    [CrossingBorderItemEnum.documentNumber]: "Номер документа",
    [CrossingBorderItemEnum.documentIssue_Date]: "Дата выдачи документа",
    [CrossingBorderItemEnum.birthDate]: "Дата рождения",
    [CrossingBorderItemEnum.grn]: "ГРН",
    [CrossingBorderItemEnum.punkt]: "Пункт",
    [CrossingBorderItemEnum.tripNum]: "Номер транспорта",
    [CrossingBorderItemEnum.srcCity]: "Город откуда",
    [CrossingBorderItemEnum.dstCity]: "Город куда",
    [CrossingBorderItemEnum.info]: "Информация",
  };

export const crossingBordersSummaryTexts: Record<
  CrossingBordersSummaryKeys,
  string
> = {
  [CrossingBordersSummaryKeys.count]: "Количество пересечений",
  [CrossingBordersSummaryKeys.countriesVisited]: "Посещенные страны",
  [CrossingBordersSummaryKeys.countryDocuments]: "Страна выдачи документов",
  [CrossingBordersSummaryKeys.transportList]: "Тип транспорта",
};

export const summaryKeys: CrossingBordersSummaryKeys[] = [
  CrossingBordersSummaryKeys.count,
  CrossingBordersSummaryKeys.countriesVisited,
  CrossingBordersSummaryKeys.countryDocuments,
  CrossingBordersSummaryKeys.transportList,
];
