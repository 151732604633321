import cn from "classnames";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import {
  ReportsPersonInfoData,
  ReportsPersonInfoDataEnum,
  reportsPersonInfoDataWithTitles,
} from "@shared/types/report/retrieve";
import { Text } from "../../ui/Text/Text";
import s from "./Data.module.scss";
import { RetrieveCardPrint } from "../RetrieveCard/types";

interface DataProps {
  reportsPersonInfoData: ReportsPersonInfoData;
  isSubReport?: boolean;
  dataId?: number;
}

export const Data = ({
  reportsPersonInfoData,
  status,
  print = RetrieveCardPrint.device,
  isSubReport,
  dataId,
}: DataProps & RetrieveCardCommonProps) => (
  <RetrieveCard
    dataId={dataId}
    isSubReport={isSubReport}
    expanded={isSubReport}
    title={reportsPersonInfoData?.source || "Данные"}
    status={status}
    print={print}
  >
    <div
      className={cn(
        s.container__content,
        print === RetrieveCardPrint.print && s.container__content_print,
      )}
    >
      {Object.values(ReportsPersonInfoDataEnum).map(
        (field) =>
          reportsPersonInfoData?.[field] && (
            <div
              key={field}
              className={cn(
                s.container__content__item,
                print === RetrieveCardPrint.print &&
                  s.container__content__item_print,
              )}
            >
              <Text color="secondary" variant="subheader-2">
                {reportsPersonInfoDataWithTitles[field]}
              </Text>
              <Text>{reportsPersonInfoData?.[field]}</Text>
            </div>
          ),
      )}

      {reportsPersonInfoData?.other_data &&
        Object.keys(reportsPersonInfoData?.other_data).map((otherData) =>
          reportsPersonInfoData?.other_data?.[otherData] ? (
            <div
              className={cn(
                s.container__content__item,
                print === RetrieveCardPrint.print &&
                  s.container__content__item_print,
              )}
              key={otherData}
            >
              <Text color="secondary" variant="subheader-2">
                {otherData}
              </Text>
              <Text>{reportsPersonInfoData?.other_data?.[otherData]}</Text>
            </div>
          ) : null,
        )}
    </div>
  </RetrieveCard>
);
