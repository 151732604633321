import { FC, useCallback } from "react";
import { Text } from "@ui/ui/Text/Text";

import { Flex } from "antd";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { Checkbox } from "@ui/ui/Checkbox/Checkbox";
import {
  allReportTypes,
  allReportTypesSet,
} from "@ui/pages/Analytics/constants";
import { ReportType } from "@shared/types/report/constants";
import { mapReportTypeToLabel } from "@ui/utils/reports/mapReportTypeToLabel";

interface SettingsModalPieReportTypeProps {
  modalValue: AnalyticsFilters;
  setModalValue: (
    value:
      | AnalyticsFilters
      | ((prevValue: AnalyticsFilters) => AnalyticsFilters),
  ) => void;
}

export const SettingsModalPieReportType: FC<
  SettingsModalPieReportTypeProps
> = ({ modalValue, setModalValue }) => {
  const onToggleAllPieReportTypes = useCallback(
    (checked: boolean) => {
      if (!checked && allReportTypesSet.size <= 1) return;
      const defaultValue = allReportTypes[0];
      setModalValue((prevFilters) => ({
        ...prevFilters,
        pieReportType:
          prevFilters.pieReportType === undefined ||
          prevFilters.pieReportType.size === allReportTypesSet.size
            ? new Set([defaultValue])
            : undefined,
      }));
    },
    [setModalValue],
  );

  const onTogglePieReportType = useCallback(
    (type: ReportType, checked: boolean) => {
      setModalValue((prevFilters) => {
        let pieReportType: Set<ReportType> | undefined =
          prevFilters.pieReportType
            ? new Set(prevFilters.pieReportType)
            : new Set(allReportTypesSet);

        if (checked) {
          pieReportType.add(type);
        } else if (pieReportType && pieReportType.size > 1) {
          pieReportType.delete(type);
        }
        if (pieReportType && pieReportType.size === allReportTypesSet.size) {
          pieReportType = undefined;
        }
        return {
          ...prevFilters,
          pieReportType,
        };
      });
    },
    [setModalValue],
  );

  return (
    <Flex vertical gap={12}>
      <Text variant="label-4">Отображать в диаграмме</Text>
      <Flex vertical gap={8}>
        <Checkbox
          checked={
            modalValue.pieReportType === undefined ||
            modalValue.pieReportType.size === allReportTypesSet.size
          }
          onUpdate={onToggleAllPieReportTypes}
        >
          Показывать все
        </Checkbox>
        {allReportTypes.map((reportType) => (
          <Checkbox
            key={reportType}
            checked={
              modalValue.pieReportType === undefined ||
              modalValue.pieReportType.has(reportType)
            }
            onUpdate={(checked) => onTogglePieReportType(reportType, checked)}
          >
            {mapReportTypeToLabel[reportType]}
          </Checkbox>
        ))}
      </Flex>
    </Flex>
  );
};
