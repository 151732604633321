import { ReportType } from "../constants";

export enum OrderType {
  ASCEND = "ASCEND",
  DESCEND = "DESCEND",
}

export interface ReportsRetrieveRequest {
  id: string;
}

export interface ReportsRetrieveAllRequest {
  limit?: number;
  offset?: number;
  request_contains?: string;
  cursor_created_at?: string;
  report_task_types?: ReportType[];
  order_type?: OrderType;
  user_ids?: number[];
}
