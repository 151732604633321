import ReactDOMServer from "react-dom/server";
import { SearchResults } from "@ui/entities/RetrievePhotoByFace/components/SearchResults/SearchResults";
import { PdfHeader } from "@shared/components/pdfLayout/components/PdfHeader/PdfHeader";
import { pdfLayout, savePdf } from "@shared/components/pdfLayout/pdfLayout";
import { ReportsPhotoByFaceRetrieveResponse } from "@shared/types/report/retrieve";
import { ReportType } from "@shared/types/report/constants";
import { RetrieveCardPrint } from "../../components/RetrieveCard/types";

export interface GeneratePdfParams {
  report: ReportsPhotoByFaceRetrieveResponse;
  type: ReportType;
  reportId: string;
}

export const buildPdf = ({ report }: GeneratePdfParams) => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const searchResults = ReactDOMServer.renderToString(
    <>
      <PdfHeader requestDescribe={report.request_describe} />
      <SearchResults
        print={RetrieveCardPrint.print}
        status={report?.photo_response?.status}
        faces={report?.photo_response?.result?.faces}
      />
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", searchResults);
  return outputHtml;
};

export const generatePdf = ({
  report,
  type,
  reportId,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
  });
  return savePdf(outputHtml, reportId);
};

export const framePdf = ({ report, type, reportId }: GeneratePdfParams) => {
  const outputHtml = buildPdf({
    report,
    type,
    reportId,
  });

  const { iframe } = pdfLayout(outputHtml, reportId);

  return iframe;
};
