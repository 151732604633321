import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { Item } from "@ui/entities/RetrieveVk/components/FriendsHistoryBlock/Item/Item";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { FriendsHistory } from "@shared/types/report/retrieve/responses/vkRetrieve";
import s from "./FriendsHistoryBlock.module.scss";

export type FriendsHistoryBlockProps = {
  friendsHistory: FriendsHistory;
} & RetrieveCardCommonProps;
export const FriendsHistoryBlock = ({
  friendsHistory,
  status,
  print = RetrieveCardPrint.device,
}: FriendsHistoryBlockProps) => (
  <RetrieveCard
    title={`Друзья (${friendsHistory.date})`}
    status={status}
    print={print}
  >
    <div className={s.container}>
      <Item
        title="Добавленные"
        items={friendsHistory.new_friends}
        print={print}
      />
      <Item
        title="Удаленные"
        items={friendsHistory.deleted_friends}
        print={print}
      />
    </div>
  </RetrieveCard>
);
