import { useQuery } from "@tanstack/react-query";

import { QueryKey } from "@ui/app/api/constants";
import { telegramUserBotRetrieveAll } from "../queries";
import { TelegramUserBotRetrieveAllRequest } from "../types";

export const useTelegramUserBotRetrieveAll = (
  data: TelegramUserBotRetrieveAllRequest,
) =>
  useQuery({
    queryFn: () => telegramUserBotRetrieveAll(data),
    queryKey: QueryKey.TelegramUserBotAll(data),
    refetchInterval: 10_000,
  });
