import { Description } from "@ui/entities/RetrieveCreditRating/components/CreditRatingResult/components/Description/Description";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { Common } from "@ui/components/Common/Common";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { getCreditRatingIndicatorModel } from "@ui/utils/creditRating/getCreditRatingIndicatorModel";
import { CreditRating } from "@shared/types/report/retrieve";

export type CreditRatingResultProps = {
  creditRating?: CreditRating;
} & RetrieveCardCommonProps;

export const CreditRatingResult = ({
  creditRating,
  status,
  print = RetrieveCardPrint.device,
}: CreditRatingResultProps) => (
  <RetrieveCard
    dataId={10}
    title="Результаты кредитного рейтинга"
    status={status}
    print={print}
  >
    <>
      <Description print={print} />
      <Common
        marker={creditRating?.rating}
        indicatorModel={getCreditRatingIndicatorModel(creditRating?.rating)}
      />
    </>
  </RetrieveCard>
);
