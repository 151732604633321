import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import { useReportsPhoneRetrieve } from "@ui/app/api/report/retrieve/mutations";
import { SearchPhone } from "@ui/entities/SearchPhone/SearchPhone";
import { ReportCommonProps } from "@ui/entities/types";

export const SearchPhoneWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: phoneReport, isLoading } = useReportsPhoneRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {phoneReport && (
        <SearchPhone report={phoneReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
