import { ChartOptions, TooltipItem } from "chart.js";
import { Theme } from "@shared/types";
import { ReportType } from "@shared/types/report/constants";
import {
  StatisticsGranularity,
  StatisticsInterval,
} from "@ui/app/api/analytics/types";

const mapThemeToColors: Record<Theme, { yGridColor: string }> = {
  dark: {
    /**
     * --color-base-gray-225
     */
    yGridColor: "rgb(79, 79, 79)",
  },
  light: {
    /**
     * --color-base-gray-800
     */
    yGridColor: "rgb(216, 224, 233)",
  },
};

interface GetOptionsProps {
  theme: Theme;
}

export const getOptions = ({
  theme,
}: GetOptionsProps): ChartOptions<"line"> => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      displayColors: false,
      callbacks: {
        label: (tooltipItem: TooltipItem<"line">) =>
          `${String(tooltipItem.raw)} запросов`,
        title: () => "", // Prevents displaying the title
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
        color: mapThemeToColors[theme].yGridColor,
      },
    },
  },
});

export const mapIntervalToLabel: Record<StatisticsInterval, string> = {
  [StatisticsInterval.all]: "За все время",
  [StatisticsInterval.custom]: "За выбранный период",
  [StatisticsInterval.last30Days]: "Последние 30 дней",
  [StatisticsInterval.last90Days]: "Последние 90 дней",
};

export const allReportTypes: ReportType[] = Object.values(ReportType);
export const allReportTypesSet = new Set<ReportType>(allReportTypes);

export const mapGranularityToLabel: Record<StatisticsGranularity, string> = {
  [StatisticsGranularity.Day]: "По дням",
  [StatisticsGranularity.Month]: "По месяцам",
  [StatisticsGranularity.Year]: "По годам",
};
