import { Permission } from "@ui/app/api/groups";

export enum PermissionType {
  Gold = "gold",
  Normal = "normal",
}

export const mapPermissionToType: Record<Permission, PermissionType> = {
  [Permission.AccessApiPermission]: PermissionType.Gold,
  [Permission.AdminPermission]: PermissionType.Normal,
  [Permission.MemberPermission]: PermissionType.Normal,
  [Permission.ReportIncludeOpenSourcesPermission]: PermissionType.Normal,
  [Permission.ReportAddressPermission]: PermissionType.Normal,
  [Permission.ReportCreditRatingPermission]: PermissionType.Normal,
  [Permission.ReportFullCreditRatingPermission]: PermissionType.Normal,
  [Permission.ReportDriverLicensePermission]: PermissionType.Normal,
  [Permission.ReportKadNumberPermission]: PermissionType.Normal,
  [Permission.ReportEmailPermission]: PermissionType.Normal,
  [Permission.ReportFioPermission]: PermissionType.Normal,
  [Permission.ReportGrnPermission]: PermissionType.Normal,
  [Permission.ReportInfoPermission]: PermissionType.Normal,
  [Permission.ReportInnFlPermission]: PermissionType.Normal,
  [Permission.ReportInnUlPermission]: PermissionType.Normal,
  [Permission.ReportOgrnPermission]: PermissionType.Normal,
  [Permission.ReportPassportPermission]: PermissionType.Normal,
  [Permission.ReportPhonePermission]: PermissionType.Normal,
  [Permission.ReportPhotoByFacePermission]: PermissionType.Normal,
  [Permission.ReportPhotoByFioPermission]: PermissionType.Normal,
  [Permission.ReportScoringPermission]: PermissionType.Normal,
  [Permission.ReportSnilsPermission]: PermissionType.Normal,
  [Permission.ReportVinPermission]: PermissionType.Normal,
  [Permission.ReportTelegramPermission]: PermissionType.Normal,
  [Permission.ReportVKPermission]: PermissionType.Normal,
  [Permission.SuperuserPermission]: PermissionType.Gold,
};
