import cn from "classnames";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import {
  RetrieveCardPrint,
  RetrieveCardVariant,
} from "@ui/components/RetrieveCard/types";
import { Row } from "@ui/entities/RetrievePhotoByFace/components/SearchResults/Row/Row";
import { Flex } from "antd";
import { Face } from "@shared/types/report/retrieve";
import s from "./SearchResults.module.scss";

export type SearchResultsProps = {
  faces?: Face[] | null;
  sourcePhoto?: string;
} & RetrieveCardCommonProps;

export const SearchResults = ({
  status,
  faces,
  sourcePhoto,
  print = RetrieveCardPrint.device,
}: SearchResultsProps) => (
  <RetrieveCard
    title="Результаты поиска"
    status={status}
    variant={RetrieveCardVariant.noBorder}
    print={print}
  >
    <Flex
      className={cn(
        s.container,
        print === RetrieveCardPrint.print && s.container_print,
      )}
      vertical
      gap={40}
    >
      {faces?.[0]?.candidates?.map((candidate) => (
        <Row
          candidate={candidate}
          key={candidate?.id}
          sourcePhoto={sourcePhoto}
        />
      ))}
    </Flex>
  </RetrieveCard>
);
