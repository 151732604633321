import React, { FC, useCallback, useEffect } from "react";
import { MainTabHintsStorage } from "@ui/pages/Main/components/MainTabs/utils/MainTabHintsStorage";
import { MainTab } from "@ui/pages/Main/constants";
import { Alert, Flex } from "antd";
import { IoCloseOutline } from "react-icons/io5";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { ReactComponent as WarningIcon } from "src/assets/warning.svg";
import s from "./HintAlert.module.scss";

interface IHintAlertProps {
  hint: string;
  tab: MainTab;
}

export const HintAlert: FC<IHintAlertProps> = ({ hint, tab }) => {
  const isShowHint = MainTabHintsStorage.get(tab);

  useEffect(() => {
    if (isShowHint === null) MainTabHintsStorage.set(tab, true);
  }, [isShowHint, tab]);

  const { state: isHintVisible, setFalse: hideHint } = useBooleanState(true);

  const onHideHint = useCallback(() => {
    hideHint();
    MainTabHintsStorage.set(tab, false);
  }, [hideHint, tab]);

  if (isShowHint !== null && !isShowHint) return null;

  return (
    isHintVisible && (
      <Alert
        message={
          <Flex gap={12} align="center">
            <Flex className={s.icon_wrapper} align="center" justify="center">
              <WarningIcon width={20} height={20} />
            </Flex>
            <Flex
              className={s.text_wrapper}
              align="center"
              justify="space-between"
            >
              {hint}
              <IoCloseOutline size={16} cursor="pointer" onClick={onHideHint} />
            </Flex>
          </Flex>
        }
        type="info"
      />
    )
  );
};
