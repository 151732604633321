import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { useMemo } from "react";

export type ChartDataType = ChartData<"line", number[], string>;

export interface LineChartProps {
  datasets: ChartDataType["datasets"];
  labels: ChartDataType["labels"];
  options?: ChartOptions<"line">;
}

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
);

export const LineChart = ({ datasets, labels, options }: LineChartProps) => {
  const data = useMemo(() => ({ datasets, labels }), [datasets, labels]);

  return <Line data={data} options={options} />;
};
