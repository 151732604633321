import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { YandexMap } from "@ui/components/YandexMap/YandexMap";
import { averageGeographicCoordinate } from "@ui/components/YandexMap/averageGeographicCoordinate";
import { OrderMapItem } from "@shared/types/report/retrieve";
import { RetrieveCardPrint } from "../../../../components/RetrieveCard/types";

export type PhoneBookProps = {
  orderMap?: OrderMapItem[];
} & RetrieveCardCommonProps;

export const OrderMap = ({
  orderMap,
  status,
  print = RetrieveCardPrint.device,
}: PhoneBookProps) => {
  const center = orderMap
    ? averageGeographicCoordinate(
        orderMap.map((order) => [
          order.latitude || "0",
          order.longitude || "0",
        ]),
      )
    : undefined;

  return (
    <RetrieveCard title="Карта заказов" status={status} print={print}>
      <YandexMap center={center} orderMap={orderMap} />
    </RetrieveCard>
  );
};
