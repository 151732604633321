import { ReportStatus } from "@shared/types/report/constants";
import { ReactComponent as Cross } from "src/assets/cross.svg";
import { ReactComponent as Finished } from "src/assets/finished.svg";
import { ReactComponent as NotFound } from "src/assets/not_found.svg";
import { Text } from "@ui/ui/Text/Text";
import { Tooltip } from "@ui/ui/Tooltip/Tooltip";
import { Loading } from "@ui/ui/Loading/Loading";
import s from "./Status.module.scss";

interface StatusProps {
  reportStatus?: ReportStatus | null;
}

export const Status = ({ reportStatus }: StatusProps) => (
  <div className={s.container}>
    {reportStatus === ReportStatus.InProgress && (
      <Tooltip
        title={
          <Text color="white">Отчет готов, некоторые данные догружаются</Text>
        }
      >
        <Loading />
      </Tooltip>
    )}
    {reportStatus === ReportStatus.Canceled && (
      <Tooltip title={<Text color="white">Отчет не сформирован</Text>}>
        <Cross className={s.container__icon} />
      </Tooltip>
    )}
    {reportStatus === ReportStatus.Finished && (
      <Tooltip title={<Text color="white">Отчет готов</Text>}>
        <Finished className={s.container__icon} />
      </Tooltip>
    )}
    {reportStatus === ReportStatus.NoData && (
      <Tooltip title={<Text color="white">Информация не найдена</Text>}>
        <NotFound className={s.container__icon} />
      </Tooltip>
    )}
  </div>
);
