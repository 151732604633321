import { Text } from "@ui/ui/Text/Text";
import { Checkbox } from "@ui/ui/Checkbox/Checkbox";
import s from "./Prop.module.scss";
import { propReportTypeConfig, titles } from "./constants";
import { ReportItem } from "../useSearchByFound";

export interface PropKey {
  propKey: string;
  items?: string[] | null;
  personInfoState: Map<string, ReportItem>;
  updatePersonInfoState: (personInfoMap: Map<string, ReportItem>) => void;
}

const Prop = ({
  propKey,
  items,
  personInfoState,
  updatePersonInfoState,
}: PropKey) =>
  items &&
  Array.isArray(items) &&
  items.length > 0 && (
    <div className={s.container}>
      <Text variant="subheader-4">{titles[propKey]}</Text>
      <div className={s.container__items}>
        {items.map((item) => (
          <Checkbox
            checked={personInfoState.has(item)}
            onUpdate={(e) => {
              if (e) {
                personInfoState.set(item, {
                  value: item,
                  type: propReportTypeConfig[propKey],
                });
              } else {
                personInfoState.delete(item);
              }

              updatePersonInfoState(new Map(personInfoState));
            }}
          >
            {item}
          </Checkbox>
        ))}
      </div>
    </div>
  );

export default Prop;
