export const downloadFile = (data: string, fileName: string) => {
  const file = new Blob([data]);

  const url = window.URL.createObjectURL(file);
  const element = document.createElement("a");
  element.href = url;
  element.download = fileName;
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  element.remove();
  window.URL.revokeObjectURL(url);
};
