import { FC } from "react";
import { Pagination as AntdPagination } from "antd";
import classNames from "classnames";
import { ReactComponent as ChevronLeftRounded } from "src/assets/ChevronLeftRounded.svg";
import { ReactComponent as ChevronRightRounded } from "src/assets/ChevronRightRounded.svg";
import s from "./Pagination.module.scss";

interface IPaginationProps {
  className?: string;
  total: number;
  page: number;
  pageSize: number;
  onUpdate: (newPage: number) => void;
}

export const Pagination: FC<IPaginationProps> = ({
  className,
  total,
  page,
  pageSize,
  onUpdate,
}) => (
  <AntdPagination
    className={classNames(s.pagination, className)}
    defaultCurrent={1}
    current={page}
    total={total}
    pageSize={pageSize}
    hideOnSinglePage
    onChange={onUpdate}
    showSizeChanger={false}
    prevIcon={<ChevronLeftRounded />}
    nextIcon={<ChevronRightRounded />}
  />
);
