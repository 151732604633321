import { Text } from "@ui/ui/Text/Text";
import { AppRoute } from "@ui/app/routes/constants";
import {
  mainTabFioFormKey,
  useSessionStorage,
} from "@ui/hooks/useSessionStorage";
import { Flex } from "antd";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { PossibleNetworkResults } from "@shared/types/report/retrieve";
import s from "./RelatedPersonsModal.module.scss";

interface RelatedPersonsModalProps {
  possibleNetworks?: PossibleNetworkResults[] | null;
  onClose: () => void;
}

export const RelatedPersonsModal = ({
  possibleNetworks,
  onClose,
}: RelatedPersonsModalProps) => {
  const [, setFioParams] = useSessionStorage<PossibleNetworkResults | null>(
    mainTabFioFormKey,
    null,
  );

  const onCreateReport = useCallback(
    (possible_network: PossibleNetworkResults) => {
      setFioParams(possible_network);
      onClose();
    },
    [onClose, setFioParams],
  );

  return (
    <Flex vertical gap={32}>
      <Text variant="header-6">Связанные лица</Text>
      <Flex vertical gap={16} className={s.list}>
        {possibleNetworks?.map((possible_network, index) => (
          <Link
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            type="button"
            to={AppRoute.Lk}
            onClick={() => onCreateReport(possible_network)}
          >
            <Flex vertical gap={12} className={s.list__item}>
              <Text variant="subheader-4">
                {possible_network.last_name} {possible_network.first_name}{" "}
                {possible_network.middle_name} {possible_network.birth_date}
              </Text>
              <Text variant="subheader-2" color="accent">
                Заказать отчет
              </Text>
            </Flex>
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};
