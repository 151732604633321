export enum EStatus {
  Completed = "COMPLETED",
  No_data = "NO_DATA",
  In_progress = "IN_PROGRESS",
  Error = "ERROR",
  Invalid = "INVALID",
}

export enum ESortType {
  ascend = "ascend",
  descend = "descend",
  updateDateAscend = "updateDateAscend",
  updateDateDescend = "updateDateDescend",
}
