import { Modal } from "antd";
import { Button } from "@ui/ui/Button/Button";
import { Text } from "@ui/ui/Text/Text";
import { useCallback, useState } from "react";
import { PersonInfo } from "@shared/types/report/retrieve";
import s from "./SearchByFound.module.scss";
import Prop from "./Prop/Prop";
import { ReportItem, useSearchByFound } from "./useSearchByFound";
import { useNotification } from "../../../../hooks/useNotification";
import fullDossierStyles from "../../FullDossier.module.scss";

export interface SearchByFoundProps {
  isSearchByFoundModalVisible: boolean;
  hideSearchByFoundModal: () => void;
  propKeys: string[];
  personInfo: PersonInfo;
}

const SearchByFound = ({
  isSearchByFoundModalVisible,
  hideSearchByFoundModal,
  propKeys,
  personInfo,
}: SearchByFoundProps) => {
  const searchByFound = useSearchByFound();
  const [copyReportUrl] = useNotification();

  const [personInfoState, setPersonInfoState] = useState<
    Map<string, ReportItem>
  >(new Map<string, ReportItem>());
  const updatePersonInfoState = useCallback(
    (personInfoMap: Map<string, ReportItem>) =>
      setPersonInfoState(personInfoMap),
    [],
  );

  const reset = useCallback(() => {
    setPersonInfoState(new Map());
  }, []);

  const search = useCallback(() => {
    searchByFound(personInfoState);
    hideSearchByFoundModal();
    reset();
    copyReportUrl.success({
      key: "search-by-found-search",
      message: "Проверки созданы",
      className: fullDossierStyles.notification,
    });
  }, [personInfoState]);

  return (
    <Modal
      className={s.modal}
      title={<Text variant="header-6">Поиск по найденному</Text>}
      open={isSearchByFoundModalVisible}
      onCancel={hideSearchByFoundModal}
      footer={
        <>
          <Button key="clear" type="flat_secondary" size="s" onClick={reset}>
            Сбросить
          </Button>
          <Button key="next" type="primary" size="s" onClick={search}>
            Искать по найденному
          </Button>
        </>
      }
    >
      <div className={s.container}>
        {propKeys?.map((propKey) => (
          <Prop
            key={propKey}
            propKey={propKey}
            items={personInfo?.[propKey]}
            personInfoState={personInfoState}
            updatePersonInfoState={updatePersonInfoState}
          />
        ))}
      </div>
    </Modal>
  );
};

export default SearchByFound;
