import React, { FC } from "react";
import { Flex } from "antd";
import { Text } from "../../../../ui/Text/Text";
import s from "./TariffBlock.module.scss";

interface ITariffBlockProps {
  title: string;
  label: string;
  requestPrice: number;
  tariffPrice: number;
}

const TariffBlock: FC<ITariffBlockProps> = ({
  title,
  label,
  requestPrice,
  tariffPrice,
}) => (
  <div className={s.tariffBlock}>
    <Flex vertical gap={4}>
      <Text variant="subheader-5">{title}</Text>
      <Text variant="subheader-1">{label}</Text>
    </Flex>
    <Flex gap={4} vertical>
      <Flex className={s.tariffBlock_requestPriceBlock}>
        <Text variant="body-2" color="secondary">
          Стоимость запроса
        </Text>
        <Text variant="label-3">{requestPrice} ₽</Text>
      </Flex>
      <Flex className={s.tariffBlock_requestPriceBlock}>
        <Text variant="body-2" color="secondary">
          Стоимость тарифа
        </Text>
        <Text variant="label-3">{tariffPrice} ₽</Text>
      </Flex>
    </Flex>
  </div>
);

export default TariffBlock;
