import { useEffect, useState } from "react";
import { Flex } from "antd";
import { Block } from "@ui/ui/Block/Block";
// import { UserSettingsApi } from './components/UserSettingsApi/UserSettingsApi';
import { AppRoute } from "@ui/app/routes/constants";
import { useDocumentDownload } from "@ui/app/api/documents/mutations/useDocumentDownload";
import { useDocumentsRetrieveAll } from "@ui/app/api/documents/mutations/useDocumentsRetrieveAll";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import { Document } from "@ui/app/api/documents/types";
import { downloadFile } from "@ui/utils/download";
import { Text } from "@ui/ui/Text/Text";
import s from "./Documents.module.scss";
import { DocumentCard } from "./components/DocumentCard/DocumentCard";

const Documents = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const { data: user } = useCurrentUserQuery();
  const { mutate: documentsRetrieveAll } = useDocumentsRetrieveAll();
  const { mutate: documentDownload } = useDocumentDownload();
  const group = user?.groups?.[0];

  useEffect(() => {
    documentsRetrieveAll(
      { group_id: group?.group_id ?? 0 },
      {
        onSuccess(data) {
          setDocuments(data);
        },
      },
    );
  }, [documentsRetrieveAll, group?.group_id]);

  const onDownload = (file_name: string) => {
    documentDownload(
      {
        group_id: group?.group_id ?? 0,
        file_name,
      },
      {
        onSuccess(data) {
          downloadFile(data, file_name);
        },
      },
    );
  };
  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Мои документы"
        mapPathToTitle={{ [AppRoute.Documents]: "Мои документы" }}
        paths={[AppRoute.Documents]}
      />
      {!documents.length ? (
        <Block>
          <Flex justify="center">
            <Text variant="body-6" color="secondary">
              На данный момент пусто. Здесь будут отображаться документы.
            </Text>
          </Flex>
        </Block>
      ) : (
        documents.map((doc) => (
          <Block key={doc.file_name}>
            <DocumentCard
              onDownload={() => onDownload(doc.file_name)}
              title={doc.file_name}
              size={doc.size}
            />
          </Block>
        ))
      )}
    </Flex>
  );
};

export default Documents;
