import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { Scoring } from "@ui/components/Scoring/Scoring";
import { ReportCommonProps } from "@ui/entities/types";
import { RetrieveStatus } from "@ui/entities/RetrieveStatus/RetrieveStatus";
import { useCallback, useState } from "react";
import { ReportsScoringRetrieveResponse } from "@shared/types/report/retrieve";
import { ReportStatus } from "@shared/types/report/constants";
import { calculateReportStatus } from "../../utils/getReportStatus";
import s from "./RetrieveScoring.module.scss";
import { framePdf, generatePdf } from "./generatePdf";

export type RetrieveScoringProps = {
  report: ReportsScoringRetrieveResponse;
} & ReportCommonProps;

export const RetrieveScoring = ({
  report,
  type,
  reportId,
}: RetrieveScoringProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);

  const handleFramePdf = useCallback(
    async () => framePdf({ report, type, reportId }),
    [report, type, reportId],
  );

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.scoring_result?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <div className={s.container}>
        {report?.request_describe && (
          <FullDossier
            requestDescribe={report?.request_describe}
            type={type}
            handleGeneratePdf={handleGeneratePdf}
            handleFramePdf={handleFramePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
            reportId={reportId}
          />
        )}
        <Scoring
          scoringResult={report?.scoring_result}
          status={report?.scoring_result?.status}
        />
      </div>
    </RetrieveStatus>
  );
};
