import { useQuery } from "@tanstack/react-query";
import { employees } from "@ui/app/api/employees/queries";
import { IGetEmployeesRequest } from "@ui/app/api/employees";
import { REFETCH_INTERVAL } from "@ui/app/api/constants";
import { EStatus } from "@ui/pages/Employees/types";

export const useEmployees = (data: IGetEmployeesRequest) =>
  useQuery({
    queryFn: () => employees(data),
    queryKey: ["employees"],
    refetchInterval: ({ state }) => {
      const isSomeEmployeeInProgress = state.data?.some((item) =>
        Object.values(item).includes(EStatus.In_progress),
      );

      return isSomeEmployeeInProgress ? REFETCH_INTERVAL : false;
    },
  });
