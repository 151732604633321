import { Text } from "@ui/ui/Text/Text";
import { DataItem } from "@ui/pages/Analytics/components/PieBlock/types";
import { FC } from "react";
import s from "./Legend.module.scss";

export interface LegendProps {
  dataItems: DataItem[];
}

export const Legend: FC<LegendProps> = ({ dataItems }) => (
  <div className={s.container}>
    {dataItems.map((dataItem: DataItem) => (
      <div key={dataItem.name} className={s.container__item}>
        <div
          className={s.container__item__marker}
          style={{
            backgroundColor: dataItem.color,
          }}
        />
        <Text variant="body-2">{dataItem.name}</Text>
      </div>
    ))}
  </div>
);
