import { ReportType } from "@shared/types/report/constants";
import { PersonInfoKeys } from "@shared/types/report/retrieve";

export const titles: Record<string, string> = {
  [PersonInfoKeys.persons]: "Поиск по лицам",
  [PersonInfoKeys.passports]: "Поиск по паспортам",
  [PersonInfoKeys.phones]: "Поиск по Номерам телефонов",
  [PersonInfoKeys.autos]: "Поиск по  Автомобилям",
  [PersonInfoKeys.snilses]: "Поиск по СНИЛС",
  [PersonInfoKeys.inns]: "Поиск по ИНН",
  [PersonInfoKeys.emails]: "Поиск по e-mail",
  [PersonInfoKeys.citizens]: "Поиск по двойным гражданствам",
  otherLastNames: "Поиск по прошлыем фамилиям",
};

export const propReportTypeConfig: Record<string, ReportType> = {
  [PersonInfoKeys.passports]: ReportType.SEARCH_PASSPORT,
  [PersonInfoKeys.phones]: ReportType.SEARCH_PHONE,
  [PersonInfoKeys.autos]: ReportType.SEARCH_GRN,
  [PersonInfoKeys.snilses]: ReportType.SEARCH_SNILS,
  [PersonInfoKeys.inns]: ReportType.SEARCH_INN_FL,
  [PersonInfoKeys.emails]: ReportType.SEARCH_EMAIL,
};
