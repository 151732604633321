import { Text } from "@ui/ui/Text/Text";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import cn from "classnames";
import { PageSearchPointer } from "@ui/components/PageSearchPointer/PageSearchPointer";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { Address } from "@shared/types/report/retrieve";
import { ReportStatus } from "@shared/types/report/constants";
import s from "./Addresses.module.scss";

export type AddressesProps = {
  addresses?: Address[] | null;
} & RetrieveCardCommonProps;

export const Addresses = ({
  addresses,
  status,
  print = RetrieveCardPrint.device,
}: AddressesProps) => {
  if (
    status === ReportStatus.Finished &&
    (!addresses || (addresses && addresses?.length < 1))
  )
    return null;
  return (
    <RetrieveCard dataId={5} title="Адреса" status={status} print={print}>
      <div className={s.container}>
        <div
          className={cn(
            s.container__table,
            print === RetrieveCardPrint.print && s.container__table_print,
          )}
        >
          <div
            className={cn(
              s.container__table__item,
              print === RetrieveCardPrint.print &&
                s.container__table__item_print,
            )}
          >
            <Text color="secondary" variant="body-1">
              Адрес
            </Text>
            <Text color="secondary" variant="body-1">
              Год
            </Text>
            <Text color="secondary" variant="body-1">
              Количество упоминаний
            </Text>
          </div>

          {addresses?.map((address, index) => (
            <div
              className={cn(
                s.container__table__item,
                print === RetrieveCardPrint.print &&
                  s.container__table__item_print,
              )}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <PageSearchPointer title={address?.address} />
              <Text variant="body-5">{address?.year}</Text>
              <Text variant="body-5">{address?.count}</Text>
            </div>
          ))}
        </div>
      </div>
    </RetrieveCard>
  );
};
