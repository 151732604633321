import { ReportType } from "@shared/types/report/constants";
import dayjs from "dayjs";

export enum StatisticsInterval {
  all = "all",
  last30Days = "last30Days",
  last90Days = "last90Days",
  custom = "custom",
}

export enum StatisticsGranularity {
  Day = "DAY",
  Month = "MONTH",
  Year = "YEAR",
}

export interface GroupStatisticsTransformedRequest {
  date_from?: dayjs.Dayjs;
  date_to?: dayjs.Dayjs;
  granularity: StatisticsGranularity;
  group_id: number;
  types?: ReportType[];
  users?: number[];
}

export interface GroupStatisticsRequest<GroupByType extends boolean> {
  date_from?: string;
  date_to?: string;
  granularity: StatisticsGranularity;
  group_by_type: GroupByType;
  group_id: number;
  types?: ReportType[];
  users?: number[];
}

export interface GroupStatisticsResponse {
  datasets: {
    data: number[];
  }[];
  labels: string[];
}

export interface GroupStatisticsLabelWithDate {
  date: Date;
  label: string;
}

export interface GroupStatisticsTransformedResponse {
  datasets: number[];
  labels: GroupStatisticsLabelWithDate[];
}

export interface GroupStatisticsByTypeResponse {
  datasets: {
    data: number[];
  }[];
  labels: ReportType[];
}

export interface GroupStatisticsByTypeTransformedItem {
  type: ReportType;
  value: number;
}

export interface GroupStatisticsByTypeTransformedResponse {
  items: GroupStatisticsByTypeTransformedItem[];
}
