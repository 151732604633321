import { useQuery } from "@tanstack/react-query";

import { GroupStatisticsTransformedRequest } from "@ui/app/api/analytics/types";
import { QueryKey } from "@ui/app/api/constants";
import { getGroupStatisticsByType } from "../queries";

export const useGroupStatisticsByType = (
  data: GroupStatisticsTransformedRequest,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    enabled,
    queryFn: () => getGroupStatisticsByType(data),
    queryKey: QueryKey.Statistics(data),
  });
