import cn from "classnames";
import { Text } from "@ui/ui/Text/Text";
import s from "./Score.module.scss";

export interface ScoreProps {
  score?: number;
}

export const Score = ({ score }: ScoreProps) => (
  <div
    className={cn(
      s.container,
      score && score <= 1 && score >= 0.75 && s.container_high,
      score && score < 0.75 && score >= 0.5 && s.container_middle,
      score && score < 0.5 && score >= 0 && s.container_low,
    )}
  >
    {score && <Text>{Math.round(score * 100)}%</Text>}
  </div>
);
