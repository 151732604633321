import React, { FC } from "react";
import { Checkbox, CheckboxProps } from "@ui/ui/Checkbox/Checkbox";

export interface IShowAllCheckboxProps
  extends Omit<CheckboxProps, "children"> {}

export const ShowAllCheckbox: FC<IShowAllCheckboxProps> = ({
  checked,
  onUpdate,
}: IShowAllCheckboxProps) => (
  <Checkbox checked={checked} onUpdate={onUpdate}>
    Показывать все
  </Checkbox>
);
