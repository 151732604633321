import { MdSnippetFolder, MdAnalytics, MdPolicy } from "react-icons/md";
// import { IoDocumentText } from "react-icons/io5";
import {
  ENavMenuItem,
  MAIN_TAB_QUERY,
  MainTab,
} from "@ui/pages/Main/constants";
import { AppRoute } from "@ui/app/routes/constants";
import { ReactNode } from "react";
import { generatePath } from "react-router-dom";
import { IoDocumentText } from "react-icons/io5";
import { env } from "@shared/env";
import { FaTelegram } from "react-icons/fa";
import { mapMainTabToLabel } from "@ui/utils/tabs/mapMainTabToLabel";
import { MenuSubItem } from "./types";

export enum MenuGroup {
  Checking = "checking",
  Cabinet = "cabinet",
  Organization = "organization",
  Info = "info",
}

type MenuItem = {
  icon?: ReactNode;
  key: string;
  meta: string;
  title: string;
} & (
  | {
      children?: never;
      href: string;
    }
  | {
      children: MenuSubItem[];
      href?: never;
    }
);

interface GetMenuItemsProps {
  group_id?: number;
  user_id?: number;
}

const isOdyssey = env.REACT_APP_THEME !== "kretchet";
const isOdysseyBlack = env.REACT_APP_THEME === "odyssey_black";

const infoTab = {
  href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Info}`,
  key: MainTab.Info,
  meta: "по ключевому слову,by keyword",
  title: mapMainTabToLabel[MainTab.Info],
};
const photoTab = {
  href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Photo}`,
  key: MainTab.Photo,
  meta: "по фото,by photo",
  title: mapMainTabToLabel[MainTab.Photo],
};
const emailTab = {
  href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Email}`,
  key: MainTab.Email,
  meta: "по email,by email",
  title: mapMainTabToLabel[MainTab.Email],
};

export const getMenuItems = ({
  group_id,
  user_id,
}: GetMenuItemsProps): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      children: [
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Fio}`,
          key: MainTab.Fio,
          meta: "по фио,by fio",
          title: mapMainTabToLabel[MainTab.Fio],
        },
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Phone}`,
          key: MainTab.Phone,
          meta: "по телефону,by phone",
          title: mapMainTabToLabel[MainTab.Phone],
        },
        ...(isOdyssey ? [] : [infoTab]),
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Address}`,
          key: MainTab.Address,
          meta: "по адресу,by address",
          title: mapMainTabToLabel[MainTab.Address],
        },
        ...(isOdyssey ? [] : [photoTab]),
        ...(isOdyssey ? [] : [emailTab]),
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.InnOrSnils}`,
          key: MainTab.InnOrSnils,
          meta: "по ИНН или СНИЛС,по СНИЛС или ИНН,by inn or snils,by snils or inn",
          title: mapMainTabToLabel[MainTab.InnOrSnils],
        },
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Passport}`,
          key: MainTab.Passport,
          meta: "по паспорту,by passport",
          title: mapMainTabToLabel[MainTab.Passport],
        },
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Auto}`,
          key: MainTab.Auto,
          meta: "по номеру авто,by auto number",
          title: mapMainTabToLabel[MainTab.Auto],
        },
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.DriverLicense}`,
          key: MainTab.DriverLicense,
          meta: "по ВУ,by driver license",
          title: mapMainTabToLabel[MainTab.DriverLicense],
        },
        ...(isOdyssey ? [emailTab] : []),
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Company}`,
          key: MainTab.Company,
          meta: "по юр. лицам,by company",
          title: mapMainTabToLabel[MainTab.Company],
        },
        ...(isOdyssey ? [photoTab] : []),
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.CreditRating}`,
          key: MainTab.CreditRating,
          meta: "кредитный рейтинг,credit rating",
          title: mapMainTabToLabel[MainTab.CreditRating],
        },
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Scoring}`,
          key: MainTab.Scoring,
          meta: "скоринг,scoring",
          title: mapMainTabToLabel[MainTab.Scoring],
        },
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Vk}`,
          key: MainTab.Vk,
          meta: "вк,vk",
          title: mapMainTabToLabel[MainTab.Vk],
        },
        ...(isOdyssey ? [infoTab] : []),
        {
          href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Telegram}`,
          key: MainTab.Telegram,
          meta: "по telegram,by telegram",
          title: mapMainTabToLabel[MainTab.Telegram],
        },
      ],
      icon: <MdPolicy size={24} />,
      key: MenuGroup.Checking,
      meta: "проверка",
      title: "Проверка",
    },
    {
      children: [
        {
          disabled: !group_id || !user_id,
          href: generatePath(AppRoute.Analytics, {
            group_id: String(group_id),
            user_id: String(user_id),
          }),
          key: ENavMenuItem.Analytics,
          meta: "аналитика,analytics",
          title: "Аналитика",
        },
        {
          href: AppRoute.History,
          key: ENavMenuItem.History,
          meta: "история проверок,history",
          title: "История проверок",
        },
        // TODO: Uncomment when it's ready
        // {
        //   key: ENavMenuItem.Employees,
        //   title: "Мониторинг",
        //   meta: "сотрудники,employees",
        //   href: AppRoute.Employees,
        // },
      ],
      icon: <MdAnalytics size={24} />,
      key: MenuGroup.Cabinet,
      meta: "кабинет,cabinet",
      title: "Кабинет",
    },
    {
      children: [
        {
          href: AppRoute.Action,
          key: ENavMenuItem.Action,
          meta: "действия,actions",
          title: "Журнал действий",
        },

        {
          disabled: !group_id,
          href: generatePath(AppRoute.Accesses, {
            group_id: String(group_id),
          }),
          key: ENavMenuItem.Accesses,
          meta: "доступы,accesses",
          title: "Доступы",
        },
        ...(isOdyssey
          ? [
              {
                href: AppRoute.Support,
                key: ENavMenuItem.Support,
                meta: "поддержка,support",
                title: "Поддержка",
              },
            ]
          : []),
      ],
      icon: <MdSnippetFolder size={24} />,
      key: MenuGroup.Organization,
      meta: "организация,organization",
      title: "Организация",
    },
    ...(isOdyssey
      ? [
          {
            children: [
              {
                href: `https://docs.${
                  isOdysseyBlack ? "zbebwk.ai" : "odyssey-search.ru"
                }`,
                key: "apiDocs",
                meta: "документация,api",
                title: "API-документация",
              },
              ...(!isOdysseyBlack
                ? [
                    {
                      href: AppRoute.Contacts,
                      key: ENavMenuItem.Contacts,
                      meta: "контакты,contacts",
                      title: "Контакты",
                    },
                  ]
                : []),
            ],
            icon: <IoDocumentText size={24} />,
            key: MenuGroup.Info,
            meta: "инфо,info,информация,information",
            title: "Инфо",
          },
        ]
      : []),
    ...(!isOdysseyBlack && isOdyssey
      ? [
          {
            href: AppRoute.Telegram,
            icon: <FaTelegram size={24} />,
            key: ENavMenuItem.Telegram,
            meta: "телеграм бот,telegram bot,telegram,bot",
            title: "Телеграм бот",
          },
        ]
      : []),
  ];

  return menuItems;
};

interface GetFlatMenuItemsProps {
  group_id?: number;
  user_id?: number;
}

interface FlatMenuItem {
  href: string;
  icon?: ReactNode;
  key: string;
  meta: string;
  title: string;
}

export const getFlatMenuItems = ({
  group_id,
  user_id,
}: GetFlatMenuItemsProps): FlatMenuItem[] => {
  const tree = getMenuItems({ group_id, user_id });

  return tree
    .map((item) => {
      if (item.children) {
        return item.children.map((subItem) => ({
          ...subItem,
          icon: item.icon,
        }));
      }

      return item;
    })
    .flat();
};
