import { FoundStatus, ReportStatus } from "../../constants";

export enum ETagsSummaryKeys {
  fio = "fio",
  birth_date = "birth_date",
  phone = "phone",
  region = "region",
  tag = "tag",
  date = "date",
  phone_networks = "phone_networks",
}

export interface ITagsItem {
  [ETagsSummaryKeys.fio]: string;
  [ETagsSummaryKeys.birth_date]: string;
  [ETagsSummaryKeys.phone]?: string;
  [ETagsSummaryKeys.region]?: string;
  [ETagsSummaryKeys.tag]: string;
  [ETagsSummaryKeys.date]?: string;
  [ETagsSummaryKeys.phone_networks]?: string;
}

export interface ITags {
  status: ReportStatus;
  found: FoundStatus;
  result: ITagsItem[];
}
