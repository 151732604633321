import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { groups_edit } from "../queries";
import { Edit } from "../types";

export const useGroupsEdit = () =>
  useMutation({
    mutationFn: (data: Edit) => groups_edit(data),
    mutationKey: ["groupsEdit"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
