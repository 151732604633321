export const generatePassword = (): Promise<string> =>
  import("generate-password-browser").then(({ generate }) => {
    const generatedPassword = generate({
      /**
       * WARNING нужно засинкать с значением по этому тегу PASSWORD-GENERATE
       */
      symbols: "!@#$%^&*",
      strict: true,
      lowercase: true,
      uppercase: true,
      numbers: true,
      length: 20,
    });

    return generatedPassword;
  });
