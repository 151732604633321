import { useCallback, useEffect, useRef } from "react";

interface OutsideClickParams {
  callback?: (event: MouseEvent) => void;
}

export const useOutsideClick = ({ callback }: OutsideClickParams) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      // Если клик произошел вне элемента, скрыть его
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback?.(event);
      }
    },
    [callback],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return { ref };
};
