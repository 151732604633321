import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { AxiosError } from "axios";
import { changePassword } from "../queries";
import { ChangePasswordRequest } from "../types";

export const useUsersChangePasswordMutation = () =>
  useMutation<undefined, AxiosError, ChangePasswordRequest>({
    mutationFn: (data: ChangePasswordRequest) => changePassword(data),
    mutationKey: ["currentUser"],
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["currentUser"] }),
  });
