import {
  IGetAlertsRequest,
  TGetAlertsResponse,
} from "@ui/app/api/alerts/types";
import { client } from "@ui/app/api/client";
import { endpoints } from "@ui/app/api/alerts/endpoints";

export const getAlerts = (data: IGetAlertsRequest) =>
  client.post<undefined, TGetAlertsResponse, IGetAlertsRequest>(
    endpoints.ALERTS,
    data,
  );
