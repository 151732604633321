import { PropsWithChildren } from "react";
import {
  StatusPaper,
  StatusPaperProps,
} from "@ui/components/StatusPaper/StatusPaper";
import s from "./RetrieveStatus.module.scss";

export type RetrieveStatusProps = {
  isSubReport?: boolean;
} & StatusPaperProps;

export const RetrieveStatus = ({
  status,
  children,
  isSubReport,
}: PropsWithChildren<RetrieveStatusProps>) => (
  <div className={s.container}>
    {!isSubReport && <StatusPaper status={status} />}
    {children}
  </div>
);
