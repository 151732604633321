import { MdMoreVert } from "react-icons/md";
import { useCallback, useState } from "react";
import { ContextMenu } from "../../ui/ContextMenu/ContextMenu";
import s from "./OptionsMenu.module.scss";
import { Text } from "../../ui/Text/Text";

export interface Option {
  text: string;
  onClick: VoidFunction;
}

interface ContentProps {
  options?: Option[];
  // eslint-disable-next-line react/no-unused-prop-types
  handleClick?: () => void;
}

type OptionsMenuProps = {
  className?: string;
} & ContentProps;

const Content = ({ options, handleClick }: ContentProps) => (
  <div className={s.content}>
    {options?.map((option) => (
      <Text
        variant="body-2"
        color="secondary"
        className={s.content__option}
        as="button"
        onClick={() => {
          handleClick?.();
          option.onClick();
        }}
      >
        {option.text}
      </Text>
    ))}
  </div>
);

export const OptionsMenu = ({ className, options }: OptionsMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => setOpen(newOpen),
    [],
  );

  const handleClick = useCallback(() => setOpen(false), []);

  return (
    <ContextMenu
      open={open}
      handleOpenChange={handleOpenChange}
      content={<Content options={options} handleClick={handleClick} />}
      buttonInner={<MdMoreVert size={20} className={className} />}
    />
  );
};
