import { AppFeatures } from "../types";

export const kretchetConfig: AppFeatures = {
  authResetPasswordEnabled: false,
  authUserSelfRegistrationEnabled: false,
  chatsAvailable: true,
  documentsPageEnabled: false,
  fioTabSopdEnabled: false,
  mainIncludeOpenSourcesEnabled: true,
  mainMultiQueryEnabled: true,
  mainTabSopdEnabled: false,
  onboardingEnabled: false,
  themeSwitchEnabled: true,
  vkTabSopdEnabled: false,
};
