import { Text } from "@ui/ui/Text/Text";
import cn from "classnames";
import { Flex } from "antd";
import { Expand } from "@ui/ui/Expand/Expand";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { EMPTY_KEY, titles } from "./constants";
import s from "./Column.module.scss";

export interface ColumnProps {
  keyProp: string;
  items?: string[] | null;
  print?: RetrieveCardPrint;
}

const PIVOT = 6;

export const Column = ({ keyProp, items, print }: ColumnProps) => {
  const { state, toggle } = useBooleanState(false);
  let renderItems = items;
  if (!state) renderItems = items?.slice(0, PIVOT);
  if (print === RetrieveCardPrint.print) renderItems = items;

  return (
    <Flex
      vertical
      gap={16}
      className={cn(keyProp.startsWith(EMPTY_KEY) && s.empty)}
    >
      <Flex vertical gap={8}>
        <Text variant="body-1" color="secondary">
          {titles[keyProp]}
        </Text>
        {renderItems &&
          renderItems.length > 0 &&
          renderItems?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={`${item}-${index}`} id={item} variant="body-5">
              {item}
            </Text>
          ))}
        {!keyProp.startsWith(EMPTY_KEY) &&
          (!renderItems || (renderItems && renderItems?.length < 1)) && (
            <Text variant="body-5">Не найдено</Text>
          )}
      </Flex>
      {print === RetrieveCardPrint.device && items && items?.length > PIVOT && (
        <div>
          <Expand
            state={state}
            toggle={toggle}
            iconSize={20}
            className={s.watchAll}
          >
            {state ? "Показать меньше" : "Показать все"}
          </Expand>
        </div>
      )}
    </Flex>
  );
};
