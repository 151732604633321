export enum ReportsEndpoint {
  // search
  FioSearch = "/api/v1/reports/fio:search",
  FioMultiSearch = "/api/v1/reports/fio:multiSearch",
  FioMultiParse = "/api/v1/reports/fio:multiParse",
  PhoneSearch = "/api/v1/reports/phone:search",
  InnFlSearch = "/api/v1/reports/inn-fl:search",
  InnUlSearch = "/api/v1/reports/inn-ul:search",
  EmailSearch = "/api/v1/reports/email:search",
  PassportSearch = "/api/v1/reports/passport:search",
  SnilsSearch = "/api/v1/reports/snils:search",
  VinSearch = "/api/v1/reports/vin:search",
  OgrnSearch = "/api/v1/reports/ogrn:search",
  AutoSearch = "/api/v1/reports/auto_registration_number:search",
  AddressSearch = "/api/v1/reports/address:search",
  KadNumberSearch = "/api/v1/reports/kad-number:search",
  GrnSearch = "/api/v1/reports/grn:search",
  DriverLicenseSearch = "/api/v1/reports/driver-license:search",
  InfoSearch = "/api/v1/reports/info:search",
  TelegramSearch = "/api/v1/reports/telegram:search",
  CreditRatingSearch = "/api/v1/reports/credit-rating:search",
  FullCreditRatingSearch = "/api/v1/reports/full-credit-rating:search",
  PhotoByFioSearch = "/api/v1/reports/photo-by-fio:search",
  PhotoByFaceSearch = "/api/v1/reports/photo-by-face:search",
  ScoringSearch = "/api/v1/reports/scoring:search",
  VkSearch = "/api/v1/reports/vk:search",

  // retrieve
  FioRetrieve = "/api/v1/reports/fio:retrieve",
  PhoneRetrieve = "/api/v1/reports/phone:retrieve",
  InnFlRetrieve = "/api/v1/reports/inn-fl:retrieve",
  InnUlRetrieve = "/api/v1/reports/inn-ul:retrieve",
  EmailRetrieve = "/api/v1/reports/email:retrieve",
  PassportRetrieve = "/api/v1/reports/passport:retrieve",
  SnilsRetrieve = "/api/v1/reports/snils:retrieve",
  VinRetrieve = "/api/v1/reports/vin:retrieve",
  OgrnRetrieve = "/api/v1/reports/ogrn:retrieve",
  AutoRetrieve = "/api/v1/reports/auto_registration_number:retrieve",
  AddressRetrieve = "/api/v1/reports/address:retrieve",
  KadNumberRetrieve = "/api/v1/reports/kad-number:retrieve",
  GrnRetrieve = "/api/v1/reports/grn:retrieve",
  DriverLicenseRetrieve = "/api/v1/reports/driver-license:retrieve",
  InfoRetrieve = "/api/v1/reports/info:retrieve",
  TelegramRetrieve = "/api/v1/reports/telegram:retrieve",
  CreditRatingRetrieve = "/api/v1/reports/credit-rating:retrieve",
  FullCreditRatingRetrieve = "/api/v1/reports/full-credit-rating:retrieve",
  PhotoByFioRetrieve = "/api/v1/reports/photo-by-fio:retrieve",
  PhotoByFaceRetrieve = "/api/v1/reports/photo-by-face:retrieve",
  ScoringRetrieve = "/api/v1/reports/scoring:retrieve",
  VKRetrieve = "/api/v1/reports/vk:retrieve",

  // other actions
  Delete = "/api/v1/reports:delete",
  Update = "/api/v1/reports:update",
  RetrieveAll = "/api/v1/reports:retrieveAll",
  TotalCount = "/api/v1/reports:totalCount",
  ExportCsv = "/api/v1/reports:exportCSV",
  PossibleNetworksExportCsv = "/api/v1/reports/possible_networks:export",
}
