import { validationRegex } from "./constants";

export const correctLettersSpacesHyphenDashRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.lettersSpacesHyphenDash.test(value);
};

export const correctLettersRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.letters.test(value);
};

export const correctKadNumberRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.kadNumber.test(value);
};

export const correctDigitRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.digits.test(value);
};

export const correctAutoNumberRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.autoNumber.test(value);
};

export const correctVinRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.vin.test(value);
};

export const correctSnilsRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.snils.test(value);
};

export const correctPassportRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.passport.test(value);
};

export const correctUrlRegexCallback = (value?: string) => {
  if (!value) return true;

  return validationRegex.url.test(value);
};
