import { Popover } from "antd";
import { ReactNode } from "react";

export interface ContextMenuProps {
  buttonInner?: ReactNode;
  content?: ReactNode;
  open: boolean;
  handleOpenChange?: (newOpen: boolean) => void;
}

export const ContextMenu = ({
  buttonInner,
  content,
  open,
  handleOpenChange,
}: ContextMenuProps) => (
  <Popover
    open={open}
    content={content}
    trigger="click"
    placement="leftTop"
    onOpenChange={handleOpenChange}
  >
    <button type="button">{buttonInner}</button>
  </Popover>
);
