import { FoundStatus, ReportStatus } from "../constants";

export enum BorderCrossingEnum {
  airplane = "Самолет",
  car = "Автомобиль",
  otherType = "Иной вид пересечения",
  train = "Железная дорога",
  waterCrossing = "Водное пересечение границы",
}

export enum CrossingBordersSummaryKeys {
  count = "count",
  countriesVisited = "countries_visited",
  countryDocuments = "country_documents",
  transportList = "transport_list",
}

export enum CrossingBorderItemEnum {
  crossingDate = "crossing_date",
  transportType = "transport_type",
  sourceCountry = "source_country",
  destinationCountry = "destination_country",
  documentCountry = "document_country",
  documentNumber = "document_number",
  documentIssue_Date = "document_issue_date",
  birthDate = "birth_date",
  grn = "grn",
  punkt = "punkt",
  tripNum = "trip_num",
  srcCity = "src_city",
  dstCity = "dst_city",
  info = "info",
  operation = "operation",
}

export interface TransportListItem {
  count: number;
  transport_type: BorderCrossingEnum;
}

export type TransportListValues = number | string[] | TransportListItem[];

export const isTransportListItems = (
  items: TransportListValues,
): items is TransportListItem[] =>
  Boolean(
    items?.[0] &&
      typeof items[0] !== "string" &&
      "count" in items[0] &&
      "transport_type" in items[0],
  );

export interface CrossingBorders {
  status: ReportStatus;
  found: FoundStatus;
  result: {
    [CrossingBordersSummaryKeys.count]: number;
    [CrossingBordersSummaryKeys.countriesVisited]: string[];
    [CrossingBordersSummaryKeys.countryDocuments]: string[];
    [CrossingBordersSummaryKeys.transportList]: TransportListItem[];
    crossing_border_items: CrossingBorderItem[];
  };
  has_sopd: boolean;
}

export interface CrossingBorderItem {
  [CrossingBorderItemEnum.crossingDate]: string;
  [CrossingBorderItemEnum.operation]: string;
  [CrossingBorderItemEnum.transportType]: BorderCrossingEnum;
  [CrossingBorderItemEnum.sourceCountry]: string;
  [CrossingBorderItemEnum.destinationCountry]: string;
  [CrossingBorderItemEnum.documentCountry]: string;
  [CrossingBorderItemEnum.documentNumber]: string;
  [CrossingBorderItemEnum.documentIssue_Date]: string;
  [CrossingBorderItemEnum.birthDate]: string;
  [CrossingBorderItemEnum.grn]: string;
  [CrossingBorderItemEnum.punkt]: string;
  [CrossingBorderItemEnum.tripNum]: string;
  [CrossingBorderItemEnum.srcCity]: string;
  [CrossingBorderItemEnum.dstCity]: string;
  [CrossingBorderItemEnum.info]: string;
}
