import { Text } from "@ui/ui/Text/Text";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { Flex } from "antd";
import {
  DetailsTable,
  DetailsTableColumn,
} from "@ui/ui/DetailsTable/DetailsTable";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import {
  CrossingBorderItem,
  CrossingBorderItemEnum,
  CrossingBorders,
  CrossingBordersSummaryKeys,
  crossingBordersSummaryTexts,
  summaryKeys,
} from "@shared/types/report/retrieve";
import { SummaryDetail } from "./components/SummaryDetail/SummaryDetail";
import s from "./CrossingBordersSection.module.scss";

const tableColumns: DetailsTableColumn<CrossingBorderItemEnum>[] = [
  {
    id: CrossingBorderItemEnum.crossingDate,
    title: "Дата пересечения",
    width: 200,
  },
  {
    id: CrossingBorderItemEnum.operation,
    title: "Тип пересечения",
    width: 200,
  },
  { id: CrossingBorderItemEnum.transportType, title: "Транспорт", width: 200 },
  {
    id: CrossingBorderItemEnum.sourceCountry,
    title: "Страна откуда",
    width: 200,
  },
  {
    id: CrossingBorderItemEnum.destinationCountry,
    title: "Страна куда",
    width: 200,
  },
  {
    id: CrossingBorderItemEnum.documentCountry,
    title: "Страна выдачи документа",
    width: 200,
  },
];

interface CrossingBordersPdfProps extends RetrieveCardCommonProps {
  crossingBorders?: CrossingBorders;
}

export const CrossingBordersPdf = ({
  crossingBorders,
  status,
}: CrossingBordersPdfProps) => (
  <RetrieveCard
    print={RetrieveCardPrint.print}
    title="Пересечение границы"
    status={status}
  >
    <Flex vertical gap={16}>
      <Text variant="subheader-4">Краткая сводка</Text>
      <Flex vertical gap={16}>
        {summaryKeys.map((summaryKey: CrossingBordersSummaryKeys) => (
          <div className={s.container__item} key={summaryKey}>
            <Text variant="body-5" color="secondary">
              {crossingBordersSummaryTexts[summaryKey]}
            </Text>
            <SummaryDetail
              summaryKey={summaryKey}
              summary={crossingBorders?.result[summaryKey]}
            />
          </div>
        ))}
      </Flex>
    </Flex>
    {crossingBorders?.result.crossing_border_items ? (
      <DetailsTable<CrossingBorderItemEnum, CrossingBorderItem>
        columns={tableColumns}
        rows={crossingBorders?.result.crossing_border_items}
      />
    ) : null}
  </RetrieveCard>
);
