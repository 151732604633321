import cn from "classnames";
import { PiWarningFill } from "react-icons/pi";

import { FullInfoItem, ScoringResult } from "@shared/types/report/retrieve";
import {
  IndicatorIconEnum,
  IndicatorModel,
} from "@ui/components/Indicator/Indicator";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { Text } from "@ui/ui/Text/Text";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { Expand } from "@ui/ui/Expand/Expand";
import s from "./Scoring.module.scss";
import { Common } from "../Common/Common";
import { RetrieveCardPrint } from "../RetrieveCard/types";
import { getScoringType } from "./utils/getScoringType";
import { ScoringType } from "./constants";

const mapScoringTypeToIndicatorModel: Record<ScoringType, IndicatorModel> = {
  [ScoringType.Bad]: {
    text: "плохо",
    indicatorIcon: IndicatorIconEnum.cancel,
    color: "red",
  },
  [ScoringType.Good]: {
    text: "хорошо",
    indicatorIcon: IndicatorIconEnum.check,
    color: "accent",
  },
};

type ScoringProps = {
  scoringResult?: ScoringResult | null;
} & RetrieveCardCommonProps;

export const Scoring = ({
  scoringResult,
  status,
  print = RetrieveCardPrint.device,
}: ScoringProps) => {
  const { state: stateInfo, toggle: toggleStateInfo } = useBooleanState(true);

  const scoringkeys = scoringResult?.scoring
    ? Object.keys(scoringResult?.scoring)
    : [];

  return (
    <RetrieveCard
      dataId={7}
      title="Результаты скоринга"
      status={status}
      print={print}
    >
      <div
        className={cn(
          s.container__content,
          print === RetrieveCardPrint.print && s.container__content_print,
        )}
      >
        <div className={s.container__content__common}>
          <div className={s.container__content__common__header}>
            <Common
              marker={scoringResult?.over_all}
              indicatorModel={
                mapScoringTypeToIndicatorModel[
                  getScoringType(scoringResult?.over_all)
                ]
              }
            />
            {print === RetrieveCardPrint.device && (
              <div className={s.container__content__common__header__label}>
                <PiWarningFill
                  className={s.container__content__common__header__label__icon}
                  size={28}
                />
                <Text variant="body-5">
                  Показатель оценивается от 0,00 до 1,00. Показатель ниже 1,00
                  указывает на присутствие негатива.
                </Text>
              </div>
            )}
          </div>

          {Boolean(scoringkeys?.length > 0) && (
            <div className={s.container__content__common__categories}>
              <Text variant="label-3">Категории</Text>
              <div className={s.container__content__common__categories__table}>
                {scoringkeys.map((scoring: string, index) => (
                  <div
                    key={scoring}
                    className={cn(
                      s.container__content__common__categories__table__item,
                      index !== scoringkeys.length - 1 &&
                        s.container__content__common__categories__table__item_border,
                    )}
                  >
                    <Text
                      className={
                        s.container__content__common__categories__table__item__text
                      }
                    >
                      {scoring}
                    </Text>
                    <Text
                      className={cn(
                        s.container__content__common__categories__table__item__text,
                        s.container__content__common__categories__table__item__text__value,
                      )}
                    >
                      {scoringResult?.scoring[scoring]}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {scoringResult?.full_info && scoringResult?.full_info?.length > 0 && (
          <div className={s.container__content__info}>
            <Expand
              state={stateInfo}
              toggle={toggleStateInfo}
              className={s.container__content__info__header}
            >
              <Text variant="label-3">Информация</Text>
            </Expand>
            {stateInfo && (
              <div className={s.container__content__info__table}>
                {scoringResult?.full_info?.map(
                  (mockFullInfoItem: FullInfoItem, index: number) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className={s.container__content__info__table__row}
                    >
                      <div
                        className={s.container__content__info__table__row__item}
                      >
                        {index === 0 && (
                          <Text variant="body-1" color="secondary">
                            Номер статьи
                          </Text>
                        )}
                        <Text variant="body-5">{mockFullInfoItem.article}</Text>
                      </div>
                      <div
                        className={s.container__content__info__table__row__item}
                      >
                        {index === 0 && (
                          <Text variant="body-1" color="secondary">
                            Категория
                          </Text>
                        )}
                        <Text variant="body-5">
                          {mockFullInfoItem.category}
                        </Text>
                      </div>
                      <div
                        className={s.container__content__info__table__row__item}
                      >
                        {index === 0 && (
                          <Text variant="body-1" color="secondary">
                            Информация
                          </Text>
                        )}
                        <Text variant="body-5">
                          {mockFullInfoItem.information}
                        </Text>
                      </div>
                    </div>
                  ),
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </RetrieveCard>
  );
};
