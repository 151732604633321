import { RetrieveStatus } from "@ui/entities/RetrieveStatus/RetrieveStatus";
import { SearchResults } from "@ui/entities/RetrievePhotoByFace/components/SearchResults/SearchResults";
import { calculateReportStatus } from "@ui/utils/getReportStatus";
import { FC, useCallback, useMemo, useState } from "react";
import { Flex } from "antd";
import { Block } from "@ui/ui/Block/Block";
import { Text } from "@ui/ui/Text/Text";
import { DownloadReportPdfButton } from "@ui/components/FullDossier/components/DownloadReportPdfButton/DownloadReportPdfButton";
import { ReportCommonProps } from "@ui/entities/types";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ReportStatus } from "@shared/types/report/constants";
import { ReportsPhotoByFaceRetrieveResponse } from "@shared/types/report/retrieve";
import { FullDossier } from "@ui/components/FullDossier/FullDossier";
import { MultiQueryReportsByType } from "@ui/entities/MultiQueryReportsByType/MultiQueryReportsByType";
import { EMultiQueryKeys } from "@shared/types/report/retrieve/multiQuery";
import { MultiQueryDossier } from "@ui/components/MultiQueryDossier/MultiQueryDossier";
import { generatePdf, framePdf } from "./generatePdf";
import s from "./RetrievePhotoByFace.module.scss";

export type RetrievePhotoByFaceProps = {
  report: ReportsPhotoByFaceRetrieveResponse;
} & ReportCommonProps;

export const RetrievePhotoByFace: FC<RetrievePhotoByFaceProps> = ({
  report,
  type,
  reportId,
}) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);
  const isMultiQueryReport = useMemo(
    () => report.multi_query?.result !== null,
    [report.multi_query?.result],
  );

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);

  const handleFramePdf = useCallback(
    async () => framePdf({ report, type, reportId }),
    [report, type, reportId],
  );

  const faces = report?.photo_response?.result?.faces;

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.photo_response?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      {report.multi_query && isMultiQueryReport && (
        <MultiQueryDossier
          multiQueryReport={report.multi_query}
          type={type}
          status={report.multi_query.status}
          reportId={reportId}
          handleGeneratePdf={handleGeneratePdf}
          handleFramePdf={handleFramePdf}
          note={report.note}
          isLoadingGeneratePdf={isLoadingGeneratePdf}
          requestDescribe={report?.request_describe}
        />
      )}
      <Flex className={s.container} vertical gap={32}>
        <Block>
          <Flex vertical gap={16}>
            <Text color="aqua">По фото</Text>
            {faces && faces.length > 0 ? (
              <Flex justify="space-between" gap={20}>
                <img
                  src={`data:image/png;base64,${report?.request_describe}`}
                  alt="Some"
                  className={s.container__sourceImage}
                />
                {report?.person?.status === ReportStatus.Finished && (
                  <FullDossier
                    isMultiQueryReport={isMultiQueryReport}
                    phones={report?.person?.person_info?.phones}
                    snilses={report?.person?.person_info?.snilses}
                    inns={report?.person?.person_info?.inns}
                    passports={report?.person?.person_info?.passports}
                    citizens={report?.person?.person_info?.citizens}
                    emails={report?.person?.person_info?.emails}
                    persons={report?.person?.person_info?.persons}
                    autos={report?.person?.person_info?.autos}
                    note={report.note}
                    type={type}
                    containerClassName={s.dossier}
                    isShowHeader={false}
                    status={report?.person?.status}
                    reportId={reportId}
                    handleFramePdf={handleFramePdf}
                  />
                )}
                <DownloadReportPdfButton
                  handleGeneratePdf={handleGeneratePdf}
                  isLoadingGeneratePdf={isLoadingGeneratePdf}
                />
              </Flex>
            ) : (
              <Flex
                justify="center"
                align="center"
                className={s.container__plugPhotoContainer}
              >
                <AiOutlineLoading3Quarters
                  className={s.container__plugPhotoContainer__loading}
                  size={24}
                />
              </Flex>
            )}
          </Flex>
        </Block>
        <SearchResults
          status={report?.photo_response?.status}
          faces={faces}
          sourcePhoto={report?.request_describe}
        />
      </Flex>
      {report.multi_query &&
        isMultiQueryReport &&
        Object.entries(report.multi_query.result).map(([key, value]) => {
          const multiQueryRequests = Object.values(value);
          if (!multiQueryRequests.length) return null;

          return (
            <MultiQueryReportsByType
              key={key}
              multiQueryRequests={value}
              multiQueryKey={key as EMultiQueryKeys}
            />
          );
        })}
    </RetrieveStatus>
  );
};
