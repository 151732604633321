export const formatCompositeDate = (
  day: number,
  month: number,
  year: number,
) => {
  const formattedDay = day !== 0 && String(day).padStart(2, "0");
  const formattedMonth = month !== 0 && String(month).padStart(2, "0");
  const formattedYear = year !== 0 && String(year);

  const dateParts: string[] = [];
  if (formattedDay) dateParts.push(formattedDay);
  if (formattedMonth) dateParts.push(formattedMonth);
  if (formattedYear) dateParts.push(formattedYear);

  return dateParts.join(".");
};
