import * as yup from "yup";

export const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    message: yup.string().required(),
    question: yup.string(),
    file: yup
      .mixed<FileList>()
      .test(
        "fileCount",
        "Можно загрузить только 1 файл",
        (value) => value?.length === 1,
      )
      .test("fileSize", "Файл слишком большой (макс. 20 МБ)", (value) =>
        value?.[0] ? value[0].size <= 20 * 1024 * 1024 : true,
      )
      .test("fileType", "Неверный формат файла", (value) =>
        value?.[0]
          ? [
              "image/jpeg",
              "image/jpg",
              "image/png",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(value[0].type)
          : true,
      )
      .test(
        "fileNotForbidden",
        "Файл имеет недопустимое расширение",
        (value) => {
          if (!value?.[0]) return true;
          const forbiddenExtensions = [
            ".mp4",
            ".avi",
            ".mkv",
            ".mov",
            ".mp3",
            ".wav",
            ".ogg",
            ".xls",
            ".xlsx",
            ".ppt",
            ".pptx",
            ".zip",
            ".rar",
            ".7z",
            ".tar",
            ".gif",
            ".bmp",
            ".exe",
            ".bat",
            ".sh",
            ".js",
            ".jsx",
            ".py",
            ".html",
            ".php",
          ];
          const fileName = value[0]?.name.toLowerCase();
          return !forbiddenExtensions.some((ext) => fileName.endsWith(ext));
        },
      ),
  })
  .required();
