import { AppFeatures } from "../types";

export const odysseyConfig: AppFeatures = {
  authResetPasswordEnabled: true,
  authUserSelfRegistrationEnabled: true,
  chatsAvailable: true,
  documentsPageEnabled: true,
  fioTabSopdEnabled: true,
  mainIncludeOpenSourcesEnabled: false,
  mainMultiQueryEnabled: false,
  mainTabSopdEnabled: true,
  onboardingEnabled: true,
  themeSwitchEnabled: true,
  vkTabSopdEnabled: true,
};
