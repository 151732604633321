import { ReportType } from "@shared/types/report/constants";

export const searchCriteriaDescriptions: Record<ReportType, string> = {
  [ReportType.SEARCH_FIO]: "По ФИО",
  [ReportType.SEARCH_PHONE]: "По номеру телефона",
  [ReportType.SEARCH_CREDIT_REPORT]: "Кредитный рейтинг",
  [ReportType.SEARCH_FULL_CREDIT_REPORT]: "Полный кредитный рейтинг",
  [ReportType.SEARCH_PASSPORT]: "По паспорту",
  [ReportType.SEARCH_INN_UL]: "По ИНН для юр.лиц",
  [ReportType.SEARCH_OGRN]: "По ОГРН для юр.лиц",
  [ReportType.SEARCH_INN_FL]: "По СНИЛС",
  [ReportType.SEARCH_SNILS]: "По ИНН",
  [ReportType.SEARCH_KAD_NUMBER]: "По кадастровому номеру",
  [ReportType.SEARCH_EMAIL]: "По E-mail",
  [ReportType.SEARCH_VIN]: "По VIN",
  [ReportType.SEARCH_GRN]: "По гос. номеру авто",
  [ReportType.SEARCH_ADDRESS]: "По адресу",
  [ReportType.SEARCH_DRIVER_LICENSE]: "По ВУ",
  [ReportType.SEARCH_INFO]: "По ключевому слову",
  [ReportType.SEARCH_PHOTO_BY_FIO]: "Поиск фото по ФИО",
  [ReportType.SEARCH_PHOTO_BY_IMAGE]: "По фото",
  [ReportType.SEARCH_SCORING]: "Скоринг",
  [ReportType.SEARCH_TELEGRAM]: "По Telegram",
  [ReportType.SEARCH_VK]: "По VK",
};

/**
 * colors from https://www.figma.com/design/5LpUI8KdBlOBjUdkxXqx6V/ODYSSEY-2.0?node-id=5132-81720&t=dSDl0wHBAQ59Pv8l-4
 */
export const searchCriteriaColors: Record<ReportType, string> = {
  [ReportType.SEARCH_FIO]: "#32ABAF",
  [ReportType.SEARCH_PHONE]: "#252F41",
  [ReportType.SEARCH_CREDIT_REPORT]: "#C22F00",
  [ReportType.SEARCH_FULL_CREDIT_REPORT]: "#1C2D50",
  [ReportType.SEARCH_INN_UL]: "#5E614F",
  [ReportType.SEARCH_OGRN]: "#5E614F",
  [ReportType.SEARCH_INN_FL]: "#E80981",
  [ReportType.SEARCH_EMAIL]: "#5653CD",
  [ReportType.SEARCH_VIN]: "#35C232",
  [ReportType.SEARCH_SNILS]: "#69FF41",
  [ReportType.SEARCH_PASSPORT]: "#1B1D30",
  [ReportType.SEARCH_ADDRESS]: "#C4FD8C",
  [ReportType.SEARCH_DRIVER_LICENSE]: "#201E82",
  [ReportType.SEARCH_GRN]: "#35C232",
  [ReportType.SEARCH_KAD_NUMBER]: "#418B6E",
  [ReportType.SEARCH_INFO]: "#8441B1",
  [ReportType.SEARCH_PHOTO_BY_FIO]: "#69FF41",
  [ReportType.SEARCH_PHOTO_BY_IMAGE]: "#AED0A5",
  [ReportType.SEARCH_SCORING]: "#8DA896",
  [ReportType.SEARCH_TELEGRAM]: "#0088CC",
  [ReportType.SEARCH_VK]: "#4C75A3",
};
