import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@ui/pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import {
  MainTabFieldValues,
  MainTabProps,
} from "@ui/pages/Main/components/MainTabs/types";
import { dropZoneFileListSchema } from "@ui/pages/Main/components/MainTabs/constants";
import { ReportsInnFlSearchRequest } from "@shared/types/report/search";
import { useReportsInnFlSearch } from "@ui/app/api/report/search/mutations";
import { Input } from "../../../../../../../ui/Input/Input";
import {
  validationBounds,
  validationMessage,
} from "../../../../../../../utils/constants";
import { correctDigitRegexCallback } from "../../../../../../../utils/validation";
import s from "../MainTabInnOrSnils.module.scss";

interface ReportsInnFlFieldValues
  extends ReportsInnFlSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    inn: yup
      .string()
      .test(
        "digits-check",
        validationMessage.WrongInn,
        correctDigitRegexCallback,
      )
      .min(validationBounds.Inn.Length)
      .max(validationBounds.Inn.Length)
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabInnProps = MainTabProps;

export const MainTabInn: FC<MainTabInnProps> = ({
  onSubmit,
  setMultiQueryCheckboxVisible,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      inn: "",
    },
  });
  setMultiQueryCheckboxVisible?.(true);

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsInnFlSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsInnFlFieldValues) =>
      onSubmit({ requestData, search, withSopd: true }).then(() => reset()),
    [search, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} className={s.controllersContainer}>
        <Controller
          name="inn"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              tabIndex={0}
              required
              mask="000000000000"
              placeholder="По номеру ИНН"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
