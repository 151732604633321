import { FC, useCallback } from "react";
import Sider from "antd/es/layout/Sider";
import { Drawer } from "@ui/ui/Drawer/Drawer";
import s from "./MainLayoutAside.module.scss";
import { MainLayoutAsideMenu } from "./components/MainLayoutAsideMenu/MainLayoutAsideMenu";

interface MainLayoutAsideProps {
  collapsed: boolean;
  isTablet: boolean;
  onCollapse: (collapsed: boolean) => void;
}

export const MainLayoutAside: FC<MainLayoutAsideProps> = ({
  collapsed,
  onCollapse,
  isTablet,
}) => {
  const onClose = useCallback(() => {
    onCollapse(true);
  }, [onCollapse]);

  return isTablet ? (
    <Drawer width={230} placement="left" visible={!collapsed} onClose={onClose}>
      <MainLayoutAsideMenu collapsed={collapsed} onClose={onClose} />
    </Drawer>
  ) : (
    <Sider
      className={s.sider}
      defaultCollapsed
      width={230}
      collapsedWidth={80}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <MainLayoutAsideMenu collapsed={collapsed} />
    </Sider>
  );
};
