import { Flex } from "antd";
import cn from "classnames";
import { FC, useMemo } from "react";
import { ReportCommonProps } from "@ui/entities/types";
import { validationRegex } from "@ui/utils/constants";
import { ReactComponent as NotesPin } from "@assets/notes_pin.svg";
import { Text } from "@ui/ui/Text/Text";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { PersonInfo, PersonInfoKeys } from "@shared/types/report/retrieve";
import { MultiQuery } from "@shared/types/report/retrieve/multiQuery";
import { MdEdit } from "react-icons/md";
import { useNotification } from "@ui/hooks/useNotification";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { ReportType } from "@shared/types/report/constants";
import styles from "@ui/entities/RetrievePhotoByFace/RetrievePhotoByFace.module.scss";
import s from "../FullDossier/FullDossier.module.scss";
import NoteModal from "../NoteModal/NoteModal";
import { DownloadReportPdfButton } from "../FullDossier/components/DownloadReportPdfButton/DownloadReportPdfButton";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "../RetrieveCard/RetrieveCard";
import { RetrieveCardPrint } from "../RetrieveCard/types";
import { Option, OptionsMenu } from "../OptionsMenu/OptionsMenu";
import SearchByFound from "../FullDossier/components/SearchByFound/SearchByFound";

type TMultiQueryDossierProps = ReportCommonProps & {
  multiQueryReport: MultiQuery;
  requestDescribe?: string;
  note?: string;
  handleGeneratePdf?: () => void;
  handleFramePdf?: () => Promise<HTMLIFrameElement>;
  isLoadingGeneratePdf?: boolean;
} & RetrieveCardCommonProps;

export const MultiQueryDossier: FC<TMultiQueryDossierProps> = ({
  multiQueryReport,
  requestDescribe,
  status,
  print = RetrieveCardPrint.device,
  note,
  type,
  reportId,
  handleGeneratePdf,
  handleFramePdf,
  isLoadingGeneratePdf,
}) => {
  const isPrintView = print === RetrieveCardPrint.print;
  const requestDescribeWithoutDob = requestDescribe
    ?.replace(validationRegex.date, "")
    .replace(/\.+$/, "")
    .trim();
  const report: PersonInfo = useMemo(
    () => ({
      passports: Object.values(multiQueryReport.result.passport)
        .flatMap((arr) => arr[PersonInfoKeys.passports])
        .filter((item): item is string => !!item),
      phones: Object.values(multiQueryReport.result.phone)
        .flatMap((arr) => arr[PersonInfoKeys.phones])
        .filter((item): item is string => !!item),
      autos: Object.values(multiQueryReport.result.grn)
        .flatMap((arr) => arr[PersonInfoKeys.autos])
        .filter((item): item is string => !!item),
      snilses: Object.values(multiQueryReport.result.snils)
        .flatMap((arr) => arr[PersonInfoKeys.snilses])
        .filter((item): item is string => !!item),
      inns: Object.values(multiQueryReport.result.inn)
        .flatMap((arr) => arr[PersonInfoKeys.inns])
        .filter((item): item is string => !!item),
      emails: Object.values(multiQueryReport.result.email)
        .flatMap((arr) => arr[PersonInfoKeys.emails])
        .filter((item): item is string => !!item),
    }),
    [multiQueryReport.result],
  );

  const {
    state: isSearchByFoundModalVisible,
    setTrue: showSearchByFoundModal,
    setFalse: hideSearchByFoundModal,
  } = useBooleanState(false);
  const [copyReportUrl] = useNotification();

  const options = useMemo<Option[]>(
    () =>
      [
        { text: "Поиск по найденному", onClick: showSearchByFoundModal },
        {
          text: "Печать",
          onClick: async () => {
            copyReportUrl.info({
              key: "iframe-print",
              message: "Отчет создается...",
              duration: 5,
              className: s.notification,
            });

            const iframe = await handleFramePdf?.();
            if (iframe) {
              iframe.onload = () => {
                iframe?.contentWindow?.print();
              };
            }
          },
        },
        {
          text: "Поделиться",
          onClick: () => {
            // eslint-disable-next-line no-restricted-globals
            const reportUrl = `${window.location.origin}${location.pathname}${location.search}`;
            navigator.clipboard.writeText(reportUrl).then(() =>
              copyReportUrl.success({
                key: "report-url",
                message: "Ссылка на отчет скопирована",
                className: s.notification,
              }),
            );
          },
        },
      ].filter(
        (point) =>
          !(
            (!report.passports || report?.passports?.length < 1) &&
            (!report.phones || report?.phones?.length < 1) &&
            (!report.autos || report?.autos?.length < 1) &&
            (!report.snilses || report?.snilses?.length < 1) &&
            (!report.inns || report?.inns?.length < 1) &&
            (!report.emails || report?.emails?.length < 1) &&
            point.text === "Поиск по найденному"
          ),
      ),
    [report],
  );

  return (
    <RetrieveCard
      print={print}
      title=""
      status={status}
      isSubReport
      expanded={Boolean(note)}
      header={
        <div className={s.container__header}>
          <Flex vertical gap={16}>
            {!isPrintView && <Text color="aqua">Мультизапрос</Text>}
            {requestDescribe &&
              (type === ReportType.SEARCH_PHOTO_BY_IMAGE ? (
                <img
                  src={`data:image/png;base64,${requestDescribe}`}
                  alt="Some"
                  className={styles.container__sourceImage}
                />
              ) : (
                <div>
                  <Text
                    className={cn(
                      !isPrintView && s.container__header__name,
                      isPrintView && s.container__header__name_print,
                    )}
                  >
                    {requestDescribeWithoutDob}
                  </Text>
                </div>
              ))}
            {!isPrintView && !note && (
              <NoteModal
                type={type}
                reportId={reportId}
                buttonTitle={
                  <Text variant="body-4" color="secondary">
                    <Flex align="center" gap={4}>
                      <NotesPin /> Добавить заметку
                    </Flex>
                  </Text>
                }
              />
            )}
          </Flex>
          {!isPrintView && (
            <Flex align="center" gap={16}>
              <OptionsMenu
                className={s.container__header__options__more}
                options={options}
              />
              <DownloadReportPdfButton
                handleGeneratePdf={handleGeneratePdf}
                isLoadingGeneratePdf={isLoadingGeneratePdf}
                isMultiQueryReport
              />
            </Flex>
          )}
          <QueryClientProvider client={queryClient}>
            <SearchByFound
              isSearchByFoundModalVisible={isSearchByFoundModalVisible}
              hideSearchByFoundModal={hideSearchByFoundModal}
              propKeys={Object.keys(report)}
              personInfo={{
                [PersonInfoKeys.passports]: report.passports,
                [PersonInfoKeys.phones]: report.phones,
                [PersonInfoKeys.autos]: report.autos,
                [PersonInfoKeys.snilses]: report.snilses,
                [PersonInfoKeys.inns]: report.inns,
                [PersonInfoKeys.emails]: report.emails,
              }}
            />
          </QueryClientProvider>
        </div>
      }
    >
      {note && (
        <Flex vertical className={s.container__row__note}>
          <Flex gap={4} align="center">
            <Text color="secondary" variant="body-1">
              Заметка
            </Text>
            {!isPrintView && (
              <NoteModal
                type={type}
                reportId={reportId}
                buttonTitle={
                  <Text color="secondary">
                    <MdEdit size={14} />
                  </Text>
                }
                note={note}
              />
            )}
          </Flex>
          <Text variant="body-5">{note}</Text>
        </Flex>
      )}
    </RetrieveCard>
  );
};
