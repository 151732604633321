import { Text } from "@ui/ui/Text/Text";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import {
  RetrieveCardPrint,
  RetrieveCardVariant,
} from "@ui/components/RetrieveCard/types";
import { Content } from "@ui/entities/SearchFull/components/OpenedData/Content/Content";
import { PiWarningFill } from "react-icons/pi";
import { Flex } from "antd";
import { Delimiter } from "@ui/ui/Delimiter/Delimiter";
import {
  isReportsOpenSourcesIoSphere,
  OpenSourcesModel,
} from "@shared/types/report/retrieve";
import { ReportStatus } from "@shared/types/report/constants";
import s from "./OpenedData.module.scss";

export type OpenedDataProps = {
  openSources: OpenSourcesModel;
  isFioSearch?: boolean;
  isPhoneSearch?: boolean;
} & RetrieveCardCommonProps;

export const OpenedData = ({
  openSources,
  status,
  print = RetrieveCardPrint.device,
  isFioSearch,
  isPhoneSearch,
}: OpenedDataProps) => {
  if (
    openSources &&
    isReportsOpenSourcesIoSphere(openSources) &&
    openSources?.iosphere?.status === ReportStatus.NoData
  )
    return null;

  if (status === ReportStatus.Canceled) return null;

  return (
    <RetrieveCard
      dataId={2}
      variant={RetrieveCardVariant.noBorder}
      title="Открытые данные"
      status={status}
      print={print}
      titleClassName={s.title}
    >
      <Flex vertical gap={16}>
        {print === RetrieveCardPrint.device && (
          <div className={s.container__alert}>
            <PiWarningFill className={s.container__alert__icon} size={28} />
            <Text variant="body-5">
              Сбор открытых данных занимает до 5 минут. Данные подгружаются в
              отчет автоматически.
            </Text>
          </div>
        )}
        <Delimiter className={s.delimiter} />
        {openSources && (
          <Content
            isFioSearch={isFioSearch}
            isPhoneSearch={isPhoneSearch}
            print={print}
            openSources={
              isReportsOpenSourcesIoSphere(openSources)
                ? openSources?.iosphere?.data
                : openSources
            }
          />
        )}
      </Flex>
    </RetrieveCard>
  );
};
