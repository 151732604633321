import { GalleryItem } from "../../../common/types";
import { ReportStatus } from "../../constants";

export interface ITelegramInfo {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  phone: number;
  region: string;
  names: string;
  addresses: string;
  tags: string;
  photos: string[] | null;
  userNames: IUserName[] | null;
  phones: IPhone[] | null;
  templateMessages: ITemplateMessage[] | null;
  geos: IGeo[] | null;
  distance: number;
  bot: boolean;
  groups: IGroup[] | null;
}

interface IUserName {
  userName: string;
  dt: string;
}

interface IPhone {
  phone: number;
  petrushka: boolean;
  region: string;
  names: string;
  addresses: string;
  date: string;
}

interface ITemplateMessage {
  gid: number;
  gUserName: string;
  gTitle: string;
  messages: IMessage[];
}

interface IMessage {
  id: number;
  dt: string;
  text: string;
  first: boolean;
}

interface IGeo {
  latitude: number;
  longitude: number;
  distance: number;
  address: string;
  dt: string;
  bot: boolean;
}

export interface IGroup {
  id: number;
  title: string;
  username: string;
  userNames: IUserName[];
  cId: number;
  isChannel: boolean;
  chatId: number;
  invite: string;
  created: string;
  owner: number;
  channel: IChannel;
  messages: IGroupMessage[];
  description: string;
  tags: string;
  count: number;
  joined: string;
  status: ReportStatus;
}

interface IChannel {
  id: number;
  title: string;
  username: string;
  userNames: IUserName[];
  cId: number;
  isChannel: boolean;
  chatId: number;
  invite: string;
  created: string;
  owner: number;
  channel: string;
  messages: IChannelMessage[];
  description: string;
  tags: string;
  count: number;
}

export interface IGroupMessage {
  id: number;
  uId: number;
  text: string;
  dt: string;
}

interface IChannelMessage {
  id: number;
  uId: number;
  text: string;
  dt: string;
}

export enum ETelegramDossierInfoKeys {
  userNames = "userNames",
  names = "names",
  tags = "tags",
  telegramAddresses = "telegramAddresses",
  telegramPhotos = "telegramPhotos",
  isBot = "isBot",
  groupsCount = "groupsCount",
  userId = "userId",
  region = "region",
}

export interface ITelegramDossierInfo {
  [ETelegramDossierInfoKeys.telegramPhotos]?: GalleryItem[] | null;
  [ETelegramDossierInfoKeys.userNames]?: string[] | null;
  [ETelegramDossierInfoKeys.names]?: string[] | null;
  [ETelegramDossierInfoKeys.userId]?: number[] | null;
  [ETelegramDossierInfoKeys.tags]?: string[] | null;
  [ETelegramDossierInfoKeys.telegramAddresses]?: string[] | null;
  [ETelegramDossierInfoKeys.isBot]?: string[];
  [ETelegramDossierInfoKeys.region]?: string[] | null;
  [ETelegramDossierInfoKeys.groupsCount]?: number[] | null;
}
