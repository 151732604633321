import { ReportType } from "@shared/types/report/constants";
import { reduceDefaultRequestObjectDates } from "./utils/reduceDefaultRequestObjectDates";
import { sortDefaultRequestObject } from "./utils/sortDefaultRequestObject";

const defaultTitleGetter = (request: unknown): string => {
  try {
    if (!request) {
      return "";
    }
    if (typeof request === "string") {
      if (request === "00.00.0000") return "";

      return request;
    }
    if (
      typeof request === "number" ||
      typeof request === "bigint" ||
      typeof request === "symbol"
    ) {
      return String(request);
    }
    if (Array.isArray(request)) {
      return request.map(defaultTitleGetter).filter(Boolean).join(" ");
    }
    if (typeof request === "object") {
      const reducesObjectEntries = reduceDefaultRequestObjectDates(
        Object.entries(request),
      );
      const sortedObjectEntries =
        sortDefaultRequestObject(reducesObjectEntries);

      return sortedObjectEntries
        .map(([_key, value]) => value)
        .map(defaultTitleGetter)
        .filter(Boolean)
        .join(" ");
    }
    return JSON.stringify(request, null);
  } catch {
    return "";
  }
};

const mapReportTypeToTitleGetter: Record<
  ReportType,
  (request: unknown) => string
> = {
  [ReportType.SEARCH_ADDRESS]: defaultTitleGetter,
  [ReportType.SEARCH_KAD_NUMBER]: defaultTitleGetter,
  [ReportType.SEARCH_CREDIT_REPORT]: defaultTitleGetter,
  [ReportType.SEARCH_FULL_CREDIT_REPORT]: defaultTitleGetter,
  [ReportType.SEARCH_EMAIL]: defaultTitleGetter,
  [ReportType.SEARCH_FIO]: defaultTitleGetter,
  [ReportType.SEARCH_GRN]: defaultTitleGetter,
  [ReportType.SEARCH_SCORING]: defaultTitleGetter,
  [ReportType.SEARCH_PHOTO_BY_IMAGE]: defaultTitleGetter,
  [ReportType.SEARCH_DRIVER_LICENSE]: defaultTitleGetter,
  [ReportType.SEARCH_INFO]: defaultTitleGetter,
  [ReportType.SEARCH_INN_FL]: defaultTitleGetter,
  [ReportType.SEARCH_INN_UL]: defaultTitleGetter,
  [ReportType.SEARCH_OGRN]: defaultTitleGetter,
  [ReportType.SEARCH_PASSPORT]: defaultTitleGetter,
  [ReportType.SEARCH_PHONE]: defaultTitleGetter,
  [ReportType.SEARCH_SNILS]: defaultTitleGetter,
  [ReportType.SEARCH_VIN]: defaultTitleGetter,
  [ReportType.SEARCH_TELEGRAM]: defaultTitleGetter,
  [ReportType.SEARCH_PHOTO_BY_FIO]: defaultTitleGetter,
  [ReportType.SEARCH_VK]: defaultTitleGetter,
};

export const getReportTypeToTitleGetter = (type: ReportType) =>
  mapReportTypeToTitleGetter[type] || defaultTitleGetter;
