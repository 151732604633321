import { Text } from "@ui/ui/Text/Text";
import { Flex } from "antd";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import { Expand } from "@ui/ui/Expand/Expand";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import { Friend } from "@shared/types/report/retrieve/responses/vkRetrieve";
import s from "./Item.module.scss";

interface ItemProps {
  title?: string;
  items?: Friend[];
  print?: RetrieveCardPrint;
}

const PIVOT = 6;

export const Item = ({ title, items, print }: ItemProps) => {
  const { state, toggle } = useBooleanState(false);
  let renderItems = items;
  if (!state) renderItems = items?.slice(0, PIVOT);
  if (print === RetrieveCardPrint.print) renderItems = items;

  return (
    <Flex vertical gap={16}>
      <Flex vertical gap={8}>
        <Text variant="body-1" color="secondary">
          {title}
        </Text>
        {renderItems &&
          renderItems?.length > 0 &&
          renderItems?.map((item) => (
            <a href={item.link} target="_blank" rel="noreferrer">
              <Text key={item.link} id={item.link} variant="body-5">
                {item.name}
              </Text>
            </a>
          ))}
        {(!renderItems || (renderItems && renderItems?.length < 1)) && (
          <Text variant="body-5">Не найдено</Text>
        )}
      </Flex>

      {print === RetrieveCardPrint.device && items && items?.length > PIVOT && (
        <div>
          <Expand
            state={state}
            toggle={toggle}
            iconSize={20}
            className={s.watchAll}
          >
            {state ? "Показать меньше" : "Показать все"}
          </Expand>
        </div>
      )}
    </Flex>
  );
};
