import { Text } from "@ui/ui/Text/Text";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import cn from "classnames";
import { IoSphereData, OpenSourcesFio } from "@shared/types/report/retrieve";
import { OpenSourcesCategories } from "@shared/types/report/constants";
import { Status } from "../Status/Status";
import s from "./Content.module.scss";

export interface ContentProps {
  openSources?: OpenSourcesFio | IoSphereData[];
  print?: RetrieveCardPrint;
  isFioSearch?: boolean;
  isPhoneSearch?: boolean;
}

export const Content = ({
  openSources,
  print = RetrieveCardPrint.device,
  isFioSearch,
  isPhoneSearch,
}: ContentProps) => (
  <div
    className={cn(
      s.container,
      print === RetrieveCardPrint.print && s.container_print,
    )}
  >
    <div
      className={cn(
        s.container__item,
        print === RetrieveCardPrint.print && s.container__item_print,
      )}
    >
      <Text color="secondary" variant="body-1">
        Источник
      </Text>
      <Text color="secondary" variant="body-1">
        Статус
      </Text>
    </div>

    {openSources &&
      Object.keys(openSources).map((openSource: string, index) => (
        <div
          className={cn(
            s.container__item,
            print === RetrieveCardPrint.print && s.container__item_print,
          )}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Text variant="body-5">
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
            {openSources[openSource]?.source
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                openSources[openSource]?.source
              : OpenSourcesCategories[openSource]}
          </Text>
          <Status
            isPhoneSearch={isPhoneSearch}
            isFioSearch={isFioSearch}
            // eslint-disable-next-line
            status={openSources[openSource]?.status}
            // eslint-disable-next-line
            found={openSources[openSource]?.found}
            // eslint-disable-next-line
            value={openSources[openSource]?.value}
          />
        </div>
      ))}
  </div>
);
