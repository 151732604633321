import { ChartData } from "chart.js";
import { env } from "@shared/env";
import { ChartDataType } from "./LineChart/LineChart";

export const mapAppToColors: Record<
  string,
  Record<
    "all" | "target",
    { borderColor: string; bgGradientTop: string; bgGradientBottom: string }
  >
> = {
  kretchet: {
    all: {
      /**
       * --color-main-1000
       */
      borderColor: "rgb(61, 101, 143)",
      /**
       * --color-main-300
       */
      bgGradientTop: "rgba(61, 101, 143, 0.3)",
      /**
       * --color-main-0
       */
      bgGradientBottom: "rgba(61, 101, 143, 0)",
    },
    target: {
      /**
       * --color-base-accident-1000
       */
      borderColor: "rgba(251, 139, 91, 1)",
      /**
       * --color-base-accident-300
       */
      bgGradientTop: "rgba(251, 139, 91, 0.3)",
      /**
       * --color-base-accident-0
       */
      bgGradientBottom: "rgba(251, 139, 91, 0)",
    },
  },
  odyssey: {
    all: {
      /**
       * --color-main-1000
       */
      borderColor: "rgb(0, 204, 105)",
      /**
       * --color-main-300
       */
      bgGradientTop: "rgba(0, 204, 105, 0.3)",
      /**
       * --color-main-0
       */
      bgGradientBottom: "rgba(0, 204, 105, 0)",
    },
    target: {
      /**
       * --color-base-accident-1000
       */
      borderColor: "rgb(251, 139, 91)",
      /**
       * --color-base-accident-300
       */
      bgGradientTop: "rgba(251, 139, 91, 0.3)",
      /**
       * --color-base-accident-0
       */
      bgGradientBottom: "rgba(251, 139, 91, 0)",
    },
  },
  odyssey_black: {
    all: {
      /**
       * --color-main-1000
       */
      borderColor: "rgb(18, 18, 18)",
      /**
       * --color-main-300
       */
      bgGradientTop: "rgba(18, 18, 18, 0.3)",
      /**
       * --color-main-0
       */
      bgGradientBottom: "rgba(18, 18, 18, 0)",
    },
    target: {
      /**
       * --color-base-accident-1000
       */
      borderColor: "rgb(251, 139, 91)",
      /**
       * --color-base-accident-300
       */
      bgGradientTop: "rgba(251, 139, 91, 0.3)",
      /**
       * --color-base-accident-0
       */
      bgGradientBottom: "rgba(251, 139, 91, 0)",
    },
  },
};

type ChatDataLineDataset = ChartData<"line", number[], string>["datasets"];

interface GetDatasetsParams {
  datasetsTarget?: ChartDataType["datasets"];
  datasetsAll?: ChartDataType["datasets"];
  height: number;
}

export const getDatasets = ({
  datasetsTarget,
  datasetsAll,
  height,
}: GetDatasetsParams): ChatDataLineDataset => {
  const { all, target } =
    mapAppToColors[env.REACT_APP_THEME] || mapAppToColors.odyssey;

  const preparedDatasetsAll: ChatDataLineDataset =
    datasetsAll?.map((dataset) => ({
      ...dataset,
      borderColor: all.borderColor,
      backgroundColor: (context) => {
        const gradient = context.chart.ctx.createLinearGradient(
          0,
          0,
          0,
          height,
        );

        gradient.addColorStop(0, all.bgGradientTop);

        gradient.addColorStop(1, all.bgGradientBottom);

        return gradient;
      },
      fill: true,
      tension: 0.4,
    })) || [];

  const preparedDatasetsTarget: ChatDataLineDataset =
    datasetsTarget?.map((dataset) => ({
      ...dataset,
      borderColor: target.borderColor,
      backgroundColor: (context) => {
        const gradient = context.chart.ctx.createLinearGradient(
          0,
          0,
          0,
          height,
        );

        gradient.addColorStop(0, target.bgGradientTop);

        gradient.addColorStop(1, target.bgGradientBottom);

        return gradient;
      },
      fill: true,
      tension: 0.4,
    })) || [];

  return [...preparedDatasetsAll, ...preparedDatasetsTarget];
};
