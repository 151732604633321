import { Flex } from "antd";
import { FC, useMemo } from "react";
import { Text } from "@ui/ui/Text/Text";
import { Expand } from "@ui/ui/Expand/Expand";
import { useBooleanState } from "@ui/hooks/useBooleanState";
import s from "./PieItems.module.scss";
import { DataItem } from "../types";

interface PieItemsProps {
  items: DataItem[];
}

export const PieItems: FC<PieItemsProps> = ({ items }) => {
  const { state: expanded, toggle: toggleExpanded } = useBooleanState(false);

  const itemsByExpanded = useMemo(
    () => ({ shortItems: items.slice(0, 5), expandedItems: items }),
    [items],
  );
  const visibleItems = useMemo(
    () =>
      expanded ? itemsByExpanded.expandedItems : itemsByExpanded.shortItems,
    [itemsByExpanded, expanded],
  );

  return (
    <Flex vertical gap={8}>
      <Text variant="subheader-1" className={s.container__title}>
        Популярные типы проверок
      </Text>
      <div className={s.container__content}>
        {visibleItems.map(({ value, name }) => (
          <>
            <Text variant="subheader-2">{value}</Text>
            <Text variant="body-2">{name}</Text>
          </>
        ))}
      </div>
      <Text variant="subheader-2" color="secondary">
        <Expand
          state={expanded}
          toggle={toggleExpanded}
          iconSize={20}
          className={s.container__expand}
        >
          Смотреть все
        </Expand>
      </Text>
    </Flex>
  );
};
