import { FC, useCallback } from "react";
import { Text } from "@ui/ui/Text/Text";

import { Flex } from "antd";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { Radio } from "@ui/ui/Radio/Radio";
import { allReportTypes } from "@ui/pages/Analytics/constants";
import { ReportType } from "@shared/types/report/constants";
import { mapReportTypeToLabel } from "@ui/utils/reports/mapReportTypeToLabel";

interface SettingsModalGraphReportTypeProps {
  modalValue: AnalyticsFilters;
  setModalValue: (
    value:
      | AnalyticsFilters
      | ((prevValue: AnalyticsFilters) => AnalyticsFilters),
  ) => void;
}

export const SettingsModalGraphReportType: FC<
  SettingsModalGraphReportTypeProps
> = ({ modalValue, setModalValue }) => {
  const onToggleAllGraphReportTypes = useCallback(
    (checked: boolean) => {
      if (!checked) return;

      setModalValue((prevFilters) => ({
        ...prevFilters,
        graphReportType: undefined,
      }));
    },
    [setModalValue],
  );

  const onToggleGraphReportType = useCallback(
    (graphReportType: ReportType, checked: boolean) => {
      if (!checked) return;

      setModalValue((prevFilters) => ({
        ...prevFilters,
        graphReportType,
      }));
    },
    [setModalValue],
  );

  return (
    <Flex vertical gap={12}>
      <Text variant="label-4">Сравнить общее число проверок с:</Text>
      <Flex vertical gap={8}>
        <Radio
          checked={modalValue.graphReportType === undefined}
          onUpdate={onToggleAllGraphReportTypes}
        >
          <Text>Показывать все</Text>
        </Radio>
        {allReportTypes.map((reportType) => (
          <Radio
            key={reportType}
            checked={modalValue.graphReportType === reportType}
            onUpdate={(checked) => onToggleGraphReportType(reportType, checked)}
          >
            <Text>{mapReportTypeToLabel[reportType]}</Text>
          </Radio>
        ))}
      </Flex>
    </Flex>
  );
};
