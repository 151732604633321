import cn from "classnames";
import { FC, ReactNode } from "react";
import { IoIosArrowForward } from "react-icons/io";
import s from "./Expand.module.scss";

export interface ExpandProps {
  state: boolean;
  toggle: () => void;
  children?: ReactNode;
  className?: string;
  iconSize?: number;
}

export const Expand: FC<ExpandProps> = ({
  state,
  toggle,
  children,
  className,
  iconSize = 24,
}) => (
  <button type="button" className={cn(s.container, className)} onClick={toggle}>
    {children}
    {state ? (
      <IoIosArrowForward size={iconSize} className={s.container__icon} />
    ) : (
      <IoIosArrowForward
        size={iconSize}
        className={cn(s.container__icon, s.container__icon_more)}
      />
    )}
  </button>
);
