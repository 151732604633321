import { VscSettings } from "react-icons/vsc";
import { PageHeader } from "@ui/app/layouts/PageHeader/PageHeader";
import { usePageSize } from "@ui/hooks/usePageSize";
import classNames from "classnames";
import { FC } from "react";
import { AppRoute } from "../../../app/routes/constants";
import { Button } from "../../../ui/Button/Button";
import s from "./Header.module.scss";

interface HeaderProps {
  onOpenSettings: () => void;
}

export const Header: FC<HeaderProps> = ({ onOpenSettings }) => {
  const { isMobile } = usePageSize();

  return (
    <PageHeader
      title="Аналитика"
      mapPathToTitle={{ [AppRoute.Analytics]: "Аналитика" }}
      paths={[AppRoute.Analytics]}
      rightElement={
        <Button
          type="secondary"
          size="m"
          className={classNames(s.container__button, {
            [s.container__button_only_icon]: isMobile,
          })}
          onClick={onOpenSettings}
          icon={<VscSettings className={s.container__button_icon} />}
        >
          {isMobile ? undefined : "Настроить временной отрезок"}
        </Button>
      }
    />
  );
};
