import { FC } from "react";
import { TooltipPropsWithTitle } from "antd/es/tooltip";
import { Tooltip as AntdTooltip } from "antd";
import classNames from "classnames";

type TooltipProps = TooltipPropsWithTitle;

export const Tooltip: FC<TooltipProps> = ({ className, ...props }) => (
  <AntdTooltip {...props} arrow={false} className={classNames(className)} />
);
