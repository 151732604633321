import { FC } from "react";
import cn from "classnames";
import { Text } from "@ui/ui/Text/Text";
import { Flex } from "antd";
import { IoAlertCircle } from "react-icons/io5";
import {
  ETagsSummaryKeys,
  ITagsItem,
} from "@shared/types/report/retrieve/tags/constants";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "@ui/components/RetrieveCard/RetrieveCard";
import { RetrieveCardPrint } from "@ui/components/RetrieveCard/types";
import s from "./Tags.module.scss";

type TTagsProps = {
  tags?: ITagsItem[];
} & RetrieveCardCommonProps;

export const Tags: FC<TTagsProps> = ({
  tags,
  status,
  print = RetrieveCardPrint.device,
}) =>
  tags &&
  tags.length > 0 && (
    <RetrieveCard dataId={8} title="Метки" status={status} print={print}>
      {tags.map((tag, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={cn(
            s.content,
            print === RetrieveCardPrint.print && s.content_print,
          )}
        >
          {tag[ETagsSummaryKeys.fio] && (
            <div className={s.content__item}>
              <Text color="secondary" variant="subheader-2">
                Имя
              </Text>
              <Text>{tag[ETagsSummaryKeys.fio].toUpperCase()}</Text>
            </div>
          )}
          {tag[ETagsSummaryKeys.birth_date] && (
            <div className={s.content__item}>
              <Text color="secondary" variant="subheader-2">
                Дата рождения
              </Text>
              <Text>{tag[ETagsSummaryKeys.birth_date]}</Text>
            </div>
          )}
          {tag[ETagsSummaryKeys.region] && (
            <div className={s.content__item}>
              <Text color="secondary" variant="subheader-2">
                Регион
              </Text>
              <Text>{tag[ETagsSummaryKeys.region].toUpperCase()}</Text>
            </div>
          )}
          {tag[ETagsSummaryKeys.phone] && (
            <div className={s.content__item}>
              <Text color="secondary" variant="subheader-2">
                Телефон
              </Text>
              <Text>{tag[ETagsSummaryKeys.phone].toUpperCase()}</Text>
            </div>
          )}
          {tag[ETagsSummaryKeys.phone_networks] && (
            <div className={s.content__item}>
              <Text color="secondary" variant="subheader-2">
                Дополнительные телефоны
              </Text>
              <Text>{tag[ETagsSummaryKeys.phone_networks].toUpperCase()}</Text>
            </div>
          )}
          {tag[ETagsSummaryKeys.tag] && (
            <div className={s.content__item}>
              <Text color="secondary" variant="subheader-2">
                Метки
              </Text>
              <Text>{tag[ETagsSummaryKeys.tag].toUpperCase()}</Text>
            </div>
          )}
        </div>
      ))}
      <div
        className={cn(
          s.note,
          print === RetrieveCardPrint.print && s.content_print,
        )}
      >
        <Flex align="center" gap={6}>
          <IoAlertCircle className={s.note__icon} size={17} />
          <Text color="secondary">
            Метка - запись со специальным значением, определяющая к какой
            категории относится/относилось проверяемое лицо. Информация собрана
            из различных источников и требует дополнительной проверки для
            подтверждения достоверности. К примеру, при появлении метки
            &quot;ранее судимый&quot; или &quot;наркоман&quot; рекомендуется
            запросить справку об отсутствии судимости или документ,
            подтверждающий, что проверяемое лицо не состоит/состояло на учете в
            психоневрологическом диспансере.
          </Text>
        </Flex>
      </div>
    </RetrieveCard>
  );
