import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { groups_create } from "../queries";
import { Create } from "../types";

export const useGroupsCreate = () =>
  useMutation({
    mutationFn: (data: Create) => groups_create(data),
    mutationKey: ["groupsCreate"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
