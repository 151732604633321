import { FC, useCallback, useMemo, useState } from "react";
import { Flex } from "antd";
import { Toggle } from "@ui/ui/Toggle/Toggle";
import { useCurrentUserQuery } from "@ui/app/api/groups/queries/useCurrentUser";
import { Permission } from "@ui/app/api/groups";
import { getMainTabToggleSettings } from "@ui/pages/Main/components/MainTabs/utils/getMainTabToggleSettings";
import { MainTabProps } from "../../types";
import { MainTabAutoNormal } from "./components/MainTabAutoNormal";
import { MainTabAutoVin } from "./components/MainTabAutoVin";

type MainTabAutoProps = MainTabProps;

export const MainTabAuto: FC<MainTabAutoProps> = ({ onSubmit }) => {
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.groups[0].permissions;

  const { shouldShowToggle, initialType } = useMemo(
    () =>
      getMainTabToggleSettings<"normal", "vin">({
        userPermissions,
        firstPermission: Permission.ReportGrnPermission,
        secondPermission: Permission.ReportVinPermission,
        firstType: "normal",
        secondType: "vin",
      }),
    [userPermissions],
  );

  const [type, setType] = useState<"vin" | "normal">(initialType);

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("vin");
    } else {
      setType("normal");
    }
  }, []);

  return (
    <Flex vertical gap={24}>
      {type === "normal" ? (
        <MainTabAutoNormal onSubmit={onSubmit} />
      ) : (
        <MainTabAutoVin onSubmit={onSubmit} />
      )}
      {shouldShowToggle && (
        <Toggle
          value={type === "vin"}
          onChange={onTypeChange}
          title="По номеру VIN"
        />
      )}
    </Flex>
  );
};
