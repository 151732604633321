import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { FC, useCallback } from "react";
import s from "./MainLayoutAlertsContent.module.scss";
import { Text } from "../../../../../../../ui/Text/Text";
import { AppRoute } from "../../../../../../routes/constants";
import AlertsList from "../../../../../../../ui/AlertsList/AlertsList";

interface MainLayoutAlertsContentProps {
  onClose: () => void;
}

export const MainLayoutAlertsContent: FC<MainLayoutAlertsContentProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();

  const handleShowAll = useCallback(() => {
    onClose();
    navigate(AppRoute.Alerts);
  }, [onClose, navigate]);

  return (
    <Flex vertical gap={16} className={s.alert}>
      <Text variant="label-4">Системные оповещения</Text>
      <Flex vertical gap={16} className={s.alert__content}>
        <AlertsList maxVisible={6} />
        <Flex
          justify="center"
          className={s.alert__content__showAllButtonContainer}
        >
          <Text
            variant="subheader-2"
            color="accent"
            className={s.alert__content__showAllButtonContainer__showAllButton}
            onClick={handleShowAll}
          >
            Показать все
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
