import { Text } from "@ui/ui/Text/Text";
import { ReactComponent as Success } from "src/assets/success_circle.svg";
import { ReactComponent as Alert } from "src/assets/alert_circle.svg";
import { ReactComponent as Cancel } from "src/assets/cancel.svg";
import { FoundStatus, ReportStatus } from "@shared/types/report/constants";
import { Check } from "@shared/types/report/retrieve";
import s from "./Status.module.scss";

export const Status = ({
  status,
  found,
  value,
  isFioSearch,
  isPhoneSearch,
}: Check) => {
  if (status === ReportStatus.InProgress)
    return <Text variant="body-5">Идет обработка</Text>;

  if (value && found === FoundStatus.found) {
    return (
      <div className={s.container}>
        <Success />
        <Text variant="body-5" color="success">
          {value}
        </Text>
      </div>
    );
  }

  return (
    <div className={s.container}>
      {found === FoundStatus.found && (
        <>
          {!isFioSearch || isPhoneSearch ? <Success /> : <Alert />}
          <Text
            variant="body-5"
            color={!isFioSearch || isPhoneSearch ? "success" : "red"}
          >
            Найден
          </Text>
        </>
      )}
      {found === FoundStatus.notFound && (
        <>
          {!isFioSearch || isPhoneSearch ? <Alert /> : <Success />}
          <Text
            variant="body-5"
            color={!isFioSearch || isPhoneSearch ? "red" : "success"}
          >
            Не найден
          </Text>
        </>
      )}
      {found === FoundStatus.error && (
        <>
          <Cancel />
          <Text variant="body-5" color="red">
            Что-то пошло не так
          </Text>
        </>
      )}
    </div>
  );
};
