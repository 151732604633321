import { useParams } from "react-router-dom";
import { useReportsScoringRetrieve } from "@ui/app/api/report/retrieve/mutations";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@ui/entities/types";
import { RetrieveScoring } from "@ui/entities/RetrieveScoring/RetrieveScoring";

export const SearchScoringWidget = ({
  type,
}: Omit<ReportCommonProps, "reportId">) => {
  const { reportId = "" } = useParams();

  const { data: scoringReport, isLoading } = useReportsScoringRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {scoringReport && (
        <RetrieveScoring
          report={scoringReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
