import { MainTab } from "@ui/pages/Main/constants";

export class MainTabHintsStorage {
  static get(tab: MainTab): boolean | null {
    try {
      const showHint = localStorage.getItem(`${tab}_tab`);
      return showHint ? (JSON.parse(showHint) as boolean) : null;
    } catch (e) {
      return null;
    }
  }

  static set(tab: MainTab, show: boolean) {
    localStorage.setItem(`${tab}_tab`, JSON.stringify(show));
  }
}
