import { FC } from "react";
import { Text } from "@ui/ui/Text/Text";
import s from "./CardChat.module.scss";

interface CardChatProps {
  date: string;
  title: string;
  message: string;
  onClick: () => void;
}

export const CardChat: FC<CardChatProps> = ({
  onClick,
  date,
  title,
  message,
}) => (
  <div className={s.card} onClick={onClick}>
    <div className={s.header}>
      <Text variant="body-2" color="secondary">
        {date}
      </Text>
    </div>
    <div className={s.text}>
      <Text variant="label-2" color="primary">
        {title}
      </Text>
      <Text variant="body-2" color="secondary">
        {message}
      </Text>
    </div>
  </div>
);
