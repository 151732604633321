import { useCallback, useState } from "react";

type UseSessionStorageReturn<T> = readonly [
  value: T,
  setValue: (value: T | ((val: T) => T)) => void,
];

export const mainTabFioFormKey = "mainTabFioFormKey";

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
): UseSessionStorageReturn<T> {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
}
