import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@ui/app/api/client";
import { AxiosError } from "axios";
import { UsersSetQuotaRequest, UsersSetQuotaResponse } from "../types";
import { usersSetQuota } from "../queries";

export const useUsersSetQuotaMutation = () =>
  useMutation<UsersSetQuotaResponse, AxiosError, UsersSetQuotaRequest>({
    mutationFn: (data: UsersSetQuotaRequest) => usersSetQuota(data),
    mutationKey: ["currentUser"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
