import { YMaps, Map as YMap, Placemark } from "@pbe/react-yandex-maps";
import { memo, useCallback, useRef, useState } from "react";
import { Skeleton } from "antd";
import { OrderMapItem } from "@shared/types/report/retrieve";
import { RetrieveCardPrint } from "../RetrieveCard/types";

export type Point = [number, number];

export interface YandexMapProps {
  center?: Point;
  orderMap?: OrderMapItem[];
  print?: RetrieveCardPrint;
}

export const YandexMap = memo(({ center, orderMap, print }: YandexMapProps) => {
  const mapRef = useRef<ymaps.Map | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handlePlacemarkClick = useCallback(
    async (coordinates: [number, number], content: string) => {
      if (mapRef.current) {
        await mapRef.current.balloon.open(coordinates, { content });
      }
    },
    [],
  );

  const handleMapLoad = () => {
    setIsLoading(false);
  };

  return (
    <YMaps>
      {isLoading && <Skeleton />}
      <YMap
        state={{ center, zoom: 9 }}
        width={print === RetrieveCardPrint.print ? "800px" : "100%"}
        height="400px"
        instanceRef={(ref) => {
          mapRef.current = ref;
          handleMapLoad();
        }}
      >
        {orderMap?.map((order, index) => (
          <Placemark
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            geometry={[Number(order.latitude), Number(order.longitude)]}
            properties={{
              iconCaption: `${order.street}, ${order.house}`,
            }}
            options={{
              preset: "islands#icon",
              iconColor: "#0095b6",
            }}
            onClick={() =>
              handlePlacemarkClick(
                [Number(order.latitude), Number(order.longitude)],
                `<b>${order.city}</b><br />${order.street}, ${order.house}${
                  order.flat ? `, кв. ${order.flat}` : ""
                }`,
              )
            }
          />
        ))}
      </YMap>
    </YMaps>
  );
});
