import { FC, useId } from "react";
import { DatePicker as AntdDatePicker } from "antd";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import ru_RU from "antd/lib/locale/ru_RU";
import dayjs from "dayjs";
import classNames from "classnames";
import s from "./DatePicker.module.scss";

const locale: PickerLocale = {
  ...ru_RU.DatePicker!,
  lang: {
    ...ru_RU.DatePicker!.lang,
    shortWeekDays: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
  },
};

interface DatePickerProps {
  className?: string;
  defaultValue?: dayjs.Dayjs;
  value?: dayjs.Dayjs;
  disabled?: boolean;
  onChange: (date: dayjs.Dayjs, dateString: string | string[]) => void;
}

export const DatePicker: FC<DatePickerProps> = ({
  className,
  disabled,
  value,
  defaultValue,
  onChange,
}) => {
  const id = useId();

  return (
    <AntdDatePicker
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      onChange={onChange}
      popupClassName={s.datepicker__popup}
      rootClassName={s.datepicker__root}
      className={classNames(s.datepicker, className)}
      format="DD.MM.YYYY"
      locale={locale}
      placeholder="дд.мм.гггг"
      id={id}
    />
  );
};
