import { FC, useCallback } from "react";
import { Text } from "@ui/ui/Text/Text";

import { Flex } from "antd";
import { AnalyticsFilters } from "@ui/pages/Analytics/types";
import { Checkbox } from "@ui/ui/Checkbox/Checkbox";
import { User } from "@ui/app/api/groups";

interface SettingsModalUserIdsProps {
  modalValue: AnalyticsFilters;
  setModalValue: (
    value:
      | AnalyticsFilters
      | ((prevValue: AnalyticsFilters) => AnalyticsFilters),
  ) => void;
  allUserIds: Set<number>;
  usersById: Record<User["user_id"], User>;
}

export const SettingsModalUserIds: FC<SettingsModalUserIdsProps> = ({
  modalValue,
  setModalValue,
  allUserIds,
  usersById,
}) => {
  const onToggleAllUserIds = useCallback(
    (checked: boolean) => {
      if (!checked && allUserIds.size <= 1) return;
      const defaultValue = [...allUserIds][0];
      setModalValue((prevFilters) => ({
        ...prevFilters,
        userIds:
          (prevFilters.userIds === undefined ||
            prevFilters.userIds.size === allUserIds.size) &&
          defaultValue
            ? new Set([defaultValue])
            : undefined,
      }));
    },
    [setModalValue, allUserIds],
  );

  const onToggleUserId = useCallback(
    (userId: number, checked: boolean) => {
      setModalValue((prevFilters) => {
        let userIds: Set<number> | undefined = prevFilters.userIds
          ? new Set(prevFilters.userIds)
          : new Set(allUserIds);

        if (checked) {
          userIds.add(userId);
        } else if (userIds && userIds.size > 1) {
          userIds.delete(userId);
        }
        if (userIds && userIds.size === allUserIds.size) {
          userIds = undefined;
        }
        return {
          ...prevFilters,
          userIds,
        };
      });
    },
    [setModalValue, allUserIds],
  );

  return (
    <Flex vertical gap={12}>
      <Text variant="label-4">Показывать проверки сотрудника:</Text>
      <Flex vertical gap={8}>
        <Checkbox
          checked={
            modalValue.userIds === undefined ||
            modalValue.userIds.size === allUserIds.size
          }
          onUpdate={onToggleAllUserIds}
        >
          Показывать все
        </Checkbox>
        {[...allUserIds].map((userId) => (
          <Checkbox
            key={userId}
            checked={
              modalValue.userIds === undefined || modalValue.userIds.has(userId)
            }
            onUpdate={(checked) => onToggleUserId(userId, checked)}
          >
            {usersById[userId]?.name || usersById[userId]?.login}
          </Checkbox>
        ))}
      </Flex>
    </Flex>
  );
};
