export enum Permission {
  SuperuserPermission = "SUPERUSER",
  AdminPermission = "ADMIN",
  MemberPermission = "MEMBER",

  ReportIncludeOpenSourcesPermission = "REPORT_INCLUDE_OPEN_SOURCES",
  ReportFioPermission = "REPORT_FIO",
  ReportPhonePermission = "REPORT_PHONE",
  ReportInnUlPermission = "REPORT_INN_UL",
  ReportInnFlPermission = "REPORT_INN_FL",
  ReportKadNumberPermission = "REPORT_KAD_NUMBER",
  ReportEmailPermission = "REPORT_EMAIL",
  ReportPassportPermission = "REPORT_PASSPORT",
  ReportSnilsPermission = "REPORT_SNILS",
  ReportVinPermission = "REPORT_VIN",
  ReportDriverLicensePermission = "REPORT_DRIVER_LICENSE",
  ReportOgrnPermission = "REPORT_OGRN",
  ReportAddressPermission = "REPORT_ADDRESS",
  ReportGrnPermission = "REPORT_GRN",
  ReportInfoPermission = "REPORT_INFO",
  ReportCreditRatingPermission = "REPORT_CREDIT_RATING",
  ReportFullCreditRatingPermission = "REPORT_FULL_CREDIT_RATING",
  ReportPhotoByFioPermission = "REPORT_PHOTO_BY_FIO",
  ReportPhotoByFacePermission = "REPORT_PHOTO_BY_FACE",
  ReportScoringPermission = "REPORT_SCORING",
  ReportTelegramPermission = "REPORT_TELEGRAM",
  ReportVKPermission = "VK_PERMISSION",

  AccessApiPermission = "ACCESS_API",
}

export interface Create {
  name: string;
}

export interface CreateResponse {
  group_id: number;
}

export interface Edit {
  group_id: number;
  name: string;
}

export interface GroupRequest {
  group_id: number;
}

export interface GroupsAddMemberRequest {
  expires_at: string;
  group_id: number;
  login: string;
  name: string;
  password?: string;
  request_quota?: number;
}

export interface GroupsAddMemberResponse {
  user_id: number;
}

export interface GroupsRemoveMemberRequest {
  group_id: number;
  user_id: number;
}

export type GroupsRemoveMemberResponse = undefined;

export interface GroupsGetPermissionsRequest {
  group_id: number;
}

export type GroupsGetPermissionsResponse = Permission[];

export interface GroupsGrantPermissionsRequest {
  group_id: number;
  permissions: Permission[];
}

export type GroupsGrantPermissionsResponse = undefined;

export interface GroupsGrantUserPermissionsRequest {
  group_id: number;
  permissions: Permission[];
  user_id: number;
}

export type GroupsGrantUserPermissionsResponse = undefined;

export interface GroupsSetNonRussianApiAllowedRequest {
  group_id: number;
  non_russian_ip_allowed: boolean;
}

export interface GroupsSetLoginDurationRequest {
  group_id: number;
  token_duration_seconds: number;
}

export interface EditMemberRequest {
  expires_at?: string;
  name: string;
  user_id: number;
}

export interface User {
  login: string;
  name: string;
  permissions: Permission[];
  request_quota?: number;
  suspended: boolean;
  user_id: number;
}

export interface Group {
  contract_date: string;
  contract_number: string;
  expires_at: string;
  group_id: number;
  name: string;
  non_russian_ip_allowed: boolean;
  permissions: Permission[];
  request_quota: null | string;
  request_usage: number;
  token_duration: string;
  users: User[];
}

export interface CurrentUser {
  expires_at: string;
  groups: {
    group_id: number;
    name: string;
    permissions: Permission[];
    request_quota: null;
    request_usage: number;
  }[];
  id: number;
  login: string;
  name: string;
  request_quota: null;
  request_usage: number;
}

export interface ChangePasswordRequest {
  new_password: string;
  old_password: string;
}

export interface ChangeUserPasswordRequest {
  password: string;
  user_id: number;
}

export interface GroupsReportsTotalCountRequest {
  group_id?: number;
  request_contains?: string;
  user_id?: number;
}

export interface GroupsReportsTotalCountResponse {
  cursor_created_at: string;
  total_count: number;
}

export interface UsersSetQuotaRequest {
  request_quota: number;
  user_id: number;
}

export interface UsersSetQuotaResponse {}

export interface UsersSetSuspendedRequest {
  suspended: boolean;
  user_id: number;
}

export type UsersSetSuspendedResponse = undefined;

export interface UsersRegisterRequest {
  email: string;
  full_name: string;
  job_title: string;
  organization_inn: string;
  organization_name: string;
  password: string;
  phone: string;
}

export interface UsersRegisterResponse {}

export interface UsersConfirmEmailRequest {
  email: string;
  verification_code: string;
}

export interface UsersConfirmEmailResponse {}

export interface UsersResendEmailRequest {
  email: string;
}

export interface UsersResendEmailResponse {}

export type UsersProfilePictureRequest = FormData;

export interface UsersProfilePictureResponse {}

export interface UsersProfilePictureDeleteRequest {
  user_id: number;
}

export interface UsersProfilePictureDeleteResponse {}

export interface UsersProfilePictureRetrieveRequest {
  user_id: number;
}

export type UsersProfilePictureRetrieveResponse = Blob;
